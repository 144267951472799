import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Pagination, TextField, Theme, Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SimpleSwitch from '../admin/common/OZSwitch/SimpleSwitch';
import '../ThresholdDrawer/ThresholdDrawer.css';
import WidgetSearch from '../Widget-Search/Widget-Search';

const useStyles: any = makeStyles((theme: Theme) => ({
	thresholdContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	thresholdHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	title?: string;
	thresholdItems?: any[];
	savedThresholdList?: any[] | undefined;
	showSkillListByDefault?: boolean;
};

const ThresholdDrawer = ({ isOpen, toggleDrawer, title, thresholdItems = [], savedThresholdList = undefined, showSkillListByDefault = false }: Props) => {
	const classes = useStyles();
	const { useState, useEffect } = React;
	const [thresholdList, setThresholdList] = useState<any[]>([]);
	const [displayedThresholds, setDisplayedThresholds] = useState<any[]>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage] = React.useState(10);
	const [showSkillList, setShowSkillList] = React.useState(false);
	const saveThreshold = () => {
		console.log("saveThreshold@@@@@@@@@@@@", thresholdItems);
		toggleDrawer(false, thresholdList, showSkillList);
	};

	const handleFieldValueChange = (event: any, itemIdx: number, fieldIdx: number) => {
		const updatedThresholdList = JSON.parse(JSON.stringify(thresholdList));
		updatedThresholdList[itemIdx].fields[fieldIdx].value = event.target.value;
		setThresholdList(updatedThresholdList);
	};

	const updateDisplayThresholds = (thresholdListWithIdx = thresholdList) => {
		const filtedDisplayThresholds = thresholdListWithIdx.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
		setDisplayedThresholds(filtedDisplayThresholds);
	};

	useEffect(() => {
		updateDisplayThresholds();
	}, [page, rowsPerPage]);

	useEffect(() => {
		if (isOpen) {
			const thresholdListWithIdx = thresholdItems.map((item, index) => ({ ...item, idx: index }));
			updateDisplayThresholds(thresholdListWithIdx);
			if (savedThresholdList !== undefined && savedThresholdList.length > 0) {
				setThresholdList(savedThresholdList);
				setShowSkillList(showSkillListByDefault);
			} else {
				setThresholdList(thresholdListWithIdx);
			}
		}
	}, [thresholdItems, isOpen, savedThresholdList !== undefined ? savedThresholdList : null]);

	const getThresholdFields = () => {
		return displayedThresholds?.map((item: any) => (
			<div key={item.idx} className="threshold-item">
				<p className="threshold-item-header">{item.label}</p>
				<Grid container spacing={2}>
					{item?.fields?.map((field: any, fieldIdx: number) => (
						<Grid item key={fieldIdx}>
							<p className="field-label">{field.label}</p>
							<TextField
								value={thresholdList[item.idx]?.fields[fieldIdx]?.value}
								onKeyDown={(evt) => ((!evt.metaKey && evt.key?.match(/[a-z]+/i)?.[0].length === 1) || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
								onChange={(e) => handleFieldValueChange(e, item.idx, fieldIdx)}
								className="threshold-field"
								variant="outlined"
								type="number"
							/>
						</Grid>
					))}
				</Grid>
				{/* <Grid container justifyContent="end">
					<Grid item>
						<span className="threshold-action" onClick={() => saveThreshold()}>
							Save
						</span>
						<span className="threshold-action" onClick={() => toggleDrawer(false)}>
							Cancel
						</span>
					</Grid>
				</Grid> */}
			</div>
		));
	};

	const getOrganisationalField = () => {
		return (
			<>
				{displayedThresholds?.map((item: any) => (
					<>
						{item.label === "Organization Level" &&
							<div key={item.idx} className="threshold-item">
								<p className="threshold-item-header">{item.label}</p>
								<Grid container spacing={2}>
									{item?.fields?.map((field: any, fieldIdx: number) => (
										<Grid item key={fieldIdx}>
											<p className="field-label">{field.label}</p>
											<TextField
												value={thresholdList[item.idx]?.fields[fieldIdx]?.value}
												onKeyDown={(evt) => ((!evt.metaKey && evt.key?.match(/[a-z]+/i)?.[0].length === 1) || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
												onChange={(e) => handleFieldValueChange(e, item.idx, fieldIdx)}
												className="threshold-field"
												variant="outlined"
												type="number"
											/>
										</Grid>
									))}
								</Grid>
								<Grid container justifyContent="end">
									<Grid item>
										<span className="threshold-action" onClick={() => { toggleDrawer(false); setShowSkillList(false) }}>
											Cancel
										</span>
										<span className="threshold-action" onClick={() => { saveThreshold(); setShowSkillList(false) }}>
											Save
										</span>
									</Grid>
								</Grid>
							</div>
						}
					</>
				))}
			</>
		);
	};

	const getThresholdFieldsNew = () => {
		return displayedThresholds?.map((item: any) => (
			<>
				{item.label !== "Organization Level" &&
					<div key={item.idx} className="threshold-item">
						<p className="threshold-item-header">{item.label}</p>
						<Grid container spacing={2}>
							{item?.fields?.map((field: any, fieldIdx: number) => (
								<Grid item key={fieldIdx}>
									<p className="field-label">{field.label}</p>
									<TextField
										value={thresholdList[item.idx]?.fields[fieldIdx]?.value}
										onKeyDown={(evt) => ((!evt.metaKey && evt.key?.match(/[a-z]+/i)?.[0].length === 1) || evt.key === '+' || evt.key === '-') && evt.preventDefault()}
										onChange={(e) => handleFieldValueChange(e, item.idx, fieldIdx)}
										className="threshold-field"
										variant="outlined"
										type="number"
									/>
								</Grid>
							))}
						</Grid>
					</div>
				}
			</>
		));
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage - 1);
	};

	const handleChangeSimpleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowSkillList(event.target.checked);
		// isOrgSkill
		let newThershod = thresholdList.map((item: any) => {
			if (item.label === "Organization Level") {
				item.isOrgSkill = !event.target.checked
			}
			return item
		})
		setThresholdList(newThershod)
	}

	const handleSearch = (event: any) => {
		const searchText = event?.target?.value;
		if (searchText?.length) {
			const filteredData = thresholdList.filter((item: any) => item.label.toLowerCase().includes(searchText.toLowerCase()));
			setDisplayedThresholds(filteredData);
		} else {
			setDisplayedThresholds(thresholdItems);
		}
	};

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => { toggleDrawer(false); setShowSkillList(false) }}>
			{(title !== 'Skill Summary' && title !== 'SLA' && title !== 'Queue Counter') ?
				<Box sx={{ width: 565 }} className={classes.thresholdContainer}>
					<div className={classes.drawerGutter}>
						<div className={classes.thresholdHeader}>
							<span className={classes.headerText}>{title} Threshold</span>
							<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
								<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
							</IconButton>
						</div>
					</div>
					{thresholdItems.length > 1 && (
						<Grid item>
							<WidgetSearch onTextChange={handleSearch}></WidgetSearch>
						</Grid>
					)}
					<div className="threshold-items-container">{getThresholdFields()}</div>
					<Box className="threshold-button-container">
						<div className="threshold-pagination">
							{thresholdItems.length > 10 && (
								<Pagination
									count={Math.ceil(thresholdList.length / rowsPerPage)}
									variant="outlined"
									shape="rounded"
									color="primary"
									page={page + 1}
									onChange={handleChangePage}
									siblingCount={0}
									boundaryCount={2}
								/>
							)}
						</div>
						<Button variant="contained" className="threshold-button" onClick={() => saveThreshold()}>
							Apply Threshold
						</Button>
					</Box>
				</Box>
				:
				<Box sx={{ width: 565 }} className={classes.thresholdContainer}>
					<div className={classes.drawerGutter}>
						<div className={classes.thresholdHeader}>
							<span className={classes.headerText}>{title} Threshold</span>
							<IconButton aria-label="delete" size="small" onClick={() => { toggleDrawer(false); setShowSkillList(false) }}>
								<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
							</IconButton>
						</div>
					</div>
					{thresholdItems.length > 1 && showSkillList && (
						<Grid item>
							<WidgetSearch onTextChange={handleSearch}></WidgetSearch>
						</Grid>
					)}

					<div style={{ display: 'flex', alignContent: 'center', marginTop: '15px' }}>
						<Typography sx={{
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '12px',
							lineHeight: '28px',
						}} flexGrow={1} >Split By Skills</Typography>
						<div>
							<SimpleSwitch
								name={'value'}
								checked={showSkillList}
								onChange={(e: any) => handleChangeSimpleSwitch(e)}
							/>
						</div>
					</div>

					<div className="threshold-items-container">{(showSkillList) ? getThresholdFieldsNew() : getOrganisationalField()}</div>

					{showSkillList &&
						<Box className="threshold-button-container">
							<div className="threshold-pagination">
								{thresholdItems.length > 10 && (
									<Pagination
										count={Math.ceil(thresholdList.length / rowsPerPage)}
										variant="outlined"
										shape="rounded"
										color="primary"
										page={page + 1}
										onChange={handleChangePage}
										siblingCount={0}
										boundaryCount={2}
									/>
								)}
							</div>
							<Button variant="contained" className="threshold-button" onClick={() => { saveThreshold(); setShowSkillList(true) }}>
								Apply Threshold
							</Button>
						</Box>
					}
				</Box>
			}
		</Drawer>
	);
};

export default ThresholdDrawer;
