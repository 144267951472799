import { Tooltip } from "@mui/material";
import { styled } from '@mui/system';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
        backgroundColor: '#FFFFFF !important',
        color: '#000000 !important',
        fontSize: '12px !important',
        fontWeight: '400 !important',
        borderRadius: '12px !important',
        padding: '6px 8px !important',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
        marginTop: '4px !important',
    },
}));

export default StyledTooltip;