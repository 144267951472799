import { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { ReactComponent as DropdownIcon } from '../../../../static/icons/dropdown.svg';
// import { ReactComponent as AddIcon } from '../../../../static/icons/addsquare.svg';
// import { ReactComponent as DeleteIcon } from '../../../../static/icons/deletenew.svg';
// import { ReactComponent as HamburgerIcon } from '../../../../static/icons/hamburger.svg';
// import { ReactComponent as SaveIcon } from '../../../../static/icons/save.svg';
// import { ReactComponent as RemoveIcon } from '../../../../static/icons/remove.svg';
import { ReactComponent as AddRoundIcon } from '../../../../static/icons/addround.svg';
// import { ReactComponent as EditIcon } from '../../../../static/icons/editpen.svg';
import '../AssignPriorities/assignPriorities.css';
import '../SendToCampaign/sendToCampaign.css';
import './deleteData.css';

export default function DeleteData({ activeTab }) {

    useEffect(() => {
        if (activeTab !== "Delete") {
            return;
        }
    }, [activeTab]);

    return (activeTab === "Delete" ?
        <Box className='oz-data-management-send-to-campaign-container'>
            <Box className="oz-data-management-send-to-campaign-header">
                <Typography className="oz-data-management-title">
                    Delete Records
                </Typography>
            </Box>
            <Box className="oz-data-management-send-to-campaign-content">
                <DeleteRecordsContent />
            </Box>
            <Box className="oz-data-management-send-to-campaign-footer">
                <Button
                    className='oz-data-management-send-to-campaign-cancel-button'
                    variant="outlined">Cancel</Button>
                <Button
                    className='oz-data-management-send-to-campaign-update-button'
                    variant="contained">Update </Button>
            </Box>
        </Box> : null
    )
}

const DeleteRecordsContent = () => {

    const [record, setRecord] = useState([{
        conditionsOperator: 'OR',
        tags: [],
        conditions: {
            field: '',
            operator: '',
            value: ''
        },
        mode: "edit",
    }]);


    return (
        <Box className="oz-data-management-send-to-campaign-content-container">
            <Box className="oz-data-management-send-to-campaign-content-header">
                <Typography className="oz-data-management-add-rules-title">
                    Choose Record
                </Typography>
                <Autocomplete
                    id="oz-data-management-send-to-campaign-rule-conditions-operator"
                    className='oz-data-management-send-to-campaign-rule-conditions-operator'
                    options={['AND', 'OR']}
                    value={record.conditionsOperator}
                    onChange={(event, newValue) => {
                        setRecord(prevRecord => {
                            const newRecord = { ...prevRecord };
                            newRecord.conditionsOperator = newValue;
                            return newRecord;
                        });
                    }}
                    clearIcon={null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Operator"
                            sx={{
                                width: "64px",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                    '&:hover fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                },
                            }}
                        />
                    )}
                    popupIcon={<DropdownIcon />}
                    size='small'
                    renderOption={(props, option, { selected }) => {
                        return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                            <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                {option}
                            </Typography>
                        </li>
                    }
                    } />
                {/* <IconButton onClick={handleClickAddRules}>
                    <AddIcon />
                </IconButton> */}
            </Box>
            <Box className="oz-data-management-send-to-campaign-rule-container">
                {record.mode === 'edit' ? <Box className="oz-data-management-send-to-campaign-rule-content">
                    <Box className="oz-data-management-send-to-campaign-rule-condition-container">
                        <Box className="oz-data-management-send-to-campaign-rule-condition-content">
                            <Box className="oz-data-management-send-to-campaign-rule-condition-item">
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
                                    <Autocomplete
                                        id="oz-data-management-send-to-campaign-rule-condition-field"
                                        options={['Field 1', 'Field 2', 'Field 3']}
                                        value={record.conditions.field}
                                        onChange={(event, newValue) => {
                                            setRecord(prevRecord => {
                                                const newRecord = { ...prevRecord };
                                                newRecord.conditions.field = newValue;
                                                return newRecord;
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Choose tag"
                                                sx={{
                                                    minWidth: "170px",
                                                    background: "#FFFFFF",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderRight: "0px",
                                                            borderTopRightRadius: "0px",
                                                            borderBottomRightRadius: "0px"
                                                        },
                                                        '&:hover fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderRight: "0px",
                                                            borderTopRightRadius: "0px",
                                                            borderBottomRightRadius: "0px"
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderRight: "0px",
                                                            borderTopRightRadius: "0px",
                                                            borderBottomRightRadius: "0px"

                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                        popupIcon={<DropdownIcon />}
                                        size='small'
                                        renderOption={(props, option, { selected }) => {
                                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                                    {option}
                                                </Typography>
                                            </li>
                                        }} />
                                    <Autocomplete
                                        id="oz-data-management-send-to-campaign-rule-condition-operator"
                                        options={['EQUALS', 'NOT_EQUALS', 'CONTAINS', 'STARTS_WITH', 'ENDS_WITH', 'MATCHES']}
                                        value={record.conditions.operator}
                                        onChange={(event, newValue) => {
                                            setRecord(prevRecord => {
                                                const newRecord = { ...prevRecord };
                                                newRecord.conditions.operator = newValue;
                                                return newRecord;
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Operator"
                                                sx={{
                                                    minWidth: "200px",
                                                    background: "#FFFFFF",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "0px",
                                                            borderRight: "0px",
                                                        },
                                                        '&:hover fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "0px",
                                                            borderRight: "0px",
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "0px",
                                                            borderRight: "0px",
                                                            borderBottomLeftRadius: "0px",
                                                            borderBottomRightRadius: "0px"
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                        popupIcon={<DropdownIcon />}
                                        size='small'
                                        renderOption={(props, option, { selected }) => {
                                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                                    {option}
                                                </Typography>
                                            </li>
                                        }} />
                                    <Autocomplete
                                        id="oz-data-management-send-to-campaign-rule-condition-value"
                                        options={['Value 1', 'Value 2', 'Value 3']}
                                        value={record.conditions.value}
                                        onChange={(event, newValue) => {
                                            setRecord(prevRecord => {
                                                const newRecord = { ...prevRecord };
                                                newRecord.conditions.value = newValue;
                                                return newRecord;
                                            });
                                        }}
                                        clearIcon={null}
                                        sx={{
                                            minWidth: "222px",
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select Attribute"
                                                sx={{
                                                    minWidth: "222px",
                                                    background: "#FFFFFF",
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderTopLeftRadius: "0px",
                                                            borderBottomLeftRadius: "0px"
                                                        },
                                                        '&:hover fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderTopLeftRadius: "0px",
                                                            borderBottomLeftRadius: "0px"
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: "1px solid #D6D6D7",
                                                            borderRadius: "8px",
                                                            borderTopLeftRadius: "0px",
                                                            borderBottomLeftRadius: "0px"
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                        popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                            <AddRoundIcon />
                                        </IconButton>}
                                        size='small'
                                        renderOption={(props, option, { selected }) => {
                                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                                    {option}
                                                </Typography>
                                            </li>
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box> : record.mode === "view" ? <Box className='oz-data-management-send-to-campaign-rule-view-content'>
                </Box> : null
                }
            </Box >
        </Box >
    )
}
