import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton, Link, Menu, MenuItem, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import React,{useRef} from 'react';
import OZCheckbox from '../OZCheckbox/OZCheckbox';

import '../FilterDrawer/FilterDrawer.css';
import { getFilters } from '../../services/api-service';
import { DynamicfilterTypes, LocationfilterTypes, filterTypes } from '../../utils/constants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const useStyles: any = makeStyles((theme: Theme) => ({
	filterContainer: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column'
	},
	filterHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	headerText: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '21px',
		color: '#212121'
	},
	drawerGutter: {
		paddingTop: '74px'
	}
}));

type Props = {
	toggleDrawer: any;
	isOpen: boolean | undefined;
	allowedFilters?: string[];
	applyFilters?: any;
	existingSelectedFilters?: any[];
	existingFilterCategory?: any | undefined;
	applyFilterOnExistingData?: any;
	additionalApiParams?: any;
	filterType?: any;
	NewWidget?: any;
	DynamicWidget?: any;
	setResponseLength?: any;
	RunningStatusWidget?: any;
};

const FilterDrawer = ({ isOpen, toggleDrawer, NewWidget, allowedFilters, applyFilters = () => { }, applyFilterOnExistingData = () => { }, existingSelectedFilters = [], existingFilterCategory, additionalApiParams = {}, setResponseLength = () => { }, filterType='', RunningStatusWidget, DynamicWidget}: Props) => {
	console.log("here allowd ", allowedFilters, filterType);
	const classes = useStyles();
	const { useState, useEffect } = React;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [selectedFilters, setSelectedFilters] = useState<any[]>(existingSelectedFilters || []);
	const [searchText, setSearchText] = useState<string>('');
	const searchInputRef = useRef<any>();
	// const [selectAllClicked, setSelectAllClicked] = useState(false);
	const [expandedGroups, setExpandedGroups] = useState<{ [key: string]: boolean }>({});

	const [filters, setFilters] = useState([]);
	const [loading, setLoading] = useState(false);

	const allowedFilterCategoryList = allowedFilters?.length ? NewWidget ? LocationfilterTypes.filter((item) => allowedFilters.includes(item.value)) : DynamicWidget ? DynamicfilterTypes.filter((item) => allowedFilters.includes(item.value)) : filterTypes.filter((item) => allowedFilters.includes(item.value)) : [...filterTypes];
	// const allowedFilterCategoryList = allowedFilters?.length ? NewWidget ? LocationfilterTypes.filter((item) => allowedFilters.includes(item.value)) : filterTypes.filter((item) => allowedFilters.includes(item.value)) : [...filterTypes];
	const existingFilteredList = existingFilterCategory ? allowedFilterCategoryList?.filter(item => item.id === existingFilterCategory) : [];
	const [filterSection, setFilterSection] = useState(existingFilteredList?.length > 0 ? existingFilteredList[0] : allowedFilterCategoryList[0]);

	const [fullLengthForSelectedFilters, setFullLengthForSelectedFilters] = useState<any>(false);

	const filterCategoryElements = allowedFilterCategoryList.map((item: any, index: number) => (
		<MenuItem key={index} className="download-item" onClick={() => handleClose(item)}>
			{item.label}
		</MenuItem>
	));

	const handleOnCheckboxChange = (event: any) => {
		// setSelectAllClicked(false);
		if (event?.target?.checked && event?.target?.value) {
			const existingSelectedFilters: any = [...selectedFilters];
			existingSelectedFilters.push(event.target.value);
			setSelectedFilters(existingSelectedFilters);
		} else {
			const newSelectedFilters: any = selectedFilters.filter((item) => item.id !== event?.target?.value?.id);
			setSelectedFilters(newSelectedFilters);
		}
	};

	const handleOnCheckboxChangeGroup = (event: any, campaignType: string = "") => {
		const isGroupSelection = Boolean(campaignType);
		let existingSelectedFilters: any = [...selectedFilters];
	
		if (isGroupSelection) {
			const campaigns = groupedCampaigns[campaignType]; 
			if (event?.target?.checked && event?.target?.value) {
				campaigns.forEach((campaign: any) => {
					if (!existingSelectedFilters.some((filter: any) => filter.id === campaign.Id)) {
						existingSelectedFilters.push({ id: campaign.Id, label: campaign.Name });
					}
				});
			} else {
				existingSelectedFilters = existingSelectedFilters.filter(
					(filter: any) => !campaigns.some((campaign: any) => campaign.Id === filter.id)
				);
			}
		} else {
			if (event?.target?.checked && event?.target?.value) {
				existingSelectedFilters.push({ id: event.target.value });
			} else {
				existingSelectedFilters = existingSelectedFilters.filter(
					(filter: any) => filter.id !== event?.target?.value
				);
			}
		}
	
		setSelectedFilters(existingSelectedFilters);
	};	

	const isChecked = (item: any) => {
		return selectedFilters.some((filterItem) => item.Id === filterItem.id);
	};


	const filterItems = searchText.length ? filters.filter((item: any) => item.Name && item.Name.toLowerCase().includes(searchText.toLowerCase())) : filters;

	// const filterItems = searchText.length ? filters.filter((item: any) => item.Name.toLowerCase().includes(searchText.toLowerCase())) : filters;

	const filterItemListElements = filterItems?.map((item: any, index: number) => (
		<Grid key={index} item xs={6} className="filter-checkbox-col">
			<OZCheckbox checked={isChecked(item)} label={item.Name} id={item.Id} onChange={handleOnCheckboxChange} />
		</Grid>
	));
	const handleClose = (type: any = null) => {
		setLoading(true);
		if (type) {
			setFilterSection(type);
			clearAll();
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
		//:::::This code extra written to avoid latency in the dropdown:::::://
		setTimeout(() => {
			setLoading(false);
		}, 1000);
		//::::::This code extra written to avoid latency in the dropdown:::::://
		setAnchorEl(null);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSearchTextChange = (event: any) => {
		setSearchText(event?.target?.value);
	};

	const handleDoubleClick = () => {
		if (searchInputRef.current) {
			searchInputRef.current.select();
		}
	};

	const isGroupChecked = (campaignType: string) => {
		const campaigns = groupedCampaigns[campaignType];
		return campaigns.every((item: any) => selectedFilters.some((filterItem) => item.Id === filterItem.id));
	};

	const getFilterData = async () => {
		if (!filterSection?.value) return;
		let runningStatusFilterSection = {
			"id": 1,
			"label": "Campaign",
			"value": "Campaign"
		};
		let response : any = [];

		if(RunningStatusWidget){
			response = await getFilters(filterType ? filterType : runningStatusFilterSection.value, additionalApiParams);
		}else{
			response = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
		}
		setFilters(response);
		setResponseLength(response.length)
	};

	const groupByCampaignType = (items: any) => {
		return items.reduce((acc: any, item: any) => {
			if (!acc[item?.CampaignType]) {
				acc[item?.CampaignType] = [];
			}
			acc[item?.CampaignType].push(item);
			return acc;
		}, {});
	};
	
	const groupedCampaigns = groupByCampaignType(filterItems);
	
	//:::::::::this is for test ::::::::::::::::::://
	// // JSX structure for displaying grouped campaigns
	// const renderGroupedCampaigns = (groupedCampaigns: any) => {
	// 	return Object.keys(groupedCampaigns).map((campaignType, index) => (
	// 		<div key={index} className="campaign-group">
	// 			{/* <Typography variant="h6" style={{ fontWeight: "bold" }}>
	// 				{campaignType}
	// 			</Typography> */}
	// 			<div className="campaign-type-header">
	// 				<OZCheckbox
	// 					id={`group_${campaignType}`}
	// 					checked={isGroupChecked(campaignType)}
	// 					label={<Typography sx={{fontSize: '14px',fontWeight: 600, color: '#212121'}}>{campaignType}</Typography>}
	// 					// label={`Group_${campaignType}`}
	// 					onChange={(event: any) => handleOnCheckboxChangeGroup(event, campaignType)}
	// 				/>
	// 			</div>
	// 			<Grid container spacing={2} sx={{paddingLeft: 4, paddingTop: 1}}>
	// 				{groupedCampaigns[campaignType].map((item: any) => (
	// 					<Grid key={item.Id} item xs={6} className="filter-checkbox-col">
	// 						<OZCheckbox 
	// 							checked={isChecked(item)} 
	// 							label={item.Name} 
	// 							id={item.Id} 
	// 							onChange={handleOnCheckboxChange} 
	// 						/>
	// 					</Grid>
	// 				))}
	// 			</Grid>
	// 		</div>
	// 	));
	// };
	//:::::::::this is for test ::::::::::::::::::://


	//:::::::::this is new custom Making ::::::::::::::::::://
	const renderGroupedCampaigns = (groupedCampaigns: any) => {

		const toggleGroupVisibility = (campaignType: string) => {
		  setExpandedGroups((prev) => ({
			...prev,
			[campaignType]: !prev[campaignType],
		  }));
		};
	  
		return Object.keys(groupedCampaigns).map((campaignType, index) => (
		  <div key={index} className="campaign-group">
			<div className="campaign-type-header" style={{ display: 'flex', alignItems: 'center' }}>
			  <OZCheckbox
				id={`group_${campaignType}`}
				checked={isGroupChecked(campaignType)}
				label={
				  <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#212121' }}>
					{campaignType}
				  </Typography>
				}
				onChange={(event: any) => handleOnCheckboxChangeGroup(event, campaignType)}
			  />
			  <IconButton onClick={() => toggleGroupVisibility(campaignType)} size="small">
				{expandedGroups[campaignType] ? <ExpandLess /> : <ExpandMore />}
			  </IconButton>
			</div>
	  
			{expandedGroups[campaignType] && (
			  <Grid container spacing={2} sx={{ paddingLeft: 4, paddingTop: 1 }}>
				{groupedCampaigns[campaignType].map((item: any) => (
				  <Grid key={item.Id} item xs={6} className="filter-checkbox-col">
					<OZCheckbox
					  checked={isChecked(item)}
					  label={item.Name}
					  id={item.Id}
					  onChange={handleOnCheckboxChange}
					/>
				  </Grid>
				))}
			  </Grid>
			)}
		  </div>
		));
	};

	//:::::::::this is new custom Making ::::::::::::::::::://

	const selectAll = () => {
		// setSelectAllClicked(true);
		setSelectedFilters(filterItems.map((item: any) => ({ id: item.Id, label: item.Name })));
	};
	const clearAll = () => {
		setSelectedFilters([]);
		setFullLengthForSelectedFilters(false);
	};

	function checkValidForLength(filtersSelected: any, filters: any) {
		const selectedLength = filtersSelected.length;
		const filtersLength = filters.length;
	
		if (selectedLength === filtersLength) {
			setFullLengthForSelectedFilters(false);
			return true;
		}
		if (selectedLength > 50) {
			setFullLengthForSelectedFilters(true);
			return false;
		}
		if (selectedLength > 0 && selectedLength <= 50) {
			setFullLengthForSelectedFilters(false);
			return true;
		}
		return false;
	}

	const handleApplyFilters = () => {
		// if (selectedFilters.length > 10 && !selectAllClicked) {
		// 	alert("Cannot apply more than 10 filters.");
		// 	return;
		// }
		if(selectedFilters.length <= 0){
			applyFilters({ selectedFilters, filterType: filterSection.id , filterSection: filterSection });
		}else if(selectedFilters.length > 0){
			let lengthCheck = checkValidForLength(selectedFilters, filters);
			if(lengthCheck){
				applyFilters({ selectedFilters, filterType: filterSection.id , filterSection: filterSection });
			}
		}
	};
	useEffect(() => {
		setSelectedFilters(existingSelectedFilters);
	}, [existingSelectedFilters]);

	React.useEffect(() => {
		if(existingSelectedFilters?.length >0){
			getFilteredSelectedData();
		}
	}, []);

	const getFilteredSelectedData = async () => {
		if (!filterSection?.value) return;
		const responseData: any = await getFilters(filterType ? filterType : filterSection.value, additionalApiParams);
		const filteredSelectedFilters = selectedFilters.filter((filter: any) =>
			responseData.some((responseFilter: any) => responseFilter.Id === filter.id)
		);
		applyFilterOnExistingData(filteredSelectedFilters);
		setSelectedFilters(filteredSelectedFilters)
	};
	
	useEffect(() => {
		if (isOpen) {
			getFilterData();
		}
		return () => { };
	}, [isOpen, filterSection]);

	useEffect(() => {
		checkValidForLength(selectedFilters, filters)
	}, [isOpen]);

	return (
		<Drawer open={isOpen} anchor={'right'} onClose={() => toggleDrawer(false)}>
			<Box sx={{ width: 455 }} className={classes.filterContainer}>
				<div className={classes.drawerGutter}>
					<div className={classes.filterHeader}>
						<span className={classes.headerText}>Filter</span>
						<IconButton aria-label="delete" size="small" onClick={() => toggleDrawer(false)}>
							<CloseIcon style={{ fontSize: '22px', color: '#99a0a8' }} />
						</IconButton>
					</div>
					<div className="drawer-search-container">
						<>
							<div className="drawer-search-dd" onClick={handleMenuOpen}>
								{RunningStatusWidget && <span>Campaign</span>}
								{!RunningStatusWidget && <span>{filterSection.label}</span> }
								{/* <span>{filterSection.label}</span> */}
							</div>
							{!RunningStatusWidget &&
								<Menu
									id="download-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left'
									}}
									open={open}
									onClose={() => handleClose()}
									className="custom-search-dropdown"
								>
									{filterCategoryElements}
								</Menu>
							}
						</>
						<>
							<div className="drawer-search-bar">
								<input type="text" ref={searchInputRef} placeholder="Search" value={searchText} onChange={handleSearchTextChange} onDoubleClick={handleDoubleClick} />
								<img src={`${process.env.PUBLIC_URL}/img/search-icon.svg`} width="12" alt="Filter Search"></img>
							</div>
						</>
					</div>
				</div>
				<div>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className="filter-select-links">
								<Link onClick={() => selectAll()} underline="hover">
									Select All
								</Link>
								<Link onClick={() => clearAll()} underline="hover">
									Clear All
								</Link>
							</div>
							{fullLengthForSelectedFilters && <div style={{fontSize: '12px', fontWeight: 400, marginBottom: '10px', paddingTop: -5, color: '#FF5733'}}>Maximum 50 filters can be selected</div>}
						</Grid>
					</Grid>
				</div>
				<div className="filter-items-container">
					{/* <Grid container spacing={2}>
						<>{filterItemListElements}</>
					</Grid> */}
					<div className="filter-items-container">
						{loading &&
							<>
								...Loading
							</>
						}
						{(filterSection.label === 'Campaign' || RunningStatusWidget) && !loading && groupedCampaigns && Object.keys(groupedCampaigns).length > 0 &&
							<>{renderGroupedCampaigns(groupedCampaigns)}</>
						}
						{filterSection.label !== 'Campaign' && !RunningStatusWidget && !loading &&
							<Grid container spacing={2}>
								<>{filterItemListElements}</>
							</Grid> 
						}
					</div>
				</div>
				<div className="filter-items-footer">
					<Button variant="contained" onClick={() => handleApplyFilters()}>
						Apply Filter
					</Button>
					{/* <div className="footer-line">
						<img src={`${process.env.PUBLIC_URL}/img/info-icon.svg`} width="12" alt="Info"></img> Adding more than 5 filters will take you to table view
					</div> */}
				</div>
			</Box>
		</Drawer>
	);
};

export default FilterDrawer;
