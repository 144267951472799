import { useEffect, useMemo, useState } from 'react'
import { Autocomplete, Backdrop, Box, Button, IconButton, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography, Grid, Chip, FormControl } from '@mui/material'
import AgentSelector, { DropDownIcon } from './AgentSelector';
import { ReactComponent as DeleteIcon } from '../../../static/icons/deletenew.svg';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { getsipLocation, bulkUpdateSettings, getSkillFlow } from '../../../services/api-service';
import './bulkupdate.css'
import { LoadingButton } from '@mui/lab';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { checkRoles, checkSettings } from '../../../utils/util';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import BulkUpdateAutoComplete from './BulkUpdateAutoComplete';

const commonAutocompleteProps = {
    popupIcon: <DropDownIcon />,
    placeholder: "Select Setting",
    size: 'small',
    isOptionEqualToValue: (option, value) => option === value,
    clearIcon: null,
    disableCloseOnSelect: true,
    componentsProps: { paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } } },
    renderOption: (props, option, { selected, inputValue }) => <Box {...props} sx={{
        paddingLeft: "10px !important",
    }} className="oz-admin-bulk-update-content-body-controls-item-select-option">
        <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} width={"100%"} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>{option}</Typography>
    </Box>,
    renderInput: (params) => <TextField placeholder='Select'
        sx={{
            '& .MuiOutlinedInput-root': { fontSize: "14px", color: "#3D4C5E", borderRadius: '8px', width: '175px', minHeight: "40px" },
            minHeight: "40px"
        }}
        {...params}
    />
}

const minimumLevelNeededForSetting = {
    "Conference": 3,
    "Transfer": 4,
    "Agent Modes": 2,
    "Auto Answer Calls": 2,
    "SIP Location": 2,
    "Call Controller": 2,
    // "Skills": 3,
}

export default function BulkUpdate() {
    const tabs = ['Agents']
    const [activeMode, setActiveMode] = useState('Agents');
    const [formValues, setFormValues] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [sipLocationList, setSipLocationList] = useState([]);
    const [skillsList, setSkillsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const canEditAgent = useMemo(() => {
        // return authPage(AdminPages.AGENTS_EDIT)
        return (newAuthPages(NewAdminPages.BULK_UPDATE) || newAuthSubAdminAccess(NewAdminPages.BULK_UPDATE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.BULK_UPDATE, 'Full'))
    }, [])

    const [isBulkUpdateSettingsEnabled, setIsBulkUpdateSettingsEnabled] = useState(false);

    useEffect(() => {
        const checkBulkUpdateSettings = async () => {
            setLoading(true);
            try {
                const resp = await checkSettings("BULK_UPDATE_OPTIONS");
                console.log("checkBulkUpdateSettings ~ resp:", resp)
                setIsBulkUpdateSettingsEnabled(resp)
            }
            catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
        checkBulkUpdateSettings();
    }, [])

    const settingsNameMap = useMemo(() => {
        let defaultSettings = {
            "Call Controller": "callControl",
            "Agent Modes": "agentModes",
            // "Auto Answer Calls": "autoAnswer",
            "SIP Location": "sipLocation",
            // "Skills": 'skills',
            // "On Call": "onCall",
            // "Screen Recording": "screenRecording",
        }
        const isAdmin = checkRoles("ROLE_ADMIN");
        if (isAdmin || checkRoles("ROLE_SIP")) {
            defaultSettings["Auto Answer Calls"] = "autoAnswer"
        }
        if (isAdmin || checkRoles("ROLE_AGENT_ONCALL")) {
            defaultSettings["On Call"] = "onCall"
        }
        if (isAdmin || checkRoles("ROLE_SCREEN_RECORDING")) {
            defaultSettings["Screen Recording"] = "screenRecording"
        }

        return defaultSettings;

    }, [])

    const selected = useMemo(() => [...selectedAgents, ...selectedGroups], [selectedAgents, selectedGroups])
    const ellipsis = useMemo(() => ({ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "100%", }), [])
    const handleChange = (event, selectedTab) => setActiveMode(selectedTab)
    const handleClickAdd = () => setFormValues((prev) => (prev.length === 0 ? [{ index: 0, value: null, path: null, enabled: true }] : [...prev, { index: prev[prev.length - 1].index + 1, value: null, path: null, enabled: true }]))
    const handleRemoveItem = (index) => setFormValues((prev) => prev.filter((item) => item.index !== index))

    useEffect(() => {
        getSipLocationsList();
        getFullSkillsList();
    }, [])


    async function getFullSkillsList() {
        try {
            const resp = await getSkillFlow();
            if (resp.Status === "Success" && resp.Data && resp.Data.length > 0) {
                setSkillsList(resp.Data.reduce((acc, item) => {
                    acc.push({ skillName: item.skillName, id: item.id });
                    return acc;
                }, []));
                setAvailableSkills(resp.Data.reduce((acc, item) => {
                    acc.push({ skillName: item.skillName, id: item.id });
                    return acc;
                }, []));
            } else {
                console.log("Unable to get skill list", resp);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    async function getSipLocationsList() {
        try {
            const resp = await getsipLocation();
            if (resp.Status === "Success" && resp.Data && resp.Data.length > 0) {
                setSipLocationList(resp.Data.reduce((acc, item) => {
                    acc.push({ location: item.location, id: item.id });
                    return acc;
                }, []))
            } else {
                console.log("Unable to get sip location list", resp);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormSubmit = () => {
        console.log("formValues", formValues, rows, merge);
        if (selected.length === 0) {
            showErrorNotification("Please select at least one agent or group to apply settings")
            return;
        }
        // if (formValues.length === 0) {
        //     showErrorNotification("Please select at least one setting to apply")
        //     return;
        // }
        if (formValues.length === 0 && rows && rows[0].skill?.length === 0 && rows[0].priority === '') {
            showErrorNotification("Please select at least one setting to apply")
            return;
        }
        const invalidValues = formValues.filter((item) => !item.value || !item.path)
        if (invalidValues.length > 0) {
            showErrorNotification("Please select valid settings to apply")
            return;
        }
        const minLevels = Object.keys(minimumLevelNeededForSetting)
        for (let i = 0; i < minLevels.length; i++) {
            const setting = minLevels[i];
            const invalidValues = formValues.filter((item) => item[setting === 'Conference' || setting === 'Transfer' ? 'subValueLvl1' : 'value'] === setting && item.path.split('.').length < minimumLevelNeededForSetting[setting])
            if (invalidValues.length > 0) {
                showErrorNotification(`Please select valid values for ${setting} settings`)
                return;
            }
        }

        let values = {};
        // for (let i = 0; i < formValues.length; i++) {
        //     if(formValues[i].value === "Skills"){
        //         values.skills = formValues[i].subValueLvl1;
        //         values.Merge = formValues[i].Merge;
        //         values.priority = formValues[i].subValueLvl2;
        //     }
        //     else{
        //         const maxLevel = formValues[i].path.split('.').length;
        //         let current = values;
        //         for (let j = 0; j < maxLevel; j++) {
        //             let path = formValues[i].path.split('.')[j];
        //             path = path.charAt(0).toLowerCase() + path.slice(1);
        //             if (j === maxLevel - 1) {
        //                 try {
        //                     current[formValues[i].path.split('.')[j - 1]] = JSON.parse(path);
        //                 } catch (e) {
        //                     if (formValues[i][`subValueLvl${j}`]) {
        //                         current[path] = formValues[i].enabled;
        //                     } else if (maxLevel === 1) {
        //                         current[path] = formValues[i].enabled;
        //                     }
        //                 }
        //             } else {
        //                 current[path] = current[path] || {};
        //                 current = current[path];
        //             }
        //         }
        //     }
        // }

        for (let i = 0; i < formValues.length; i++) {
            const maxLevel = formValues[i].path.split('.').length;
            let current = values;
            for (let j = 0; j < maxLevel; j++) {
                let path = formValues[i].path.split('.')[j];
                path = path.charAt(0).toLowerCase() + path.slice(1);
                if (j === maxLevel - 1) {
                    try {
                        current[formValues[i].path.split('.')[j - 1]] = JSON.parse(path);
                    } catch (e) {
                        if (formValues[i][`subValueLvl${j}`]) {
                            current[path] = formValues[i].enabled;
                        } else if (maxLevel === 1) {
                            current[path] = formValues[i].enabled;
                        }
                    }
                } else {
                    current[path] = current[path] || {};
                    current = current[path];
                }
            }
        }

        if (values.autoAnswer) {
            const autoAnswer = Object.keys(values.autoAnswer)[0]
            switch (autoAnswer) {
                case "none":
                    values.autoAnswer = 0
                    break;
                case "all":
                    values.autoAnswer = 1
                    break;
                case "inbound":
                    values.autoAnswer = 2
                    break;
                case "outbound":
                    values.autoAnswer = 3
                    break;
                default: break;
            }
        }
        if (values.agentModes) {
            values.agentModes = JSON.stringify(values.agentModes)
        }
        if (values?.sipLocation?.sipLocation?.id) {
            values.sipLocation = values.sipLocation.sipLocation;
        }

        let callControl = {};
        if (values.callControl) {
            const selectedCallControls = Object.keys(values.callControl);
            selectedCallControls.forEach(control => {
                const controlValues = values.callControl[control];
                if (control === 'conference') {
                    Object.keys(controlValues).forEach(subControl => callControl[`conference_${subControl}`] = controlValues[subControl])
                } else if (control === 'transfer') {
                    Object.keys(controlValues).forEach(subControl => {
                        Object.keys(controlValues[subControl]).forEach(subSubControl => callControl[`transfer_${subControl}_${subSubControl}`] = controlValues[subControl][subSubControl]);
                    });
                } else {
                    callControl[control] = controlValues;
                }
            });
        }
        if (Object.keys(callControl).length > 0) {
            if (callControl.hasOwnProperty('endCall')) {
                callControl['endcall'] = values.callControl.endCall;
                delete callControl.endCall;
            }
            if (callControl.hasOwnProperty('call Recording')) {
                callControl['callrecording'] = values.callControl['call Recording'];
                delete callControl['call Recording'];
            }
            if (callControl.hasOwnProperty('manual Dial')) {
                callControl['manualdial'] = values.callControl['manual Dial'];
                delete callControl['manual Dial'];
            }
            if (callControl.hasOwnProperty('Disposition')) {
                callControl['disposition'] = values.callControl['Disposition'];
                delete callControl['Disposition'];
            }
            if (callControl.hasOwnProperty('dial Pad')) {
                callControl['dail_pad'] = values.callControl['dial Pad'];
                delete callControl['dial Pad'];
            }
            if (callControl.hasOwnProperty('Message')) {
                callControl['message'] = values.callControl['Message'];
                delete callControl['Message'];
            }
            values.callControls = JSON.stringify(callControl);
            delete values.callControl;
        }
        values.agentIdsList = selectedAgents.map((agent) => agent.id);
        values.groupIdsList = selectedGroups.map((group) => group.id);

        if (rows) {
            values.skills = rows
        }
        if (merge) {
            values.merge = merge
        } else if (!merge) {
            values.merge = merge
        }

        if (values.skills && values.skills[0].skill && values.skills[0].skill?.length === 0) {
            delete values.skills;
            delete values.merge;
        }
        if (values.skills && values.skills.length) {
            let formattedSkills = [];
            values.skills.forEach((skillItem) => {
                if (skillItem.priority && skillItem.skill.length) {
                    skillItem.skill.forEach((skill) => {
                        formattedSkills.push({ priority: skillItem.priority, skillName: skill.skillName, id: skill.id });
                    })
                }
            });
            values.skills = formattedSkills;
        }
        console.log("final form value", values)
        submitForm(values);
    }

    async function submitForm(values) {
        console.log("bulk submitForm ~ values:", values)
        setLoading(true);
        try {
            const resp = await bulkUpdateSettings(values);
            console.log("submitForm ~ resp:", resp)
            if (resp.Status === "Success") {
                console.log("Successfully updated settings")
                showSuccessNotification("Successfully updated settings")
            } else {
                if (resp.Message) {
                    showErrorNotification(resp.Message)
                }
                console.log("Settings update failed ~ resp:", resp)
            }
            setLoading(false);
        }
        catch (err) {
            console.log(err)
            setLoading(false);
            showErrorNotification("Failed to update settings")
        }
    }

    const [rows, setRows] = useState([{ id: 1, priority: '', skill: [] }]);
    const [disableButton, setDisableButton] = useState(false);
    const [merge, setMerge] = useState(true);

    const [availableSkills, setAvailableSkills] = useState([]);

    const handleError = (value) => {
        if (value === true) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }
    console.log("here rows are ", rows, merge, 'BULK_UPDATE_OPTIONS', isBulkUpdateSettingsEnabled);

    return (
        <Box className="oz-admin-bulk-update-container">
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
                Configurations
            </Typography>
            <Typography fontSize={"24px"} fontWeight={600} color={"#212121"} flexGrow={1}>
                Bulk Update of Settings
            </Typography>
            <Box className={"oz-admin-bulk-update-tabs-container"}>
                <Tabs value={activeMode} onChange={handleChange} variant="scrollable" indicatorColor='primary' scrollButtons={false}>
                    {tabs.map((mode, index) => <Tab value={mode} label={mode} key={mode + index} />)}
                </Tabs >
            </Box >
            <Box className='oz-admin-bulk-update-content'>
                {canEditAgent ?
                    <>
                        <Box className='oz-admin-bulk-update-content-header'>
                            <Typography fontSize={"16px"} fontWeight={500} color={"#546881"} flexGrow={1}>
                                Update the Settings in One Go
                            </Typography>
                        </Box>
                        <Box className='oz-admin-bulk-update-content-body'>
                            <AgentSelector selected={selected} selectedAgents={selectedAgents} setSelectedAgents={setSelectedAgents} selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} />
                        </Box>
                        <Box className='oz-admin-bulk-update-content-body'>
                            <SkillsSelector rows={rows} setRows={setRows} skillsList={skillsList} error={handleError} alignment={merge ? 'Enable' : 'Disable'}
                                onChange={(value) => { setMerge(value) }} setAvailableSkills={setAvailableSkills} availableSkills={availableSkills}
                            />
                        </Box>
                        {isBulkUpdateSettingsEnabled && <Box className='oz-admin-bulk-update-content-body-settings'>
                            <Box className='oz-admin-bulk-update-content-body-settings-header'>
                                <Typography fontSize={"12px"} fontWeight={400} color={"#47586E"} flexGrow={1}>
                                    Select settings & values to apply <span style={{ color: "red" }}>*</span>
                                </Typography>
                                <IconButton className='oz-admin-bulk-update-content-header-add-button' onClick={handleClickAdd}>
                                    <img src={`${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`} alt="add" />
                                </IconButton>
                            </Box>
                            {formValues.length > 0 && <Box className='oz-admin-bulk-update-content-body-settings-body'>
                                {formValues.map((formValue, index) => {
                                    return (
                                        <ControlsRenderer key={formValue.index} formValue={formValue} formValues={formValues}
                                            setFormValues={setFormValues}
                                            handleRemoveItem={handleRemoveItem}
                                            sipLocationList={sipLocationList}
                                            agentGroupsList={[]}
                                            ellipsis={ellipsis}
                                            settingsNameMap={settingsNameMap}
                                            skillListfull={skillsList}
                                        />
                                    )
                                })}
                            </Box>}
                        </Box>}
                    </> :
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "calc(100vh - 270px)",
                        color: "#546881",
                        fontSize: "16px",
                        fontWeight: 500,
                    }}>
                        User does not have permission to update agents.
                    </Box>
                }
            </Box>
            {canEditAgent && <Box className='oz-admin-bulk-update-footer'>
                <Button
                    onClick={
                        () => {
                            setFormValues([]);
                            setSelectedAgents([]);
                            setSelectedGroups([]);
                            setRows([{ id: 1, priority: '', skill: [] }]);
                            setMerge('Enable');
                        }
                    }
                    disabled={loading}
                    variant="outlined" size="large" sx={{ width: "91px", height: "44px", borderRadius: "8px", marginRight: "20px", background: "#FFFFFF", color: "#008DFF", fontWeight: 400, fontSize: "14px" }}>
                    Cancel
                </Button>
                <LoadingButton disabled={disableButton} loading={loading} onClick={handleFormSubmit} variant="contained" size="large" sx={{ width: "91px", height: "44px", borderRadius: "8px", fontWeight: 400, fontSize: "14px", background: "#3D8BF8" }}>
                    Apply
                </LoadingButton>
            </Box>}
            {loading && <Backdrop open={true} style={{ zIndex: 1000 }} ><LoadingScreen /></Backdrop>}
        </Box >
    )
}

const SkillsSelector = ({ rows, setRows, skillsList, error, alignment, onChange, setAvailableSkills, availableSkills }) => {

    const handleClick = (value) => {
        onChange(value === 'Enable' ? true : false);
    }

    useEffect(() => {
        function validateData(objects) {
            let errors = [];

            // Special case when rows.length === 1
            if (objects.length === 1) {
                const [singleRow] = objects;
                const isPriorityEmpty = !singleRow.priority || singleRow.priority.trim() === "";
                const isSkillsEmpty = singleRow.skill.length === 0;

                // If both priority and skills are empty, do not throw an error
                if (isPriorityEmpty && isSkillsEmpty) {
                    errors = [];
                }

                // If either is populated, validate normally
                if (!isPriorityEmpty || !isSkillsEmpty) {
                    if (!singleRow.priority) {
                        errors.push("Priority must not be empty if skills are populated.");
                    }
                    if (singleRow.skill.length === 0) {
                        errors.push("Skills must not be empty if priority is populated.");
                    }
                }

                return errors;
            }

            // Check for skill.id duplication
            const skillIdMap = new Map();
            objects.forEach((obj, index) => {
                obj.skill.forEach((item) => {
                    if (!skillIdMap.has(item.id)) {
                        skillIdMap.set(item.id, index);
                    } else {
                        errors.push(`Skill ID ${item.id} in object at index ${index} matches with index ${skillIdMap.get(item.id)}`);
                    }
                });
            });

            // Check for number duplication
            const numberMap = new Map();
            objects.forEach((obj, index) => {
                if (obj.priority) {
                    if (!numberMap.has(obj.priority)) {
                        numberMap.set(obj.priority, index);
                    } else {
                        errors.push(`Number "${obj.priority}" in object at index ${index} matches with index ${numberMap.get(obj.priority)}`);
                    }
                }
            });

            // Check for empty number and empty skill array
            objects.forEach((obj, index) => {
                if (!obj.priority || obj.skill.length === 0) {
                    errors.push(`Object at index ${index} has an empty "priority" and no skills.`);
                }
            });

            return errors;
        }
        const errors = validateData(rows);
        if (errors?.length > 0) {
            showErrorNotification(errors[0]);
            error(true);
        } else {
            error(false);
        }

    }, [rows]);

    // console.log("here error", error);

    // const handleAddRow = () => {
    //     setRows((prevRows) => [
    //         ...prevRows,
    //         { id: prevRows.length + 1, priority: '', skill: [] },
    //     ]);
    // };

    const handleAddRow = () => {
        setRows((prevRows) => {
            const newRow = { id: prevRows.length + 1, priority: '', skill: [] };

            // Calculate all selected skill IDs from existing rows
            const selectedSkillIds = prevRows
                .flatMap((row) => row.skill.map((skill) => skill.id));

            // Filter out selected skills from availableSkills
            setAvailableSkills((prevSkills) =>
                prevSkills.filter((skill) => !selectedSkillIds.includes(skill.id))
            );

            return [...prevRows, newRow];
        });
    };

    const handleDeleteRow = (id) => {
        setRows((prevRows) => {
            // Get the skills from the row being deleted
            const deletedRow = prevRows.find(row => row.id === id);
            const deletedSkills = deletedRow.skill;

            // Add the deleted skills back to availableSkills
            setAvailableSkills(prevSkills => {
                const newAvailableSkills = [...prevSkills];
                deletedSkills.forEach(skill => {
                    if (!newAvailableSkills.some(s => s.id === skill.id)) {
                        newAvailableSkills.push(skill);
                    }
                });
                return newAvailableSkills;
            });

            return prevRows.filter(row => row.id !== id);
        });
    };

    // const handleDeleteRow = (id) => {
    //     setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    // };

    const handleInputChange = (id, field, value) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            )
        );
    };

    const handleSkillChange = (rowId, selectedSkillsFromAuto, event) => {
        setRows(prevRows => {
            const currentRow = prevRows.find(row => row.id === rowId);
            const previousSkills = currentRow.skill;

            // Handle "Select All" case
            let selectedSkills = [...selectedSkillsFromAuto];
            const selectAllIndex = selectedSkills.findIndex(skill => skill.id === 0);
            if (selectAllIndex !== -1) {
                if (availableSkills.length === 0 && event.currentTarget.id === "skills-option-0") {
                    selectedSkills = [];
                } else {
                    // If "Select All" is clicked and we have previous selections,
                    // combine previous selections with all available skills
                    if (previousSkills.length > 0) {
                        // Get all skill IDs that are already selected
                        const selectedSkillIds = new Set(previousSkills.map(skill => skill.id));

                        // Add any available skills that aren't already selected
                        const additionalSkills = availableSkills.filter(skill => !selectedSkillIds.has(skill.id));
                        selectedSkills = [...previousSkills, ...additionalSkills];
                    } else {
                        // If no previous selections, select all available skills
                        selectedSkills = availableSkills;
                    }
                }
            }

            // Update available skills
            setAvailableSkills(prevAvailableSkills => {
                let newAvailableSkills = [...prevAvailableSkills];

                // Add back previously selected skills that are no longer selected
                previousSkills.forEach(skill => {
                    if (!selectedSkills.some(s => s.id === skill.id) &&
                        !newAvailableSkills.some(s => s.id === skill.id)) {
                        newAvailableSkills.push(skill);
                    }
                });

                // Remove newly selected skills
                return newAvailableSkills.filter(skill =>
                    !selectedSkills.some(s => s.id === skill.id)
                );
            });

            // Update rows with new skill selection
            return prevRows.map(row =>
                row.id === rowId ? { ...row, skill: selectedSkills } : row
            );
        });
    };
    return (
        <>
            <Box
                sx={{
                    minHeight: '100px',
                    overflowY: 'auto',
                    padding: '8px',
                    borderRadius: '4px',
                    maxHeight: '250px',
                }}
            >
                <Typography sx={{ marginLeft: '-5px', marginBottom: '5px' }} fontSize={"12px"} fontWeight={400} color={"#212121"}>
                    Choose Skills
                </Typography>

                <Grid container xs={7}>
                    {rows.map((row, index) => (
                        <div style={{ display: 'flex', marginBottom: '4px' }} key={row.id}>
                            <TextField
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        height: '40px',
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        width: '5rem',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    },
                                    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                        border: '1px solid #D6D6D7'
                                    }
                                }}
                                type="number"
                                placeholder='Priority'
                                value={row.priority}
                                onChange={(e) =>
                                    handleInputChange(row.id, 'priority', e.target.value)
                                }
                            />
                            <div style={{ marginRight: '5px', marginLeft: '5px', width: '350px' }} flexGrow={1}>
                                <BulkUpdateAutoComplete
                                    mode="Add"
                                    row={row}
                                    limitTags={2}
                                    values={row.skill}
                                    name="skills"
                                    placeholder={"Search Skill Name"}
                                    Datas={availableSkills}
                                    optionLabelName="skillName"
                                    showCheckBox={true}
                                    multiple={true}
                                    showselectall="true"
                                    popupIcon={null}
                                    height='40px'
                                    handleInputChange={handleInputChange}
                                    onChange={(event, newSelectedSkills) => {
                                        handleSkillChange(row.id, newSelectedSkills, event);
                                    }}
                                />
                            </div>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}>
                                {index === 0 ? (
                                    <div>
                                        <IconButton className='oz-admin-bulk-update-content-header-add-button' onClick={handleAddRow}>
                                            <img src={`${process.env.PUBLIC_URL}/icons/anotherlevelblue.svg`} alt="add" />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <IconButton
                                        onClick={() => handleDeleteRow(row.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </div>
                            <div style={{ marginRight: '5px', marginLeft: '5px' }}>
                                {index === 0 &&
                                    <ToggleButtonGroup value={alignment} exclusive
                                        sx={{
                                            '& .MuiToggleButtonGroup-groupedHorizontal': {
                                                borderRadius: "8px",
                                                height: "30px",
                                                color: "#263238",
                                                width: "60px",
                                                border: "1px solid ##DAE1E6",
                                                '&.Mui-selected': {
                                                    color: "#008DFF",
                                                    border: "1px solid #008DFF",
                                                    background: "#FFFFFF"
                                                }
                                            }
                                        }}
                                    >
                                        <ToggleButton onClick={() => { handleClick('Enable') }} value="Enable">Merge</ToggleButton>
                                        <ToggleButton onClick={() => { handleClick('Disable') }} value="Disable">Replace</ToggleButton>
                                    </ToggleButtonGroup>
                                }
                            </div>
                        </div>
                    ))}
                </Grid>
            </Box>
        </>
    )
}

const ControlsRenderer = ({ formValue, formValues, setFormValues, handleRemoveItem, sipLocationList, agentGroupsList, ellipsis, settingsNameMap, skillListfull }) => {

    const settingsOptions = useMemo(() => (Object.keys(settingsNameMap)), [])
    const isAdmin = useMemo(() => checkRoles("ROLE_ADMIN"), [])

    const settingsType = useMemo(() => {
        if (!formValue.path) return null
        return {
            isCallControls: formValue.path.includes('callControl'),
            isAgentModes: formValue.path.includes('agentModes'),
            isAutoAnswer: formValue.path.includes('autoAnswer'),
            isSipLocation: formValue.path.includes('sipLocation'),
            isOnCall: formValue.path.includes('onCall'),
            isScreenRecording: formValue.path.includes('screenRecording'),
            isAgentGroups: formValue.path.includes('agentGroups'),
        }
    }, [formValue])

    const needSubOptionsLvl1 = useMemo(() => {
        if (!settingsType) return false
        return !(settingsType.isOnCall || settingsType.isScreenRecording)
    }, [settingsType, formValues])

    const needSubOptionsLvl2 = useMemo(() => {
        if (!settingsType) return false
        return settingsType.isCallControls && (formValue.subValueLvl1 === 'Transfer' || formValue.subValueLvl1 === 'Conference')
    }, [settingsType, formValue.value])

    const needSubOptionsLvl3 = useMemo(() => {
        if (!settingsType) return false
        return settingsType.isCallControls && formValue.subValueLvl1 === 'Transfer' && (formValue.subValueLvl2 === 'Agent' || formValue.subValueLvl2 === 'Skill' || formValue.subValueLvl2 === 'Phone' || formValue.subValueLvl2 === 'Ivr')
    }, [settingsType, formValue.subValueLvl1, formValue.subValueLvl2])

    const buttonsRequired = useMemo(() => {
        if (!settingsType) return false
        return settingsType.isCallControls || settingsType.isOnCall || settingsType.isScreenRecording || settingsType.isAgentModes;
    }, [settingsType])

    const options = useMemo(() => {

        let newOptions = settingsOptions.filter((option) => formValue.value === option ? true : formValues.every((item) => item.value !== option))

        function prepareOptionsUsingPath(possible, type, path) {
            const totalFilled = formValues.filter((item) => item.path && item.path.includes(path)).length;
            if (totalFilled === possible) {
                newOptions = newOptions.filter((option) => option !== type)
            } else if (totalFilled < possible && !newOptions.includes(type)) {
                newOptions = [type, ...newOptions]
            }
            if (formValue.value === type && !newOptions.includes(type)) {
                newOptions = [type, ...newOptions]
            }
        }

        prepareOptionsUsingPath(20, 'Call Controller', 'callControl');
        let maxAgentModes = 0;
        if (isAdmin) {
            maxAgentModes = 6;
        } else {
            maxAgentModes = 2;
            if (checkRoles("ROLE_OUTBOUND")) {
                maxAgentModes += 2
            }
            if (checkRoles("ROLE_BLENDED")) {
                ++maxAgentModes;
            }
            if (checkRoles("ROLE_CHAT")) {
                ++maxAgentModes;
            }
        }
        prepareOptionsUsingPath(maxAgentModes, 'Agent Modes', 'agentModes');
        return newOptions;
    }, [formValues, settingsOptions, formValue.value]);

    const subOptionsLvl1 = useMemo(() => {
        if (!settingsType) return []
        if (settingsType.isCallControls) {
            const totalOptions = ['Hold', 'Mute', 'EndCall', 'Answer', 'Decline', 'Transfer', 'Conference', 'Call Recording', 'Manual Dial', 'Disposition', 'Dial Pad', 'Message'];
            const totalOptionsFilled = formValues.filter((item) => item.path && item.path.includes('callControl')).map((item) => item.subValueLvl1);
            let newOptions = totalOptions.filter((option) => formValue.subValueLvl1 === option ? true : totalOptionsFilled.every((item) => item !== option))

            function prepareCallControlSubOptions(type, possible, path) {
                newOptions = [type, ...newOptions]
                const totalFilled = formValues.filter((item) => item.path && item.path.includes(path) && item.subValueLvl1 === type).length;
                if (totalFilled === possible) {
                    newOptions = newOptions.filter((option) => option !== type)
                } else if (totalFilled < possible && !newOptions.includes(type)) {
                    newOptions = [type, ...newOptions]
                }
                if (formValue.subValueLvl1 === type && !newOptions.includes(type)) {
                    newOptions = [type, ...newOptions]
                }
            }

            if (totalOptionsFilled.includes('Transfer')) prepareCallControlSubOptions('Transfer', 8, 'callControl.Transfer');
            if (totalOptionsFilled.includes('Conference')) prepareCallControlSubOptions('Conference', 2, 'callControl.Conference');
            return [...new Set(newOptions)];
        } else if (settingsType.isAgentModes) {
            let agentModesList = ['Inbound', 'Manual']
            if (isAdmin) {
                agentModesList = [...agentModesList, 'Progressive', 'Preview', 'Digital', 'Blended']
            } else {
                if (checkRoles("ROLE_OUTBOUND")) {
                    agentModesList.push("Preview");
                    agentModesList.push("Progressive");
                }
                if (checkRoles("ROLE_BLENDED")) {
                    agentModesList.push("Blended");
                }
                if ((checkRoles("ROLE_CHAT"))) {
                    agentModesList.push("Digital");
                }
            }
            return prepareOptionsUsingPath(agentModesList, 'agentModes', 1);
        } else if (settingsType.isAutoAnswer) {
            return ['None', 'All', 'Inbound', 'Outbound'];
        } else if (settingsType.isSipLocation) {
            return sipLocationList;
        } else if (settingsType.isAgentGroups) {
            return agentGroupsList;
        } else if (settingsType.isSkills) {
            return skillListfull;
        } else {
            return []
        }
    }, [settingsType, formValue.subValueLvl1, formValues, sipLocationList, skillListfull])

    const subOptionsLvl2 = useMemo(() => {
        if (!settingsType) return []
        if (settingsType.isCallControls) {
            if (formValue.subValueLvl1 === 'Conference') {
                return prepareOptionsUsingPath(['Agent', 'Phone'], 'callControl.Conference', 2)
            } else if (formValue.subValueLvl1 === 'Transfer') {
                const totalOptions = ['Agent', 'Skill', 'Phone', 'Ivr'];
                const maxAppearLimitForEachOption = 2;
                const totalOptionsFilled = formValues.filter((item) => item.path && item.path.includes('callControl.Transfer')).map((item) => item.subValueLvl2);
                let newOptions = [];
                totalOptions.forEach((option) => {
                    const optionFilledLength = totalOptionsFilled.filter((item) => item === option).length;
                    if (optionFilledLength < maxAppearLimitForEachOption) {
                        newOptions.push(option);
                    }
                })
                if (formValue.subValueLvl2 && !newOptions.includes(formValue.subValueLvl2)) {
                    newOptions = [formValue.subValueLvl2, ...newOptions];
                }
                return newOptions;
            } else {
                return []
            }
        }
    }, [settingsType, formValue.subValueLvl2, formValues])

    const subOptionsLvl3 = useMemo(() => {
        if (!settingsType) return []
        if (settingsType.isCallControls && formValue.subValueLvl1 === 'Transfer' && formValue.subValueLvl2) {
            return prepareOptionsUsingPath(['Warm', 'Blind'], `callControl.Transfer.${formValue.subValueLvl2}`, 3)
        } else {
            return []
        }
    }, [settingsType, formValue.subValueLvl2, formValues])

    function prepareOptionsUsingPath(totalOptions, path, level) {
        const totalOptionsFilled = formValues.filter((item) => item.path && item.path.includes(path)).map((item) => item[`subValueLvl${level}`]);
        return totalOptions.filter((option) => {
            return formValue[`subValueLvl${level}`] === option ? true : totalOptionsFilled.every((item) => {
                return item !== option
            })
        })
    }

    return (
        <Box className='oz-admin-bulk-update-content-body-settings-body-item'>
            <Autocomplete placeholder='Select Setting' {...commonAutocompleteProps} value={formValue.value} options={options}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, value: newValue, path: settingsNameMap[newValue], subValueLvl1: null, subValueLvl2: null, subValueLvl3: null } : item))))
                    } else {
                        setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, value: null, path: null, subValueLvl1: null, subValueLvl2: null, subValueLvl3: null } : item))))
                    }
                }}
            />
            {(needSubOptionsLvl1 && settingsType) ? (settingsType.isSipLocation ?
                <Autocomplete
                    {...commonAutocompleteProps}
                    value={formValue.subValueLvl1}
                    options={subOptionsLvl1}
                    onChange={(event, newValue) => {
                        if (newValue && newValue.location && newValue.id) {
                            setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl1: newValue, path: `${settingsNameMap[item.value]}.${JSON.stringify(newValue)}`, subValueLvl2: null, subValueLvl3: null } : item))))
                        } else {
                            setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl1: null, path: `${settingsNameMap[item.value]}`, subValueLvl2: null, subValueLvl3: null } : item))))
                        }
                    }}
                    getOptionLabel={(option) => option ? option.location + ' ~ ' + option.id : ''}
                    isOptionEqualToValue={(option, value) => (option && value && value.id) ? option.id === value.id : false}
                    renderOption={(props, option, { selected, inputValue }) => <Box sx={{ paddingLeft: "10px !important" }} {...props} className="oz-admin-bulk-update-content-body-controls-item-select-option">
                        <Typography sx={ellipsis} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>{option.location + ' ~ ' + option.id}</Typography>
                    </Box>}
                />
                : <Autocomplete {...commonAutocompleteProps} value={formValue.subValueLvl1} options={subOptionsLvl1}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl1: newValue, path: `${settingsNameMap[item.value]}.${newValue}`, subValueLvl2: null, subValueLvl3: null } : item))))
                        } else {
                            setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl1: null, path: `${settingsNameMap[item.value]}`, subValueLvl2: null, subValueLvl3: null } : item))))
                        }
                    }}
                />) : null}
            {needSubOptionsLvl2 ?
                (settingsType.isSkills ?
                    <TextField
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                height: '40px',
                                fontWeight: 400,
                                fontSize: "14px",
                                maxWidth: '12rem'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #D6D6D7'
                            },
                            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #D6D6D7'
                            }
                        }}
                        type={'number'}
                        placeholder='Priority'
                        onChange={(e) => setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl2: e.target.value, path: `${settingsNameMap[item.value]}.${item.subValueLvl1}.${e.target.value}`, subValueLvl3: null } : item))))}
                        value={formValue.subValueLvl2}
                    />
                    :
                    <Autocomplete {...commonAutocompleteProps} value={formValue.subValueLvl2} options={subOptionsLvl2}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl2: newValue, path: `${settingsNameMap[item.value]}.${item.subValueLvl1}.${newValue}`, subValueLvl3: null } : item))))
                            } else {
                                setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl2: null, path: `${settingsNameMap[item.value]}.${item.subValueLvl1}`, subValueLvl3: null } : item))))
                            }
                        }}
                    />
                ) : null
            }
            {needSubOptionsLvl3 && <Autocomplete {...commonAutocompleteProps} value={formValue.subValueLvl3} options={subOptionsLvl3}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl3: newValue, path: `${settingsNameMap[item.value]}.${item.subValueLvl1}.${item.subValueLvl2}.${newValue}` } : item))))
                    } else {
                        setFormValues((prev) => (prev.map((item) => (item.index === formValue.index ? { ...item, subValueLvl3: null, path: `${settingsNameMap[item.value]}.${item.subValueLvl1}.${item.subValueLvl2}` } : item))))
                    }
                }}
            />}
            <Box className='oz-admin-bulk-update-content-body-settings-body-item-right'>
                {buttonsRequired && <ColorToggleButton value={formValue.enabled ? 'Enable' : 'Disable'}
                    subOptionName={settingsType.isSkills}
                    onChange={(event, newAlignment) => { setFormValues((prev) => prev.map((item) => (item.index === formValue.index ? { ...item, enabled: newAlignment === 'Enable' } : item))) }}
                />}
                <IconButton onClick={() => handleRemoveItem(formValue.index)}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

function ColorToggleButton({ value, onChange, subOptionName }) {

    const [alignment, setAlignment] = useState('Enable');

    function handleClick(value) {
        console.log("value", value);
        onChange({}, value);
        setAlignment(value);
    }

    return <ToggleButtonGroup value={alignment} exclusive
        sx={{
            '& .MuiToggleButtonGroup-groupedHorizontal': {
                borderRadius: "8px",
                height: "30px",
                color: "#263238",
                width: "60px",
                border: "1px solid ##DAE1E6",
                '&.Mui-selected': {
                    color: "#008DFF",
                    border: "1px solid #008DFF",
                    background: "#FFFFFF"
                }
            }
        }}
    >
        <ToggleButton onClick={() => { handleClick('Enable') }} value="Enable">Enable</ToggleButton>
        <ToggleButton onClick={() => { handleClick('Disable') }} value="Disable">Disable</ToggleButton>
    </ToggleButtonGroup>
}