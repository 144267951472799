// whatsappApi.ts
import axios from 'axios';
import { getAuthToken } from '../../../services/auth-service';
import { ApiResponse, PaginatedData } from './interfaces';
import { BASE_URL_CXI } from '../../../utils/properties';

export interface WhatsAppNumber {
  id: number;
  number: string;
  wabaId: string;
  numberId: string;
  approved: boolean;
  approvedBy: number;
  approvedOn: string;
  createdBy: number;
  createdOn: string;
  updatedBy: number;
  updatedOn: string;
  deleted: boolean;
  deletedBy: number | null;
  deletedOn: string | null;
  userId: number;
  userName: string;
}

export interface WhatsAppTemplate {
  id: number;
  name: string;
  text: string;
  templateId: string;
  type: string;
  language: string;
  whatsAppNumber: string;
  approved: boolean;
  approvedBy: number;
  approvedOn: string;
  createdBy: number;
  createdOn: string;
  updatedBy: number | null;
  updatedOn: string | null;
  deleted: boolean;
  deletedBy: number | null;
  deletedOn: string | null;
  userId: number;
  userName: string;
  variables: Record<string, any>;
}



export const whatsappApi = {
  listWhatsAppNumbers: async (userId: number | any, page: number = 0, size: number = 1000) => {
    try {
      const response = await axios.get<ApiResponse<PaginatedData<WhatsAppNumber>>>(
        `${BASE_URL_CXI}/whatsAppNumber`,
        {
          params: { page, size },
          headers: {
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch WhatsApp numbers');
    }
  },

// whatsappApi.ts
getWhatsAppTemplates: async (userId: number | string , page: number = 0, size: number = 100) => {
    try {
      const response = await axios.get<ApiResponse<PaginatedData<WhatsAppTemplate>>>(
        `${BASE_URL_CXI}/whatsAppTemplate`,
        {
          params: { page, size },
          headers: {
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch WhatsApp templates');
    }
  }
};