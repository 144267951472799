import { useState } from "react";
import { showErrorNotification } from "../../../../components/admin/common/NotiService";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as DropIcon } from '../../../../static/icons/drop.svg';
import './fileDropZone.css';

const FileDropZone = ({ setFileData }) => {

    const [dragOver, setDragOver] = useState(false);

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const files = event.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        const acceptedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv', 'application/json'];
        const acceptedFiles = Array.from(files).filter(file => acceptedTypes.includes(file.type));
        if (acceptedFiles.length > 0) {
            setFileData(acceptedFiles[0]);
        } else {
            showErrorNotification(`Invalid file type. Please upload a file of type xls, xlsx, csv, json only.`);
        }
        console.log(acceptedFiles);
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        handleFiles(files);
    };

    return <Box
        className={`oz-data-management-files-add-form-content-item-drop-container ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
    >
        <input
            type="file"
            accept=".xls, .xlsx, .csv, .json"
            style={{ display: 'none' }}
            id="fileInput"
            onChange={handleFileChange}
        />
        <Box className="oz-data-management-files-add-form-content-item-file">
            <DropIcon />
            <Typography className="oz-data-management-files-add-form-content-item-file-text">
                Drop Your File Here
            </Typography>
        </Box>
        <Typography sx={{ my: 2 }} className="oz-data-management-files-add-form-content-item-file-info-bold">
            Or
        </Typography>
        <Box className='oz-data-management-files-add-form-content-item-file'>
            <Button
                className="oz-data-management-files-add-form-content-item-file-button"
                variant='contained'
                onClick={() => document.getElementById('fileInput').click()}
            >
                Browse File
            </Button>
            <Typography className="oz-data-management-files-add-form-content-item-file-info">
                File type : xls, xlsx, csv, json
            </Typography>
        </Box>
    </Box>
}

export default FileDropZone;