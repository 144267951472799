import { Card, Grid, Stack, Chip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import ReportsPageHead from '../../../components/ReportsPageHead/ReportsPageHead';
import ThresholdDrawer from '../../../components/ThresholdDrawer/ThresholdDrawer';
import { getSLAData, getSystemAnalyticsFilters, getSystemAnalyticsReport, getWfmDashboard, getWidgetConfig } from '../../../services/api-service';
import { ReactComponent as AlertIcon } from '../../../static/icons/Alert.svg';
import HistoricReportsFilterDrawer from '../../../pages/reports/HistoricReportsFilterDrawer/HistoricReportsFilterDrawer';
import { OZContext } from '../../../contexts/OZContext';
import React from 'react';
import FilterItems from '../../../components/FilterItems/FilterItems';
import { checkDataAccessFilters, convertSecondsToTimeNew, createHistoricFilterIds, determineFilterType, mapRowDataWithThreshold } from '../../../utils/util';
import WidgetDownload from '../../../components/Widget-Download/Widget-Download';
import '../WFMDashboard/WFMDashboard.css';
import { getLastFetchedDetail } from '../../../services/application-service';
import OZTable from '../../OZTable/OZTable';
import { WIDGET_REFRESH_INTERVAL, dummyData, dummyDataHappy } from '../../../utils/constants';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetSearch from '../../Widget-Search/Widget-Search';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import NewSelectPopover from '../../SelectPopover/NewSelectPopover';
import useChartResize from '../../../hooks/useChartResize';

// const thresholdItemsDefault = [
// 	{
// 		label: '',
// 		fields: [
// 			{ label: 'Calls InQueue', value: '', key: 'TotalCalls', condition: 'GREATER_THAN' },
// 			{ label: 'Avg. Queue Time', value: '', key: 'avgQueueTime', condition: 'GREATER_THAN' },
// 			{ label: 'Avg. Pickup Time', value: '', key: 'avgPickUpTime', condition: 'GREATER_THAN' },
// 			{ label: 'Avg. Talk Time', value: '', key: 'avgTalkTime', condition: 'GREATER_THAN' },
// 			{ label: 'Avg. Wrapup Time', value: '', key: 'avgWrapUpTime', condition: 'GREATER_THAN' },
// 			{ label: 'Avg. Handling Time', value: '', key: 'avgHandlingTime', condition: 'GREATER_THAN' }
// 		]
// 	}
// ];

const useStyles: any = makeStyles(() => ({
	cardTile: { border: '1px solid #E6E5E6 !important', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important', borderRadius: '12px !important' },
	textCard: {
		position: 'relative',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	},
	textCardTitle: {
		fontWeight: '400 !important',
		fontSize: '14px !important',
		color: '#99A0A8 !important'
	},
	textCardSubtitle: {
		fontWeight: '500 !important',
		fontSize: '22px !important',
		color: '#212121 !important'
	},
	legendWrapper: {
		padding: '0 15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginLeft: '500px'
	},
	legend: {
		minWidth: '6rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	legendIcon: {
		margin: '0.5rem 1rem !important'
	},
	legendIconLine: {
		display: 'inline-block',
		minWidth: '27px',
		height: '0px',
		borderTop: '3px solid #008DFF',
		content: "''"
	},
	legendIconDottedLine: {
		width: '27px',
		height: '0px',
		borderTop: '1px dashed #008DFF'
	},
	legendText: {
		whiteSpace: 'nowrap'
	},
	boundButton: {
		padding: '5px !important',
		minWidth: 50
	},
}));

let interval: any;

const columns = [ //here id and label are just for hamBurger icon
	{ name: 'Skill Name', key: 'SkillName', label: 'Skill Name', id: 'SkillName' },
	{ name: 'Total Calls', key: 'TotalCalls', label: 'Total Calls', id: 'TotalCalls' },
	{ name: 'Answered Calls', key: 'TotalAnsweredCalls', label: 'Answered Calls', id: 'TotalAnsweredCalls' },
	{ name: 'Skill Queues', key: 'CallQueueCount', type: 'CallQueueCount', label: 'Skill Queues', id: 'CallQueueCount' },
	{ name: 'Abandoned Calls %', key: 'AbandonedCallRatio', type: 'callerDisconnects', label: 'Abandoned Calls %', id: 'AbandonedCallRatio' },
	{ name: 'Sla %', key: 'SLARatio', label: 'Sla %', id: 'SLARatio' },
	{ name: 'ATT', key: 'AvgTalkTime', label: 'ATT', id: 'AvgTalkTime' },
	{ name: 'AWT', key: 'AvgWrapupTime', label: 'AWT', id: 'AvgWrapupTime' },
	{ name: 'ASA', key: 'AvgSpeedToAnswer', label: 'ASA', id: 'AvgSpeedToAnswer' },
	{ name: 'AHT', key: 'AvgHandlingTime', label: 'AHT', id: 'AvgHandlingTime' },
	{ name: 'Calls/Agent', key: 'CallsPerAgent', label: 'Calls/Agent', id: 'CallsPerAgent' },
	{ name: 'Agent Disconnect', key: 'TotalAgentDisconnects', label: 'Agent Disconnect', id: 'TotalAgentDisconnects' },
	{ name: 'Agent Disconnect %', key: 'AgentDisconnectRatio', label: 'Agent Disconnect %', id: 'AgentDisconnectRatio' },
	{ name: 'Agents Staffed', key: 'TotalAgentCount', label: 'Agents Staffed', id: 'TotalAgentCount', type: 'TotalAgentCount' },
	{ name: '', key: 'agentStaffedSplit', type: 'agentStaffedSplit', label: '', id: 'agentStaffedSplit' },
];

const WFMDashboard = (props: any) => {
	const classes = useStyles();
	// const donutChartElement: any = useRef(null);
	const downloadContent = useRef<null | HTMLDivElement>(null);
	const [selectedFilters, setSelectedFilters] = useState<any>(props?.selectedFilters?.filters || []);
	const [duration, setDuration] = useState<any>();
	// const [durationLabel, setDurationLabel] = useState<string>('Yesterday');
	const [data, setData] = useState<any>([]);
	const [filters, setFilters] = useState<any>([]);
	const [isFilterDrawerOpenInDefaultView, setIsFilterDrawerOpenInDefaultView] = useState<boolean | undefined>(false);
	const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean | undefined>(false);
	// const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const { setIsLoaderOpen } = React.useContext(OZContext);
	//:::::::::::::::::::::::::::::::::This is added new for Graph:::::::::::::::::::::::::::://
	const chartElement: any = useRef(null);
	const [liveValue, setLiveValue] = useState<number>(0);
	const [slaData, setSlaData] = useState<any>([]);
	const [targetValue, setTargetValue] = useState<number>(0);
	const [graphData, setGraphData] = useState<any>([]);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const [columnData, setColumnData] = useState<any>(props?.selectedFilters?.filterHeaders?.data || columns);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [controller, setController] = useState({
		page: 1,
		rowsPerPage: 10
	});
	const [totalPages, setTotalPages] = useState<any>();
	const [totalRows, setTotalRows] = useState<any>();
	const [sortingObj, setSortingObj] = useState<any>({});
	const [showGraphData, setShowGraphData] = useState<Boolean>(false);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [searchText, setSearchText] = useState<any>({});
	const [filteredRowData, setFilteredRowData] = useState<Array<any>>([]);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || (checkDataAccessFilters() ? 2 : '1'));
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [toggleState, setToggleState] = useState<any>(false);
	const [isColumnsPopoverOpen, setIsColumnsPopoverOpen] = React.useState<boolean>(false);
	const [columnsEleRefPoint, setColumnsElRefPoint] = React.useState<any>(null);
	const { chartContainerRef, chartDimensions, } = useChartResize();
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);

	// const [storeColumnSelected, setStoreColumnSelected] = useState<any>(columns);
	// const [thresholdItems, setThresholdItems] = useState<Array<any>>(thresholdItemsDefault);

	// const handleDuration = (type: string = '', timeObj: any) => {
	// 	if (type) {
	// 		setDurationLabel(type);
	// 		setDuration(timeObj);
	// 	}
	// };

	const handleDownload = (type: string) => {
		console.log(type);
	};

	// const toggleThresholdDrawer = (isOpen: boolean, dataN: any = null) => {
	// 	setIsThresholdDrawerOpen(isOpen);
	// 	if (dataN) {
	// 		console.log("@@@@@@@@@@@@@@@@@@@@", data)
	// 		console.log("@@@@@@@@@@@@@@@@@@@@", data)
	// 		const updatedRowData = mapRowDataWithThreshold(dataN, data);
	// 		console.log("updatedRowData", updatedRowData[0])
	// 		setData(updatedRowData[0]);
	// 		setThresholdItems(dataN);
	// 	}
	// };


	const retriveWFMGraphData = async (filters = '0', type = '2') => {
		const apiResponse = await getSLAData({ filters, type });
		setSlaData(apiResponse);
		const sortedResponse = [...apiResponse];
		sortedResponse.sort((a, b) => (+a.CallHour > +b.CallHour ? 1 : +b.CallHour > +a.CallHour ? -1 : 0));
		const finalData = filters === '0' ? sortedResponse.filter((item) => +item.SkillId === 0) : sortedResponse;
		const slaPercentage = finalData.map((item) => ({ hour: +item.CallHour, y: +item.SLAPercentage, ...item }));
		const latestDataPoint: any = slaPercentage.length ? slaPercentage[slaPercentage.length - 1] : {};
		const { hour = 0, percentage = 0 } = latestDataPoint;
		setLiveValue(hour);
		setTargetValue(percentage);
		const populatedData = new Array(hour + 1).fill({ y: 0 });
		for (const item of slaPercentage) {
			populatedData[item.hour] = item;
		}
		const data = [
			{
				name: 'SLA %',
				data: populatedData,
				type: 'area'
			},
			{
				name: 'Target',
				color: '#008DFF',
				dashStyle: 'dash'
			},
			{
				name: 'Live',
				color: '#D42015',
				dashStyle: 'dash'
			}
		];
		setGraphData(data);
		setLastFetchedTime(getLastFetchedDetail());
		chartElement?.current?.chart?.redraw();
	};

	const handleOnSelectColumnChange = (selected: any) => {

		let selectedIds: string[] = []
		let orderedSelected = [];

		selected.map((selected: any) => {
			selectedIds.push(selected.id)
		})

		if (selectedIds.includes("SkillName")) {
			orderedSelected.push({ name: 'Skill Name', key: 'SkillName', label: 'Skill Name', id: 'SkillName' })
		}

		if (selectedIds.includes("TotalCalls")) {
			orderedSelected.push({ name: 'Total Calls', key: 'TotalCalls', label: 'Total Calls', id: 'TotalCalls' })
		}
		if (selectedIds.includes("TotalAnsweredCalls")) {
			orderedSelected.push({ name: 'Answered Calls', key: 'TotalAnsweredCalls', label: 'Answered Calls', id: 'TotalAnsweredCalls' })
		}
		if (selectedIds.includes("CallQueueCount")) {
			orderedSelected.push({ name: 'Skill Queues', key: 'CallQueueCount', type: 'CallQueueCount', label: 'Skill Queues', id: 'CallQueueCount' })
		}
		if (selectedIds.includes("AbandonedCallRatio")) {
			orderedSelected.push({ name: 'Abandoned Calls %', key: 'AbandonedCallRatio', type: 'callerDisconnects', label: 'Abandoned Calls %', id: 'AbandonedCallRatio' })
		}
		if (selectedIds.includes("SLARatio")) {
			orderedSelected.push({ name: 'Sla %', key: 'SLARatio', label: 'Sla %', id: 'SLARatio' })
		}
		if (selectedIds.includes("AvgTalkTime")) {
			orderedSelected.push({ name: 'ATT', key: 'AvgTalkTime', label: 'ATT', id: 'AvgTalkTime' })
		}
		if (selectedIds.includes("AvgWrapupTime")) {
			orderedSelected.push({ name: 'AWT', key: 'AvgWrapupTime', label: 'AWT', id: 'AvgWrapupTime' })
		}
		if (selectedIds.includes("AvgSpeedToAnswer")) {
			orderedSelected.push({ name: 'ASA', key: 'AvgSpeedToAnswer', label: 'ASA', id: 'AvgSpeedToAnswer' })
		}
		if (selectedIds.includes("AvgHandlingTime")) {
			orderedSelected.push({ name: 'AHT', key: 'AvgHandlingTime', label: 'AHT', id: 'AvgHandlingTime' })
		}
		if (selectedIds.includes("CallsPerAgent")) {
			orderedSelected.push({ name: 'Calls/Agent', key: 'CallsPerAgent', label: 'Calls/Agent', id: 'CallsPerAgent' })
		}
		if (selectedIds.includes("TotalAgentDisconnects")) {
			orderedSelected.push({ name: 'Agent Disconnect', key: 'TotalAgentDisconnects', label: 'Agent Disconnect', id: 'TotalAgentDisconnects' })
		}
		if (selectedIds.includes("AgentDisconnectRatio")) {
			orderedSelected.push({ name: 'Agent Disconnect %', key: 'AgentDisconnectRatio', label: 'Agent Disconnect %', id: 'AgentDisconnectRatio' })
		}
		if (selectedIds.includes("TotalAgentCount")) {
			orderedSelected.push({ name: 'Agents Staffed', key: 'TotalAgentCount', label: 'Agents Staffed', id: 'TotalAgentCount', type: 'TotalAgentCount' })
		}
		if (selectedIds.includes("agentStaffedSplit")) {
			orderedSelected.push({ name: '', key: 'agentStaffedSplit', type: 'agentStaffedSplit', label: '', id: 'agentStaffedSplit' })
		}
		props?.handleFilterChange?.(selectedFilters, filterSection, props?.widgetId,'0', '', '', [], '', '', '', '', false, {data: orderedSelected});
		setColumnData(orderedSelected);
		// setStoreColumnSelected(orderedSelected);
		handleOnColumnsPopoverClose();
	};

	const onHamburgerClick = (event: any) => {
		setColumnsElRefPoint({ top: event.clientY, left: event.clientX });
		setIsColumnsPopoverOpen(true);
	};

	const handleOnColumnsPopoverClose = () => {
		setColumnsElRefPoint(null);
		setIsColumnsPopoverOpen(false);
	};

	React.useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('2503', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	const retrieveTableData = async (filters: any) => {
		// if (!duration?.fromTime) return;
		const { page, rowsPerPage } = controller;
		setIsLoaderOpen(true);
		let finalRows: any = [];
		let sortedRows: any = [];
		let rows: any = await getWfmDashboard({ filters, type: filterType });
		// rows = rows?.filter((data: any) => parseInt(data?.TotalCalls) > 0);
		sortedRows = rows?.sort((a: any, b: any) => {
			const totalCallsA = parseInt(a.TotalCalls) || 0;
			const totalCallsB = parseInt(b.TotalCalls) || 0;
			return totalCallsB - totalCallsA;
		});

		finalRows = sortedRows?.map((data: any) => {
			return {
				...data,
				AvgTalkTime: convertSecondsToTimeNew(data?.AvgTalkTime || 0),
				AvgWrapupTime: convertSecondsToTimeNew(data?.AvgWrapupTime || 0),
				AvgSpeedToAnswer: convertSecondsToTimeNew(data?.AvgSpeedToAnswer || 0),
				AvgHandlingTime: convertSecondsToTimeNew(data?.AvgHandlingTime || 0),
				IdleAgentCount: parseInt(data.IdleAgentCount) || 0,
				TotalAgentCount: parseInt(data.TotalAgentCount) || 0,
				ReadyAgentCount: parseInt(data.ReadyAgentCount) || 0,
				BusyAgentCount: parseInt(data.BusyAgentCount) || 0,
				PauseAgentCount: parseInt(data.PauseAgentCount) || 0,
				AUXAgentCount: parseInt(data.AUXAgentCount) || 0,
				CallingAgentCount: parseInt(data.CallingAgentCount) || 0,
				ACWAgentCount: parseInt(data.ACWAgentCount) || 0,
				CallQueueCount: data.CallQueueCount || 0,
				agentStaffedSplit: {
					// Ready: (parseInt(data.ReadyAgentCount) || 0) + (parseInt(data.IdleAgentCount) || 0),
					Ready: parseInt(data.ReadyAgentCount) || 0,
					Busy: parseInt(data.BusyAgentCount) || 0,
					Pause: parseInt(data.PauseAgentCount) || 0,
					AUX: parseInt(data.AUXAgentCount) || 0,
					CALLING: parseInt(data.CallingAgentCount) || 0,
					ACW: parseInt(data.ACWAgentCount) || 0,
				}
			};
		});

		setRowData(finalRows);
		// if(toggleState){
		// 	setColumnData(storeColumnSelected);
		// }else{
		// 	setColumnData(columns);
		// }
		setTotalPages(rows.totalNoPages);
		setTotalRows(rows.totalNoOfRows);
		setLastFetchedTime(getLastFetchedDetail());
		setIsLoaderOpen(false);
		if (searchText.length) {
			const filteredRows = finalRows.filter((item: any) => item.SkillName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(finalRows);
		}
	}

	// const retrieveFilters = async () => {
	// 	const apiResponse = await getSystemAnalyticsFilters();
	// 	setFilters(apiResponse);
	// };


	const handleViewToggle = () => {
		// if (showGraphData) {
		// 	if (!rowData?.length) {
		// 		let filterIds: any = {};
		// 		Object.keys(selectedFilters).forEach((key) => {
		// 			if (typeof selectedFilters[key] === 'string') {
		// 				filterIds[key] = selectedFilters[key];
		// 			} else {
		// 				filterIds[key] = selectedFilters[key].map((item: any) => item.id).join(',');
		// 			}
		// 		});
		// 		retrieveTableData(filterIds);
		// 	}
		// 	setShowGraphData(false);
		// } else {
		// 	setShowGraphData(true);
		// }
	};

	const toggleFilterDrawer = (isOpen: boolean) => {
		if (showGraphData) {
			setIsFilterDrawerOpenInDefaultView(isOpen);
		} else {
			setIsFilterDrawerOpen(isOpen);
		}
	};

	const handleFilterExistingData = (filteredData: any) => {
		setSelectedFilters(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId, '0', '', '', [], '', '', '', '', false, {data: columnData});
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setSelectedFilters(selectedFilters);
		toggleFilterDrawer(false);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId, '0', '', '', [], '', '', '', '', false, {data: columnData});
	};

	const clearFilter = () => {
		setSelectedFilters([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId, '0', '', '', [], '', '', '', '', false, {data: columnData});
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = selectedFilters.filter((item: any) => item.id !== id);
		setSelectedFilters(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId, '0', '', '', [], '', '', '', '', false, {data: columnData});
	};

	const filterItems = selectedFilters.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const handlePointClick = async (point: any) => {
		// const filters = slaFilter.length ? slaFilter.map((item: any) => item.id).join(',') : '0';
		// const historicData = await getSLAData({ filters, type: filterType, dateType: '1' });
		// const currentPointData = historicData.find((item: any) => item.CallHour === point.options.CallHour);
		// const updatedPoint = { ...point };
		// updatedPoint.options.HistoricSLAPercentage = currentPointData?.HistoricSLAPercentage || 0;
		// setSelectedPoint(point);
		// setGraphRefPoint(point?.graphic?.element);
		// setIsPopoverOpen(true);
	};

	const graphOptions = {
		chart: {
			type: 'line',
			height: 345
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Time ➡',
				style: { fontWeight: 'bold' }
			},
			categories: [
				'00:00',
				'01:00',
				'02:00',
				'03:00',
				'04:00',
				'05:00',
				'06:00',
				'07:00',
				'08:00',
				'09:00',
				'10:00',
				'11:00',
				'12:00',
				'13:00',
				'14:00',
				'15:00',
				'16:00',
				'17:00',
				'18:00',
				'19:00',
				'20:00',
				'21:00',
				'22:00',
				'23:00'
			],
			plotLines: [
				{
					color: '#D42015',
					width: 1,
					value: liveValue,
					dashStyle: 'dash'
				}
			]
		},
		yAxis: {
			title: {
				text: ''
			},
			plotLines: [
				{
					color: '#008DFF',
					width: 2,
					value: targetValue,
					dashStyle: 'dash'
				}
			],
			gridLineDashStyle: 'dash',
			minorGridLineDashStyle: 'dash',
			minorTickInterval: 'auto',
			minorTickWidth: 0
		},
		legend: {
			layout: 'horizontal',
			align: 'right',
			verticalAlign: 'top',
			itemMarginTop: 10,
			symbolWidth: 50,
			enabled: false
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}%`;
			}
		},
		plotOptions: {
			line: {
				marker: {
					enabled: true
				}
			},
			series: {
				lineWidth: 1.5,
				cursor: 'pointer',
				fillColor: {
					linearGradient: [0, 0, 0, 250],
					stops: [
						[0, Highcharts?.getOptions()?.colors?.[0]],
						[
							1,
							Highcharts?.color(Highcharts?.getOptions()?.colors?.[0] || '')
								?.setOpacity(0)
								?.get('rgba')
						]
					]
				},
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
		series: graphData
	};


	const handlePageChange = (newPage: any) => {
		setController({
			...controller,
			page: newPage
		});
	};

	const handleChangeRowsPerPage = (event: any) => {
		setController({
			...controller,
			rowsPerPage: parseInt(event.target.value, 10),
			page: 1
		});
	};

	const handleOnSearch = (event: any) => {
		setSearchText(event.target.value.length ? event.target.value.toLowerCase() : '');
		if (event?.target?.value?.length) {
			const searchText = event.target.value.toLowerCase();
			const filteredRows = rowData.filter((item: any) => item.SkillName.toLowerCase().includes(searchText));
			setFilteredRowData(filteredRows);
		} else {
			setFilteredRowData(rowData);
		}
	};

	const onTableColumnSort = (columnName: string, order: any) => {
		setSortingObj({ sortOrderColumn: columnName, sortOrderType: order });
	};

	const toggleRefresh = () => {
		// const filterIds = selectedFilters.length ? selectedFilters.map((item: any) => item.id).join(',') : '0';
		const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
		setToggleState(true);
		retrieveTableData(filterIds);
	};


	useEffect(() => {
		// const filterIds = createHistoricFilterIds(selectedFilters);
		const filterIds = (selectedFilters.length && !(responseLength === selectedFilters.length)) ? selectedFilters.map((item: any) => item.id).join(',') : '0';
		// retrieveGraphData(filterIds); //this is commented as highCharts is there for Graph
		if (showGraphData === true) {
			retriveWFMGraphData(filterIds);
		} else if (showGraphData === false) {
			retrieveTableData(filterIds);
		}
		// if (interval) {
		// 	clearInterval(interval);
		// }
		// interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.WFM_DASHBOARD);
	}, [selectedFilters, showGraphData, controller, sortingObj]);

	// useEffect(() => {
	// 	retrieveFilters();
	// }, []);

	const Alert = (
		<Box sx={{ margin: '0 0.5rem', alignSelf: 'flex-end', position: 'absolute', top: 0, right: 0, zIndex: 100 }}>
			<AlertIcon />
		</Box>
	);

	function isObject(obj: any) {
		return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
	};

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				TotalAgentCount: item?.TotalAgentCount ? item?.TotalAgentCount : 0,
				AvgTalkTime: convertSecondsToTimeNew(item?.AvgTalkTime),
				AvgWrapupTime: convertSecondsToTimeNew(item?.AvgWrapupTime),
				AvgSpeedToAnswer: convertSecondsToTimeNew(item?.AvgSpeedToAnswer),
				AvgHandlingTime: convertSecondsToTimeNew(item?.AvgHandlingTime),
			};
			return rowObj;
		});
		return row;
	}

	return (
		<>
			<Card ref={downloadContent || chartElement} className="customCard_WFM">
				<div className="card-header">
					<div className='card-title'>
						Skill Summary<div className="card-title-badge">Live</div>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
						{/* <div className="card-title-badge">Live</div> */}
					</div>
					<div className="card-actions">
						{showGraphData ?
							<>
								<div>
									<ReportsPageHead
										// duration={durationLabel}
										filters={selectedFilters}
										// handleDuration={handleDuration}
										toggleFilterDrawer={toggleFilterDrawer}
										// toggleThresholdDrawer={toggleThresholdDrawer}
										showToggleIcon
										showGraphData={showGraphData}
										handleViewToggle={handleViewToggle}
									/>
								</div>
								<WidgetDownload Wtype={"historic"} duration={duration} element={downloadContent || chartElement} handleDownload={handleDownload} name={"Skill Summary(WFM)"}></WidgetDownload>
							</>
							:
							<>
								<WidgetSearch placeholder={'Search'} onTextChange={handleOnSearch}></WidgetSearch>
								<WidgetDownload element={downloadContent || chartElement} xlsx rowData={getRowData(filteredRowData)} columns={columnData} handleDownload={handleDownload} name={"Skill Summary(WFM)"}></WidgetDownload>
								<Stack>
									<IconButton onClick={onHamburgerClick}>
										<img src={`${process.env.PUBLIC_URL}/icons/table-hamburger-icon.svg`} width="20" alt="Hamburger" style={{ marginTop: '5px' }}></img>
									</IconButton>
								</Stack>
								<ReportsPageHead
									filters={selectedFilters}
									toggleFilterDrawer={toggleFilterDrawer}
									showToggleIconWithName
									showGraphData={showGraphData}
									handleViewToggle={handleViewToggle}
								/>
							</>
						}
					</div>
				</div>

				{showGraphData ?
					(
						<Box sx={{ width: '100%', typography: 'body1', marginBottom: '1rem' }}>
							{Object.values(selectedFilters).length > 0 && <FilterItems selectedFilters={selectedFilters} maxItems={3} />}
							{/* <HighchartsReact ref={chartElement} highcharts={Highcharts} options={graphOptions} /> */}

							{/* <Grid container sx={{ padding: '1rem' }}>
                            <Grid item container sx={{ maxHeight: '350px', height: '350px' }} spacing={2}>
                                <Grid item xs={4}>
                                    <Card className={classes.cardTile} sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                                    </Card>
                                </Grid>
                                <Grid item xs>
                                    <Card className={classes.cardTile} sx={{ height: '100%' }}>
                                        <Grid container direction={'column'} height="100%">
                                            <Grid item className={`${classes.textCard}`} sx={{
                                                flex: 1,
                                                background: isObject(data?.TotalCalls) ? data.TotalCalls.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                            }}>
                                                <Box>
                                                    <Typography className={classes.textCardTitle}>Calls InQueue</Typography>
                                                    <Typography className={classes.textCardSubtitle}>{isObject(data?.TotalCalls) ? data.TotalCalls.value : data.TotalCalls}</Typography>
                                                </Box>
                                            </Grid>
                                            <Divider variant="middle" flexItem />
                                            <Grid item className={`${classes.textCard}`} sx={{
                                                flex: 1,
                                                background: isObject(data?.avgQueueTime) ? data.avgQueueTime.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                            }}>
                                                <Box>
                                                    <Typography className={classes.textCardTitle} >Avg. Queue Time</Typography>
                                                    <Typography className={classes.textCardSubtitle} placeholder="134567890-">{isObject(data?.avgQueueTime) ? data.avgQueueTime.value : data.avgQueueTime}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction={'column'} height="100%" gap={2}>
                                        <Grid component={Card} item className={`${classes.textCard} ${classes.cardTile}`} sx={{
                                            flex: 1,
                                            background: isObject(data?.avgWrapUpTime) ? data.avgWrapUpTime.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                        }}>
                                            <Box>
                                                <Typography className={classes.textCardTitle}>Avg. Wrapup Time</Typography>
                                                <Typography className={classes.textCardSubtitle}>{isObject(data?.avgWrapUpTime) ? data.avgWrapUpTime.value : data.avgWrapUpTime}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid component={Card} item className={`${classes.textCard} ${classes.cardTile}`} sx={{
                                            flex: 1,
                                            background: isObject(data?.avgPickUpTime) ? data.avgPickUpTime.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                        }}>
                                            {Alert}
                                            <Box>
                                                <Typography className={classes.textCardTitle}>Avg. Pickup Time</Typography>
                                                <Typography className={classes.textCardSubtitle}>{isObject(data?.avgPickUpTime) ? data.avgPickUpTime.value : data.avgPickUpTime}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction={'column'} height="100%" gap={2}>
                                        <Grid component={Card} item className={`${classes.textCard} ${classes.cardTile}`} sx={{
                                            flex: 1,
                                            background: isObject(data?.avgTalkTime) ? data.avgTalkTime.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                        }}>
                                            <Box padding={2}>
                                                <Typography className={classes.textCardTitle}>Avg. Talk Time</Typography>
                                                <Typography className={classes.textCardSubtitle}>{isObject(data?.avgTalkTime) ? data.avgTalkTime.value : data.avgTalkTime}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            component={Card}
                                            className={`${classes.textCard} ${classes.cardTile}`}
                                            item
                                            sx={{
                                                flex: 1, flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'relative',
                                                background: isObject(data?.avgHandlingTime) ? data.avgHandlingTime.warning === "warning" ? "rgb(242, 194, 194, 0.3) " : "" : ""
                                            }}
                                        >
                                            <Box>
                                                <Typography className={classes.textCardTitle}>Avg. Handling Time</Typography>
                                                <Typography className={classes.textCardSubtitle}>{isObject(data?.avgHandlingTime) ? data.avgHandlingTime.value : data.avgHandlingTime}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <Grid container direction={'column'} height="100%" gap={2}>
                                        <Grid component={Card} item className={`${classes.textCard} ${classes.cardTile}`} sx={{ flex: 1 }}>
                                            <Box padding={2}>
                                                <Typography className={classes.textCardTitle}>Max Call Count</Typography>
                                                <Typography className={classes.textCardSubtitle}>{data?.maxCalls}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            component={Card}
                                            className={`${classes.textCard} ${classes.cardTile}`}
                                            item
                                            sx={{ flex: 1, flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'relative' }}
                                        >
                                            <Box>
                                                <Typography className={classes.textCardTitle}>Max Call Date</Typography>
                                                <Typography className={classes.textCardSubtitle}>{data?.maxCallDate}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
						</Box>
					)
					:
					(
						<Box ref={downloadContent || chartElement} sx={{ width: '100%', typography: 'body1', height: '100%' }}>
							{selectedFilters.length > 0 && (
								<Box className="customTabPanel">
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<div className="filter-outer">
												<div className="filter-container">
													<Stack direction="row" spacing={1}>
														{filterItems}
													</Stack>
												</div>
												<div className="filter-remove" onClick={clearFilter}>
													Remove
												</div>
											</div>
										</Grid>
									</Grid>
								</Box>
							)}
							<Box sx={{ width: '100%', typography: 'body1', padding: '8px', height: 'calc(100% - 47px)', overflowY: 'auto' }} ref={chartContainerRef}>
								<OZTable
									columns={columnData}
									// pagination
									rows={filteredRowData}
									component={Card}
									noMaxHeight={true}
									height={Math.floor(selectedFilters.length > 0 ? chartDimensions?.height - 72 : chartDimensions?.height)}
									onPageChangeEvent={handlePageChange}
									pageController={controller}
									totalCount={totalPages}
									onRowsChangeEvent={handleChangeRowsPerPage}
									onTableColumnSort={onTableColumnSort}
									totalRows={totalRows}
								></OZTable>
							</Box>
						</Box>
					)}
			</Card>
			{showGraphData ?
				(
					{/* <HistoricReportsFilterDrawer
				isOpen={isFilterDrawerOpen}
				filters={filters}
				toggleDrawer={toggleFilterDrawer}
				applyFilters={handleApplyFilters}
				updatedFilterValues={selectedFilters}
				singleSelect={['campaignName', 'skill']}
			/> */}
				)
				:
				(
					<>
						<FilterDrawer isOpen={isFilterDrawerOpen} allowedFilters={allowedFilters} toggleDrawer={toggleFilterDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={selectedFilters} additionalApiParams={{ campaignType: 'inbound' }} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
						<NewSelectPopover
							isOpen={isColumnsPopoverOpen}
							elementsArray={columns || []}
							anchorRef={columnsEleRefPoint}
							handlePopoverClose={handleOnColumnsPopoverClose}
							selectedElements={columnData}
							handleOnSelectionChange={handleOnSelectColumnChange}
							addMetricsLabel
						/>
					</>
				)}
			{/* <ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Set'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} /> */}
		</>
	);
};

export default WFMDashboard;