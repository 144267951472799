import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Grid
} from '@mui/material';
import { getUserName } from '../../services/auth-service';

const LandingPage = () => {

	const getFormattedUserName = () => {
		const username = getUserName()
		if (typeof username !== 'string') return username;
		return username.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
	}

	const username = getFormattedUserName()
	const [currentDate, setCurrentDate] = useState('');

	useEffect(() => {
		// Update date based on user's locale and timezone
		const updateDate = () => {
			const now = new Date();
			try {
				// Try to use the user's locale first
				const userLocale = navigator.language || 'en';
				const formattedDate = new Intl.DateTimeFormat(userLocale, {
					weekday: 'long',
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				}).format(now);
				setCurrentDate(formattedDate);
			} catch (error) {
				// Fallback to a basic date format if there's any issue
				setCurrentDate(now.toLocaleDateString());
			}
		};

		updateDate();
		// Update every minute to keep the date current
		const interval = setInterval(updateDate, 60000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Box
			sx={{
				bgcolor: '#f8faff', // Light blue background
				p: 4,
				height: 'calc(100vh - 140px)',
				backgroundColor: 'white',
				borderRadius: '12px'
			}}
		>
			<Grid container spacing={2}>
				{/* Left side - Welcome text */}
				<Grid item xs={6}>
					<Box>
						<Typography
							variant="h5"
							component="h1"
							sx={{
								fontWeight: 500,
								color: '#1a365d', // Darker blue for main text
								mb: 1,
								display: 'flex',
								alignItems: 'center',
								gap: 1
							}}
						>
							Welcome, {username} <span style={{ fontSize: '24px' }}>👋</span>
						</Typography>

						<Typography
							variant="body2"
							sx={{
								color: '#4a5568', // Muted blue for secondary text
								fontWeight: 400
							}}
						>
							{currentDate}
						</Typography>
					</Box>
				</Grid>

				{/* Right side - Image
				<Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Box
						component="img"
						src={`${process.env.PUBLIC_URL}/img/image.png`}
						alt="Welcome illustration"
						sx={{
							maxWidth: '100%',
							height: 'auto',
							objectFit: 'contain',
							borderRadius: '8px',
							boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)', // Subtle shadow
							bgcolor: '#ffffff' // White background for image
						}}
					/>
				</Grid> */}
			</Grid>
		</Box>
	);
};

export default LandingPage;