import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { Alert, Backdrop, Button, CircularProgress, Grid, IconButton, Snackbar, TextField } from '@mui/material';
import html2canvas from 'html2canvas';
import * as React from 'react';
import { useRef } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { useNavigate } from 'react-router-dom';
import Dummy from '../../components/widgets/Dummy/Dummy';
import WidgetsSidebar from '../../components/WidgetsSidebar/WidgetsSidebar';
import { OZContext } from '../../contexts/OZContext';
import { dashboard, uploadImage } from '../../services/api-service';
import { WIDGET_COMPONENT_MAPPING } from '../../utils/constants';
import '../CreateDashboard/CreateDashboard.css';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import * as _ from "lodash"
import { PRESET_MOCK } from '../../mocks/mock-data';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface OZLayout extends Layout {
	component?: any;
	widgetName: string;
	widgetProps?: any;
	widgetFilters?: any;
	widgetThresholds?: any;
}

type Props = {
	selectedDashboard?: any;
};

const CreateDashboard = ({ selectedDashboard }: Props) => {
	const { useState } = React;
	const navigate = useNavigate();
	const selectedLayouts = (selectedDashboard?.dashboard?.layouts?.lg || []).map((widget: any) => ({
		x: 0,
		y: 0,
		h: 2,
		w: 6,
		...widget,
		maxH: 3,
		component: WIDGET_COMPONENT_MAPPING[widget.widgetName] || Dummy
	}));

	React.useEffect(() => {
		getPresetData();
		setDashboardNameBeforeOnChange(dashboardName)
	}, []);



	const presetWallboardData = {
		dashboard: {
			id: 1,
			dashboardName: 'Wallboard',
			favourite: true,
			thumbnail: `${process.env.PUBLIC_URL}/thumbnails/wallboard-preset-thumbnail.png`
		}
	};

	const parseDashboardData = (data: any): any => {
		const finalData: any[] = [];
		for (const item of data) {
			const parsedItem = { ...item };
			try {
				parsedItem.dashboard = JSON.parse(parsedItem.dashboard);
			} catch {
				parsedItem.dashboard = {};
			}
			finalData.push(parsedItem);
		}

		finalData.sort((a: any, b: any) => {
			if (parseInt(a?.userId) === -2 && parseInt(b?.userId) !== -2) {
				return -1;
			} else if (parseInt(a?.userId) !== -2 && parseInt(b?.userId) === -2) {
				return 1;
			}
			else {
				if (a?.dashboard?.favourite && !b?.dashboard?.favourite) {
					return -1;
				} else if (b?.dashboard?.favourite && !a?.dashboard?.favourite) {
					return 1;
				}
				return 0;
			}
		})
		return finalData;
	};

	const getPresetData = async () => {
		const response = await dashboard('list', {});
		if (response?.status === 200) {
			const data = JSON.parse((await response.json()).Data);
			const parsedData = [presetWallboardData, ...parseDashboardData(data)];
			setPresetData(parsedData);
		} else {
			setPresetData(PRESET_MOCK);
		}
	};

	const resizeEvent = new Event('widgetResize');
	const { dashboardTabs, setDashboardTabs } = React.useContext(OZContext);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [loaderOpen, setLoaderOpen] = useState<boolean>(false);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [favourite, setFavourite] = useState(selectedDashboard?.dashboard?.favourite || false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarMessageSuccess, setSnackbarMessageSuccess] = React.useState('');
	const [openSnackbarSuccess, setOpenSnackbarSuccess] = React.useState(false);
	const [dashboardName, setDashboardName] = useState(selectedDashboard?.dashboard?.dashboardName || 'Untitled Dashboard');
	const [dashboardNameBeforeOnChange, setDashboardNameBeforeOnChange] = useState('');
	const [onChange, setOnChange] = useState(false);
	const breakpoints: any = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
	const cols: any = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
	const [layoutItems, setLayoutItems] = useState<OZLayout[]>(selectedLayouts);
	const [layouts, setLayouts] = useState({ lg: layoutItems });
	const dashboardContent = useRef<any>(null);
	const [presetData, setPresetData] = React.useState<any>(null);

	const handleLayoutChange = (layout: OZLayout[]) => {
		const newLayout = [];
		for (let i = 0; i < layouts.lg.length; i++) {
			let date = _.merge(layouts.lg[i], layout[i])
			newLayout.push(date);
		}
		setLayouts({ lg: newLayout });
		window.dispatchEvent(resizeEvent);
	};

	// const handleFilterChange = (selectedFilters: any[], filterSection: any, index: any, selectedHeaders: any[] = [], headerSection: any, isCarousel = false, internalIdx: number, existingSelectedFilters: any[]) => {
	// 	if(isCarousel){
	// 		existingSelectedFilters[internalIdx] = {filters: selectedFilters, filterSection: filterSection, filterHeaders: selectedHeaders.length > 0 ? selectedHeaders : undefined, headerSection : headerSection};
	// 	}
	// 	const widgetFilters = {filters: selectedFilters, filterSection: filterSection, filterHeaders: selectedHeaders.length > 0 ? selectedHeaders : undefined, headerSection : headerSection};
	// 	const updatedLayoutItems = layoutItems.map(item => {
	// 		if (item.i === index) {
	// 		  return {
	// 			...item,
	// 			widgetFilters: isCarousel ? existingSelectedFilters : widgetFilters
	// 		  };
	// 		}
	// 		return item;
	// 	});
	// 	const updatedLayout = layoutItems.map(item => {
	// 		if (item.i === index) {
	// 		  return {
	// 			...item,
	// 			widgetFilters: isCarousel ? existingSelectedFilters : widgetFilters
	// 		  };
	// 		}
	// 		return item;
	// 	});
	// 	setLayoutItems(updatedLayoutItems);
	// 	setLayouts({ lg: updatedLayout });
	// };

	//::::::::::::::::::::written for the Dynamic Component::::::::::::::::::://
	const handleFilterChange = (selectedFilters: any[], filterSection: any, index: any, procedureId: any = '0', dynamicHeaders: any = '', headersToMap: any = '', filterSelectionToShow: any = [], widgetTitleName: any = '', extraParams: any = '', refreshInterval: any = '', subUserId: any = '', dynamicWidget: any = false, selectedHeaders: any = {}, headerSection: any, isCarousel = false, internalIdx: number, existingSelectedFilters: any[]) => {
		if (isCarousel) {
			existingSelectedFilters[internalIdx] = { filters: selectedFilters, filterSection: filterSection, filterHeaders: Object.keys(selectedHeaders).length > 0 ? selectedHeaders : undefined, headerSection: headerSection };
		}
		const widgetFilters = { filters: selectedFilters, filterSection: filterSection, procedureId: procedureId, dynamicHeaders: dynamicHeaders, headersToMap: headersToMap, filterSelectionToShow: filterSelectionToShow, widgetTitleName: widgetTitleName, extraParams: extraParams, refreshInterval: refreshInterval, subUserId: subUserId, dynamicWidget: dynamicWidget, filterHeaders: Object.keys(selectedHeaders).length > 0 ? selectedHeaders : undefined, headerSection: headerSection };
		const updatedLayoutItems = layoutItems.map(item => {
			if (item.i === index) {
				return {
					...item,
					widgetFilters: isCarousel ? existingSelectedFilters : widgetFilters
				};
			}
			return item;
		});
		const updatedLayout = layoutItems.map(item => {
			if (item.i === index) {
				return {
					...item,
					widgetFilters: isCarousel ? existingSelectedFilters : widgetFilters
				};
			}
			return item;
		});
		setLayoutItems(updatedLayoutItems);
		setLayouts({ lg: updatedLayout });
	};

	const handleThresholdChange = (selectedThresholds: any[], showSkillList: boolean, index: any) => {
		const updatedLayoutItems = layoutItems.map(item => {
			if (item.i === index) {
				return {
					...item,
					widgetThresholds: { thresholdList: selectedThresholds, showSkillList }
				};
			}
			return item;
		});
		const updatedLayout = layoutItems.map(item => {
			if (item.i === index) {
				return {
					...item,
					widgetThresholds: { thresholdList: selectedThresholds, showSkillList }
				};
			}
			return item;
		});
		setLayoutItems(updatedLayoutItems);
		setLayouts({ lg: updatedLayout });
	}

	const handleOnDrop = (currentLayout: Layout[], item: Layout, event: DragEvent) => {
		const newLayout: OZLayout[] = [...layouts.lg].filter((item) => item.i !== '__dropping-elem__');
		const widgetData: any = JSON.parse(event.dataTransfer?.getData('widgetData') || '{}');
		if (!widgetData.widgetName || !WIDGET_COMPONENT_MAPPING[widgetData.widgetName]) {
			return;
		}
		const newWidget: OZLayout = {
			...item,
			i: layoutItems.length.toString(),
			w: widgetData.width || 6,
			h: 2,
			minW: widgetData.width || 6,
			maxW: 12,
			minH: 2,
			maxH: 3,
			component: WIDGET_COMPONENT_MAPPING[widgetData.widgetName],
			widgetName: widgetData.widgetName
		};
		newLayout.push(newWidget);
		setLayoutItems(newLayout);
		setLayouts({ lg: newLayout });
	};
	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const deleteLayoutItem = (itemToDelete: Layout) => {
		const newLayout: OZLayout[] = [...layouts.lg].filter((item) => item.i !== itemToDelete.i).map((item, idx) => ({ ...item, i: idx.toString() }));
		setLayoutItems(newLayout);
		setLayouts({ lg: newLayout });
		setSnackbarMessage('You have deleted the Widget!');
		setOpenSnackbar(true);
	};

	const handleOnCarouselWidgetChange = (selectedWidgets: any[], index: number) => {
		const newLayout: OZLayout[] = [...layouts.lg].filter((item) => item.i !== '__dropping-elem__');
		newLayout[index].widgetProps = { selectedWidgets };
		setLayouts({ lg: newLayout });
	};

	const generateDOM = () => {
		return layouts.lg.map((layout, idx) => {
			const ComponentToRender = layout.component;
			return (
				<div key={idx} className="grid-item" >
					<div className="delete-icon" onClick={() => deleteLayoutItem(layout)}>
						<DoDisturbOnIcon style={{ color: 'red' }} />
					</div>
					<div className="widget-container" >
						<ComponentToRender
							name={layout.widgetName}
							{...(layout?.widgetProps || {})}
							enableWidgetEdit={true}
							onWidgetChange={(selectedWidgets: any[]) => handleOnCarouselWidgetChange(selectedWidgets, idx)}
							handleFilterChange={handleFilterChange}
							handleThresholdChange={handleThresholdChange}
							selectedFilters={layout?.widgetFilters || {}}
							selectedThresholds={layout?.widgetThresholds || {}}
							widgetId={layout?.i}
						/>
					</div>
				</div>
			);
		});
	};

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
	};

	const handleCloseSuccess = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbarSuccess(false);
	};

	const getDashboardImageURL = async () => {
		if (!dashboardContent?.current?.elementRef?.current) return;
		const canvas = await html2canvas(dashboardContent.current.elementRef.current);
		const imgData = canvas.toDataURL('image/png');
		const blobResponse = await fetch(imgData);
		const file = new File([await blobResponse.blob()], 'fileName.png', { type: 'image/png' });
		const formData = new FormData();
		formData.append('fileName', file);
		const response = await uploadImage(formData);
		if (response?.status === 'success') {
			return response.message;
		}
		return null;
	};
	console.log("dashboardName", dashboardName)
	console.log("dashboardName>>>", dashboardNameBeforeOnChange)
	console.log("dashboardList", presetData)
	const saveDashboard = async () => {
		setLoaderOpen(true);
		if (!dashboardName || !layouts?.lg?.length) {
			setSnackbarMessage('Please enter dashboard name and add widgets!');
			setLoaderOpen(false);
			setOpenSnackbar(true);
			return;
		}
		// eslint-disable-next-line array-callback-return

		const alreadyExistingName = presetData.map((data: any) => {
			const isDashboardNameInArray = presetData.some((item: any) => item.dashboard.dashboardName === dashboardName);
			if (!onChange) {
				return true;
			}
			else if (onChange && dashboardNameBeforeOnChange === dashboardName) {
				return true;
			}
			else if (onChange && dashboardNameBeforeOnChange !== dashboardName && !isDashboardNameInArray) {
				return true;
			}
			else {
				setSnackbarMessage("DashboardName already exists. Please Enter different DashboardName");
				setOpenSnackbar(true);
				setLoaderOpen(false);
				return false;
			}
		});

		// remove empty threshold fields
		if (layouts?.lg?.length > 0) {
			layouts.lg.forEach((layout: any) => {
				if (layout.widgetThresholds?.thresholdList?.length > 12) {
					let thresholdList: any = [];
					layout.widgetThresholds.thresholdList.forEach((threshold: any) => {
						let valueChanged = false;
						let fields: any = threshold?.fields;
						if (fields?.length > 0) {
							fields.forEach((field: any) => {
								if (field.value && field.value !== ' ') {
									valueChanged = true;
								}
							});
							if (valueChanged) {
								thresholdList.push(threshold);
							}
						}
					});
					layout.widgetThresholds.thresholdList = thresholdList;
				}
			});
		}

		const thumbnaillUrl = await getDashboardImageURL();
		if (!thumbnaillUrl) {
			setSnackbarMessage('Error Uploading image!!');
			setLoaderOpen(false);
			setOpenSnackbar(true);
			return;
		}
		const metadata: any = { layouts, dashboardName, favourite, thumbnail: thumbnaillUrl };
		const action = selectedDashboard?.id ? 'update' : 'save';
		const params = { metadata: JSON.stringify(metadata), id: undefined, SubUsers: layouts?.lg[0]?.widgetFilters?.dynamicWidget ? layouts?.lg[0]?.widgetFilters?.subUserId : "" };
		if (selectedDashboard?.id) {
			params.id = selectedDashboard?.id;
		}
		if (alreadyExistingName.every((value: any) => value === true)) {
			const response = await dashboard(action, params);
			if (response.status !== 200) {
				setSnackbarMessage(`Error ${action === 'save' ? 'saving' : 'updating'} dashboard!`);
				setLoaderOpen(false);
				setOpenSnackbar(true);
				return;
			} else {
				setSnackbarMessageSuccess(`Dashboard ${action === 'save' ? 'saved' : 'updated'} successfully`);
				setLoaderOpen(false);
				setOpenSnackbarSuccess(true);
			}
			if (selectedDashboard?.id) {
				const newDashboardTabs = dashboardTabs.filter((item: any) => item.id !== selectedDashboard?.id);
				setDashboardTabs(newDashboardTabs);
			}
			// setLoaderOpen(false);
			setTimeout(() => {
				navigate('/preset');
			}, 1000);

		}
	};
	const toggleFavourite = () => {
		setFavourite(!favourite);
	};
	const handleNameChange = (event: any) => {
		setDashboardName(event.target.value);
		setOnChange(true)
	};
	return (
		<>
			<Grid container direction="row" justifyContent="space-between" alignItems="center">
				<Grid item>
					<TextField id="dashboard-name" value={dashboardName} onChange={handleNameChange} variant="outlined" />
					<IconButton onClick={() => toggleFavourite()}>{favourite ? <StarIcon color="warning" /> : <StarOutlineOutlinedIcon />}</IconButton>
				</Grid>
				<Grid item>
					<Button variant="outlined" startIcon={<WidgetsOutlinedIcon />} onClick={() => toggleDrawer(true)}>
						Add Widget
					</Button>
					<Button variant="contained" onClick={() => saveDashboard()} style={{ marginLeft: '12px' }}>
						Save
					</Button>
				</Grid>
			</Grid>
			<ResponsiveReactGridLayout
				ref={dashboardContent}
				rowHeight={200}
				breakpoints={breakpoints}
				cols={cols}
				layouts={layouts}
				onLayoutChange={handleLayoutChange}
				onDrop={handleOnDrop}
				isDroppable={true}
			>
				{generateDOM()}
			</ResponsiveReactGridLayout>
			<WidgetsSidebar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}></WidgetsSidebar>
			<Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleClose}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'red' }} />
					}}
					onClose={handleClose}
					severity="error"
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<Snackbar sx={{ zIndex: 10005 }} open={openSnackbarSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000} onClose={handleCloseSuccess}>
				<Alert
					iconMapping={{
						error: <DoDisturbOnIcon style={{ color: 'green' }} />
					}}
					onClose={handleCloseSuccess}
					severity="error"
				>
					{snackbarMessageSuccess}
				</Alert>
			</Snackbar>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loaderOpen}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};

export default CreateDashboard;
