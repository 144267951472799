import React, { useState, useMemo, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, TextField, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import OZRichText from '../../../components/admin/common/OZRichText/OZRichText';
import './Settings.css';
import OZDialog from '../../../components/admin/common/OZDialog';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { addTemplate, deleteSettingCategoryById, deleteTemplateById, saveSettingCategory, updateTemplate } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { ScrollToFieldError } from '../../../components/admin/common/ScrollToFieldError';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import OZSelect from '../../../components/admin/common/OZSelect';

const SettingParameterView = (viewProps) => {
    const TemplateFieldsView = (props) => {
        return (
            <div>
                <label className='oz-template-view-value' dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        )
    }

    let editButtonStyle = useMemo(
        () => ({
            fontWeight: 400,
            fontSize: "14px",
            color: "#3D8BF8",
            width: "32px",
            minWidth: "29px",
        }),
        []
    );

    return (
        <div className="oz-Template-view-lablel-container">
            <div style={{ display: 'flex', alignContent: 'center' }}>
                {viewProps?.data?.name &&
                    <TemplateFieldsView data={viewProps?.data?.name} label={"Category Name *"} />
                }
                <Typography
                    size="small"
                    variant="text"
                    sx={{ ...editButtonStyle, cursor: "pointer" }}
                    onClick={() => {
                        viewProps?.clickOnEdit();
                    }}
                    paddingtop={"5px"}
                >
                    Edit
                </Typography>

            </div>
        </div>
    )
}
const SettingCategoriesForm = (props) => {
    console.log("here data is ", props);
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const [open, setState] = useState(true)
    const [settingParameterFormMode, setSettingParamterFormMode] = useState(initMode);
    const [settingParameterAnchorE1, setSettingParameterAnchorE1] = useState(false);
    const anchorRef = useRef();
    const opens = Boolean(settingParameterAnchorE1);
    const [openDeleteDailog, setDeleteDialog] = useState(false)


    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string('Enter Category Name ')
            .min(5, "Category Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Category Name cannot start and end with Special characters.")
            .max(50, "Category Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Category Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_| )?[A-Za-z0-9])+)$/, "Category Name should not be empty and it should be between 5 to 50 alphanumeric characters long and allows special characters like _,spaces and Category Name cannot start and end with Special characters.")
            .required('Category Name is required'),
    })), []);

    const closeSettingParameterForm = () => {
        props.onClose();
        setState(false);
    };

    const closeSettingParameterFormEdit = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.view);
    };

    const EditSettingParameterForm = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.edit);
        setState(true);
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    const morevertclick = () => {
        setTimeout(() => setSettingParameterAnchorE1(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setSettingParameterAnchorE1(null);
    };

    function openDeleteDialog() {
        setSettingParameterAnchorE1(null);
        setDeleteDialog(true)
    };

    const deleteCategory = () => {
        setSettingParameterAnchorE1(null);
        deleteCategoryById(props.data.id)
        setDeleteDialog(false)
    };

    async function deleteCategoryById() {
        try {
            deleteSettingCategoryById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSettingParameterForm();
                        props.emailTemplate();
                        showSuccessNotification(resp.Message)

                    } else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const onClickDiscardButton = () => {
        setSettingParamterFormMode(ADMIN_FORM_MODES.view);
    };

    async function addSettingCategory(data) {
        data.type = "email"
        try {
            saveSettingCategory(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification(`Category ${data.name} has been added Successfully`);
                        props.emailTemplate();
                        closeSettingParameterForm();
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }

                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateSettingCategory(data) {
        data.type = "email"
        try {
            saveSettingCategory(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeSettingParameterForm();
                        props.emailTemplate();
                        showSuccessNotification(`Category ${data.name} has been updated Successfully`);
                    }
                    else {
                        showErrorNotification(resp?.Message);
                        // showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const styleListItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {
        let initData = props.data || {
            name: '',
        };
        let style1 = useMemo(() => ({
            width: '600px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxHeight: '76vh',
            overflowY: 'auto',
            height: '10rem',
            padding: '5px',
            maxWidth: '600px'
        }), [])


        return (
            <div className='oz-email-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);

                        if (settingParameterFormMode === ADMIN_FORM_MODES.add) {
                            addSettingCategory(data);
                        }
                        if (settingParameterFormMode !== ADMIN_FORM_MODES.add) {
                            updateSettingCategory(data);
                        }
                    }}>
                    {props => (
                        <form onSubmit={props.handleSubmit} id='settingForm' className='ozcw-scrollbar' style={settingParameterFormMode === ADMIN_FORM_MODES.add ? style1 :
                            style2} >
                            <Grid container spacing={1} >
                                <Grid container spacing={2} direction={'row'}>
                                    <>
                                        <Grid item xs={12} sx={{ marginLeft: '10px', marginTop: '30px' }}>
                                            <OZInput
                                                name="name"
                                                label="Category *"
                                                placeholder="Category Name"
                                            />
                                        </Grid>
                                    </>
                                </Grid>
                            </Grid>
                            <ScrollToFieldError />
                        </form>
                    )}
                </Formik>
                {settingParameterFormMode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-emailtemplate-drawer-buttons' style={{ bottom: 6 }}>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={settingParameterFormMode === ADMIN_FORM_MODES.view} sx={{ ...buttonStyle, marginLeft: "10px" }} variant='outlined' onClick={settingParameterFormMode === ADMIN_FORM_MODES.edit ? closeSettingParameterFormEdit : closeSettingParameterForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={settingParameterFormMode === ADMIN_FORM_MODES.view}

                                variant="contained"
                                type="submit"
                                sx={{ ...buttonStyle, width: "130px" }}
                                form='settingForm'
                            >
                                Save Changes
                            </Button></Grid>
                    </div>)}
            </div >
        )
    };
    return (
        <div>
            <div>
                {settingParameterFormMode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Setting Categories"
                        formType="Setting"
                        subTitle=" Create Settings Category"
                        saveButtonProps={{
                            form: "settingForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        open={open}
                        mode={settingParameterFormMode}
                        onClose={closeSettingParameterForm}>
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {settingParameterFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {(settingParameterFormMode === ADMIN_FORM_MODES.view || settingParameterFormMode === ADMIN_FORM_MODES.edit) && (
                <OZDrawer
                    open={open}
                    formType='template'
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                {(settingParameterFormMode !== ADMIN_FORM_MODES.edit && (newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit'))) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>}
                                {settingParameterFormMode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                marginLeft: "0px",
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography>
                                    </Tooltip>
                                }
                                <div >
                                    <Menu
                                        anchorEl={settingParameterAnchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleListItemButton }} disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteDailog &&
                                <DeleteDialog
                                    title={"Delete Setting Category"}
                                    content={`This will delete (${props.data.name}) category permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDeleteDailog}
                                    deleteFunction={deleteCategory}
                                />
                            }
                        </div>
                    }
                    onClose={closeSettingParameterForm}
                    hideEditIcon={true}
                    // hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                    mode={settingParameterFormMode}>
                    {settingParameterFormMode === ADMIN_FORM_MODES.view && <SettingParameterView data={props.data} clickOnEdit={EditSettingParameterForm} />}
                </OZDrawer>
            )}

            {settingParameterFormMode === ADMIN_FORM_MODES.edit && (
                <OZDialog
                    formType="Setting"
                    subTitle="Edit Setting Category"
                    hideActions
                    open={open}
                    mode={settingParameterFormMode}
                    onClose={closeSettingParameterFormEdit}>
                    <Divider style={{ color: "#E6E5E6" }}></Divider>
                    {settingParameterFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDialog>
            )}
        </div>
    );
};
export default SettingCategoriesForm;
