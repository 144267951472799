import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import * as React from 'react';
import { getAdminSettingsList, getAgentStateDetail, getContinuousBargeStatus, getHuntingStatusOfflineAgents, getWidgetConfig, releaseAgent, releaseAgentByFwpId } from '../../../services/api-service';
// import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import useChartResize from '../../../hooks/useChartResize';
import { Badge, Box, Chip, Stack, Grid, TableRow, tableCellClasses, TableCell, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { STATUS_CLASS_MAP, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import { secondsToMinSec, checkDataAccessFilters, determineFilterType, secondsToTime, checkRoles } from '../../../utils/util';
import { styled } from '@mui/material/styles';
import HuntingStatusVTable from './HuntingStatusVTable';
import daysjs from 'dayjs';
import BargeInDrawerSip from '../../BargeinDrawerSip/BargeinDrawerSip';

let interval: any;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#F0F1F2',
        color: '#000000',
        padding: '10px 16px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: '10px 16px'
    }
}));

export default function HuntingStatus(props: any) {
    const { useState, useRef } = React;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [allowedFilters, setAllowedFilters] = useState(['Campaign', 'Skill']);
    const [filterType, setFilterType] = useState(props?.selectedFilters?.filterSection?.id || (checkDataAccessFilters() ? 2 : 1));
    const [filterSection, setFilterSection] = useState(props?.selectedFilters?.filterSection || {});
    const [responseLength, setresponseLength] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [releaseDetails, setReleaseDetails] = useState([]);
    const [onselect, setOnSelect] = useState(false);
    const [isBargeInOpen, setIsBargeInOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedAgentDetails, setSelectedAgentDetails] = useState(null);
    const [selectedAgentEventDetails, setSelectedAgentEventDetails] = useState(null);
    const [eleRefPoint, setElRefPoint] = useState(null);
    const [sipClient, setSipClient] = useState("");
    const [sipUrl, setSipUrl] = useState("");
    const [bargeinCallType, setBargeinCallType] = useState("");
    const [huntingStatusFilter, setHuntingStatusFilter] = useState(props?.selectedFilters?.filters || []);
    const [settings, setSettings] = useState([]);
    const [continuousBarge, setContinuousBarge] = useState(false);
    const tableDataRef = useRef(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const { chartContainerRef, chartDimensions } = useChartResize();

    const columns = React.useMemo(() => [
        { name: 'SNO', key: 'SNO', minWidth: '80px' },
        { name: 'Next Flag', key: 'NextFlag', minWidth: '80px' },
        { name: 'Phone Name', key: 'PhoneName', },
        { name: 'Phone Number', key: 'PhoneNumber' },
        { name: 'State', key: 'State', minWidth: '150px' },
        { name: 'UCID', key: 'UCID', minWidth: '200px' },
        { name: 'Call Status', key: 'CallStatus' },
        { name: 'Last Selected', key: 'LastSelected', type: 'DateTime' },
        { name: 'Since', key: 'Since' },
        { name: 'Contact', key: 'Contact' },
        { id: 'adminAction', label: 'Admin Action', minWidth: 185, }
    ], []);

    React.useEffect(() => {
        getSettings();
        continuousBargeEnabled();
    }, [bargeinCallType])

    const getSettings = () => {
        try {
            getAdminSettingsList().then((resp: any) => {
                setSettings(resp.Data);
                resp?.Data?.map((item: any) => {
                    if (item?.ParameterCode === "BARGEIN_CALL_TYPE") {
                        if (item?.ParameterValue === null) {
                            setBargeinCallType(item?.DefaultValue);
                        } else if (item?.ParameterValue !== null) {
                            setBargeinCallType(item?.ParameterValue);
                        }
                    }
                    if (item?.ParameterCode === "SIP_CLIENT") {
                        if (item?.ParameterValue === null) {
                            setSipClient(item?.DefaultValue);
                        } else if (item?.ParameterValue !== null) {
                            setSipClient(item?.ParameterValue);
                        }
                    }
                    if (item?.ParameterCode === "SIP_URL") {
                        if (item?.ParameterValue === null) {
                            setSipUrl(item?.DefaultValue);
                        } else if (item?.ParameterValue !== null) {
                            setSipUrl(item?.ParameterValue);
                        }
                    }
                })
            })
                .catch((e) => {
                    console.log("error", e);
                });
        } catch (e) {
            console.log("error2 2 2 22", e);
        }
    };

    const continuousBargeEnabled = () => {
        getContinuousBargeStatus().then((resp: any) => {
            if (resp?.isContinueBargeEnabled) {
                setContinuousBarge(resp?.isContinueBargeEnabled);
            }
        })
    };

    React.useEffect(() => {
        console.log("ROLE_CONTINUE_BARGE , continuousBarge", checkRoles("ROLE_CONTINUE_BARGE"), continuousBarge)
        if (selectedAgent && checkRoles("ROLE_CONTINUE_BARGE") && continuousBarge) {
            const updatedData = rowData?.filter((item: any) => item?.AgentUniqueId == selectedAgent?.AgentUniqueId)
            if (updatedData.length > 0 && updatedData[0]?.AgentState != selectedAgent?.AgentState) {
                if (updatedData[0]?.AgentState === 'BUSY') {
                    updatedData[0].continuousBarge = true;
                }
                setSelectedAgent(updatedData[0]);
            }
        }
    }, [rowData])

    const retrieveTableData = async (filters = '0') => {
        const rows: any = await getHuntingStatusOfflineAgents({ filters, type: filterType, ChannelType: '1' });
        console.log('rows', rows);
        setRowData(rows);
        setColumnData(columns);
    };

    const handleDownload = (type: string) => {
        console.log(type);
    };

    const toggleDrawer = (isOpen: boolean) => {
        setIsDrawerOpen(isOpen);
    };

    const toggleBargeInDrawer = () => {
        setIsBargeInOpen(!isBargeInOpen);
    };

    const toggleBargeInDrawerSip = (value: any) => {
        setIsBargeInOpen(!isBargeInOpen);
        setBargeinCallType('');
        setOnSelect(false)
    };

    const handleFilterDelete = (id: Number) => {
        const newFilter = huntingStatusFilter.filter((item: any) => item.id !== id);
        setHuntingStatusFilter(newFilter);
        props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
    };

    const clearFilter = () => {
        console.info('Clear all filter.');
        setHuntingStatusFilter([]);
        props?.handleFilterChange?.([], filterSection, props?.widgetId);
    };

    const handleFilterExistingData = (filteredData: any) => {
        setHuntingStatusFilter(filteredData);
        props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
    };

    const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
        setHuntingStatusFilter(selectedFilters);
        setFilterType(newFilterType);
        setFilterSection(newfilterSection);
        props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
        toggleDrawer(false);
    };

    const filterItems = huntingStatusFilter.map((item: any, index: number) => (
        <Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
    ));


    React.useEffect(() => {
        const fetchWidgetConfig = async () => {
            try {
                if (checkDataAccessFilters()) {
                    let widgetConfig = await getWidgetConfig('103', 'widget');
                    if (widgetConfig) {
                        if (typeof widgetConfig === 'string') {
                            widgetConfig = JSON.parse(widgetConfig);
                        }
                        // Check the condition and set the appropriate filters
                        const filters = widgetConfig.data && checkDataAccessFilters()
                            ? widgetConfig.data
                            : widgetConfig.config;

                        if (filters) {
                            const filtersArray = filters.split(',');
                            setAllowedFilters(filtersArray);
                            const determinedFilterType = determineFilterType(filtersArray);
                            if (determinedFilterType !== undefined) {
                                setFilterType(determinedFilterType);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching widget config:', error);
            }
        };
        fetchWidgetConfig();
    }, []);

    React.useEffect(() => {
        return () => {
            clearInterval(interval);
        };
    }, []);

    React.useEffect(() => {
        // const filterIds = (agentListFilter.length && !(responseLength === agentListFilter.length)) ? agentListFilter.map((item: any) => item.id).join(',') : '0';
        const filterIds = (huntingStatusFilter.length && !(responseLength === huntingStatusFilter.length)) ? huntingStatusFilter.map((item: any) => item.id).join(',') : '0';
        retrieveTableData(filterIds);
        if (interval) {
            clearInterval(interval);
        }
        interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.HUNTING_STATUS);
    }, [huntingStatusFilter]);


    const handleClose = () => {
        setOpenAlert(false);
    };

    const handleRelease = async () => {
        console.log("releaseDetails", releaseDetails);
        await releaseAgentByFwpId({ fwpId: releaseDetails.PhoneId });
        setOpenAlert(false);
    };

    const agentClick = async (action: string, agent: any) => {
        if (action === 'LOGOUT') {
            // setOpenLogOffAlert(true);
            // setLogOffDetails(agent);
        } else if (action === 'RELEASE') {
            setOpenAlert(true);
            setReleaseDetails(agent);
        } else if (action === 'BARGEIN') {
            setSelectedAgent(agent);
            setOnSelect(true);
            toggleBargeInDrawer();
        } else if (action === "SCREEN_BARGEIN") {
            // setScreenBargeinData(agent);
        }
    };


    const agentDetails = async (event: any, agent: any) => {
        setSelectedAgent(agent);
        const agentData = await getAgentStateDetail({ agentId: agent.AgentUniqueId });
        setSelectedAgentDetails(agentData.find((eachAgent) => eachAgent));
        setElRefPoint({ top: event.clientY, left: event.clientX });
        setIsPopoverOpen(true);
    };

    function getRowData(row: any) {
        row = row?.map((item: any) => {
            let rowObj = {
                ...item,
                LongestWaitTime: secondsToMinSec(item?.LongestWaitTime),
            };
            return rowObj;
        });
        return row;
    }

    const HuntingStatusHeader = () => {
        return (
            <TableRow>
                {columns.map((column: any, idx: any) => {
                    if (column.id === 'adminAction') {
                        return (
                            <StyledTableCell key={column.id + idx} align="center" style={{ minWidth: column.minWidth }}>
                                {column.label}
                                <span className="icon-row">
                                    <span className="icon-col">Release</span>
                                    <span className="icon-col">Bargein</span>
                                </span>
                            </StyledTableCell>
                        );
                    }
                    return (
                        <StyledTableCell
                            key={column.name + idx}
                            // align={column.align}
                            style={{
                                minWidth: column.minWidth ?? '200px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {column.name}
                        </StyledTableCell>
                    );
                }
                )}
            </TableRow>
        )
    }

    const HuntingStatusRowContent = (_index: number, row: any) => {
        let rowIdx = _index
        return columns.map((column: any) => {
            let value: any = row[column.key];
            if (value && value !== 'null') {
                if (column.type === 'DateTime') {
                    value = daysjs(value).format('DD-MM-YYYY HH:mm:ss');
                }
            }
            return (
                <StyledTableCell
                    key={column.key + rowIdx}
                    align={column.align}
                    style={{
                        minWidth: column.minWidth,
                        whiteSpace: 'nowrap',
                    }}
                    className={`${row[column.key]?.warning || ''}`}
                >

                    {(column.id === 'adminAction') ? <span className="icon-row">
                        <span className="icon-col">
                            <IconButton disableRipple onClick={() => agentClick('RELEASE', row)} aria-label="delete">
                                <img src={`${process.env.PUBLIC_URL}/icons/table-release-icon.svg`} width="16" alt="Release"></img>
                            </IconButton>
                        </span>
                        <span className="icon-col">
                            <IconButton disableRipple disabled={row.State !== 'BUSY'} onClick={() => agentClick('BARGEIN', row)}>
                                <img src={`${process.env.PUBLIC_URL}/icons/table-bargein-icon.svg`} width="16" alt="Bargein"></img>
                            </IconButton>
                        </span>
                    </span>
                        : value}
                </StyledTableCell>
            );
        })
    }



    return (
        <Card className="customCard">
            <div className="card-header">
                <div className="card-title">
                    Hunting Status (Offline Agents)
                    <div className="card-title-badge">Live</div>
                </div>
                <div className="card-actions">
                    <WidgetDownload element={tableDataRef} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Hunting Status'}></WidgetDownload>
                    {/* <div className="card-icon" onClick={() => toggleDrawer(true)}>
                        <Badge badgeContent={huntingStatusFilter.length} color="primary">
                            <FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
                        </Badge>
                    </div> */}
                </div>
            </div>
            {/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={allowedFilters} existingSelectedFilters={huntingStatusFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} /> */}
            {/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Group']} existingSelectedFilters={huntingStatusFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData}/> */}
            <Box sx={{ width: '100%', typography: 'body1', background: "#FFFFFF" }}>
                {huntingStatusFilter.length > 0 && (
                    <Box className='customTabPanel'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="filter-outer">
                                    <div className="filter-container">
                                        <Stack direction="row" spacing={1}>
                                            {filterItems}
                                        </Stack>
                                    </div>
                                    <div className="filter-remove" onClick={clearFilter}>
                                        Remove
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
            <Box ref={chartContainerRef} sx={{ height: huntingStatusFilter.length > 0 ? 'calc(100% - 118px)' : 'calc(100% - 24px)', }}>
                <HuntingStatusVTable
                    data={rowData}
                    tableDataRef={tableDataRef}
                    HeaderContent={HuntingStatusHeader}
                    rowContent={HuntingStatusRowContent}
                />
            </Box>
            {onselect &&
                <BargeInDrawerSip
                    isOpen={isBargeInOpen}
                    toggleDrawer={toggleBargeInDrawerSip}
                    agentDetails={selectedAgent}
                    callType={bargeinCallType}
                    url={sipUrl}
                    SipClient={bargeinCallType === 'SIP' ? sipClient : undefined}
                />
            }
            <Dialog open={openAlert} onClose={handleClose} aria-labelledby="hunt-alert-dialog-title" aria-describedby="hunt-alert-dialog-description">
                <DialogTitle id="hunt-alert-dialog-title">{'Release'}</DialogTitle>
                    <DialogContent id="hunt-alert-dialog-description">{`Are you sure you want to release ${releaseDetails?.PhoneName} - ${releaseDetails?.PhoneNumber}`}?</DialogContent>
                <DialogActions>
                    <div className="buttons-container">
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleRelease} sx={{ ml: 2 }}>
                            Release
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </Card >
    );
}
