import AgentCurrentState from '../components/widgets/AgentCurrentState/AgentCurrentState';
import AgentList from '../components/widgets/AgentList/AgentList';
import AgentPerformance from '../components/widgets/AgentPerformance/AgentPerformance';
import AgentStateCounter from '../components/widgets/AgentStateCounter/AgentStateCounter';
import CallStatus from '../components/widgets/CallStatus/CallStatus';
import ComparativeCallTrends from '../components/widgets/ComparativeCallTrends/ComparativeCallTrends';
import DataSummary from '../components/widgets/DataSummary/DataSummary';
import DropRatio from '../components/widgets/DropRatio/DropRatio';
import HourlyCallSummary from '../components/widgets/HourlyCallSummary/HourlyCallSummary';
import LiveCalls from '../components/widgets/LiveCalls/LiveCalls';
import OccupancyCall from '../components/widgets/OccupancyvsCall/OccupancyvsCall';
import OutboundCampaignStatus from '../components/widgets/OutboundCampaignStatus/OutboundCampaignStatus';
import Pacing from '../components/widgets/Pacing/Pacing';
import QueueCounters from '../components/widgets/QueueCounters/QueueCounters';
import QueueCountersDigital from '../components/widgets/QueueCountersDigital/QueueCountersDigital';
import SkillSummary from '../components/widgets/SkillSummary/SkillSummary';
import SLA from '../components/widgets/SLA/SLA';
import TopDisposition from '../components/widgets/TopDisposition/TopDisposition';
import WidgetsCarousel from '../components/widgets/WidgetsCarousel/WidgetsCarousel';
import ChannelStatus from '../components/widgets/ChanelStatusDigital/ChannelStatus';
import AgentPerformanceDigital from '../components/widgets/AgentPerformanceDigital/AgentPerformanceDigital'
import SkillSummaryDigital from '../components/widgets/SkillSummaryDigital/SkillSummaryDigital';
import ChatStatusDigital from '../components/widgets/ChatStatus/ChatStatusDigital';
import HourlyDigitalSummary from '../components/widgets/HourlyDigitalSummary/HourlyDigitalSummary';
import { LOGIN_URL, OAUTH2_VALIDATE_URL, BASE_URL, IMAGE_UPLOAD_URL, BASE_API_URL, LIVE_BASE_URL, ADMIN_BASE_URL, FORGOT_PASSWORD_URL, UPDATE_PASSWORD_URL } from './properties';
import LocationQueueCounter from '../components/widgets/LocationQueueCounter/LocationQueueCounter';
import OverallQueueCounter from '../components/widgets/OverallQueueCounter/OverallQueueCounter';
import CPaaSCallStatus from '../components/widgets/CPaaSCallStatus/CPaaSCallStatus';
import HuntingStatus from '../components/widgets/HuntingStatus/HuntingStatus';
import ActivityLog from '../components/widgets/ActivityLog/ActivityLog';
import WFMDashboard from '../components/widgets/WFMDashboard/WFMDashboard';
import PerformanceMetrics from '../components/widgets/PerformanceMetrics/PerformanceMetrics';
import RunningStatus from '../components/widgets/RunningStatus/RunningStatus';
import InteractionQueueCounter from '../components/widgets/InteractionQueueCounter/InteractionQueueCounter';
import { checkRoles } from './util';
import NewDynamicWidget from '../components/widgets/EmptyWidget/NewDynamicWidget';
// import InternalApiUtilization from '../components/widgets/InternalApiUtilization/InternalApiUtilization';

export const WIDGET_COMPONENT_MAPPING: any = {
    SLA: SLA,
    CALL_STATUS: CallStatus,
    QUEUE_COUNTER: QueueCounters,
    LOCATION_QUEUE_COUNTER: LocationQueueCounter,
    OVERALL_QUEUE_COUNTER: OverallQueueCounter,
    QUEUE_COUNTER_DIGITAL: QueueCountersDigital,
    AGENT_LIST: AgentCurrentState,
    AGENT_PERFORMANCE: AgentPerformance,
    AGENT_STATE_COUNTER: AgentStateCounter,
    HOURLY_CALL_SUMMARY: HourlyCallSummary,
    SKILL_SUMMARY: SkillSummary,
    TOP_DISPOSITION: TopDisposition,
    OCCUPANCY_CALL: OccupancyCall,
    COMPARATIVE_CALL_TRENDS: ComparativeCallTrends,
    OUTBOUND_CALL_CAMPAIGN: OutboundCampaignStatus,
    HOURLY_DIGITAL_SUMMARY: HourlyDigitalSummary,
    LIVE_CALLS: LiveCalls,
    DATA_SUMMARY: DataSummary,
    PACING: Pacing,
    DROP_RATIO: DropRatio,
    AGENT_LIST_V2: AgentList,
    CAROUSEL: WidgetsCarousel,
    CHANNEL_STATUS_DIGITAL: ChannelStatus,
    AGENT_PERFORMANCE_DIGITAL: AgentPerformanceDigital,
    SKILL_SUMMARY_DIGITAL: SkillSummaryDigital,
    DIGITAL_STATUS: ChatStatusDigital,
    CPAAS_CALL_STATUS: CPaaSCallStatus,
    ACTIVITY_LOG: ActivityLog,
    WFM_DASHBOARD: WFMDashboard,
    PERFORMANCE_METRICS: PerformanceMetrics,
    RUNNING_STATUS: RunningStatus,
    INTERACTION_QUEUE_COUNTER: InteractionQueueCounter,
    NEW_DYNAMIC_WIDGET: NewDynamicWidget,
    HUNTING_STATUS: HuntingStatus,
    // INTERNAL_API_UTILIZATION: InternalApiUtilization,
};

export const WIDGET_NAMES: any = Object.keys(WIDGET_COMPONENT_MAPPING).reduce((acc, value) => ({ ...acc, [value]: value }), {});

export const WIDGET_LIST = [
    { label: 'Carousel', widgetName: WIDGET_NAMES.CAROUSEL, image: 'carousel.png', width: 12 },
    { label: 'SLA', widgetName: WIDGET_NAMES.SLA, image: 'sla.png', width: 12 },
    { label: 'Call Status', widgetName: WIDGET_NAMES.CALL_STATUS, image: 'call-status.png' },
    { label: 'Queue Counter', widgetName: WIDGET_NAMES.QUEUE_COUNTER, image: 'queue-counter.png' },
    { label: 'Location Queue Counter', widgetName: WIDGET_NAMES.LOCATION_QUEUE_COUNTER, image: 'location-queue-counter.png' },
    { label: 'Overall Queue Counter', widgetName: WIDGET_NAMES.OVERALL_QUEUE_COUNTER, image: 'overall-queue-counter.png' },
    // { label: 'Queue Counter Digital', widgetName: WIDGET_NAMES.QUEUE_COUNTER_DIGITAL, image: 'queue-counter.png' },
    { label: 'Agent List', widgetName: WIDGET_NAMES.AGENT_LIST, image: 'agent-list.png', width: 12 },
    { label: 'Agent Performance', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE, image: 'agent-performance.png' },
    { label: 'Agent State Counter', widgetName: WIDGET_NAMES.AGENT_STATE_COUNTER, image: 'agent-state-counter.png' },
    // { label: 'Hourly Call Summary', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
    // { label: 'Skill Summary', widgetName: WIDGET_NAMES.SKILL_SUMMARY, image: 'skill-summary.png' },
    { label: 'Top Disposition', widgetName: WIDGET_NAMES.TOP_DISPOSITION, image: 'top-disposition.png' },
    { label: 'Skill Summary Digital', widgetName: WIDGET_NAMES.SKILL_SUMMARY_DIGITAL, image: 'skill-summary-digital.png' },
    { label: 'Live Calls', widgetName: WIDGET_NAMES.LIVE_CALLS, image: 'live-calls.png', width: 12 },
    { label: 'Pacing', widgetName: WIDGET_NAMES.PACING, image: 'pacing.png' },
    { label: 'Drop Ratio', widgetName: WIDGET_NAMES.DROP_RATIO, image: 'drop-ratio.png' },
    { label: 'Data Summary', widgetName: WIDGET_NAMES.DATA_SUMMARY, image: 'data-summary.png', width: 12 },
    { label: 'Agent List V2', widgetName: WIDGET_NAMES.AGENT_LIST_V2, image: 'agent-list-v2.png', width: 12 },
    { label: 'Comparative Call Trends', widgetName: WIDGET_NAMES.COMPARATIVE_CALL_TRENDS, image: 'comparative-call-trends.png', width: 12 },
    { label: 'Outbound Campaign Status', widgetName: WIDGET_NAMES.OUTBOUND_CALL_CAMPAIGN, image: 'outbound-call-campaign.png', width: 12 },
    // { label: 'Hourly Digital Summary', widgetName: WIDGET_NAMES.HOURLY_DIGITAL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
    { label: 'Digital Status', widgetName: WIDGET_NAMES.DIGITAL_STATUS, image: 'chat-status.png', width: 12 },
    { label: 'Channel Status Digital', widgetName: WIDGET_NAMES.CHANNEL_STATUS_DIGITAL, image: 'channel-status.png' },
    { label: 'Agent Performance Digital', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE_DIGITAL, image: 'agent-performance-digital.png' },
    { label: 'CPaaS Call Status', widgetName: WIDGET_NAMES.CPAAS_CALL_STATUS, image: 'cPaaSCallStatus.png', width: 7.5 },
    { label: 'Activity Log', widgetName: WIDGET_NAMES.ACTIVITY_LOG, image: 'activityLog.png' },
    { label: 'Interaction Running Status', widgetName: WIDGET_NAMES.RUNNING_STATUS, image: 'runningStatus.png', width: 6 },
    { label: 'Skill Summary', widgetName: WIDGET_NAMES.WFM_DASHBOARD, image: 'WFMDashboard.png', width: 12 },
    { label: 'Performance Metrics', widgetName: WIDGET_NAMES.PERFORMANCE_METRICS, image: 'performanceTrend.png', width: 12 },
    { label: 'Interaction Trend', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'interaction_trend.png', width: 12 },
    { label: 'Interaction Queues', widgetName: WIDGET_NAMES.INTERACTION_QUEUE_COUNTER, image: 'interactionQueues.png' },
    { label: 'Dynamic Widget', widgetName: WIDGET_NAMES.NEW_DYNAMIC_WIDGET, image: 'dynamic_widget.png', width: 12 },
    { label: 'Hunting Status (Offline Agents)', widgetName: WIDGET_NAMES.HUNTING_STATUS, image: 'huntingStatus.png', width: 12 },
];

export const WIDGET_LIST_ADMIN = [
    { label: 'Carousel', widgetName: WIDGET_NAMES.CAROUSEL, image: 'carousel.png', width: 12 },
    { label: 'SLA', widgetName: WIDGET_NAMES.SLA, image: 'sla.png', width: 12 },
    { label: 'Call Status', widgetName: WIDGET_NAMES.CALL_STATUS, image: 'call-status.png' },
    { label: 'Queue Counter', widgetName: WIDGET_NAMES.QUEUE_COUNTER, image: 'queue-counter.png' },
    { label: 'Location Queue Counter', widgetName: WIDGET_NAMES.LOCATION_QUEUE_COUNTER, image: 'location-queue-counter.png' },
    { label: 'Overall Queue Counter', widgetName: WIDGET_NAMES.OVERALL_QUEUE_COUNTER, image: 'overall-queue-counter.png' },
    // { label: 'Queue Counter Digital', widgetName: WIDGET_NAMES.QUEUE_COUNTER_DIGITAL, image: 'queue-counter.png' },
    { label: 'Agent List', widgetName: WIDGET_NAMES.AGENT_LIST, image: 'agent-list.png', width: 12 },
    { label: 'Agent Performance', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE, image: 'agent-performance.png' },
    { label: 'Agent State Counter', widgetName: WIDGET_NAMES.AGENT_STATE_COUNTER, image: 'agent-state-counter.png' },
    // { label: 'Hourly Call Summary', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
    // { label: 'Skill Summary', widgetName: WIDGET_NAMES.SKILL_SUMMARY, image: 'skill-summary.png' },
    { label: 'Top Disposition', widgetName: WIDGET_NAMES.TOP_DISPOSITION, image: 'top-disposition.png' },
    { label: 'Skill Summary Digital', widgetName: WIDGET_NAMES.SKILL_SUMMARY_DIGITAL, image: 'skill-summary-digital.png' },
    { label: 'Live Calls', widgetName: WIDGET_NAMES.LIVE_CALLS, image: 'live-calls.png', width: 12 },
    { label: 'Pacing', widgetName: WIDGET_NAMES.PACING, image: 'pacing.png' },
    { label: 'Drop Ratio', widgetName: WIDGET_NAMES.DROP_RATIO, image: 'drop-ratio.png' },
    { label: 'Data Summary', widgetName: WIDGET_NAMES.DATA_SUMMARY, image: 'data-summary.png', width: 12 },
    { label: 'Agent List V2', widgetName: WIDGET_NAMES.AGENT_LIST_V2, image: 'agent-list-v2.png', width: 12 },
    { label: 'Comparative Call Trends', widgetName: WIDGET_NAMES.COMPARATIVE_CALL_TRENDS, image: 'comparative-call-trends.png', width: 12 },
    { label: 'Outbound Campaign Status', widgetName: WIDGET_NAMES.OUTBOUND_CALL_CAMPAIGN, image: 'outbound-call-campaign.png', width: 12 },
    // { label: 'Hourly Digital Summary', widgetName: WIDGET_NAMES.HOURLY_DIGITAL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
    { label: 'Digital Status', widgetName: WIDGET_NAMES.DIGITAL_STATUS, image: 'chat-status.png', width: 12 },
    { label: 'Channel Status Digital', widgetName: WIDGET_NAMES.CHANNEL_STATUS_DIGITAL, image: 'channel-status.png' },
    { label: 'Agent Performance Digital', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE_DIGITAL, image: 'agent-performance-digital.png' },
    { label: 'CPaaS Call Status', widgetName: WIDGET_NAMES.CPAAS_CALL_STATUS, image: 'cPaaSCallStatus.png', width: 7.5 },
    { label: 'Activity Log', widgetName: WIDGET_NAMES.ACTIVITY_LOG, image: 'activityLog.png' },
    { label: 'Interaction Running Status', widgetName: WIDGET_NAMES.RUNNING_STATUS, image: 'runningStatus.png', width: 6 },
    { label: 'Skill Summary', widgetName: WIDGET_NAMES.WFM_DASHBOARD, image: 'WFMDashboard.png', width: 12 },
    { label: 'Performance Metrics', widgetName: WIDGET_NAMES.PERFORMANCE_METRICS, image: 'performanceTrend.png', width: 12 },
    { label: 'Interaction Trend', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'interaction_trend.png', width: 12 },
    { label: 'Interaction Queues', widgetName: WIDGET_NAMES.INTERACTION_QUEUE_COUNTER, image: 'interactionQueues.png' },
    { label: 'Dynamic Widget', widgetName: WIDGET_NAMES.NEW_DYNAMIC_WIDGET, image: 'dynamic_widget.png', width: 12 },
    { label: 'Hunting Status (Offline Agents)', widgetName: WIDGET_NAMES.HUNTING_STATUS, image: 'huntingStatus.png', width: 12 },
    // { label: 'Api Utilization', widgetName: WIDGET_NAMES.INTERNAL_API_UTILIZATION, image: 'api-utilization.png', width: 12},
];

// export const WIDGET_LIST_ADMIN = [
// 	{ label: 'Carousel', widgetName: WIDGET_NAMES.CAROUSEL, image: 'carousel.png', width: 12 },
// 	{ label: 'SLA', widgetName: WIDGET_NAMES.SLA, image: 'sla.png', width: 12 },
// 	{ label: 'Call Status', widgetName: WIDGET_NAMES.CALL_STATUS, image: 'call-status.png' },
// 	{ label: 'Queue Counter', widgetName: WIDGET_NAMES.QUEUE_COUNTER, image: 'queue-counter.png' },
// 	{ label: 'Location Queue Counter', widgetName: WIDGET_NAMES.LOCATION_QUEUE_COUNTER, image: 'location-queue-counter.png' },
// 	{ label: 'Overall Queue Counter', widgetName: WIDGET_NAMES.OVERALL_QUEUE_COUNTER, image: 'overall-queue-counter.png' },
// 	// { label: 'Queue Counter Digital', widgetName: WIDGET_NAMES.QUEUE_COUNTER_DIGITAL, image: 'queue-counter.png' },
// 	{ label: 'Agent List', widgetName: WIDGET_NAMES.AGENT_LIST, image: 'agent-list.png', width: 12 },
// 	{ label: 'Agent Performance', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE, image: 'agent-performance.png' },
// 	{ label: 'Agent State Counter', widgetName: WIDGET_NAMES.AGENT_STATE_COUNTER, image: 'agent-state-counter.png' },
// 	// { label: 'Hourly Call Summary', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
// 	// { label: 'Skill Summary', widgetName: WIDGET_NAMES.SKILL_SUMMARY, image: 'skill-summary.png' },
// 	{ label: 'Top Disposition', widgetName: WIDGET_NAMES.TOP_DISPOSITION, image: 'top-disposition.png' },
// 	{ label: 'Skill Summary Digital', widgetName: WIDGET_NAMES.SKILL_SUMMARY_DIGITAL, image: 'skill-summary-digital.png' },
// 	{ label: 'Live Calls', widgetName: WIDGET_NAMES.LIVE_CALLS, image: 'live-calls.png', width: 12 },
// 	{ label: 'Pacing', widgetName: WIDGET_NAMES.PACING, image: 'pacing.png' },
// 	{ label: 'Drop Ratio', widgetName: WIDGET_NAMES.DROP_RATIO, image: 'drop-ratio.png' },
// 	{ label: 'Data Summary', widgetName: WIDGET_NAMES.DATA_SUMMARY, image: 'data-summary.png', width: 12 },
// 	{ label: 'Agent List V2', widgetName: WIDGET_NAMES.AGENT_LIST_V2, image: 'agent-list-v2.png', width: 12 },
// 	{ label: 'Comparative Call Trends', widgetName: WIDGET_NAMES.COMPARATIVE_CALL_TRENDS, image: 'comparative-call-trends.png', width: 12 },
// 	{ label: 'Outbound Campaign Status', widgetName: WIDGET_NAMES.OUTBOUND_CALL_CAMPAIGN, image: 'outbound-call-campaign.png', width: 12 },
// 	// { label: 'Hourly Digital Summary', widgetName: WIDGET_NAMES.HOURLY_DIGITAL_SUMMARY, image: 'hourly-digital-summary.png', width: 12 },
// 	{ label: 'Digital Status', widgetName: WIDGET_NAMES.DIGITAL_STATUS, image: 'chat-status.png', width: 12 },
// 	{ label: 'Channel Status Digital', widgetName: WIDGET_NAMES.CHANNEL_STATUS_DIGITAL, image: 'channel-status.png' },
// 	{ label: 'Agent Performance Digital', widgetName: WIDGET_NAMES.AGENT_PERFORMANCE_DIGITAL, image: 'agent-performance-digital.png' },
// 	{ label: 'CPaaS Call Status', widgetName: WIDGET_NAMES.CPAAS_CALL_STATUS, image: 'cPaaSCallStatus.png', width: 7.5 },
// 	{ label: 'Activity Log', widgetName: WIDGET_NAMES.ACTIVITY_LOG, image: 'activityLog.png' },
// 	{ label: 'Interaction Running Status', widgetName: WIDGET_NAMES.RUNNING_STATUS, image: 'runningStatus.png', width: 6 },
// 	{ label: 'Skill Summary', widgetName: WIDGET_NAMES.WFM_DASHBOARD, image: 'WFMDashboard.png', width: 12 },
// 	{ label: 'Performance Metrics', widgetName: WIDGET_NAMES.PERFORMANCE_METRICS, image: 'performanceTrend.png', width: 12 },
// 	{ label: 'Interaction Trend', widgetName: WIDGET_NAMES.HOURLY_CALL_SUMMARY, image: 'interaction_trend.png', width: 12 },
// 	{ label: 'Interaction Queues', widgetName: WIDGET_NAMES.INTERACTION_QUEUE_COUNTER, image: 'interactionQueues.png' },
// 	// { label: 'Api Utilization', widgetName: WIDGET_NAMES.INTERNAL_API_UTILIZATION, image: 'api-utilization.png', width: 12},
// ];

export const WALLBOARD_WIDGET_NAMES = {
    CALLS_IN_QUEUE: 'CALLS_IN_QUEUE',
    LONGEST_WAIT_TIME: 'LONGEST_WAIT_TIME',
    SKILL_TABLE: 'SKILL_TABLE',
    TOTAL_AGENTS: 'TOTAL_AGENTS',
    TOTAL_CALLS: 'TOTAL_CALLS',
    READY_PAUSE: 'READY_PAUSE',
    TOP_AGENTS: 'TOP_AGENTS'
};

export const WALLBOARD_WIDGET_LIST = [
    { label: 'Top 5 calls in queue (skills)', widgetName: WALLBOARD_WIDGET_NAMES.SKILL_TABLE, image: 'wallboardSkills.svg' },
    { label: 'Total Calls', widgetName: WALLBOARD_WIDGET_NAMES.TOTAL_CALLS, image: 'totalCalls.png' },
    { label: 'Top agents', widgetName: WALLBOARD_WIDGET_NAMES.TOP_AGENTS, image: 'topAgents.png' },
    { label: 'Total Agents', widgetName: WALLBOARD_WIDGET_NAMES.TOTAL_AGENTS, image: 'totalAgents.png' },
    { label: 'Ready Pause', widgetName: WALLBOARD_WIDGET_NAMES.READY_PAUSE, image: 'readyPause.png', width: 6 },
    { label: 'Calls in Queue', widgetName: WALLBOARD_WIDGET_NAMES.CALLS_IN_QUEUE, image: 'callsAndQueue.png' },
    { label: 'Longest wait time', widgetName: WALLBOARD_WIDGET_NAMES.LONGEST_WAIT_TIME, image: 'callsAndQueue.png' }
];

export const filterTypes = [
    { id: 1, label: 'Campaign', value: 'Campaign' },
    { id: 2, label: 'Skill', value: 'Skill' },
    { id: 3, label: 'Group', value: 'Group' },
];

export const LocationfilterTypes = [
    { id: 4, label: 'Skill Location', value: 'Location' },
    { id: 5, label: 'Skill Details', value: 'Detail' },
];

export const DynamicfilterTypes = [
    { id: 4, label: 'Skill Location', value: 'Location' },
    { id: 5, label: 'Skill Details', value: 'Detail' },
    { id: 6, label: 'Skill Details2', value: 'Detail2' },
    { id: 7, label: 'Skill Details3', value: 'Detail3' },
    { id: 1, label: 'Campaign', value: 'Campaign' },
    { id: 2, label: 'Skill', value: 'Skill' },
    { id: 3, label: 'Group', value: 'Group' },
];

export const filterTypeMapping: any = filterTypes.reduce((acc, item) => ({ ...acc, [item.id]: item.value }), {});

export const WIDGET_REFRESH_INTERVAL = {
    WALLBOARD: 5000,
    COMPARATIVE_CALL_TRENDS: 10000,
    CALL_STATUS: 10000,
    SLA: 10000,
    OUTBOUND_CAMPAIGN_STATUS: 10000,
    HOURLY_CALL_SUMMARY: 1800000,
    HOURLY_DIGITAL_SUMMARY: 10000,
    AGENT_STATE_COUNTER: 10000,
    AGENT_LIST: 10000,
    HUNTING_STATUS: 10000,
    QUEUE_COUNTER: 10000,
    LOCATION_QUEUE_COUNTER: 10000,
    OVERALL_QUEUE_COUNTER: 10000,
    QUEUE_COUNTER_DIGITAL: 10000,
    TOP_DISPOSITION: 10000,
    SKILL_SUMMARY: 10000,
    AGENT_PERFORMANCE: 10000,
    CSAT: 10000,
    DROP_RATIO: 10000,
    OCCUPANCY_VS_CALL: 10000,
    LIVE_CALLS: 10000,
    PACING: 10000,
    DATA_SUMMARY: 10000,
    CPAAS_CALL_STATUS: 10000,
    ACTIVITY_LOG: 10000,
    WFM_DASHBOARD: 10000,
    PERFORMANCE_METRICS: 1800000,
    RUNNING_STATUS: 10000,
    INTERACTION_QUEUE: 10000,
    INTERNAL_API_UTILIZATION: 10000,
    SIP_REGISTER_INTERVAL: 2000,
    CUSTOMER_INTEREST_REPORT: 10000
};

export const LIVE_REPORTS_LIST: any = [
    { name: 'Agent Break Details', type: 'LINK', route: '/reports/agent-break-details-live' },
    { name: 'Call Details', type: 'LINK', route: '/reports/Calldetails-live' },
    { name: 'Digital Channels Details', type: 'LINK', route: '/reports/digital-channels-details-live' },
    { name: 'Customer Interaction Details', type: 'LINK', route: '/reports/customer-interaction-details-live' },
    { name: 'Customer Interest (Missed Calls)', type: 'LINK', route: '/reports/customer-interest-live' },
    { name: 'IVR Feedback Details', type: 'LINK', route: '/reports/ivr-feedback-details-live' },
    { name: 'IVR Hangup Details', type: 'LINK', route: '/reports/ivr-hangup-details-live' },
    { name: 'Missed Call Details', type: 'LINK', route: '/reports/missed-call-details-live' },
    { name: 'Predictive Campaign Performance', type: 'LINK', route: '/reports/predictive-campaign-performance-live' },
    { name: 'Skill', type: 'LINK', route: '/reports/skill-live' },
    { name: 'SLA', type: 'LINK', route: '/reports/sla-live' },
    { name: 'SMS', type: 'LINK', route: '/reports/sms-live' },
    { name: 'Voicemail', type: 'LINK', route: '/reports/voicemail-live' },
    // { name: 'Pri Utilization', type: 'LINK', route: '/reports/pri-utilisation' },
    { name: 'Customer Hold Details', type: 'LINK', route: '/reports/customer-hold-details-live' },
    { name: 'Agent Overview', type: 'LINK', route: '/reports/agent-overview-live' },
    { name: 'Conference Details', type: 'LINK', route: '/reports/conference-details-live' },
    { name: 'Whatsapp Outbound Report', type: 'LINK', route: '/reports/whatsapp-outbound-report-live' },
    // {name:'Audit Log',type:'LINK',route:'/reports/current-day-audit-log'},
    { name: 'Audit Log', type: 'LINK', route: '/reports/current-day-audit-log' },
    { name: 'Port Utilization', type: 'LINK', route: '/reports/port-utilization-live' },
    { name: 'CXI REPORT', type: 'LINK', route: '/reports/ba-report-live' },
    { name: 'Abandoned Call Details', type: 'LINK', route: '/reports/abandoned-calls-live' },
].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
export const HISTORIC_REPORTS_LIST: any = [
    { name: 'Home', type: 'LINK', route: '/reports/home' },
    {
        name: 'Dashboard',
        type: 'GROUP',
        items: [
            { name: 'Organization Metrics', type: 'LINK', route: '/reports/organization-metrics' },
            { name: 'SLA', type: 'LINK', route: '/reports/sla' },
            { name: 'Daily Call Summary', type: 'LINK', route: '/reports/daily-call-summary' },
            { name: 'Organization Overview', type: 'LINK', route: '/reports/organization-overview' },
            { name: 'Performance', type: 'LINK', route: '/reports/performance' },
            { name: 'Hourly Calls Analysis', type: 'LINK', route: '/reports/hourly-call-analysis' },
            { name: 'Inbound Call Analytics', type: 'LINK', route: '/reports/inbound-call-analytics' },
            { name: 'Queue Overview', type: 'LINK', route: '/reports/queue-overview' },
            { name: 'Offline Phone Overview', type: 'LINK', route: '/reports/offline-phone-overview' }
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },
    { name: 'Call Details', type: 'LINK', route: '/reports/call-details' },
    {
        name: 'Call Analytics',
        type: 'GROUP',
        items: [
            { name: 'Callbacks', type: 'LINK', route: '/reports/callbacks' },
            { name: 'Dialer Count Details', type: 'LINK', route: '/reports/dailer-count-details' },
            { name: 'Inbound Call Summary', type: 'LINK', route: '/reports/inbound-call-summary' },
            { name: 'Hourly Call Details', type: 'LINK', route: '/reports/hourly-call-details' },
            { name: 'Hourly Phone Call', type: 'LINK', route: '/reports/hourly-phone-call' },
            { name: 'Phone Productivity', type: 'LINK', route: '/reports/phone-productivity' },
            // { name: 'Abandoned Call Details', type: 'LINK', route: '/reports/abandoned-calls' },
            { name: 'Skill Productivity', type: 'LINK', route: '/reports/skills-productivity' },
            { name: 'Campaign Productivity', type: 'LINK', route: '/reports/campaign-productivity' },
            { name: 'Conference Details', type: 'LINK', route: '/reports/conference-details' },
            { name: 'Customer Hold Details', type: 'LINK', route: '/reports/customer-hold-details' },
            { name: 'IVR Feedback', type: 'LINK', route: '/reports/ivr-feedback' },
            { name: 'Missed Call Details', type: 'LINK', route: '/reports/missed-call-details' },
            { name: 'Predictive Campaign Performance', type: 'LINK', route: '/reports/predictive-campaign-performance' },
            { name: 'Organization Summary', type: 'LINK', route: '/reports/organization-summary' },
            { name: 'Transferred Calls', type: 'LINK', route: '/reports/transferred-calls' },
            { name: 'IVR Hangup Details', type: 'LINK', route: '/reports/ivr-hangup-details' },
            { name: 'Audit Log', type: 'LINK', route: '/reports/audit-log' },
            { name: 'Outbound Call Summary', type: 'LINK', route: '/reports/outbound-call-summary' },
            { name: 'Port Utilization', type: 'LINK', route: '/reports/port-utilization' },
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },
    {
        name: 'Agent Reports',
        type: 'GROUP',
        items: [
            { name: 'Agent Break Details', type: 'LINK', route: '/reports/agent-break-details' },
            { name: 'Agent Dispositions', type: 'LINK', route: '/reports/agent-disposition' },
            // { name: 'Agent Efficiency', type: 'LINK', route: '/reports/agent-efficiency' },//made not to show Agent Efficiency Report
            { name: 'Agent Login Details', type: 'LINK', route: '/reports/agent-login-details' },
            { name: 'Agent Productivity', type: 'LINK', route: '/reports/agent-productivity' },
            { name: 'Agent State Details', type: 'LINK', route: '/reports/agent-state-details' },
            { name: 'Hourly Active Agents', type: 'LINK', route: '/reports/hourly-active-agents' },
            { name: 'Hourly Agent Calls', type: 'LINK', route: '/reports/hourly-agent-call' },
            { name: 'Hourly Agent Summary', type: 'LINK', route: '/reports/hourly-agent-summary' },
            { name: 'Performance Analytics', type: 'LINK', route: '/reports/performance-analytics' },
            { name: 'Agent Call Summary', type: 'LINK', route: '/reports/agent-call-summary' },
            { name: 'Agent Screen Recording', type: 'LINK', route: '/reports/agent-screen-recording' }
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },
    {
        name: 'Digital',
        type: 'GROUP',
        items: [
            { name: 'Digital Disposition Summary', type: 'LINK', route: '/reports/digital-disposition-summary' },
            { name: 'Digital Channels Details', type: 'LINK', route: '/reports/digital-channels-detail' },
            { name: 'Whatsapp Outbound Report', type: 'LINK', route: '/reports/whatsapp-outbound-report' }
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },
    {
        name: 'Dynamic',
        type: 'GROUP',
        items: [
            { name: 'Agent Call Summary', type: 'LINK', route: '/reports/agent-call-summary-dynamic' },
            { name: 'Daily Call Summary', type: 'LINK', route: '/reports/daily-call-summary-dynamic' },
            { name: 'Disposition Summary', type: 'LINK', route: '/reports/disposition-summary' }
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },
    {
        name: 'Custom Reports',
        type: 'GROUP',
        items: [
            { name: 'Reports List', type: 'LINK', route: '/reports/custom' },
            { name: 'Scheduled Reports', type: 'LINK', route: '/reports/schedule' }
        ].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    },

    { name: 'Downloaded Reports', type: 'LINK', route: '/reports/downloaded_reports' },
];

export const LOCAL_STORAGE = {
    SELECTED_DASHBOARD: 'selectedDashboard',
    DASHBOARD_TABS: 'dashboardTabs'
};



// const BASE_URL = 'https://ka7rkhzv1k.execute-api.us-east-1.amazonaws.com/dashboard-vpc';
// const BASE_API_URL = 'https://1pi35c3v8a.execute-api.us-east-1.amazonaws.com/reports-api-prod/endpoint';
// const LIVE_BASE_URL = 'https://m09hnkaaif.execute-api.us-east-1.amazonaws.com/live-reports';
// const ADMIN_BASE_URL = 'https://fcwbjtkqy4.execute-api.us-east-1.amazonaws.com/admin-portal-api/CloudAgentAPI';

export const API = {
    FORGOT_PASSWORD_URL,
    UPDATE_PASSWORD_URL,
    LOGIN_URL,
    OAUTH2_VALIDATE_URL,
    BASE_URL,
    IMAGE_UPLOAD_URL,
    REPORT_BASE_URL: BASE_API_URL,
    REPORTS_URL: `${BASE_API_URL}/reports`,
    FILTERS_URL: `${BASE_API_URL}/filters`,
    LIVE_API_URL: `${LIVE_BASE_URL}/liveReports`,
    ADMIN_BASE_URL,
    ADMIN_REPORT_URL: `${BASE_API_URL}/admin`,
    SCHEDULER_URL: `${BASE_API_URL}/scheduler`
};

export const interactiontrendsDummy = [
    // {
    //     "ConnectedPercentageRatio": "0.00",
    //     "UnansweredCalls": "4",
    //     "TotalCalls": "4",
    //     "SNO": 1,
    //     "CallType": "preview",
    //     "CallHour": "13",
    //     "AnsweredCalls": "0",
    // 	"Busy": "2",
    // 	"DND": '3',
    // 	"InvalidData": '5',
    // 	"MaxDialTimeExceeded": "6"
    // },
    {
        "ConnectedPercentageRatio": "0.00",
        "UnansweredCalls": "4",
        "TotalCalls": "4",
        "SNO": 1,
        "CallType": "preview",
        "CallHour": "13",
        "AnsweredCalls": "0",
        "Busy": "2",
        "DND": '3',
        "InvalidData": '5',
        "MaxDialTimeExceeded": "6",
        "NetworkOutOfOrder": "6",
        "NormalCallClearing": "6",
        "Rejected": "6",
        "Unspecified": "6",
        "NoAnswer": "6",
    },
    {
        "ConnectedPercentageRatio": "0.00",
        "UnansweredCalls": "4",
        "TotalCalls": "3",
        "SNO": 1,
        "CallType": "preview",
        "CallHour": "11",
        "AnsweredCalls": "0",
        "Busy": "1",
        "DND": '3',
        "InvalidData": '5',
        "MaxDialTimeExceeded": "6",
        "NetworkOutOfOrder": "6",
        "NormalCallClearing": "6",
        "Rejected": "6",
        "Unspecified": "6",
        "NoAnswer": "6",
    },
    {
        "ConnectedPercentageRatio": "0.00",
        "UnansweredCalls": "4",
        "TotalCalls": "4",
        "SNO": 2,
        "CallType": "All",
        "CallHour": "13",
        "AnsweredCalls": "0",
        "Busy": "2",
        "DND": '3',
        "InvalidData": '5',
        "MaxDialTimeExceeded": "6",
        "NetworkOutOfOrder": "6",
        "NormalCallClearing": "6",
        "Rejected": "6",
        "Unspecified": "6",
        "NoAnswer": "6",
    },
    {
        "ConnectedPercentageRatio": "0.00",
        "UnansweredCalls": "4",
        "TotalCalls": "4",
        "SNO": 2,
        "CallType": "All",
        "CallHour": "11",
        "AnsweredCalls": "0",
        "Busy": "2",
        "DND": '3',
        "InvalidData": '5',
        "MaxDialTimeExceeded": "6",
        "NetworkOutOfOrder": "6",
        "NormalCallClearing": "6",
        "Rejected": "6",
        "Unspecified": "6",
        "NoAnswer": "6",
    }
]

export const dummyUserDetails = [
    {
        "id": 175,
        "appPlanUser": {
            "id": 175,
            "appPlan": {
                "id": 68,
                "name": "8PMPlan",
                "createdDate": "2024-09-02 14:34:43",
                "updatedDate": "2024-10-17 11:26:06",
                "features": [
                    {
                        "id": 38,
                        "name": "Manage Numbers"
                    },
                    {
                        "id": 50,
                        "name": "Locations"
                    },
                    {
                        "id": 49,
                        "name": "Holidays"
                    },
                    {
                        "id": 21,
                        "name": "Dashboard"
                    },
                    {
                        "id": 12,
                        "name": "Dispositions"
                    },
                    {
                        "id": 45,
                        "name": "Pause Reasons"
                    },
                    {
                        "id": 11,
                        "name": "Skills"
                    },
                    {
                        "id": 44,
                        "name": "Sub Users"
                    },
                    {
                        "id": 34,
                        "name": "Templates"
                    },
                    {
                        "id": 18,
                        "name": "Pre Post"
                    },
                    {
                        "id": 52,
                        "name": "Historic Reports"
                    },
                    {
                        "id": 48,
                        "name": "Sip Number"
                    },
                    {
                        "id": 47,
                        "name": "Music File"
                    },
                    {
                        "id": 24,
                        "name": "Sub Users"
                    },
                    {
                        "id": 22,
                        "name": "Users"
                    },
                    {
                        "id": 41,
                        "name": "Live Reports"
                    },
                    {
                        "id": 40,
                        "name": "Mapping"
                    },
                    {
                        "id": 39,
                        "name": "Pre Post"
                    },
                    {
                        "id": 29,
                        "name": "Holidays"
                    },
                    {
                        "id": 25,
                        "name": "Pause Reasons"
                    },
                    {
                        "id": 13,
                        "name": "Groups"
                    },
                    {
                        "id": 10,
                        "name": "Templates"
                    },
                    {
                        "id": 51,
                        "name": "Live Reports"
                    },
                    {
                        "id": 54,
                        "name": "Settings"
                    },
                    {
                        "id": 43,
                        "name": "Users"
                    },
                    {
                        "id": 19,
                        "name": "Mapping"
                    },
                    {
                        "id": 33,
                        "name": "IVR Flows"
                    },
                    {
                        "id": 46,
                        "name": "Sip Location"
                    },
                    {
                        "id": 1,
                        "name": "Campaigns"
                    },
                    {
                        "id": 31,
                        "name": "Campaigns"
                    },
                    {
                        "id": 35,
                        "name": "Skills"
                    },
                    {
                        "id": 37,
                        "name": "Groups"
                    },
                    {
                        "id": 32,
                        "name": "Agents"
                    },
                    {
                        "id": 36,
                        "name": "Dispositions"
                    },
                    {
                        "id": 30,
                        "name": "Locations"
                    },
                    {
                        "id": 42,
                        "name": "Dashboard"
                    },
                    {
                        "id": 26,
                        "name": "Sip Location"
                    },
                    {
                        "id": 27,
                        "name": "Music File"
                    },
                    {
                        "id": 28,
                        "name": "Sip Number"
                    },
                    {
                        "id": 5,
                        "name": "IVR Flows"
                    },
                    {
                        "id": 14,
                        "name": "Manage Numbers"
                    }
                ],
                "roles": [
                    {
                        "id": 80,
                        "name": "ROLE_CONTINUE_BARGE",
                        "description": null,
                        "authority": "ROLE_CONTINUE_BARGE"
                    },
                    {
                        "id": 52,
                        "name": "ROLE_DIDMasking",
                        "description": null,
                        "authority": "ROLE_DIDMasking"
                    },
                    {
                        "id": 17,
                        "name": "ROLE_BLENDED",
                        "description": null,
                        "authority": "ROLE_BLENDED"
                    },
                    {
                        "id": 22,
                        "name": "ROLE_CAMP_PRIORITY",
                        "description": null,
                        "authority": "ROLE_CAMP_PRIORITY"
                    },
                    {
                        "id": 7,
                        "name": "ROLE_DIRECTROUTING",
                        "description": null,
                        "authority": "ROLE_DIRECTROUTING"
                    },
                    {
                        "id": 81,
                        "name": "ROLE_PARKING",
                        "description": null,
                        "authority": "ROLE_PARKING"
                    },
                    {
                        "id": 9,
                        "name": "ROLE_RATING",
                        "description": null,
                        "authority": "ROLE_RATING"
                    },
                    {
                        "id": 60,
                        "name": "ROLE_CUST_MASKING",
                        "description": null,
                        "authority": "ROLE_CUST_MASKING"
                    },
                    {
                        "id": 77,
                        "name": "ROLE_DID_CREATION",
                        "description": null,
                        "authority": "ROLE_DID_CREATION"
                    },
                    {
                        "id": 50,
                        "name": "ROLE_CDR_CRT",
                        "description": null,
                        "authority": "ROLE_CDR_CRT"
                    },
                    {
                        "id": 37,
                        "name": "ROLE_AGENTBULKUPLOAD",
                        "description": null,
                        "authority": "ROLE_AGENTBULKUPLOAD"
                    },
                    {
                        "id": 28,
                        "name": "ROLE_CALLPREFIX",
                        "description": null,
                        "authority": "ROLE_CALLPREFIX"
                    },
                    {
                        "id": 41,
                        "name": "ROLE_SKILL_CHOLDTRFR",
                        "description": null,
                        "authority": "ROLE_SKILL_CHOLDTRFR"
                    },
                    {
                        "id": 63,
                        "name": "ROLE_TAPI_LOGIN",
                        "description": null,
                        "authority": "ROLE_TAPI_LOGIN"
                    },
                    {
                        "id": -2,
                        "name": "ROLE_USER",
                        "description": null,
                        "authority": "ROLE_USER"
                    },
                    {
                        "id": 19,
                        "name": "ROLE_AGENT_CALHIST",
                        "description": null,
                        "authority": "ROLE_AGENT_CALHIST"
                    },
                    {
                        "id": 3,
                        "name": "ROLE_BARGEIN",
                        "description": null,
                        "authority": "ROLE_BARGEIN"
                    },
                    {
                        "id": 45,
                        "name": "ROLE_AMD",
                        "description": null,
                        "authority": "ROLE_AMD"
                    },
                    {
                        "id": 11,
                        "name": "ROLE_EMAIL_CAMPAIGN",
                        "description": null,
                        "authority": "ROLE_EMAIL_CAMPAIGN"
                    },
                    {
                        "id": 6,
                        "name": "ROLE_MUTE",
                        "description": null,
                        "authority": "ROLE_MUTE"
                    },
                    {
                        "id": 13,
                        "name": "ROLE_SUBADMIN",
                        "description": null,
                        "authority": "ROLE_SUBADMIN"
                    },
                    {
                        "id": 64,
                        "name": "ROLE_CUSTOMER_DISCLAIMER",
                        "description": null,
                        "authority": "ROLE_CUSTOMER_DISCLAIMER"
                    },
                    {
                        "id": 23,
                        "name": "ROLE_DND_CHECK",
                        "description": null,
                        "authority": "ROLE_DND_CHECK"
                    },
                    {
                        "id": 46,
                        "name": "ROLE_CAMPAIGNHOLDMUSIC",
                        "description": null,
                        "authority": "ROLE_CAMPAIGNHOLDMUSIC"
                    },
                    {
                        "id": 78,
                        "name": "ROLE_QA",
                        "description": null,
                        "authority": "ROLE_QA"
                    },
                    {
                        "id": 47,
                        "name": "ROLE_SENTIMENT",
                        "description": null,
                        "authority": "ROLE_SENTIMENT"
                    },
                    {
                        "id": 21,
                        "name": "ROLE_SIP",
                        "description": null,
                        "authority": "ROLE_SIP"
                    },
                    {
                        "id": 15,
                        "name": "ROLE_SMS",
                        "description": null,
                        "authority": "ROLE_SMS"
                    },
                    {
                        "id": 73,
                        "name": "ROLE_DNC_REGULATION",
                        "description": null,
                        "authority": "ROLE_DNC_REGULATION"
                    },
                    {
                        "id": 72,
                        "name": "ROLE_SKILL_PRIORITY",
                        "description": null,
                        "authority": "ROLE_SKILL_PRIORITY"
                    },
                    {
                        "id": 16,
                        "name": "ROLE_EMAIL_ALERTS",
                        "description": null,
                        "authority": "ROLE_EMAIL_ALERTS"
                    },
                    {
                        "id": 5,
                        "name": "ROLE_CONFERENCE",
                        "description": null,
                        "authority": "ROLE_CONFERENCE"
                    },
                    {
                        "id": 25,
                        "name": "ROLE_RTC",
                        "description": null,
                        "authority": "ROLE_RTC"
                    },
                    {
                        "id": 2,
                        "name": "ROLE_TICKET",
                        "description": null,
                        "authority": "ROLE_TICKET"
                    },
                    {
                        "id": 76,
                        "name": "ROLE_A2A_CALLING",
                        "description": null,
                        "authority": "ROLE_A2A_CALLING"
                    },
                    {
                        "id": 58,
                        "name": "ROLE_AGENT_ONCALL",
                        "description": null,
                        "authority": "ROLE_AGENT_ONCALL"
                    },
                    {
                        "id": 51,
                        "name": "ROLE_CDR_DUMP",
                        "description": null,
                        "authority": "ROLE_CDR_DUMP"
                    },
                    {
                        "id": 4,
                        "name": "ROLE_HOLD",
                        "description": null,
                        "authority": "ROLE_HOLD"
                    },
                    {
                        "id": 30,
                        "name": "ROLE_HELP",
                        "description": null,
                        "authority": "ROLE_HELP"
                    },
                    {
                        "id": 32,
                        "name": "ROLE_IVRTRANSFER",
                        "description": null,
                        "authority": "ROLE_IVRTRANSFER"
                    },
                    {
                        "id": 79,
                        "name": "ROLE_REDIAL",
                        "description": null,
                        "authority": "ROLE_REDIAL"
                    },
                    {
                        "id": 14,
                        "name": "ROLE_REP_MISSEDCALL",
                        "description": null,
                        "authority": "ROLE_REP_MISSEDCALL"
                    },
                    {
                        "id": 39,
                        "name": "ROLE_RETRYRULES",
                        "description": null,
                        "authority": "ROLE_RETRYRULES"
                    },
                    {
                        "id": 61,
                        "name": "ROLE_BulkEditPhoneNumber",
                        "description": null,
                        "authority": "ROLE_BulkEditPhoneNumber"
                    },
                    {
                        "id": 29,
                        "name": "ROLE_PREDICTIVE",
                        "description": null,
                        "authority": "ROLE_PREDICTIVE"
                    },
                    {
                        "id": 44,
                        "name": "ROLE_SECURE_RECORDING",
                        "description": null,
                        "authority": "ROLE_SECURE_RECORDING"
                    },
                    {
                        "id": 31,
                        "name": "ROLE_CAMPAIGNSUB",
                        "description": null,
                        "authority": "ROLE_CAMPAIGNSUB"
                    },
                    {
                        "id": 34,
                        "name": "ROLE_SALESFORCE",
                        "description": null,
                        "authority": "ROLE_SALESFORCE"
                    },
                    {
                        "id": 8,
                        "name": "ROLE_CONSLTHOLDTRFR",
                        "description": null,
                        "authority": "ROLE_CONSLTHOLDTRFR"
                    },
                    {
                        "id": 40,
                        "name": "ROLE_SIP_LOCATION",
                        "description": null,
                        "authority": "ROLE_SIP_LOCATION"
                    },
                    {
                        "id": 54,
                        "name": "ROLE_SMS_CHAT",
                        "description": null,
                        "authority": "ROLE_SMS_CHAT"
                    },
                    {
                        "id": 24,
                        "name": "ROLE_CHAT",
                        "description": null,
                        "authority": "ROLE_CHAT"
                    },
                    {
                        "id": 38,
                        "name": "ROLE_ACTIVITYSTREAM",
                        "description": null,
                        "authority": "ROLE_ACTIVITYSTREAM"
                    },
                    {
                        "id": 75,
                        "name": "ROLE_TRANSFER_HOLD",
                        "description": null,
                        "authority": "ROLE_TRANSFER_HOLD"
                    },
                    {
                        "id": 12,
                        "name": "ROLE_CALLBACKS",
                        "description": null,
                        "authority": "ROLE_CALLBACKS"
                    },
                    {
                        "id": 49,
                        "name": "ROLE_CALL_RECORDING",
                        "description": null,
                        "authority": "ROLE_CALL_RECORDING"
                    },
                    {
                        "id": 53,
                        "name": "ROLE_IVR_COPY_USER",
                        "description": null,
                        "authority": "ROLE_IVR_COPY_USER"
                    },
                    {
                        "id": 10,
                        "name": "ROLE_ADMIN_ALERTS",
                        "description": null,
                        "authority": "ROLE_ADMIN_ALERTS"
                    },
                    {
                        "id": 55,
                        "name": "ROLE_INTERNAL",
                        "description": null,
                        "authority": "ROLE_INTERNAL"
                    },
                    {
                        "id": 56,
                        "name": "ROLE_WHATSAPP_CHAT",
                        "description": null,
                        "authority": "ROLE_WHATSAPP_CHAT"
                    },
                    {
                        "id": 70,
                        "name": "ROLE_EMAIL",
                        "description": null,
                        "authority": "ROLE_EMAIL"
                    },
                    {
                        "id": 18,
                        "name": "ROLE_SKILLDROPACTION",
                        "description": null,
                        "authority": "ROLE_SKILLDROPACTION"
                    },
                    {
                        "id": 33,
                        "name": "ROLE_CBK_CAMPAIGN",
                        "description": null,
                        "authority": "ROLE_CBK_CAMPAIGN"
                    },
                    {
                        "id": 35,
                        "name": "ROLE_FBCHAT",
                        "description": null,
                        "authority": "ROLE_FBCHAT"
                    },
                    {
                        "id": 62,
                        "name": "ROLE_FallBackDID",
                        "description": null,
                        "authority": "ROLE_FallBackDID"
                    },
                    {
                        "id": 69,
                        "name": "ROLE_SCREEN_RECORDING",
                        "description": null,
                        "authority": "ROLE_SCREEN_RECORDING"
                    },
                    {
                        "id": 20,
                        "name": "ROLE_HOME_DASHBOARD",
                        "description": null,
                        "authority": "ROLE_HOME_DASHBOARD"
                    },
                    {
                        "id": 1,
                        "name": "ROLE_OUTBOUND",
                        "description": null,
                        "authority": "ROLE_OUTBOUND"
                    },
                    {
                        "id": 48,
                        "name": "ROLE_TRANSFER_RECORD",
                        "description": null,
                        "authority": "ROLE_TRANSFER_RECORD"
                    },
                    {
                        "id": 27,
                        "name": "ROLE_MARKETING",
                        "description": null,
                        "authority": "ROLE_MARKETING"
                    },
                    {
                        "id": 66,
                        "name": "ROLE_PREVIEWDATA_EDIT",
                        "description": null,
                        "authority": "ROLE_PREVIEWDATA_EDIT"
                    },
                    {
                        "id": 74,
                        "name": "ROLE_ADMIN_CHAT_BROA",
                        "description": null,
                        "authority": "ROLE_ADMIN_CHAT_BROA"
                    },
                    {
                        "id": 26,
                        "name": "ROLE_IVRDESIGNER_ALL",
                        "description": null,
                        "authority": "ROLE_IVRDESIGNER_ALL"
                    },
                    {
                        "id": 43,
                        "name": "ROLE_NO_AUDIO_DOWNLOAD",
                        "description": null,
                        "authority": "ROLE_NO_AUDIO_DOWNLOAD"
                    },
                    {
                        "id": 67,
                        "name": "ROLE_OBD_RESTRICTION",
                        "description": null,
                        "authority": "ROLE_OBD_RESTRICTION"
                    },
                    {
                        "id": 36,
                        "name": "ROLE_TWITTER",
                        "description": null,
                        "authority": "ROLE_TWITTER"
                    }
                ]
            }
        },
        "appProfileUser": [],
        "city": "kakinada",
        "province": "sttate",
        "country": "IN",
        "postalCode": "533001",
        "userLocation": "AddressData",
        "username": "9PM",
        "password": "0b49ab4d35a27d514af9cb6eaa15b1c187c8f4e6",
        "password_sha2": "d881eb0137d82aa001d0e2dee76f976cc3826f9939b359dd3131a62a39947733",
        "apiKey": "KK2dff8d4ba7b512d73607169075e664dd",
        "confirmPassword": null,
        "passwordHint": "Test@123#",
        "firstName": "QA8",
        "lastName": "Testers",
        "email": "Padma.balagani@ozonetel.com",
        "phoneNumber": "+918790894041",
        "extension": "123",
        // "logoImage": "https://images.unsplash.com/1/type-away-numero-dos.jpg?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // "iconImage": "https://images.unsplash.com/1/type-away-numero-dos.jpg?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "website": "",
        "version": 119,
        "sipBargeExtension": null,
        "createdDate": "2024-08-14 00:00:00",
        "sipLocationExtension": null,
        "roles": [
            {
                "id": 52,
                "name": "ROLE_DIDMasking"
            },
            {
                "id": 80,
                "name": "ROLE_CONTINUE_BARGE"
            },
            {
                "id": 7,
                "name": "ROLE_DIRECTROUTING"
            },
            {
                "id": 17,
                "name": "ROLE_BLENDED"
            },
            {
                "id": 22,
                "name": "ROLE_CAMP_PRIORITY"
            },
            {
                "id": 81,
                "name": "ROLE_PARKING"
            },
            {
                "id": 9,
                "name": "ROLE_RATING"
            },
            {
                "id": 60,
                "name": "ROLE_CUST_MASKING"
            },
            {
                "id": 77,
                "name": "ROLE_DID_CREATION"
            },
            {
                "id": 50,
                "name": "ROLE_CDR_CRT"
            },
            {
                "id": 28,
                "name": "ROLE_CALLPREFIX"
            },
            {
                "id": 37,
                "name": "ROLE_AGENTBULKUPLOAD"
            },
            {
                "id": 41,
                "name": "ROLE_SKILL_CHOLDTRFR"
            },
            {
                "id": 63,
                "name": "ROLE_TAPI_LOGIN"
            },
            {
                "id": -2,
                "name": "ROLE_USER"
            },
            {
                "id": 3,
                "name": "ROLE_BARGEIN"
            },
            {
                "id": 19,
                "name": "ROLE_AGENT_CALHIST"
            },
            {
                "id": 45,
                "name": "ROLE_AMD"
            },
            {
                "id": 11,
                "name": "ROLE_EMAIL_CAMPAIGN"
            },
            {
                "id": 6,
                "name": "ROLE_MUTE"
            },
            {
                "id": 13,
                "name": "ROLE_SUBADMIN"
            },
            {
                "id": 23,
                "name": "ROLE_DND_CHECK"
            },
            {
                "id": 64,
                "name": "ROLE_CUSTOMER_DISCLAIMER"
            },
            {
                "id": 46,
                "name": "ROLE_CAMPAIGNHOLDMUSIC"
            },
            {
                "id": 21,
                "name": "ROLE_SIP"
            },
            {
                "id": 47,
                "name": "ROLE_SENTIMENT"
            },
            {
                "id": 78,
                "name": "ROLE_QA"
            },
            {
                "id": 15,
                "name": "ROLE_SMS"
            },
            {
                "id": 72,
                "name": "ROLE_SKILL_PRIORITY"
            },
            {
                "id": 73,
                "name": "ROLE_DNC_REGULATION"
            },
            {
                "id": 16,
                "name": "ROLE_EMAIL_ALERTS"
            },
            {
                "id": 2,
                "name": "ROLE_TICKET"
            },
            {
                "id": 5,
                "name": "ROLE_CONFERENCE"
            },
            {
                "id": 25,
                "name": "ROLE_RTC"
            },
            {
                "id": 58,
                "name": "ROLE_AGENT_ONCALL"
            },
            {
                "id": 76,
                "name": "ROLE_A2A_CALLING"
            },
            {
                "id": 4,
                "name": "ROLE_HOLD"
            },
            {
                "id": 51,
                "name": "ROLE_CDR_DUMP"
            },
            {
                "id": 30,
                "name": "ROLE_HELP"
            },
            {
                "id": 32,
                "name": "ROLE_IVRTRANSFER"
            },
            {
                "id": 14,
                "name": "ROLE_REP_MISSEDCALL"
            },
            {
                "id": 79,
                "name": "ROLE_REDIAL"
            },
            {
                "id": 39,
                "name": "ROLE_RETRYRULES"
            },
            {
                "id": 61,
                "name": "ROLE_BulkEditPhoneNumber"
            },
            {
                "id": 29,
                "name": "ROLE_PREDICTIVE"
            },
            {
                "id": 44,
                "name": "ROLE_SECURE_RECORDING"
            },
            {
                "id": 31,
                "name": "ROLE_CAMPAIGNSUB"
            },
            {
                "id": 34,
                "name": "ROLE_SALESFORCE"
            },
            {
                "id": 8,
                "name": "ROLE_CONSLTHOLDTRFR"
            },
            {
                "id": 40,
                "name": "ROLE_SIP_LOCATION"
            },
            {
                "id": 54,
                "name": "ROLE_SMS_CHAT"
            },
            {
                "id": 24,
                "name": "ROLE_CHAT"
            },
            {
                "id": 38,
                "name": "ROLE_ACTIVITYSTREAM"
            },
            {
                "id": 75,
                "name": "ROLE_TRANSFER_HOLD"
            },
            {
                "id": 12,
                "name": "ROLE_CALLBACKS"
            },
            {
                "id": 49,
                "name": "ROLE_CALL_RECORDING"
            },
            {
                "id": 53,
                "name": "ROLE_IVR_COPY_USER"
            },
            {
                "id": 10,
                "name": "ROLE_ADMIN_ALERTS"
            },
            {
                "id": 55,
                "name": "ROLE_INTERNAL"
            },
            {
                "id": 56,
                "name": "ROLE_WHATSAPP_CHAT"
            },
            {
                "id": 70,
                "name": "ROLE_EMAIL"
            },
            {
                "id": 18,
                "name": "ROLE_SKILLDROPACTION"
            },
            {
                "id": 33,
                "name": "ROLE_CBK_CAMPAIGN"
            },
            {
                "id": 35,
                "name": "ROLE_FBCHAT"
            },
            {
                "id": 62,
                "name": "ROLE_FallBackDID"
            },
            {
                "id": 69,
                "name": "ROLE_SCREEN_RECORDING"
            },
            {
                "id": 20,
                "name": "ROLE_HOME_DASHBOARD"
            },
            {
                "id": 1,
                "name": "ROLE_OUTBOUND"
            },
            {
                "id": 48,
                "name": "ROLE_TRANSFER_RECORD"
            },
            {
                "id": 27,
                "name": "ROLE_MARKETING"
            },
            {
                "id": 66,
                "name": "ROLE_PREVIEWDATA_EDIT"
            },
            {
                "id": 26,
                "name": "ROLE_IVRDESIGNER_ALL"
            },
            {
                "id": 74,
                "name": "ROLE_ADMIN_CHAT_BROA"
            },
            {
                "id": 43,
                "name": "ROLE_NO_AUDIO_DOWNLOAD"
            },
            {
                "id": 36,
                "name": "ROLE_TWITTER"
            },
            {
                "id": 67,
                "name": "ROLE_OBD_RESTRICTION"
            }
        ],
        "enabled": true,
        "accountExpired": false,
        "accountLocked": false,
        "continueBarge": false,
        "credentialsExpired": false,
        "screenPopMode": 2,
        "callsApiKey": "KK2dff8d4ba7b512d73607169075e664dd",
        "chatApiKey": null,
        "callbackUrl": "",
        "urlMap": {
            "id": 59,
            "name": "MDL_New"
        },
        "appModules": [
            {
                "id": 162,
                "moduleName": "ReportDownloadMenu"
            }
        ],
        "userCampaigns": [],
        "userCampaignsData": [],
        "userSkills": [],
        "userSkillsData": [],
        "userGroups": [],
        "userGroupsData": [],
        "redis": false,
        "accountUser": true,
        "account": {
            "accountId": -2,
            "accountName": "ozoneca10",
            "partnerLevel": 0
        },
        "is_update": false,
        "allowedIp": "183.82.99.139",
        "userTimezone": "Asia/Kolkata",
        "serverTimezone": "Asia/Kolkata",
        "authType": null,
        "authConfig": null,
        "parentUser": null,
        "dialerMapping": "",
        "fullName": "QA8 Testers",
        // "partnerDomain": "new.com"
    }
]

export function getDefaultDuration() {
    const yesterdayDate = new Date();
    yesterdayDate.setDate(new Date().getDate() - 1);
    return { fromTime: yesterdayDate.toISOString().split('T')[0] + ' 00:00:00', toTime: yesterdayDate.toISOString().split('T')[0] + ' 23:59:59' };
    // return {
    // 	fromTime: '2022-12-01 00:00:00',
    // 	toTime: '2022-12-06 23:59:59'
    // };
}

export function getDefaultLiveDuration() {
    const currentDate = new Date();
    return { fromTime: currentDate.toISOString().split('T')[0] + ' 00:00:00', toTime: currentDate.toISOString().split('T')[0] + ' 23:59:59' };
}

export const checkObjectValues = (obj: any, search: string): any => {
    try {
        return Object.keys(obj).some((k) => (typeof obj[k] === 'string' ? obj[k].toLowerCase().includes(search.toLowerCase()) : checkObjectValues(obj[k], search)));
    } catch (e) {
        return false;
    }
};
export const FILTER_LABELS: any = {
    agent: 'Agent Name',
    campaignName: 'Campaign Name',
    location: 'Location',
    locationForLive: 'Location',
    phoneName: 'Phone Name',
    skill: 'Skill',
    disposition: 'Disposition',
    transfered: 'Transfered',
    callEvent: 'Call Event',
    callEventForLive: 'Call Event',
    callType: 'Call Type',
    callStatus: 'Status',
    reportType: 'Campaign Type',
    durationinsecs: 'Duration (in sec)',
    durationInput: 'Enter Duration',
    dateFormat: 'Date Format',
    callMetrics: 'Call Metrics',
    phone: 'Phone Name',
    mode: 'Mode Type',
    phoneNumber: 'Phone Number',
    modeType: 'Mode Type',
    modeTypeFilterId: 'Mode Type Filter Id',
    channelType: 'Channel Type',
    campaignDId: 'Business Number',
    agentName: 'Agent Name',
};

export const dummypieChart = [
    { name: 'busy', y: 18.7, color: '#24528D' },
    { name: 'Calling', y: 20.2, color: '#70DAA9' },
    { name: 'CallsinQueue', y: 14.2, color: '#008DFF' },
    { name: 'ActiveCallsinIVR', y: 25.6, color: '#B9D8FB' }
]

export const dummypieChartDigital = [
    { name: 'ChatsInQueue', y: 21.3, color: '#536580' },
    { name: 'busy', y: 20.2, color: '#B9D8FB' },
]


export const dummyInboundData = [
    {
        "CallType": "inbound",
        "CallHour": 13,
        "TotalCalls": 2,
        "AnsweredCalls": 1,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 50.00
    },
    {
        "CallType": "inbound",
        "CallHour": 9,
        "TotalCalls": 2,
        "AnsweredCalls": 0,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "inbound",
        "CallHour": 10,
        "TotalCalls": 5,
        "AnsweredCalls": 5,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    },
    {
        "CallType": "All",
        "CallHour": 13,
        "TotalCalls": 2,
        "AnsweredCalls": 1,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 50.00
    },
    {
        "CallType": "All",
        "CallHour": 9,
        "TotalCalls": 2,
        "AnsweredCalls": 0,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "All",
        "CallHour": 10,
        "TotalCalls": 5,
        "AnsweredCalls": 5,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    }
]

export const dummyAgentWise = [
    {
        "SkillName": "whatsapp",
        "agentname": "whatsapp",
        "did": 9876567,
        "AgentsAvailable": 2,
        "LongestWaitTime": 20,
        "date": 1,
    },
    {
        "SkillName": "whatsapp",
        "agentname": "whatsapp",
        "did": 98765678,
        "AgentsAvailable": 2,
        "LongestWaitTime": 30,
        "date": 1,
    }
]

export const dummySkillWise = [
    {
        "SkillName": "whatsapp",
        "QueueCount": 9,
        "AgentsAvailable": 2,
        "LongestWaitTime": 1,
    },
    {
        "SkillName": "whatsapp",
        "QueueCount": 9,
        "AgentsAvailable": 2,
        "LongestWaitTime": 1,
    },
]

export const dummyHourlyChatData = [
    {
        "CallType": "whatsapp",
        "CallHour": 9,
        "TotalCalls": 2,
        "AnsweredCalls": 1,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 50.00
    },
    {
        "CallType": "All",
        "CallHour": 9,
        "TotalCalls": 2,
        "AnsweredCalls": 1,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 50.00
    }
]

export const dummyHourlyData = [
    {
        "CallType": "manual",
        "CallHour": 9,
        "TotalCalls": 3,
        "AnsweredCalls": 0,
        "UnansweredCalls": 3,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "manual",
        "CallHour": 10,
        "TotalCalls": 1,
        "AnsweredCalls": 1,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    },
    {
        "CallType": "manual",
        "CallHour": 11,
        "TotalCalls": 1,
        "AnsweredCalls": 1,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    },
    {
        "CallType": "manual",
        "CallHour": 12,
        "TotalCalls": 13,
        "AnsweredCalls": 7,
        "UnansweredCalls": 6,
        "ConnectedPercentageRatio": 53.85
    },
    {
        "CallType": "manual",
        "CallHour": 13,
        "TotalCalls": 11,
        "AnsweredCalls": 6,
        "UnansweredCalls": 5,
        "ConnectedPercentageRatio": 54.55
    },
    {
        "CallType": "manual",
        "CallHour": 14,
        "TotalCalls": 11,
        "AnsweredCalls": 10,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 90.91
    },
    {
        "CallType": "progressive",
        "CallHour": 11,
        "TotalCalls": 1,
        "AnsweredCalls": 1,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    },
    {
        "CallType": "progressive",
        "CallHour": 13,
        "TotalCalls": 3,
        "AnsweredCalls": 1,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 33.33
    },
    {
        "CallType": "preview",
        "CallHour": 11,
        "TotalCalls": 2,
        "AnsweredCalls": 2,
        "UnansweredCalls": 0,
        "ConnectedPercentageRatio": 100.00
    },
    {
        "CallType": "preview",
        "CallHour": 13,
        "TotalCalls": 3,
        "AnsweredCalls": 1,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 33.33
    },
    {
        "CallType": "predictive",
        "CallHour": 9,
        "TotalCalls": 8,
        "AnsweredCalls": 0,
        "UnansweredCalls": 8,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "predictive",
        "CallHour": 10,
        "TotalCalls": 3,
        "AnsweredCalls": 1,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 33.33
    },
    {
        "CallType": "predictive",
        "CallHour": 11,
        "TotalCalls": 1,
        "AnsweredCalls": 0,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "predictive",
        "CallHour": 13,
        "TotalCalls": 29,
        "AnsweredCalls": 1,
        "UnansweredCalls": 28,
        "ConnectedPercentageRatio": 3.45
    },
    {
        "CallType": "All",
        "CallHour": 9,
        "TotalCalls": 11,
        "AnsweredCalls": 0,
        "UnansweredCalls": 11,
        "ConnectedPercentageRatio": 0.00
    },
    {
        "CallType": "All",
        "CallHour": 11,
        "TotalCalls": 5,
        "AnsweredCalls": 4,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 80.00
    },
    {
        "CallType": "All",
        "CallHour": 12,
        "TotalCalls": 13,
        "AnsweredCalls": 7,
        "UnansweredCalls": 6,
        "ConnectedPercentageRatio": 53.85
    },
    {
        "CallType": "All",
        "CallHour": 13,
        "TotalCalls": 46,
        "AnsweredCalls": 9,
        "UnansweredCalls": 37,
        "ConnectedPercentageRatio": 19.57
    },
    {
        "CallType": "All",
        "CallHour": 14,
        "TotalCalls": 11,
        "AnsweredCalls": 10,
        "UnansweredCalls": 1,
        "ConnectedPercentageRatio": 90.91
    },
    {
        "CallType": "All",
        "CallHour": 10,
        "TotalCalls": 4,
        "AnsweredCalls": 2,
        "UnansweredCalls": 2,
        "ConnectedPercentageRatio": 50.00
    }
]

export const dummyData = {
    "pageNo": 1,
    "rowsPerpage": 10,
    "totalNoOfRows": 1,
    "totalNoPages": 1,
    "requestExcecutionId": "163d6fcd-4ab9-4ca1-8b86-bd4e7550832d",
    "reports": [
        {
            "skillName": 'happy',
            "totalCalls": "16048",
            "totalAnsweredCalls": "15885",
            "skillQueues": "10",
            "queueTime": "00:00:17",
            "callerDisconnectsPercentage": "95",
            "callerDisconnects": "20",
            "slaPercentage": "95",
            "att": "00:00:30",
            "awt": "00:01:30",
            "ast": "00:20:30",
            "aht": "01:20:30",
            "callsAgent": "1585",
            "agentDisconnect": "85",
            "agentDisconnectPercentage": "12",
            "agentStaffedTotal": "50",
            "agentStaffed": {
                "Ready": "34",
                "Pause": "25",
                "AUX": "35",
                "BUSY": "71"
            }
        },
        {
            "skillName": 'happy1',
            "totalCalls": "16048",
            "totalAnsweredCalls": "15885",
            "skillQueues": "10",
            "queueTime": "00:10:17",
            "callerDisconnectsPercentage": "95",
            "callerDisconnects": "2",
            "slaPercentage": "95",
            "att": "00:00:30",
            "awt": "00:01:30",
            "ast": "00:20:30",
            "aht": "01:20:30",
            "callsAgent": "1585",
            "agentDisconnect": "85",
            "agentDisconnectPercentage": "12",
            "agentStaffedTotal": "50",
            "agentStaffed": {
                "Ready": "4",
                "Pause": "2",
                "AUX": "5",
                "BUSY": "1"
            }
        },
        {
            "skillName": 'happy3',
            "totalCalls": "16048",
            "totalAnsweredCalls": "15885",
            "skillQueues": "10",
            "queueTime": "00:00:13",
            "callerDisconnectsPercentage": "95",
            "callerDisconnects": "10",
            "slaPercentage": "95",
            "att": "00:00:30",
            "awt": "00:01:30",
            "ast": "00:20:30",
            "aht": "01:20:30",
            "callsAgent": "1585",
            "agentDisconnect": "85",
            "agentDisconnectPercentage": "12",
            "agentStaffedTotal": "50",
            "agentStaffed": {
                "Ready": "4",
                "Pause": "2",
                "AUX": "5",
                "BUSY": "1"
            }
        },
        {
            "skillName": 'happy2',
            "totalCalls": "16048",
            "totalAnsweredCalls": "15885",
            "skillQueues": "10",
            "queueTime": "00:00:11",
            "callerDisconnectsPercentage": "95",
            "callerDisconnects": "4",
            "slaPercentage": "95",
            "att": "00:00:30",
            "awt": "00:01:30",
            "ast": "00:20:30",
            "aht": "01:20:30",
            "callsAgent": "1585",
            "agentDisconnect": "85",
            "agentDisconnectPercentage": "12",
            "agentStaffedTotal": "50",
            "agentStaffed": {
                "Ready": "4",
                "Pause": "2",
                "AUX": "0",
                "BUSY": "1"
            }
        }
    ]
}

export const dummyDataHappy = {
    "pageNo": 1,
    "rowsPerpage": 10,
    "totalNoOfRows": 1,
    "totalNoPages": 1,
    "requestExcecutionId": "163d6fcd-4ab9-4ca1-8b86-bd4e7550832d",
    "reports": [
        {
            "agentStaffedTotal": "50",
            "skillName": 'happy',
            "agentStaffed": {
                "Ready": "34",
                "Pause": "25",
                "AUX": "35",
                "BUSY": "71"
            }
        },
        {
            "agentStaffedTotal": "50",
            "skillName": 'happy1',
            "agentStaffed": {
                "Ready": "34",
                "Pause": "20",
                "AUX": "35",
                "BUSY": "71"
            }
        },
        {
            "agentStaffedTotal": "50",
            "skillName": 'happy3',
            "agentStaffed": {
                "Ready": "34",
                "Pause": "0",
                "AUX": "35",
                "BUSY": "71"
            }
        },
        {
            "agentStaffedTotal": "50",
            "skillName": 'happy2',
            "agentStaffed": {
                "Ready": "34",
                "Pause": "215",
                "AUX": "35",
                "BUSY": "71"
            }
        },
    ]
}

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

export const STATUS_CLASS_MAP: any = {
    READY: 'ready-color bold-font',
    Ready: 'ready-color bold-font',
    IDLE: 'idle-color bold-font',
    Idle: 'idle-color bold-font',
    BUSY: 'busy-color bold-font',
    Busy: 'busy-color bold-font',
    ACW: 'acw-color bold-font',
    Acw: 'acw-color bold-font',
    CALLING: 'calling-color bold-font',
    Calling: 'calling-color bold-font',
    PAUSED: 'pause-color bold-font',
    Paused: 'pause-color bold-font',
    Aux: 'aux-color bold-font',
    AUX: 'aux-color bold-font',
};
export const additionalColumns = [
    { id: 'totalAgentTalkTime', label: 'Total Talk Time', minWidth: 80, positionIndex: 4 },
    {
        id: 'totalWrapupTime',
        label: 'Total Wrapup Time',
        minWidth: 50,
        positionIndex: 5
    },
    { id: 'totalAgentHandlingTime', label: 'Total Handle Time', minWidth: 50, positionIndex: 6 },
    {
        id: 'totalAgentLoginDuration',
        label: 'Total Login Time',
        minWidth: 30,
        positionIndex: 7
    },
    { id: 'avgAgentWrapupTime', label: 'Avg. Wrapup Time', minWidth: 65, positionIndex: 8 },
    { id: 'avgAgentTalkTime', label: 'Avg. Talk Time', minWidth: 50, positionIndex: 9 },
    { id: 'avgAgentHandlingTime', label: 'Avg. Handle Time', minWidth: 50, positionIndex: 10 },
    { id: 'avgAgentIdleTime', label: 'Avg. Idle Time', minWidth: 50, positionIndex: 11 }
];

export const colorDataForBackground: any = {
    AvgTalkTime: '#F9D4D4',
    AvgHoldTime: '#FFF8EF',
    AvgWaitTime: '#E6F4FF',
    AvgWrapupTime: '#FFF8EF',
    AvgHandlingTime: '#F3FDEF',
    AvgSpeedToAnswer: '#F7F5FF',
    CallConnectionRatio: '#F3FDEE',
    WrapTimeExceeded: '#E2F1FD',
    AbandonedCalls: '#FFF8EF',
    CallAbandonedRatio: '#FFF0DE',
    AgentDisconnects: '#E3F2FF',
    AgentDisconnectsRatio: '#E6F4FF',
    TotalCalls: '#F3FDEF',
    SLA: '#FFF8EF',
    // avgSpeedAnswer: '#F7F5FF',
    CallPerAgents: '#F7E8E8',
    // callAgents: '#F3FDEF',
    // AbandonedCallsDigi: '#E6F4FF',
    // CallAbandonedRatioDigi: '#E6F4FF',
    // avgChatTime: '#F7F5FF',
    TotalQueuedCalls: '#FFF8EF',
}

export const colorDataForPlot: any = {
    AvgTalkTime: '#E65B5C',
    AvgHoldTime: '#F7A35C',
    AvgWaitTime: '#008DFF',
    AvgWrapupTime: '#F7A35C',
    AvgHandlingTime: '#4F9F52',
    AvgSpeedToAnswer: '#B2A1F9',
    CallConnectionRatio: '#4F9F52',
    WrapTimeExceeded: '#E2F1FD',
    AbandonedCalls: '#F7A35C',
    CallAbandonedRatio: '#F7A35C',
    AgentDisconnects: '#008DFF',
    AgentDisconnectsRatio: '#008DFF',
    TotalCalls: '#4F9F52',
    SLA: '#F7A35C',
    // avgSpeedAnswer: '#B2A1F9',
    CallPerAgents: '#E65B5C',
    // chatPerAgent: '#4F9F52',
    // AbandonedCallsDigi: '#DAEEFF',
    // CallAbandonedRatioDigi: '#DAEEFF',
    // avgChatTime: '#B2A1F9',
    TotalQueuedCalls: '#F7A35C',
}

export const historicDummyData = [
    {
        "SNO": 1,
        "HourlyValues": "{\"Rows\": [[0, 0,0, 0, 0, 0, 0, 0.00, 0.00, 0.00, 0.00, 0.00, 0, 0, 0, 0, 0, 0, 0, 0, 0.00, 0.00]], \"Headers\": [\"CallHour\", \"TotalCalls\", \"WrapTimeExceeded\", \"TotalQueuedCalls\", \"LongestQueueTime\", \"AnsweredCalls\", \"AbandonedCalls\", \"AvgTalkTime\", \"AvgHoldTime\", \"AvgWrapupTime\", \"AvgHandlingTime\", \"AvgSpeedToAnswer\", \"CallConnectionRatio\", \"CallAbandonedRatio\", \"CallerDisconnects\", \"AgentDisconnects\", \"SystemDisconnects\", \"TotalWrapupCalls\", \"CallPerAgents\", \"SLA\", \"AvgWaitTime\", \"AgentDisconnectsRatio\"]}",
        "DailyValues": "{\"Rows\": [[0, 0,0, 0, 0, 0, 0, 0.00, 0, 0, 0, 0.00, 0.00, 0, 0, 0, 0, 0, 0, 0, 0.00]], \"Headers\": [\"TotalCalls\", \"TotalQueuedCalls\", \"WrapTimeExceeded\", \"LongestQueueTime\", \"AnsweredCalls\", \"AbandonedCalls\", \"AvgTalkTime\", \"AvgHoldTime\", \"AvgWrapupTime\", \"AvgHandlingTime\", \"AvgSpeedToAnswer\", \"CallConnectionRatio\", \"CallAbandonedRatio\", \"CallerDisconnects\", \"AgentDisconnects\", \"SystemDisconnects\", \"TotalWrapupCalls\", \"CallPerAgents\", \"SLA\", \"AvgWaitTime\", \"AgentDisconnectsRatio\"]}"
    }
]

export const historicDummyDataDigital = [
    {
        "SNO": 1,
        "HourlyValues": "{\"Rows\": [[0, 0,0, 0, 0, 0, 0, 0.00, 0.00, 0.00, 0.00, 0.00, 0, 0, 0, 0, 0, 0, 0, 0, 0.00, 0.00]], \"Headers\": [\"CallHour\", \"TotalCalls\", \"WrapTimeExceeded\", \"TotalQueuedCalls\", \"LongestQueueTime\", \"AnsweredCalls\", \"AbandonedCalls\", \"AvgTalkTime\", \"AvgHoldTime\", \"AvgWrapupTime\", \"AvgHandlingTime\", \"AvgSpeedToAnswer\", \"CallConnectionRatio\", \"CallAbandonedRatio\", \"CallerDisconnects\", \"AgentDisconnects\", \"SystemDisconnects\", \"TotalWrapupCalls\", \"CallPerAgents\", \"SLA\", \"AvgWaitTime\", \"AgentDisconnectsRatio\"]}",
        "DailyValues": "{\"Rows\": [[0, 0,0, 0, 0, 0, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0, 0, 0, 0, 0, 0, 0.00, 0.0]], \"Headers\": [\"TotalCalls\", \"WrapTimeExceeded\", \"TotalQueuedCalls\", \"LongestQueueTime\", \"AnsweredCalls\", \"AbandonedCalls\", \"AvgTalkTime\", \"AvgHoldTime\", \"AvgWrapupTime\", \"AvgHandlingTime\", \"AvgSpeedToAnswer\", \"CallConnectionRatio\", \"CallAbandonedRatio\", \"CallerDisconnects\", \"AgentDisconnects\", \"SystemDisconnects\", \"TotalWrapupCalls\", \"CallPerAgents\", \"SLA\", \"AvgWaitTime\", \"AgentDisconnectsRatio\"]}"
    }
]

export const dummyMusicrowDatra = [
    {
        "Name": "ozo_auto1",
        "SkillCount": 73,
        "audioUrl": "http://www.ggogle.com"
    },
    {
        "Name": "s1h2rw2n",
        "audioUrl": "http://www.ggogle.com",
        "SkillCount": 19
    },
    {
        "Name": "Bangalor",
        "SkillCount": 19,
        "audioUrl": "http://www.ggogle.com"
    },
    {
        "Name": "dekeer",
        "SkillCount": 3,
        "audioUrl": "http://www.ggogle.com"
    },
    {
        "Name": "DelhiNcr",
        "SkillCount": 1,
        "audioUrl": "http://www.ggogle.com"
    },
    {
        "audioUrl": "http://www.ggogle.com",
        "Name": "Goa_New",
        "SkillCount": 0
    },
    {
        "audioUrl": "http://www.ggogle.com",
        "SkillCount": 4,
        "Name": "Canada"
    },
    {
        "Name": "uttarakhand",
        "audioUrl": "http://www.ggogle.com",
        "SkillCount": 0
    },
    {
        "audioUrl": "http://www.ggogle.com",
        "SkillCount": 1,
        "Name": "Kurnool"
    },
    {
        "audioUrl": "http://www.ggogle.com",
        "SkillCount": 0,
        "Name": "Vijayawada"
    },
    {
        "SkillCount": 0,
        "audioUrl": "http://www.ggogle.com",
        "Name": "Kadapa"
    }
]
export const dummyAgentrowDatra = [
    {
        "AgentName": "NameisHarii",
        "Email": "",
        "id": 132655,
        "Priority": 1,
        "AssingedSKills": "hari_skill,General,IVRCheck,Resource,olo",
        "AgentId": "hari"
    },
    {
        "Email": "",
        "id": 132716,
        "Priority": 1,
        "AgentName": "Hari",
        "AgentId": "HARI045",
        "AssingedSKills": "General,Preview1,Preview2,Balu_Skill,Resource"
    },
    {
        "AssingedSKills": "General",
        "Email": "",
        "AgentId": "HariAgent",
        "Priority": 1,
        "AgentName": "hari",
        "id": 132741
    },
    {
        "AssingedSKills": "General",
        "Email": "",
        "Priority": 1,
        "id": 132688,
        "AgentId": "Hello",
        "AgentName": "Hello"
    },
    {
        "AssingedSKills": "General",
        "Email": "",
        "Priority": 1,
        "AgentId": "Hello1",
        "AgentName": "Hello1",
        "id": 132690
    },
    {
        "AgentId": "jagadish",
        "AgentName": "jagadish",
        "AssingedSKills": "General,cherry5,cherry7,cherry9,QA_Salman,QA_Salman1",
        "id": 132652,
        "Priority": 1,
        "Email": "jagadish@ozonetel.com"
    },
    {
        "Email": "",
        "id": 132653,
        "Priority": 2,
        "AssingedSKills": "Preview1,Preview2,General",
        "AgentId": "Kantha",
        "AgentName": "Kantha"
    },
    {
        "id": 132651,
        "Email": "",
        "AgentName": "manish",
        "AssingedSKills": "sticky,General,MANSkill",
        "Priority": 1,
        "AgentId": "manishOO7"
    },
    {
        "id": 132650,
        "Email": "",
        "Priority": 1,
        "AgentName": "manish1",
        "AgentId": "manishOO8",
        "AssingedSKills": "General,sticky,MANSkill"
    },
    {
        "AssingedSKills": "General",
        "AgentId": "ozone",
        "Priority": 1,
        "AgentName": "ozonetel",
        "id": 132643,
        "Email": "sainath.m@ozonetel.com"
    },
    {
        "AssingedSKills": "Resource,Predictive,lol",
        "AgentName": "Padma_AgentName",
        "Email": "",
        "Priority": 1,
        "AgentId": "Padma",
        "id": 132646
    },
    {
        "Email": "",
        "Priority": 1,
        "id": 132657,
        "AssingedSKills": "",
        "AgentId": "Po45",
        "AgentName": "Padhu"
    },
    {
        "Email": "",
        "AssingedSKills": "Preview1,Preview2,General,sticky,IVRCheck,hari_skill,Resource",
        "id": 132654,
        "Priority": 1,
        "AgentId": "Priya@123",
        "AgentName": "Priya@123"
    },
    {
        "AssingedSKills": "General",
        "Email": "",
        "AgentId": "rajesh",
        "AgentName": "rajesh",
        "Priority": 1,
        "id": 132742
    },
    {
        "id": 132731,
        "AssingedSKills": "sticky",
        "Email": "",
        "AgentId": "Ram",
        "AgentName": "Ram",
        "Priority": 1
    },
    {
        "Email": "",
        "Priority": 1,
        "AssingedSKills": "General,cherry5,cherry7,cherry6,cherry9",
        "id": 132645,
        "AgentId": "Salman",
        "AgentName": "Salman"
    },
    {
        "id": 132649,
        "AssingedSKills": "General",
        "Email": "",
        "Priority": 1,
        "AgentId": "salman_sk",
        "AgentName": "Salman_sk"
    },
    {
        "AgentId": "shiva",
        "Email": "",
        "Priority": 1,
        "AssingedSKills": "",
        "id": 132660,
        "AgentName": "Siva Prasad Annam"
    },
    {
        "Email": "",
        "AssingedSKills": "General,Preview2",
        "Priority": 1,
        "AgentId": "Sudhakar",
        "AgentName": "Sudhakar",
        "id": 132692
    },
    {
        "id": 132713,
        "AgentName": "BaluYadav",
        "Priority": 1,
        "AssingedSKills": "",
        "AgentId": "test",
        "Email": "www.balu@gmail.com"
    },
    {
        "AgentName": "BaluYadav",
        "id": 132714,
        "Priority": 1,
        "AssingedSKills": "",
        "AgentId": "test45",
        "Email": "www.balu@gmail.com"
    },
    {
        "AssingedSKills": "General,Predictive,Skill_Vathi,Resource",
        "id": 132648,
        "Email": "",
        "Priority": 1,
        "AgentName": "Vathi",
        "AgentId": "Vathi123"
    },
    {
        "AssingedSKills": "General",
        "Email": "",
        "Priority": 1,
        "AgentId": "venkat",
        "AgentName": "venkat",
        "id": 132663
    }
]
export const dummyUserListrowData = [
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 2",
        "FullName": "Abhishek",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 5",
        "FullName": "Gaurav Bansal",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "Aayush Mann",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "bunny",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
    {
        "locked": "General",
        "UserName": "Sub Admin 1",
        "FullName": "boon",
        "Email": "admin@ozonetel.com",
        "AddedOn": "15 May 2022, 8:00 am",
        "Status": "Active"
    },
]

export const secondaryDID = [
    {
        "TrunkName": "CCAAS_Tata_HDB_462_8069808100",
        "PriId": 7,
        "PriName": "8069808100",
        "DID": "918069898030"
    },
    {
        "TrunkName": "CCAAS_Tata_HDB_462_8069808100",
        "PriId": 7,
        "PriName": "8069808100",
        "DID": "918069898031"
    },
    {
        "TrunkName": "CCAAS_Tata_HDB_462_8069808100",
        "PriId": 7,
        "PriName": "8069808100",
        "DID": "918069898032"
    },
    {
        "TrunkName": "CCAAS_Tata_HDB_462_8069808100",
        "PriId": 7,
        "PriName": "8069808100",
        "DID": "918069898033"
    }
]

export const dummyCampaignrowDatra = [
    {
        "SMSMessage": null,
        "Position": "RUNNING",
        "URLToPush": "",
        "campaignName": "charan2",
        "Method": null,
        "modifiedOn": "2022-10-04 15:47:46",
        "DNDCheck": false,
        "CallPrefix": "",
        "AllowForManualDialing": false,
        "campaignId": 83170,
        "OfflineMode": false,
        "CreatedDate": "2022-10-03 17:26:51",
        "did": "12346",
        "DialCustomerFirst": false,
        "campaignType": "Missed Call",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "General",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "RUNNING",
        "URLToPush": "",
        "campaignName": "CampaignTest",
        "Method": null,
        "modifiedOn": "2022-10-04 15:47:20",
        "DNDCheck": true,
        "CallPrefix": "",
        "AllowForManualDialing": true,
        "campaignId": 83006,
        "OfflineMode": false,
        "createdOn": "2022-07-25 12:36:48",
        "did": "914030015804",
        "DialCustomerFirst": false,
        "campaignType": "Push Data",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "General",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "RUNNING",
        "URLToPush": "",
        "campaignName": "InboundDilaing",
        "Method": null,
        "modifiedOn": "2022-10-03 18:08:42",
        "DNDCheck": false,
        "CallPrefix": "",
        "AllowForManualDialing": true,
        "campaignId": 82959,
        "OfflineMode": false,
        "createdOn": "2022-06-27 12:50:30",
        "did": "914030015803",
        "DialCustomerFirst": false,
        "campaignType": "Chat",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "Namaste",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "RUNNING",
        "URLToPush": "",
        "campaignName": "ManiTest",
        "Method": null,
        "modifiedOn": "2022-10-03 17:41:04",
        "DNDCheck": true,
        "CallPrefix": "",
        "AllowForManualDialing": true,
        "campaignId": 82960,
        "OfflineMode": false,
        "createdOn": "2022-06-27 12:54:32",
        "did": "914030015802",
        "DialCustomerFirst": false,
        "campaignType": "Chat",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "Namaste",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "RUNNING",
        "URLToPush": "",
        "campaignName": "testInbound",
        "Method": null,
        "modifiedOn": "2022-10-03 16:58:55",
        "DNDCheck": false,
        "CallPrefix": "",
        "AllowForManualDialing": true,
        "campaignId": 82956,
        "OfflineMode": true,
        "createdOn": "2022-06-24 18:34:43",
        "did": "914030015801",
        "DialCustomerFirst": false,
        "campaignType": "Chat",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "Namaste",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "READY",
        "URLToPush": "",
        "campaignName": "charan1",
        "Method": null,
        "modifiedOn": "2022-09-30 12:52:57",
        "DNDCheck": false,
        "CallPrefix": "",
        "AllowForManualDialing": false,
        "CampaignId": 83168,
        "OfflineMode": false,
        "createdOn": "2022-09-29 18:34:22",
        "did": "12345555",
        "DialCustomerFirst": false,
        "campaignType": "Push Data",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "Namaste",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "READY",
        "URLToPush": "",
        "CampaignName": "test",
        "Method": null,
        "ModifiedDate": "2022-09-27 11:31:17",
        "DNDCheck": true,
        "CallPrefix": "",
        "AllowForManualDialing": false,
        "CampaignId": 83147,
        "OfflineMode": false,
        "CreatedDate": "2022-09-07 13:59:51",
        "DID": "91403001580245",
        "DialCustomerFirst": false,
        "campaignType": "Push Data",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "PlayMsg",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    },
    {
        "SMSMessage": null,
        "Position": "READY",
        "URLToPush": "",
        "CampaignName": "hari_prog_non1",
        "Method": null,
        "ModifiedDate": "2022-08-11 18:40:17",
        "DNDCheck": true,
        "CallPrefix": "",
        "AllowForManualDialing": false,
        "CampaignId": 83107,
        "OfflineMode": false,
        "CreatedDate": "2022-08-11 18:40:17",
        "DID": "9140302470628",
        "DialCustomerFirst": false,
        "campaignType": "Missed Call",
        "campaignTypeSkill": ['hello', 'hello1', 'hello3'],
        "campaignSettingsInbound": [],
        "agentId": 'dashboard@ozonetel.com',
        "firstname": 'ozone',
        "lastname": 'tel',
        "email": 'dashboard@ozonetel.com',
        "StartTime": "14-01-2022",
        "IVRFlow": "PlayMsg",
        "ACWforUnansweredCall": true,
        "ApplyDNCRegulation": true,
        "ApplyDIDMasking": false,
        "AgenttoAgentCalling": false,
        "FallBackDID": 'FallBack DID',
    }
]

export const ADMIN_FORM_MODES = {
    add: 'Create',
    edit: 'Edit',
    view: 'View'
}


export const USER_ROLES = [

    {
        "id": -2,
        "name": "ROLE_USER",
        "description": "Default role for all Users",
        "authority": "ROLE_USER"
    },
    {
        "id": -1,
        "name": "ROLE_ADMIN",
        "description": "Default role for all Admins",
        "authority": "ROLE_ADMIN"
    },
    {
        "id": 1,
        "name": "ROLE_OUTBOUND",
        "description": "OutBound role",
        "authority": "ROLE_OUTBOUND"
    },
    {
        "id": 2,
        "name": "ROLE_TICKET",
        "description": "For customers who need\nticketing sys.",
        "authority": "ROLE_TICKET"
    },
    {
        "id": 3,
        "name": "ROLE_BARGEIN",
        "description": "Barge in future",
        "authority": "ROLE_BARGEIN"
    },
    {
        "id": 4,
        "name": "ROLE_HOLD",
        "description": "Role hold",
        "authority": "ROLE_HOLD"
    },
    {
        "id": 5,
        "name": "ROLE_CONFERENCE",
        "description": "Role for conference",
        "authority": "ROLE_CONFERENCE"
    },
    {
        "id": 6,
        "name": "ROLE_MUTE",
        "description": "Role for mute",
        "authority": "ROLE_MUTE"
    },
    {
        "id": 7,
        "name": "ROLE_DIRECTROUTING",
        "description": "Direct routing functionality",
        "authority": "ROLE_DIRECTROUTING"
    },
    {
        "id": 8,
        "name": "ROLE_CONSLTHOLDTRFR",
        "description": "Consultative Hold Transfer",
        "authority": "ROLE_CONSLTHOLDTRFR"
    },
    {
        "id": 9,
        "name": "ROLE_RATING",
        "description": "Rating for Reports",
        "authority": "ROLE_RATING"
    },
    {
        "id": 10,
        "name": "ROLE_ADMIN_ALERTS",
        "description": "Admin Desktop Alerts",
        "authority": "ROLE_ADMIN_ALERTS"
    },
    {
        "id": 11,
        "name": "ROLE_EMAIL_CAMPAIGN",
        "description": "Role for Email Campaign access",
        "authority": "ROLE_EMAIL_CAMPAIGN"
    },
    {
        "id": 12,
        "name": "ROLE_CALLBACKS",
        "description": "Call backs feature",
        "authority": "ROLE_CALLBACKS"
    },
    {
        "id": 13,
        "name": "ROLE_SUBADMIN",
        "description": "Role for Sub Admin",
        "authority": "ROLE_SUBADMIN"
    },
    {
        "id": 14,
        "name": "ROLE_REP_MISSEDCALL",
        "description": "Enable MissedCall Report",
        "authority": "ROLE_REP_MISSEDCALL"
    },
    {
        "id": 15,
        "name": "ROLE_SMS",
        "description": "Enables SMS feature in Agent toolbar",
        "authority": "ROLE_SMS"
    },
    {
        "id": 16,
        "name": "ROLE_EMAIL_ALERTS",
        "description": "Enables Email Alerts",
        "authority": "ROLE_EMAIL_ALERTS"
    },
    {
        "id": 17,
        "name": "ROLE_BLENDED",
        "description": "Enables agent to be in blended mode",
        "authority": "ROLE_BLENDED"
    },
    {
        "id": 18,
        "name": "ROLE_SKILLDROPACTION",
        "description": "Call Drop Action For Skill",
        "authority": "ROLE_SKILLDROPACTION"
    },
    {
        "id": 19,
        "name": "ROLE_AGENT_CALHIST",
        "description": "Call history of an agent.",
        "authority": "ROLE_AGENT_CALHIST"
    },
    {
        "id": 20,
        "name": "ROLE_HOME_DASHBOARD",
        "description": "Home Dashboard",
        "authority": "ROLE_HOME_DASHBOARD"
    },
    {
        "id": 21,
        "name": "ROLE_SIP",
        "description": "Enable SIP functionality",
        "authority": "ROLE_SIP"
    },
    {
        "id": 22,
        "name": "ROLE_CAMP_PRIORITY",
        "description": "For outbound campaign priorities",
        "authority": "ROLE_CAMP_PRIORITY"
    },
    {
        "id": 23,
        "name": "ROLE_DND_CHECK",
        "description": "DND check",
        "authority": "ROLE_DND_CHECK"
    },
    {
        "id": 24,
        "name": "ROLE_CHAT",
        "description": "Enables admin agent communication",
        "authority": "ROLE_CHAT"
    },
    {
        "id": 25,
        "name": "ROLE_RTC",
        "description": "Enable real time communication",
        "authority": "ROLE_RTC"
    },
    {
        "id": 26,
        "name": "ROLE_IVRDESIGNER_ALL",
        "description": "IVR Designer",
        "authority": "ROLE_IVRDESIGNER_ALL"
    },
    {
        "id": 27,
        "name": "ROLE_MARKETING",
        "description": "Marketing Role",
        "authority": "ROLE_MARKETING"
    },
    {
        "id": 28,
        "name": "ROLE_CALLPREFIX",
        "description": "Call Prefix",
        "authority": "ROLE_CALLPREFIX"
    },
    {
        "id": 29,
        "name": "ROLE_PREDICTIVE",
        "description": "For Predictive Dialer",
        "authority": "ROLE_PREDICTIVE"
    },
    {
        "id": 30,
        "name": "ROLE_HELP",
        "description": "Help",
        "authority": "ROLE_HELP"
    },
    {
        "id": 31,
        "name": "ROLE_CAMPAIGNSUB",
        "description": "Campaign based subadmin",
        "authority": "ROLE_CAMPAIGNSUB"
    },
    {
        "id": 32,
        "name": "ROLE_IVRTRANSFER",
        "description": "Enable ivr transfer for agent",
        "authority": "ROLE_IVRTRANSFER"
    },
    {
        "id": 33,
        "name": "ROLE_CBK_CAMPAIGN",
        "description": "Role for campaign based Call Back URL",
        "authority": "ROLE_CBK_CAMPAIGN"
    },
    {
        "id": 34,
        "name": "ROLE_SALESFORCE",
        "description": "Role for salesforce users",
        "authority": "ROLE_SALESFORCE"
    },
    {
        "id": 35,
        "name": "ROLE_FBCHAT",
        "description": "Role for Facebook chat integration",
        "authority": "ROLE_FBCHAT"
    },
    {
        "id": 36,
        "name": "ROLE_TWITTER",
        "description": "Role for Twitter Chat Integration",
        "authority": "ROLE_TWITTER"
    },
    {
        "id": 37,
        "name": "ROLE_AGENTBULKUPLOAD",
        "description": "Role for Agent Bulk Creation",
        "authority": "ROLE_AGENTBULKUPLOAD"
    },
    {
        "id": 38,
        "name": "ROLE_ACTIVITYSTREAM",
        "description": "Role for Activity Stream in Live Dashboard",
        "authority": "ROLE_ACTIVITYSTREAM"
    },
    {
        "id": 39,
        "name": "ROLE_RETRYRULES",
        "description": "Role for Retry Mechanism Rules",
        "authority": "ROLE_RETRYRULES"
    },
    {
        "id": 40,
        "name": "ROLE_SIP_LOCATION",
        "description": "Allow mutlple sip location",
        "authority": "ROLE_SIP_LOCATION"
    },
    {
        "id": 41,
        "name": "ROLE_SKILL_CHOLDTRFR",
        "description": "Role for Consultative Hold in Skill Transfer",
        "authority": "ROLE_SKILL_CHOLDTRFR"
    },
    {
        "id": 43,
        "name": "ROLE_NO_AUDIO_DOWNLOAD",
        "description": "No Audio Download",
        "authority": "ROLE_NO_AUDIO_DOWNLOAD"
    },
    {
        "id": 44,
        "name": "ROLE_SECURE_RECORDING",
        "description": "Role for private audio recording URL access",
        "authority": "ROLE_SECURE_RECORDING"
    },
    {
        "id": 45,
        "name": "ROLE_AMD",
        "description": "Role for Automatic Machine Detect",
        "authority": "ROLE_AMD"
    },
    {
        "id": 46,
        "name": "ROLE_CAMPAIGNHOLDMUSIC",
        "description": "Role To assing and create Hold Music For the Campaign",
        "authority": "ROLE_CAMPAIGNHOLDMUSIC"
    },
    {
        "id": 47,
        "name": "ROLE_SENTIMENT",
        "description": "Role for sentiment analysis users",
        "authority": "ROLE_SENTIMENT"
    },
    {
        "id": 48,
        "name": "ROLE_TRANSFER_RECORD",
        "description": "Role for Enable/Disable call recording on transfer call",
        "authority": "ROLE_TRANSFER_RECORD"
    },
    {
        "id": 49,
        "name": "ROLE_CALL_RECORDING",
        "description": "Role for Audio Record Enable or Disable",
        "authority": "ROLE_CALL_RECORDING"
    },
    {
        "id": 50,
        "name": "ROLE_CDR_CRT",
        "description": "Role for showing Customer Ring Time in CDR",
        "authority": "ROLE_CDR_CRT"
    },
    {
        "id": 51,
        "name": "ROLE_CDR_DUMP",
        "description": "Role for generating CDR Dump Report",
        "authority": "ROLE_CDR_DUMP"
    },
    {
        "id": 52,
        "name": "ROLE_DIDMasking",
        "description": "Role FOR DID Masking",
        "authority": "ROLE_DIDMasking"
    },
    {
        "id": 54,
        "name": "ROLE_SMS_CHAT",
        "description": "Role for Sms chat integration",
        "authority": "ROLE_SMS_CHAT"
    },
    {
        "id": 55,
        "name": "ROLE_INTERNAL",
        "description": "Role For all the Features given to our Internal Teams",
        "authority": "ROLE_INTERNAL"
    },
    {
        "id": 56,
        "name": "ROLE_WHATSAPP_CHAT",
        "description": "Role for Whatsapp chat integration",
        "authority": "ROLE_WHATSAPP_CHAT"
    },
    {
        "id": 58,
        "name": "ROLE_AGENT_ONCALL",
        "description": "Role for Agent to Receive Calls On Busy",
        "authority": "ROLE_AGENT_ONCALL"
    },
    {
        "id": 61,
        "name": "ROLE_BulkEditPhoneNumber",
        "description": "Role for Bulk Edit Phone Number",
        "authority": "ROLE_BulkEditPhoneNumber"
    },
    {
        "id": 62,
        "name": "ROLE_FallBackDID",
        "description": "Role for FallBack DID in Campaign Form",
        "authority": "ROLE_FallBackDID"
    },
    {
        "id": 65,
        "name": "ROLE_SCREEN_RECORDING",
        "description": "Role to enable screen recording option for Agent in toolbar ",
        "authority": "ROLE_SCREEN_RECORDING"
    },
    {
        "id": 66,
        "name": "ROLE_EMAIL",
        "description": "Role for Email Integration",
        "authority": "ROLE_EMAIL"
    },
    {
        "id": 69,
        "name": "ROLE_CUSTOMER_DISCLAIMER",
        "description": "Role for Customer Disclaimer",
        "authority": "ROLE_CUSTOMER_DISCLAIMER"
    },
    {
        "id": 70,
        "name": "ROLE_SKILL_PRIORITY",
        "description": "Role for skill priority to show",
        "authority": "ROLE_SKILL_PRIORITY"
    },
    {
        "id": 71,
        "name": "ROLE_DNC_REGULATION",
        "description": "Role for DNC Regulation",
        "authority": "ROLE_DNC_REGULATION"
    }
]

export const DataManagementTabsList = [
    // 'Files',
    // 'Segments',
    // 'Delete',
    'Assigning',
    // 'Send to Campaign',
];