import { useEffect, useMemo, useRef, useState } from 'react'
import { Autocomplete, Box, Button, Chip, CircularProgress, createFilterOptions, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ReactComponent as DropdownIcon } from '../../../../static/icons/dropdown.svg';
import { ReactComponent as AddIcon } from '../../../../static/icons/addsquare.svg';
import { ReactComponent as DeleteIcon } from '../../../../static/icons/deletenew.svg';
import { ReactComponent as HamburgerIcon } from '../../../../static/icons/hamburger.svg';
import { ReactComponent as RemoveIcon } from '../../../../static/icons/remove.svg';
import { ReactComponent as AddRoundIcon } from '../../../../static/icons/addround.svg';
import { ReactComponent as EditIcon } from '../../../../static/icons/editpen.svg';
import { ReactComponent as MinimiseIcon } from '../../../../static/icons/minimise.svg';
import { showErrorNotification, showSuccessNotification } from '../../../../components/admin/common/NotiService';
import './assignPriorities.css';
import '../SendToCampaign/sendToCampaign.css';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { deleteCampaignRule, getCampaignOutboundListForAdmin, getCampaignRule, getDispositionsDropdown, updateCampaignRule } from '../../../../services/api-service';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from 'antd';
import SimpleSwitch from '../../../../components/admin/common/OZSwitch/SimpleSwitch';

const FIELD_TYPES = {
    STRING_OPERATORS: ['EQUALS', 'NOT_EQUALS', 'CONTAINS', 'STARTS_WITH', 'ENDS_WITH', 'MATCHES'],
    NUMERIC_OPERATORS: ['GREATER_THAN', 'LESS_THAN', 'GREATER_THAN_EQUALS', 'LESS_THAN_EQUALS', 'BETWEEN'],
    ARRAY_OPERATORS: ['IN', 'NOT_IN', 'CONTAINS_ANY', 'CONTAINS_ALL'],
    TIME_WINDOWS: ['LAST_HOUR', 'LAST_24H', 'LAST_WEEK', 'LAST_MONTH'],
    BOOLEAN_OPERATORS: ['EQUALS', 'NOT_EQUALS']
};

const SET_ACTION_TYPES = {
    SET: 'SET',
    UPDATE_STATUS: 'UPDATE_STATUS',
    SCHEDULE: 'SCHEDULE'
};

const FIELD_TYPE_MAPPING = {
    dialCount: 'numeric',
    priority: 'numeric',
    currentTrail: 'numeric',
    dateCreated: 'date',
    dateUpdated: 'date',
    lastSelected: 'date',
    isDone: 'boolean',
    isDelete: 'boolean',
    disposition: 'array',
    dialStatus: 'string',
    callStatus: 'string'
};

const FIELDS = {
    dialCount: 'Dial Count',
    priority: 'Priority',
    currentTrail: 'Current Trail',
    dateCreated: 'Date Created',
    dateUpdated: 'Date Updated',
    lastSelected: 'Last Selected',
    isDone: 'Is Done',
    isDelete: 'Is Delete',
    disposition: 'Disposition',
    dialStatus: 'Dial Status',
    callStatus: 'Call Status'
}

const SCHEDULE_TIMES = {
    'MORNING': 'MORNING',
    'AFTERNOON': 'AFTERNOON',
    'EVENING': 'EVENING',
    'MIDNIGHT': 'MIDNIGHT',
    'Custom DateTime': 'Custom DateTime'
}

const ACTION_TYPES_MAPPING = {
    SET: ['priority', 'isDone', 'isDelete'],
    UPDATE_STATUS: [],
    SCHEDULE: Object.keys(SCHEDULE_TIMES)
};

const dateUtils = {
    convertFromUTC: (utcString) => {
        if (!utcString) return null;
        const date = new Date(utcString);
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        );
    },
    convertToUTC: (localDate) => {
        if (!localDate) return null;
        const date = new Date(localDate);
        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        ));
        const year = utcDate.getUTCFullYear();
        const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(utcDate.getUTCDate()).padStart(2, '0');
        const hours = String(utcDate.getUTCHours()).padStart(2, '0');
        const minutes = String(utcDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(utcDate.getUTCSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
};

const convertFromUTC = (utcString) => {
    if (!utcString) return null;
    const date = new Date(utcString);
    return date;
};


export default function AssignPriorities({ activeTab }) {
    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [dispositionsList, setDispositionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);

    const autoCompleteListRef = useRef(null);
    const defaultRule = {
        name: 'Rule 1',
        priority: '',
        operator: 'AND',
        conditionOperator: ['AND'],
        conditions: [
            [{
                field: '',
                operator: '',
                value: '',
                timeWindow: null
            }]
        ],
        actions: [{
            type: '',
            field: '',
            value: ''
        }],
        mode: "edit"
    };

    const [rules, setRules] = useState([defaultRule]);

    const handleScroll = () => {
        const l = limit;
        setLimit(l + 5);
    }
    const handleClose = () => {
        setLimit(10);
    }

    async function getCampaigns() {
        setLoading(true);
        try {
            const resp = await getCampaignOutboundListForAdmin()
            if (resp.Status === "Success") {
                const reducedData = resp.Data.map(campaign => {
                    return {
                        name: campaign.campaignName,
                        id: campaign.campaignId
                    }
                });
                setCampaigns(reducedData);
                const dispResp = await getDispositionsDropdown()
                if (dispResp.Status === "Success") {
                    setDispositionsList(dispResp.Data.map(disp => disp.reason));
                }
            }
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (activeTab !== "Assigning") return;
        getCampaigns();
    }, [activeTab]);


    async function getRules(selectedCampaign) {
        setLoading(true);
        try {
            const respData = await getCampaignRule(selectedCampaign);
            if (respData.status === 'success') {
                const response = respData?.payload?.rules ?? [];
                let rules = response?.length && response.length > 0 ? [] : [defaultRule];
                response.forEach(rule => {
                    const actions = rule.actions.map(action => {
                        if (action.type === 'SCHEDULE') {
                            if (action.value.scheduleTime !== 'MORNING' && action.value.scheduleTime !== 'AFTERNOON' && action.value.scheduleTime !== 'EVENING' && action.value.scheduleTime !== 'MIDNIGHT') {
                                return {
                                    type: action.type,
                                    field: 'Custom DateTime',
                                    value: new Date(action.value.scheduleTime),
                                    actionName: action.value.actionName
                                }
                            }
                            return {
                                type: action.type,
                                field: action.value.scheduleTime,
                                actionName: action.value.actionName
                            }
                        }
                        if (action.type === 'SET') {
                            return {
                                type: action.type,
                                field: action.field,
                                value: action.value.value
                            }
                        }
                        return {
                            type: action.type,
                            field: action.field,
                            value: action.value
                        }
                    });
                    const conditions = [];
                    let globalOperator = Object.keys(rule.conditions)[0];
                    console.log('globalOperator', globalOperator);
                    let conditionOperator = [];
                    rule.conditions[globalOperator].forEach((condition, index) => {
                        const op = Object.keys(condition)
                        if (op[0] === 'all' || op[0] === 'any') {
                            conditionOperator.push(op[0]);
                        } else {
                        }
                    });
                    if (conditionOperator.length === 0) {
                        conditionOperator = [globalOperator];
                        conditions.push([])
                        rule.conditions[globalOperator].forEach((condition, index) => {
                            let value = condition.value;
                            if (FIELD_TYPE_MAPPING[condition.field] === 'date') {
                                value = convertFromUTC(value);
                            }
                            if (condition.field === 'disposition' && condition.inType === 'INPUT') {
                                conditions[0].push({
                                    field: condition.field,
                                    operator: condition.operator,
                                    value: [],
                                    enableInputDisposition: true,
                                    inputValue: value.join(',')
                                })
                            } else {
                                conditions[0].push({
                                    field: condition.field,
                                    operator: condition.operator,
                                    value: value
                                })
                            }
                        });
                        globalOperator = globalOperator === 'all' ? 'AND' : 'OR';
                        conditionOperator = [globalOperator];
                    } else {
                        rule.conditions[globalOperator].forEach((condition, index) => {
                            conditions.push([]);
                            condition[conditionOperator[index]].forEach(cond => {
                                let value = cond.value;
                                if (FIELD_TYPE_MAPPING[cond.field] === 'date') {
                                    value = convertFromUTC(value);
                                }
                                if (cond.field === 'disposition' && cond.inType === 'INPUT') {
                                    conditions[index].push({
                                        field: cond.field,
                                        operator: cond.operator,
                                        value: [],
                                        enableInputDisposition: true,
                                        inputValue: value.join(',')
                                    })
                                } else {
                                    conditions[index].push({
                                        field: cond.field,
                                        operator: cond.operator,
                                        value: value
                                    });
                                }
                            });
                        });
                        globalOperator = globalOperator === 'all' ? 'AND' : 'OR';
                        conditionOperator = conditionOperator.map(op => op === 'all' ? 'AND' : 'OR');
                    }
                    rules.push({
                        name: rule.name,
                        priority: rule.priority,
                        operator: globalOperator,
                        conditions: conditions,
                        conditionOperator: conditionOperator,
                        actions: actions,
                        mode: 'edit'
                    });
                });
                console.log('rules converted', rules);
                setRules(rules);
            } else {
                showErrorNotification('Failed to fetch rules');
                setRules([defaultRule]);
            }
            setLoading(false);
        } catch (error) {
            showErrorNotification('Failed to fetch rules');
            console.log('error', error);
            setLoading(false);
        }
    }

    async function saveRules() {
        setLoading(true);
        let error = '';
        if (!selectedCampaign) {
            error = 'Please select a campaign';
        }
        let ruleNames = [];
        let rulePriorities = [];
        for (let rule of rules) {
            if (ruleNames.includes(rule.name)) {
                error = 'Rule name cannot be duplicate';
                break;
            }
            ruleNames.push(rule.name);
            if (!rule.priority || rule.priority === '') {
                error = `Priority cannot be empty in rule '${rule.name}'`;
                break;
            }
            if (rulePriorities.includes(rule.priority)) {
                const duplicateRule = rules.find(r => r.priority === rule.priority);
                error = `Priority in rule '${rule.name}' is duplicate with rule '${duplicateRule.name}'`;
                break;
            }
            rulePriorities.push(rule.priority);
            for (let condition of rule.conditions) {
                if (!condition.length) {
                    error = `Condition cannot be empty in rule '${rule.name}'`;
                    break;
                }
                for (let cond of condition) {
                    if (!cond.field || cond.field === '') {
                        error = `Condition Field cannot be empty in rule '${rule.name}'`;
                        break;
                    }
                    if (!cond.operator || cond.operator === '') {
                        error = `Condition Operator cannot be empty for field '${FIELDS[cond.field]}' in rule '${rule.name}'`;
                        break;
                    }
                    const type = FIELD_TYPE_MAPPING[cond.field] || 'string';
                    if (type === 'array' && !Array.isArray(cond.value)) {
                        error = `Condition Value should be an array for field '${FIELDS[cond.field]}' in rule '${rule.name}'`;
                        break;
                    }
                    if (type === 'array') {
                        if (Array.isArray(cond.value) && !cond.value.length && !cond?.enableInputDisposition) {
                            error = `Condition Value cannot be empty for field '${FIELDS[cond.field]}' in rule '${rule.name}'`;
                            break;
                        }
                        if (cond.field === 'disposition' && cond?.enableInputDisposition && cond.inputValue.trim().length === 0) {
                            error = `Condition Value cannot be empty for field '${FIELDS[cond.field]}' in rule '${rule.name}'`;
                            break;
                        }
                    }
                    if (!cond.value || cond.value === '') {
                        error = `Condition Value cannot be empty for field '${FIELDS[cond.field]}' and operator '${cond.operator}' in rule '${rule.name}'`;
                        break;
                    }
                }
                if (error) break;
            }
            if (error) break;
            for (let action of rule.actions) {
                if (action.type === 'SCHEDULE') {
                    if (!action.field || action.field === '') {
                        error = `Action Field cannot be empty in rule '${rule.name}' for action type '${action.type}'`;
                        break;
                    }
                    if (action.field === 'Custom DateTime' && !action.value) {
                        error = `Custom DateTime cannot be empty in rule '${rule.name}' for action type '${action.type}'`;
                        break;
                    }
                    if (!action.actionName || action.actionName === '') {
                        error = `Action Name cannot be empty in rule '${rule.name}' for action type '${action.type}' and field '${FIELDS[action.field] || action.field}'`;
                        break;
                    }
                } else {
                    if (!action.type || action.type === '') {
                        error = `Action type cannot be empty in rule '${rule.name}'`;
                        break;
                    }
                    if (action.type !== "UPDATE_STATUS" && (!action.field || action.field === '')) {
                        error = `Action Field cannot be empty in rule '${rule.name}' for action type '${action.type}'`;
                        break;
                    }
                    if (!action.value || action.value === '') {
                        error = `Action Value cannot be empty in rule '${rule.name}' for action type '${action.type}' and field '${FIELDS[action.field] || action.field}'`;
                        break;
                    }
                }
            }
            if (error) break;
        }
        if (error) {
            showErrorNotification(error);
            setLoading(false);
            return;
        }
        try {
            const rulesToSave = rules.map(rule => {
                const actions = rule.actions.map(action => {
                    if (action.type === 'SCHEDULE') {
                        if (action.field === 'Custom DateTime') {
                            return {
                                type: action.type,
                                value: {
                                    scheduleTime: dateUtils.convertToUTC(action.value),
                                    actionName: action.actionName
                                }
                            }
                        }
                        return {
                            type: action.type,
                            value: {
                                scheduleTime: action.field,
                                actionName: action.actionName
                            }
                        }
                    }
                    if (action.type === 'SET') {
                        return {
                            type: action.type,
                            field: action.field,
                            value: {
                                updatePreviewData: true,
                                value: action.value
                            }
                        }
                    }
                    return {
                        type: action.type,
                        field: action.field,
                        value: action.value
                    }
                });
                let conditions = {};
                let { operator, conditionOperator } = rule;
                if (conditionOperator.length === 1) {
                    const op = conditionOperator[0] === 'AND' ? 'all' : 'any';
                    conditions = {
                        [op]: rule.conditions.map((condition, index) => {
                            return condition.map(cond => {
                                let value = cond.value;
                                let newCondition = {
                                    field: cond.field,
                                    operator: cond.operator,
                                }
                                if (FIELD_TYPE_MAPPING[cond.field] === 'date') {
                                    value = dateUtils.convertToUTC(value);
                                } else if (cond.field === 'disposition') {
                                    if (cond?.enableInputDisposition) {
                                        value = cond.inputValue ? cond.inputValue.split(',').map(val => val.trim()) : [];
                                        newCondition.inType = 'INPUT';
                                    }
                                }
                                newCondition.value = value;
                                return newCondition;
                            })
                        }).flat(1)
                    }
                } else {
                    const globalOperator = operator === 'AND' ? 'all' : 'any';
                    conditions = {
                        [globalOperator]: rule.conditions.map((condition, index) => {
                            const op = conditionOperator[index] === 'AND' ? 'all' : 'any';
                            return {
                                [op]: condition.map(cond => {
                                    let value = cond.value;
                                    let newCondition = {
                                        field: cond.field,
                                        operator: cond.operator,
                                    }
                                    if (FIELD_TYPE_MAPPING[cond.field] === 'date') {
                                        value = dateUtils.convertToUTC(value);
                                    } else if (cond.field === 'disposition') {
                                        if (cond?.enableInputDisposition) {
                                            value = cond.inputValue ? cond.inputValue.split(',').map(val => val.trim()) : [];
                                            newCondition.inType = 'INPUT';
                                        }
                                    }
                                    newCondition.value = value;
                                    return newCondition;
                                })
                            }
                        })
                    }
                }
                return {
                    name: rule.name,
                    priority: rule.priority,
                    conditions: conditions,
                    actions: actions
                }
            });
            console.log('rules to save', structuredClone(rulesToSave))
            const respData = await updateCampaignRule({ rules: rulesToSave }, selectedCampaign);
            if (respData.status === 'success') {
                showSuccessNotification('Rules updated successfully');
                getRules(selectedCampaign);
            } else {
                showErrorNotification('Failed to save rules');
            }
        } catch (error) {
            showErrorNotification('Failed to save rules');
        } finally {
            setLoading(false);
        }
    }

    async function deleteRule() {
        setLoading(true);
        try {
            const respData = await deleteCampaignRule(selectedCampaign);
            if (respData.status === "success") {
                showSuccessNotification(`Rules removed successfully for campaign ${selectedCampaign.name}`);
                setRules([defaultRule]);
            } else {
                showErrorNotification('Failed to remove rules');
            }
        } catch (error) {
            showErrorNotification('Failed to save rules');
        } finally {
            setLoading(false);
        }
    }

    console.log('rules log', rules);

    return (activeTab === "Assigning" ?
        <Box className='oz-data-management-send-to-campaign-container'>
            <Box className="oz-data-management-send-to-campaign-header">
                <Typography className="oz-data-management-title">
                    Assign Priorities
                </Typography>
            </Box>
            <Box className="oz-data-management-send-to-campaign-content">
                <Box className='oz-data-management-send-to-campaign-autocomplete-container'>
                    <Typography className='oz-data-management-send-to-campaign-autocomplete-label'>
                        Choose Campaign
                    </Typography>
                    <Autocomplete
                        id="oz-data-management-send-to-campaign-autocomplete"
                        loading={loading}
                        options={campaigns ? campaigns.length ? campaigns : [] : []}
                        value={selectedCampaign}
                        onChange={(event, newValue) => {
                            setSelectedCampaign(newValue);
                            if (newValue && newValue.id) {
                                getRules(newValue);
                            }
                        }}
                        noOptionsText="No Campaigns"
                        onClose={handleClose}
                        getOptionLabel={(option) => option.name || ''}
                        componentsProps={{
                            paper: {
                                sx: {
                                    border: "1px solid #D6D6D7", borderRadius: "8px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Campaign"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                    },
                                }}
                            />
                        )}
                        role={"listbox"}
                        ListboxProps={{
                            ref: autoCompleteListRef,
                            onScroll: handleScroll,
                            sx: {
                                maxHeight: "280px",
                            }
                        }}
                        filterOptions={(options, params) => {
                            let filtered = createFilterOptions({
                                matchFrom: 'any',
                                limit: limit,
                            })(options, params);
                            return filtered;
                        }}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option.name || ''}
                                </Typography>
                            </li>
                        }}
                        autoComplete={true}
                        limitTags={3}
                    />
                </Box>
                {loading ? <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px',
                    width: '100%',
                }}>
                    <CircularProgress disableShrink />
                </Box> : (selectedCampaign && rules && rules.length) ? <RulesContent rules={rules} setRules={setRules} defaultRule={defaultRule} dispositionsList={dispositionsList} /> : null}
            </Box>
            <Box className="oz-data-management-send-to-campaign-footer">
                <Button
                    onClick={() => getRules(selectedCampaign)}
                    className='oz-data-management-send-to-campaign-cancel-button'
                    variant="outlined">Cancel</Button>
                <LoadingButton
                    onClick={deleteRule}
                    disabled={!selectedCampaign}
                    loading={loading}
                    className='oz-data-management-send-to-campaign-remove-button'
                    variant="contained">Remove Rules</LoadingButton>
                <LoadingButton
                    onClick={saveRules}
                    disabled={!selectedCampaign}
                    loading={loading}
                    className='oz-data-management-send-to-campaign-update-button'
                    variant="contained" >Update </LoadingButton>
            </Box>
        </Box> : null
    )
}

const RulesContent = ({
    rules,
    setRules,
    defaultRule,
    dispositionsList
} = {}) => {

    const [editingNameIndex, setEditingNameIndex] = useState(null);
    const getFieldOperators = (fieldName) => {
        const fieldType = FIELD_TYPE_MAPPING[fieldName] || 'string';
        switch (fieldType) {
            case 'date':
            case 'numeric': return FIELD_TYPES.NUMERIC_OPERATORS;
            case 'array': return FIELD_TYPES.ARRAY_OPERATORS;
            case 'boolean': return FIELD_TYPES.BOOLEAN_OPERATORS;
            default: return FIELD_TYPES.STRING_OPERATORS;
        }
    };

    const handleClickAddRules = () => {
        setRules(prevRules => {
            return [...prevRules, { ...defaultRule, mode: 'edit', name: `Rule ${prevRules.length + 1}` }];
        });
    }

    return (
        <Box className="oz-data-management-send-to-campaign-content-container">
            <Box className="oz-data-management-send-to-campaign-content-header">
                <Typography className="oz-data-management-add-rules-title">
                    Add Rules
                </Typography>
                <Tooltip title="Add Rule" placement="top">
                    <IconButton onClick={handleClickAddRules}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {rules.length > 0 && rules.map((rule, index) => {
                return (
                    <Box key={index} className="oz-data-management-send-to-campaign-rule-container">
                        <Box className="oz-data-management-send-to-campaign-rule-header-container">
                            <Box className="oz-data-management-send-to-campaign-rule-header">
                                <HamburgerIcon />
                                {editingNameIndex === index ? (
                                    <TextField
                                        autoFocus
                                        value={rule.name}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                showErrorNotification('Rule name cannot be empty');
                                                return;
                                            }
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules[index].name = e.target.value;
                                                return newRules;
                                            });
                                        }}
                                        onBlur={() => setEditingNameIndex(null)}
                                        sx={{
                                            width: "300px",
                                            maxWidth: "300px",
                                            height: "36px",
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: '14px',
                                                height: "36px",
                                                '& fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                },
                                                '&:hover fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                },
                                            },
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        className="oz-data-management-send-to-campaign-rule-title"
                                        flexGrow={1}
                                        onClick={() => setEditingNameIndex(index)}
                                        minWidth={'300px'}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {rule.name}
                                    </Typography>
                                )}
                            </Box>
                            <Autocomplete
                                id="oz-data-management-send-to-campaign-rule-conditions-operator"
                                className='oz-data-management-send-to-campaign-rule-conditions-operator'
                                options={['AND', 'OR']}
                                value={rule.operator}
                                onChange={(event, newValue) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].operator = newValue;
                                        return newRules;
                                    });
                                }}
                                clearIcon={null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Conditions Operator"
                                        sx={{
                                            width: "94px",
                                            marginLeft: "auto",
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: '12px',
                                                '& fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                },
                                                '&:hover fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderBottomRightRadius: "0px"
                                                },
                                            },
                                        }}
                                    />
                                )}
                                popupIcon={<DropdownIcon />}
                                size='small'
                                renderOption={(props, option, { selected }) => {
                                    return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                        <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                            {option}
                                        </Typography>
                                    </li>
                                }
                                } />
                        </Box>
                        {rule.mode === 'edit' ?
                            <Box className="oz-data-management-send-to-campaign-rule-content-container">
                                <Box className="oz-data-management-send-to-campaign-rule-content">
                                    <ConditionsContent getFieldOperators={getFieldOperators}
                                        parentConditions={rule.conditions}
                                        index={index}
                                        rule={rule}
                                        setRules={setRules}
                                        dispositionsList={dispositionsList}
                                        conditionOperator={rule.conditionOperator}
                                    />
                                    <ActionContent
                                        rule={rule}
                                        setRules={setRules}
                                        actions={rule.actions}
                                        setActions={(newActions) => {
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules[index].actions = newActions;
                                                return newRules;
                                            });
                                        }}
                                        index={index}
                                        dispositionsList={dispositionsList}
                                    />
                                    <Box className='oz-data-management-send-to-campaign-rule-priority-container'>
                                        <Typography className='oz-data-management-send-to-campaign-rule-priority-title-2'>
                                            Priority -
                                        </Typography>
                                        <TextField
                                            className='oz-data-management-send-to-campaign-rule-priority'
                                            placeholder="Enter Value"
                                            size='small'
                                            type='number'
                                            sx={{
                                                minWidth: "200px",
                                                borderRadius: "8px",
                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: "14px",
                                                    background: "#FFFFFF",
                                                    '& fieldset': {
                                                        border: "1px solid #D6D6D7",
                                                        borderRadius: "8px",
                                                    },
                                                    '&:hover fieldset': {
                                                        border: "1px solid #D6D6D7",
                                                        borderRadius: "8px",
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        border: "1px solid #D6D6D7",
                                                        borderRadius: "8px",

                                                    },
                                                },
                                            }}
                                            value={rule.priority}
                                            inputProps={{ min: 1, max: 999 }}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (value && value > 999) {
                                                    showErrorNotification('Priority cannot be greater than 1000');
                                                    return;
                                                }
                                                if (value && value < 1) {
                                                    showErrorNotification('Priority cannot be less than 1');
                                                    return;
                                                }
                                                setRules(prevRules => {
                                                    const newRules = [...prevRules];
                                                    newRules[index].priority = value;
                                                    return newRules;
                                                });
                                            }}
                                        />
                                        {/* <IconButton className='oz-data-management-send-to-campaign-rule-priority-remove-button'>
                                            <RemoveIcon />
                                        </IconButton> */}
                                    </Box>
                                </Box>
                            </Box>
                            : rule.mode === "view" ? <Box className='oz-data-management-send-to-campaign-rule-view-content'>
                                <Box className='oz-data-management-send-to-campaign-rule-view-condition-list'>
                                    {rule.conditions.map((conditions, conditionsIndex) => {
                                        return <>
                                            {conditionsIndex > 0 && <>
                                                <Typography color={'#909DAD'} fontSize={'12px'} fontWeight={600} >
                                                    {rule.operator}
                                                </Typography>
                                            </>
                                            }
                                            {conditions.map((condition, conditionIndex) => {
                                                return <Box className='oz-data-management-send-to-campaign-rule-view-condition'>
                                                    <Typography className='oz-data-management-send-to-campaign-rule-priority-title' mx={1}>
                                                        If:
                                                    </Typography>
                                                    <Box sx={{ width: '140px', px: 1 }}>
                                                        <Chip
                                                            label={FIELDS[condition.field]}
                                                            size='small' className='oz-data-management-send-to-campaign-rule-view-condition-chip' />
                                                    </Box>
                                                    <Divider orientation="vertical" sx={{ height: '32px' }} />
                                                    <Typography sx={{ width: 'fit-content', color: '#546881', fontStyle: 'italic', fontSize: '14px', fontWeight: 500, px: 2 }}>
                                                        {condition.operator}
                                                    </Typography>
                                                    <Divider orientation="vertical" sx={{ height: '32px' }} />
                                                    <Box sx={{ width: '140px', px: 1 }}>
                                                        {typeof condition.value === 'object' ?
                                                            <>
                                                                {condition.value.slice(0, 2).map((value, valueIndex) => (
                                                                    <Chip
                                                                        key={valueIndex}
                                                                        label={value}
                                                                        size='small'
                                                                        className='oz-data-management-send-to-campaign-rule-view-condition-chip'
                                                                    />
                                                                ))}
                                                                {condition.value.length > 2 && (
                                                                    <Tooltip title={condition.value.slice(2).join(', ')}>
                                                                        <Chip
                                                                            label={`+${condition.value.length - 2}`}
                                                                            size='small'
                                                                            className='oz-data-management-send-to-campaign-rule-view-condition-chip'
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                            :
                                                            <Chip label={condition.value} size='small' className='oz-data-management-send-to-campaign-rule-view-condition-chip' />}
                                                    </Box>
                                                    {conditionIndex < rule.conditions.length - 1 && <>
                                                        <Divider orientation="vertical" sx={{ height: '32px' }} />
                                                        <Typography color={"#1D242D"} fontSize={"14px"} fontWeight={500} fontStyle={"italic"} px={2}>
                                                            {rule.conditionOperator[conditionsIndex]}
                                                        </Typography>
                                                        <Divider orientation="vertical" sx={{ height: '32px', px: 2 }} />
                                                    </>}
                                                </Box>
                                            })}
                                        </>
                                    })}
                                    {rule && rule.actions && rule.actions.length && rule.actions.map((rule, ruleIndex) => {
                                        return rule && <Box className='oz-data-management-send-to-campaign-rule-view-condition'>
                                            <Typography className='oz-data-management-send-to-campaign-rule-priority-title' mx={1}>
                                                Then:
                                            </Typography>
                                            <Typography sx={{ width: '138px', color: '#546881', fontStyle: 'italic', fontSize: '14px', fontWeight: 500, px: 2 }}>
                                                {rule.type}
                                            </Typography>
                                            <Divider orientation="vertical" sx={{ height: '32px' }} />
                                            {rule.field && <Box sx={{ width: '140px', px: 1 }}>
                                                <Chip label={rule.type === "SCHEDULE" ? rule.field : FIELDS[rule.field]} size='small' className='oz-data-management-send-to-campaign-rule-view-condition-chip' />
                                            </Box>}
                                            <Divider orientation="vertical" sx={{ height: '32px' }} />
                                            {rule && <Box sx={{ width: '140px', px: 1 }}>
                                                <Chip
                                                    label={rule.field === 'Custom DateTime' ? new Date(rule.value).toLocaleString() : rule.value || rule.actionName}
                                                    size='small' className='oz-data-management-send-to-campaign-rule-view-condition-chip' />
                                            </Box>}
                                            {rule && rule.actions && rule.actions.length && ruleIndex < rule.actions.length - 1 && <>
                                                <Divider orientation="vertical" sx={{ height: '32px' }} />
                                                <Typography color={"#1D242D"} fontSize={"14px"} fontWeight={500} fontStyle={"italic"} px={2}>
                                                    {rule.operator}
                                                </Typography>
                                                <Divider orientation="vertical" sx={{ height: '32px', px: 2 }} />
                                            </>}
                                        </Box>
                                    })}
                                    <Box className='oz-data-management-send-to-campaign-rule-view-priority'>
                                        <span style={{
                                            color: '#546881', fontSize: '14px', fontWeight: 400, overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                        }}>Priority - {rule.priority} </span>
                                    </Box>
                                </Box>
                                <Tooltip title="Edit Rule" placement="top">
                                    <IconButton onClick={() => {
                                        setRules(prevRules => {
                                            const newRules = [...prevRules];
                                            newRules[index].mode = 'edit';
                                            return newRules;
                                        });
                                    }}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Rule" placement="top">
                                    <IconButton
                                        onClick={() => {
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules.splice(index, 1);
                                                return newRules;
                                            });
                                        }}
                                        disabled={rules.length <= 1}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box> : null
                        }
                        {rule.mode === 'edit' && <Box className='oz-data-management-send-to-campaign-rule-footer'>
                            <Tooltip title="Delete Rule" placement="top">
                                <IconButton onClick={() => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules.splice(index, 1);
                                        if (newRules.length === 0) {
                                            newRules.push(defaultRule);
                                            showErrorNotification('At least one rule is required');
                                        }
                                        return newRules;
                                    });
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Minimize Rule" placement="top">
                                <IconButton
                                    sx={{

                                    }}
                                    onClick={() => {
                                        setRules(prevRules => {
                                            const newRules = [...prevRules];
                                            newRules[index].mode = 'view';
                                            return newRules;
                                        });
                                    }}>
                                    <MinimiseIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>}
                    </Box >
                )
            })}
        </Box>
    )
}

const ConditionsContent = ({ parentConditions, index, rule, setRules, getFieldOperators, dispositionsList, conditionOperator = [] }) => {

    return <Box className="oz-data-management-send-to-campaign-rule-condition-container">
        <Box className="oz-data-management-send-to-campaign-rule-condition-content">
            {parentConditions.map((conditions, conditionsIndex) => {
                return conditions.map((condition, conditionIndex) => <ConditionItem
                    key={conditionIndex}
                    condition={condition}
                    conditionIndex={conditionIndex}
                    conditionsIndex={conditionsIndex}
                    conditions={conditions}
                    index={index} rule={rule}
                    setRules={setRules}
                    getFieldOperators={getFieldOperators}
                    dispositionsList={dispositionsList}
                    conditionOperator={conditionOperator} />
                )
            })}
        </Box>
    </Box>
}

const ConditionItem = ({ condition, conditionsIndex, conditionIndex, conditions, index, rule, setRules, getFieldOperators, dispositionsList, conditionOperator }) => {

    const isDisposition = condition.field === 'disposition';

    return <>
        {conditionsIndex > 0 && conditionIndex === 0 && <Typography className="oz-data-management-send-to-campaign-rule-priority-title" sx={{
            my: 1
        }}>
            {rule.operator}
        </Typography>}
        <Box className="oz-data-management-send-to-campaign-rule-condition-item">
            {conditionIndex === 0 && <Typography className="oz-data-management-send-to-campaign-rule-priority-title" mr={4}>
                If:
            </Typography>}
            <Box className={"oz-data-management-send-to-campaign-rule-condition-item"}>
                {conditionIndex !== 0 && <Box sx={{
                    ml: (4 * conditionIndex) + 2.5,
                    position: 'relative',
                    mb: 1
                }}>
                    <Box sx={{
                        borderLeft: "1px solid #909DAD",
                        height: '8px',
                        width: '0px',
                    }} />
                    <Typography className="oz-data-management-send-to-campaign-rule-priority-title">
                        {rule.conditionOperator[conditionsIndex]}
                    </Typography>
                    <Box sx={{
                        borderLeft: "1px solid #909DAD",
                        height: '8px',
                        width: '1px',
                    }} />
                    <Box sx={{
                        borderBottom: "1px solid #909DAD",
                        height: '0px',
                        width: '76px',
                    }} />
                </Box>}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: conditionIndex === 0 ? 0 : 3,
                    ml: conditionIndex === 0 ? 0 : 1,
                    width: '100%',
                }}>
                    <Autocomplete
                        id="oz-data-management-send-to-campaign-rule-condition-field"
                        options={Object.keys(FIELDS)}
                        value={condition.field}
                        getOptionLabel={(option) => option ? FIELDS[option] : ""}
                        onChange={(event, newValue) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].conditions[conditionsIndex][conditionIndex].field = newValue;
                                newRules[index].conditions[conditionsIndex][conditionIndex].value = FIELD_TYPE_MAPPING[newValue] === 'array' ? [] : '';
                                newRules[index].conditions[conditionsIndex][conditionIndex].operator = '';
                                return newRules;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Field"
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"

                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option ? FIELDS[option] : ""}
                                </Typography>
                            </li>
                        }} />
                    <Autocomplete
                        id="oz-data-management-send-to-campaign-rule-conditions-operator"
                        options={getFieldOperators(condition.field)}
                        value={condition.operator}
                        onChange={(event, newValue) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].conditions[conditionsIndex][conditionIndex].operator = newValue;
                                if (newValue === 'BETWEEN') {
                                    newRules[index].conditions[conditionsIndex][conditionIndex].value = ['', ''];
                                }
                                return newRules;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Operator"
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: condition.field ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: condition.field ? "0px" : "8px",
                                            borderBottomRightRadius: condition.field ? "0px" : "8px",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: condition.field ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: condition.field ? "0px" : "8px",
                                            borderBottomRightRadius: condition.field ? "0px" : "8px",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: condition.field ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: condition.field ? "0px" : "8px",
                                            borderBottomRightRadius: condition.field ? "0px" : "8px",
                                            borderBottomLeftRadius: "0px",
                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option}
                                </Typography>
                            </li>
                        }}
                    />
                    {condition?.field && FIELD_TYPE_MAPPING[condition.field] === 'array' ?
                        (isDisposition && condition?.enableInputDisposition ? <TextField
                            id="oz-data-management-send-to-campaign-rule-condition-disposition-value"
                            placeholder="Enter Value"
                            size='small'
                            sx={{
                                minWidth: "240px",
                                maxWidth: "240px",
                                borderRadius: "8px",
                                '& .MuiOutlinedInput-root': {
                                    fontSize: "14px",
                                    background: "#FFFFFF",
                                    '& fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                        borderBottomLeftRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                    },
                                    '&:hover fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                        borderBottomLeftRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                        borderBottomLeftRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                    },
                                },
                            }}
                            value={condition?.inputValue || ''}
                            onChange={(event) => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].conditions[conditionsIndex][conditionIndex].inputValue = event.target.value;
                                    return newRules;
                                });
                            }}
                            InputProps={
                                {
                                    endAdornment: <IconButton
                                        sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                                    field: '',
                                                    operator: '',
                                                    value: ''
                                                });
                                                return newRules;
                                            });
                                        }}>
                                        <AddRoundIcon />
                                    </IconButton>
                                }
                            }
                            helperText={'Enter , separated values for multiple dispositions'}
                            FormHelperTextProps={{
                                sx: {
                                    fontSize: '10px',
                                    color: '#546881',
                                    fontWeight: 400,
                                    mt: 0,
                                    ml: .3,
                                    mr: 0
                                }
                            }}
                        />
                            : <Autocomplete
                                id="oz-data-management-send-to-campaign-rule-condition-value"
                                options={condition.field === 'disposition' ? dispositionsList : []}
                                multiple
                                limitTags={1}
                                value={condition.value || []}
                                onChange={(event, newValue) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex][conditionIndex].value = newValue;
                                        return newRules;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Value"
                                        sx={{
                                            minWidth: "240px",
                                            maxWidth: "500px",
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: "14px",
                                                background: "#FFFFFF",
                                                '& fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&:hover fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderBottomRightRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                            },
                                        }}
                                    />
                                )}
                                popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                                    e.stopPropagation();
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                            field: '',
                                            operator: '',
                                            value: ''
                                        });
                                        return newRules;
                                    });
                                }}>
                                    <AddRoundIcon />
                                </IconButton>}
                                size='small'
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                        <Chip
                                            key={index}
                                            label={option.length > 20 ? option.slice(0, 20) + '...' : option}
                                            {...getTagProps({ index })}
                                            sx={{
                                                maxWidth: '40px',
                                                minWidth: '30px',
                                                height: '20px',
                                                py: 0,
                                                px: .4,
                                                fontSize: '12px',
                                                color: '#546881',
                                                fontWeight: 400,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',

                                            }}
                                        />
                                    ));
                                }}
                                disableCloseOnSelect
                                renderOption={(props, option, { selected }) => {
                                    return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                        <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                            {option}
                                        </Typography>
                                    </li>
                                }} />
                        )
                        : FIELD_TYPE_MAPPING[condition.field] === 'numeric' || FIELD_TYPE_MAPPING[condition.field] === 'string' ? <>
                            <TextField
                                id="oz-data-management-send-to-campaign-rule-condition-value"
                                placeholder={condition.operator === 'BETWEEN' ? 'Value 1' : 'Enter Value'}
                                size='small'
                                sx={{
                                    minWidth: "200px",
                                    maxWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: condition.operator === "BETWEEN" ? "0px" : "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: condition.operator === "BETWEEN" ? "0px" : "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: condition.operator === "BETWEEN" ? "0px" : "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px",
                                        },
                                    },
                                }}
                                value={condition.operator === 'BETWEEN' ? condition.value[0] : condition.value}
                                onChange={(event) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex][conditionIndex].value = condition.operator === 'BETWEEN' ? [event.target.value, condition.value[1]] : event.target.value;
                                        return newRules;
                                    });
                                }}
                                type={FIELD_TYPE_MAPPING[condition.field] === 'numeric' ? 'number' : 'text'}
                                InputProps={{
                                    endAdornment: condition.operator !== 'BETWEEN' ? <IconButton
                                        sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                                    field: '',
                                                    operator: '',
                                                    value: ''
                                                });
                                                return newRules;
                                            });
                                        }}>
                                        <AddRoundIcon />
                                    </IconButton> : <></>
                                }}
                                {...(condition.operator === 'BETWEEN' ? { inputProps: { min: 0 } } : {})}
                            />
                            {condition.operator === 'BETWEEN' && <TextField
                                id="oz-data-management-send-to-campaign-rule-condition-value"
                                placeholder="Value 2"
                                size='small'
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderLeft: "0px",
                                            borderTopLeftRadius: "0px",
                                            borderBottomLeftRadius: "0px"

                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderLeft: "0px",
                                            borderTopLeftRadius: "0px",
                                            borderBottomLeftRadius: "0px"
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderLeft: "0px",
                                            borderTopLeftRadius: "0px",
                                            borderBottomLeftRadius: "0px"
                                        },
                                    },
                                }}
                                value={condition.value[1]}
                                onChange={(event) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex][conditionIndex].value = [condition.value[0], event.target.value];
                                        return newRules;
                                    });
                                }}
                                type={FIELD_TYPE_MAPPING[condition.field] === 'numeric' ? 'number' : 'text'}
                                InputProps={{
                                    endAdornment: <IconButton
                                        sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setRules(prevRules => {
                                                const newRules = [...prevRules];
                                                newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                                    field: '',
                                                    operator: '',
                                                    value: ''
                                                });
                                                return newRules;
                                            });
                                        }}>
                                        <AddRoundIcon />
                                    </IconButton>
                                }}
                                inputProps={{ min: 0 }}
                            />}
                        </>
                            : FIELD_TYPE_MAPPING[condition.field] === 'boolean' ? <Autocomplete
                                id="oz-data-management-send-to-campaign-rule-condition-value"
                                options={['True', 'False']}
                                value={condition.value}
                                onChange={(event, newValue) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex][conditionIndex].value = newValue;
                                        return newRules;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Value"
                                        sx={{
                                            minWidth: "200px",
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: "14px",
                                                background: "#FFFFFF",
                                                '& fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&:hover fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderBottomRightRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                            },
                                        }}
                                    />
                                )}
                                popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                                    e.stopPropagation();
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                            field: '',
                                            operator: '',
                                            value: ''
                                        });
                                        return newRules;
                                    });
                                }}>
                                    <AddRoundIcon />
                                </IconButton>}
                                size='small'
                                renderOption={(props, option, { selected }) => {
                                    return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                        <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                            {option}
                                        </Typography>
                                    </li>
                                }} /> : FIELD_TYPE_MAPPING[condition.field] === 'date' ? <DateTimePickerForConditions condition={condition} setCondition={(newCondition) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditions[conditionsIndex][conditionIndex] = newCondition;
                                        return newRules;
                                    });
                                }} /> : null}
                    {isDisposition && <Stack direction="row" alignItems="center" sx={{ ml: 1, mb: Boolean(condition?.enableInputDisposition) ? 1.6 : 0 }}>
                        <Typography sx={{ fontSize: '12px', color: '#546881', fontWeight: 400, mr: 0.4 }}>Enable Input Disposition</Typography>
                        <SimpleSwitch
                            name={"Disposition Switch"}
                            checked={Boolean(condition?.enableInputDisposition)}
                            onChange={e => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].conditions[conditionsIndex][conditionIndex].enableInputDisposition = e.target.checked;
                                    return newRules;
                                });
                            }}
                        />
                    </Stack>
                    }
                    {FIELD_TYPE_MAPPING[condition.field] === 'date' && <IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", ml: 1 }} onClick={(e) => {
                        e.stopPropagation();
                        setRules(prevRules => {
                            const newRules = [...prevRules];
                            newRules[index].conditions[conditionsIndex].splice(conditionIndex + 1, 0, {
                                field: '',
                                operator: '',
                                value: ''
                            });
                            return newRules;
                        });
                    }}>
                        <AddRoundIcon />
                    </IconButton>}
                    {conditionIndex !== 0 && <IconButton
                        // className="oz-data-management-send-to-campaign-rule-priority-remove-button"
                        sx={{ ml: 1 }}
                        onClick={() => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].conditions[conditionsIndex].splice(conditionIndex, 1);
                                return newRules;
                            });
                        }}>
                        <RemoveIcon />
                    </IconButton>}
                    {conditionIndex === 0 && <Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto' }}>
                        <Autocomplete
                            id="oz-data-management-send-to-campaign-rule-condition-operator"
                            className='oz-data-management-send-to-campaign-rule-conditions-operator'
                            options={['AND', 'OR']}
                            value={rule.conditionOperator[conditionsIndex]}
                            onChange={(event, newValue) => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].conditionOperator[conditionsIndex] = newValue;
                                    return newRules;
                                });
                            }}
                            clearIcon={null}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Conditions Operator"
                                    sx={{
                                        width: "94px",
                                        marginLeft: "auto",
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: '12px',
                                            '& fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&:hover fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderBottomRightRadius: "0px"
                                            },
                                        },
                                    }}
                                />
                            )}
                            popupIcon={<DropdownIcon />}
                            size='small'
                            renderOption={(props, option, { selected }) => {
                                return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                    <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                        {option}
                                    </Typography>
                                </li>
                            }
                            } />
                        <Tooltip title="Add New Condition" placement="top">
                            <IconButton sx={{ ml: 1 }}
                                id='oz-data-management-send-to-campaign-rule-condition-add-new-condition'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setRules(prevRules => {
                                        let newRules = [...prevRules];
                                        const newCondition = {
                                            field: '',
                                            operator: '',
                                            value: ''
                                        };
                                        newRules[index].conditionOperator.splice(conditionsIndex + 1, 0, 'AND');
                                        newRules[index].conditions.splice(conditionsIndex + 1, 0, [newCondition]);
                                        return newRules;
                                    });
                                }}
                            >
                                <AddRoundIcon />
                            </IconButton>
                        </Tooltip>
                        {conditionsIndex !== 0 && <Tooltip title="Remove Condition" placement="bottom">
                            <IconButton sx={{ ml: 1 }}
                                id='oz-data-management-send-to-campaign-rule-condition-remove-condition'
                                onClick={() => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].conditionOperator.splice(conditionsIndex, 1);
                                        newRules[index].conditions.splice(conditionsIndex, 1);
                                        return newRules;
                                    });
                                }
                                }>
                                <RemoveIcon className='oz-data-management-rule-condition-remove-button' />
                            </IconButton>
                        </Tooltip>}
                    </Box>
                    }

                </Box>
            </Box>
        </Box>
    </>
}

const ActionContent = ({ actions, setActions, index, rule, setRules, dispositionsList }) => {
    return <Box className="oz-data-management-send-to-campaign-rule-condition-container" sx={{ mt: 1 }}>
        <Box className="oz-data-management-send-to-campaign-rule-condition-content">
            {actions.map((action, actionIndex) => {
                return <ActionItem action={action} setAction={setActions} index={index} rule={rule} setRules={setRules} actionIndex={actionIndex} dispositionsList={dispositionsList} />
            })}
        </Box>
    </Box>
}

const ActionItem = ({ action, setAction, index, rule, setRules, actionIndex, dispositionsList }) => {
    const actionFields = useMemo(() => {
        return ACTION_TYPES_MAPPING[action.type] || [];
    }, [action.type]);

    const isValueEnabled = useMemo(() => {
        if (action.type === 'UPDATE_STATUS') {
            return false;
        }
        if (action.type === 'SCHEDULE') {
            if (action.field) {
                return action.field === 'Custom DateTime';
            }
            return false;
        }
        return Boolean(action.field);
    }, [action.type, action.field]);

    return <>
        <Box className="oz-data-management-send-to-campaign-rule-condition-item">
            {actionIndex === 0 && <Typography className="oz-data-management-send-to-campaign-rule-priority-title" mr={4}>
                Then:
            </Typography>}
            <Box className={"oz-data-management-send-to-campaign-rule-condition-item"}>
                {actionIndex !== 0 && <Box sx={{
                    ml: (4 * actionIndex) + 4.5,
                    position: 'relative',
                    mb: 1
                }}>
                    <Box sx={{
                        borderLeft: "1px solid #909DAD",
                        height: '8px',
                        width: '0px',
                    }} />
                    <Typography className="oz-data-management-send-to-campaign-rule-priority-title">
                        AND
                    </Typography>
                    <Box sx={{
                        borderLeft: "1px solid #909DAD",
                        height: '8px',
                        width: '1px',
                    }} />
                    <Box sx={{
                        borderBottom: "1px solid #909DAD",
                        height: '0px',
                        width: '76px',
                    }} />
                </Box>}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: actionIndex === 0 ? 0 : 3,
                    ml: actionIndex === 0 ? 0 : 1,
                    width: '100%',
                }}>
                    <Autocomplete
                        id="oz-data-management-send-to-campaign-rule-action-field"
                        options={Object.keys(SET_ACTION_TYPES)}
                        value={action.type}
                        getOptionLabel={(option) => option ? SET_ACTION_TYPES[option] : ""}
                        onChange={(event, newValue) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].actions[actionIndex].type = newValue;
                                newRules[index].actions[actionIndex].field = '';
                                newRules[index].actions[actionIndex].value = '';
                                return newRules;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Action"
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option ? SET_ACTION_TYPES[option] : ""}
                                </Typography>
                            </li>
                        }} />
                    {action.type !== 'UPDATE_STATUS' && <Autocomplete
                        id="oz-data-management-send-to-campaign-rule-action-field"
                        options={actionFields}
                        value={action.field}
                        getOptionLabel={(option) => option ? FIELDS[option] ? FIELDS[option] : SCHEDULE_TIMES[option] ? SCHEDULE_TIMES[option] : "" : ""}
                        onChange={(event, newValue) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].actions[actionIndex].field = newValue;
                                newRules[index].actions[actionIndex].value = '';
                                return newRules;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Field"
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: isValueEnabled ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: isValueEnabled ? "0px" : "8px",
                                            borderBottomRightRadius: isValueEnabled ? "0px" : "8px",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: isValueEnabled ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: isValueEnabled ? "0px" : "8px",
                                            borderBottomRightRadius: isValueEnabled ? "0px" : "8px",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "0px",
                                            borderRight: isValueEnabled ? "0px" : "1px solid #D6D6D7",
                                            borderTopRightRadius: isValueEnabled ? "0px" : "8px",
                                            borderBottomRightRadius: isValueEnabled ? "0px" : "8px",
                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option ? FIELDS[option] ? FIELDS[option] : SCHEDULE_TIMES[option] ? SCHEDULE_TIMES[option] : "" : ""}
                                </Typography>
                            </li>
                        }} />}
                    {(action.type === 'SCHEDULE' && action.field === 'Custom DateTime') ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            value={action.value || null}
                            onChange={(newValue) => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].actions[actionIndex].value = newValue;
                                    return newRules;
                                });
                            }}
                            openTo="day"
                            showToolbar={false}
                            renderInput={(params) => <TextField
                                size='small'
                                sx={{
                                    width: "236px",
                                    minWidth: "236px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        borderRadius: '8px',
                                        backgroundColor: '#ffff',
                                        height: '37px',
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                    },
                                }}
                                fullWidth
                                {...params}
                            />}
                        />
                    </LocalizationProvider> : FIELD_TYPE_MAPPING[action.field] === 'array' ? <Autocomplete
                        id="oz-data-management-send-to-campaign-rule-action-value"
                        options={action.field === 'disposition' ? dispositionsList : []}
                        value={action.value}
                        onChange={(event, newValue) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].actions[actionIndex].value = newValue;
                                return newRules;
                            });
                        }}
                        size='small'
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Value"
                                sx={{
                                    minWidth: "200px",
                                    '& .MuiOutlinedInput-root': {
                                        fontSize: "14px",
                                        background: "#FFFFFF",
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px",
                                            borderTopLeftRadius: "0px"
                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                            e.stopPropagation();
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].actions.splice(actionIndex + 1, 0, {
                                    type: '',
                                    field: '',
                                    value: ''
                                });
                                return newRules;
                            }
                            );
                        }}>
                            <AddRoundIcon />
                        </IconButton>
                        }
                        renderOption={(props, option, { selected }) => {
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option}
                                </Typography>
                            </li>
                        }} /> : FIELD_TYPE_MAPPING[action.field] === 'numeric' || FIELD_TYPE_MAPPING[action.field] === 'string' || action.type === 'UPDATE_STATUS' ? <>
                            {action.type === 'UPDATE_STATUS' ? <Autocomplete
                                id="oz-data-management-send-to-campaign-rule-action-value-update-status"
                                options={['DO_NOT_DIAL']}
                                value={action.value}
                                onChange={(event, newValue) => {
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].actions[actionIndex].value = newValue;
                                        return newRules;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Value"
                                        sx={{
                                            minWidth: "200px",
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: "14px",
                                                background: "#FFFFFF",
                                                '& fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&:hover fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: "1px solid #D6D6D7",
                                                    borderRadius: "8px",
                                                    borderBottomLeftRadius: "0px",
                                                    borderBottomRightRadius: "0px",
                                                    borderTopLeftRadius: "0px"
                                                },
                                            },
                                        }}
                                    />
                                )}
                                popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                                    e.stopPropagation();
                                    setRules(prevRules => {
                                        const newRules = [...prevRules];
                                        newRules[index].actions.splice(actionIndex + 1, 0, {
                                            type: '',
                                            field: '',
                                            value: ''
                                        });
                                        return newRules;
                                    });
                                }}>
                                    <AddRoundIcon />
                                </IconButton>
                                }
                                size='small'
                                renderOption={(props, option, { selected }) => {
                                    return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                        <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                            {option}
                                        </Typography>
                                    </li>
                                }} />
                                :
                                <TextField
                                    id="oz-data-management-send-to-campaign-rule-action-value"
                                    placeholder="Enter Value"
                                    size='small'
                                    sx={{
                                        minWidth: "200px",
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: "14px",
                                            background: "#FFFFFF",
                                            '& fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                            '&:hover fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                        },
                                    }}
                                    value={action.value}
                                    onChange={(event) => {
                                        setRules(prevRules => {
                                            const newRules = [...prevRules];
                                            newRules[index].actions[actionIndex].value = event.target.value;
                                            return newRules;
                                        });
                                    }}
                                    type={FIELD_TYPE_MAPPING[action.field] === 'numeric' ? 'number' : 'text'}
                                    InputProps={{
                                        endAdornment: <IconButton
                                            sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setRules(prevRules => {
                                                    const newRules = [...prevRules];
                                                    newRules[index].actions.splice(actionIndex + 1, 0, {
                                                        type: '',
                                                        field: '',
                                                        value: ''
                                                    });
                                                    return newRules;
                                                });
                                            }}>
                                            <AddRoundIcon />
                                        </IconButton>
                                    }}
                                    {...(FIELD_TYPE_MAPPING[action.field] === 'numeric' ? { inputProps: { min: 0 } } : {})}
                                />}
                        </>
                        : FIELD_TYPE_MAPPING[action.field] === 'boolean' ? <Autocomplete
                            id="oz-data-management-send-to-campaign-rule-action-value"
                            options={['True', 'False']}
                            value={action.value}
                            onChange={(event, newValue) => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].actions[actionIndex].value = newValue;
                                    return newRules;
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select Value"
                                    sx={{
                                        minWidth: "200px",
                                        '& .MuiOutlinedInput-root': {
                                            fontSize: "14px",
                                            background: "#FFFFFF",
                                            '& fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                            '&:hover fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderBottomRightRadius: "0px",
                                                borderTopLeftRadius: "0px"
                                            },
                                        },
                                    }}
                                />
                            )}
                            popupIcon={<IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", }} onClick={(e) => {
                                e.stopPropagation();
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].actions.splice(actionIndex + 1, 0, {
                                        type: '',
                                        field: '',
                                        value: ''
                                    });
                                    return newRules;
                                });
                            }}>
                                <AddRoundIcon />
                            </IconButton>
                            }
                            size='small'
                            renderOption={(props, option, { selected }) => {
                                return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                    <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                        {option}
                                    </Typography>
                                </li>
                            }} /> : FIELD_TYPE_MAPPING[action.field] === 'date' ? <DateTimePickerForConditions condition={action} setCondition={(newCondition) => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].actions[actionIndex] = newCondition;
                                    return newRules;
                                }
                                );
                            }} /> : null}
                    {action.type === 'SCHEDULE' && <TextField
                        id="oz-data-management-send-to-campaign-rule-action-name"
                        placeholder="Action Name"
                        size='small'
                        sx={{
                            minWidth: "200px",
                            mx: 2,
                            '& .MuiOutlinedInput-root': {
                                fontSize: "14px",
                                background: "#FFFFFF",
                                '& fieldset': {
                                    border: "1px solid #D6D6D7",
                                    borderRadius: "8px",
                                },
                                '&:hover fieldset': {
                                    border: "1px solid #D6D6D7",
                                    borderRadius: "8px",
                                },
                                '&.Mui-focused fieldset': {
                                    border: "1px solid #D6D6D7",
                                    borderRadius: "8px",
                                },
                            },
                        }}
                        value={action.actionName}
                        onChange={(event) => {
                            setRules(prevRules => {
                                const newRules = [...prevRules];
                                newRules[index].actions[actionIndex].actionName = event.target.value;
                                return newRules;
                            });
                        }}
                    />}
                    {(FIELD_TYPE_MAPPING[action.field] === 'date' || action.type === 'SCHEDULE') && <IconButton sx={{ minWidth: "24px", minHeight: "24px", padding: "0px", ml: 1 }} onClick={(e) => {
                        e.stopPropagation();
                        setRules(prevRules => {
                            const newRules = [...prevRules];
                            newRules[index].actions.splice(actionIndex + 1, 0, {
                                type: '',
                                field: '',
                                value: ''
                            });
                            return newRules;
                        });
                    }}>
                        <AddRoundIcon />
                    </IconButton>}

                    {actionIndex !== 0 && <Tooltip title="Remove Action" placement="bottom">
                        <IconButton
                            // className="oz-data-management-send-to-campaign-rule-priority-remove-button"
                            sx={{ ml: 1 }}
                            onClick={() => {
                                setRules(prevRules => {
                                    const newRules = [...prevRules];
                                    newRules[index].actions.splice(actionIndex, 1);
                                    return newRules;
                                });
                            }}>
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>}
                </Box>
            </Box>
        </Box>
    </>
}

const DateTimePickerForConditions = ({ condition, setCondition }) => {
    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
            value={condition.value || null}
            onChange={(newValue) => {
                setCondition({
                    ...condition,
                    value: newValue
                });
            }}
            openTo="day"
            showToolbar={false}
            renderInput={(params) => <TextField
                size='small'
                sx={{
                    width: "236px",
                    minWidth: "236px",
                    '& .MuiOutlinedInput-root': {
                        fontSize: "14px",
                        borderRadius: '8px',
                        backgroundColor: '#ffff',
                        height: '37px',
                        '& fieldset': {
                            border: "1px solid #D6D6D7",
                            borderRadius: "8px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px"
                        },
                        '&:hover fieldset': {
                            border: "1px solid #D6D6D7",
                            borderRadius: "8px",
                            borderBottomLeftRadius: "0px",
                            borderTopLeftRadius: "0px"
                        },
                        '&.Mui-focused fieldset': {
                            border: "1px solid #D6D6D7",
                            borderRadius: "8px",
                            borderBottomLeftRadius: "0px",
                            borderBottomRightRadius: "0px",
                            borderTopLeftRadius: "0px"
                        },
                    },
                }}
                fullWidth
                {...params}
            />}
        />
    </LocalizationProvider>
}