// campaignApi.ts
import axios from 'axios';
import { getAuthToken } from '../../../services/auth-service';
import { Campaign, Campaign_Request, Sender, Sort } from './interfaces';
import { BASE_URL_CXI } from '../../../utils/properties';

interface ApiResponse<T> {
  status: 'success' | 'error';
  message: string | null;
  code: number | null;
  data: T;
  errors: Record<string, string[]> | null;
}
interface ApiErrorResponse {
  status: 'error';
  message: string;
  code: number;
  data: null;
  errors: null;
}

interface PageableInfo {
  pageNumber: number;
  pageSize: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

interface PaginatedData<T> {
  content: T[];
  pageable: PageableInfo;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: Sort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export const campaignApi = {
  listCampaigns: async (userId: number | any, page: number = 0, size: number = 10, type?: string): Promise<ApiResponse<PaginatedData<Campaign>>> => {
    try {
      const params: Record<string, any> = { page, size };
      if (type && type.toLowerCase() !== 'all') {
        params.type = type.toLowerCase();
      }
      const response = await axios.get<ApiResponse<PaginatedData<Campaign>>>(
        `${BASE_URL_CXI}/campaign/active`,
        {
          params,
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      return {
        status: 'error',
        message: error.response?.data?.message || 'Failed to fetch campaigns',
        code: error.response?.status || 500,
        data: {
          content: [],
          pageable: {
            pageNumber: 0,
            pageSize: size,
            sort: { empty: true, sorted: false, unsorted: true },
            offset: 0,
            paged: true,
            unpaged: false
          },
          totalPages: 0,
          totalElements: 0,
          last: true,
          size: size,
          number: 0,
          sort: { empty: true, sorted: false, unsorted: true },
          numberOfElements: 0,
          first: true,
          empty: true
        },
        errors: null
      };
    }
  },

  getActiveCampaigns: async (userId: number | any, page: number = 0, size: number = 10): Promise<ApiResponse<PaginatedData<Campaign>>> => {
    try {
      const response = await axios.get<ApiResponse<PaginatedData<Campaign>>>(
        `${BASE_URL_CXI}/campaign/active`,
        {
          params: { page, size },
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch active campaigns');
    }
  },

  getCampaign: async (userId: number | any, campaignId: number): Promise<ApiResponse<Campaign>> => {
    try {
      const response = await axios.get<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/${campaignId}`,
        {
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Failed to fetch campaign details');
    }
  },

  startCampaign: async (userId: number | any, campaignId: number): Promise<ApiResponse<Campaign>> => {
    try {
      const response = await axios.get<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/start/${campaignId}`,
        {
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Failed to start campaign:', error);
      throw new Error(error.response?.data?.message || 'Failed to start campaign');
    }
  },

  stopCampaign: async (userId: number | any, campaignId: number): Promise<ApiResponse<Campaign>> => {
    try {
      const response = await axios.get<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/stop/${campaignId}`,
        {
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Failed to stop campaign:', error);
      throw new Error(error.response?.data?.message || 'Failed to stop campaign');
    }
  },

  createCampaign: async (userId: number | any, campaignData: Campaign_Request, formData: FormData): Promise<ApiResponse<Campaign>> => {
      try {
        // Always use FormData, even for simple campaigns     
        // Add campaign data as a blob
      /*  formData.append('campaign', new Blob([JSON.stringify(campaignData)], {
          type: 'application/json'
        }));*/  
        const response = await axios.post<ApiResponse<Campaign>>(
          `${BASE_URL_CXI}/campaign`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              userid: userId,
              Authorization: `Bearer ${getAuthToken()}`
            }
          }
        );
  
        return response.data;
      } catch (error: any) {
        console.error('Failed to create campaign:', error);
        throw {
          response: {
            data: {
              status: 'error',
              message: error.response?.data?.message || 'Failed to create campaign',
              code: error.response?.status || 500,
              data: null,
              errors: error.response?.data?.errors || null
            }
          }
        };
      }
  },

  // This is a new method specifically for handling bulk uploads
  uploadBulkData: async (userId: number | any, campaignId: number, file: File): Promise<ApiResponse<Campaign>> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/${campaignId}/bulk-upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Failed to upload bulk data:', error);
      throw new Error(error.response?.data?.message || 'Failed to upload bulk data');
    }
  },

  updateCampaign: async (userId: number | any, campaignId: number, campaignData: Campaign_Request): Promise<ApiResponse<Campaign>> => {
    try {
      // Process extraData based on campaign type and templateType
      let extraData: Record<string, any> = { ...campaignData.extraData };

      if (campaignData.type === 'whatsapp') {
        switch (campaignData.templateType) {
          case 'document':
            extraData.documentUrl = extraData.documentUrl || '';
            extraData.documentName = extraData.documentName || '';
            break;
          case 'image':
            extraData.imageUrl = extraData.imageUrl || '';
            break;
          case 'video':
            extraData.videoUrl = extraData.videoUrl || '';
            break;
        }
      }

      // Prepare API request payload
      const payload: Campaign_Request = {
        ...campaignData,
        extraData
      };

      const response = await axios.put<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/${campaignId}`,
        payload,
        {
          headers: {
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );

      return response.data;
    } catch (error: any) {
      console.error('Failed to update campaign:', error);
      throw {
        response: {
          data: {
            status: 'error',
            message: error.response?.data?.message || 'Failed to update campaign',
            code: error.response?.status || 500,
            data: null,
            errors: error.response?.data?.errors || null
          }
        }
      };
    }
  },

  deleteCampaign: async (userId: number | any, campaignId: number): Promise<ApiResponse<Campaign>> => {
    try {
      const response = await axios.delete<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/${campaignId}`,
        {
          headers: { userid: userId, Authorization: `Bearer ${getAuthToken()}` }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Failed to delete campaign:', error);
      throw new Error(error.response?.data?.message || 'Failed to delete campaign');
    }
  },

  uploadCampaignFile: async (userId: number | any, campaignId: number, file: File): Promise<ApiResponse<Campaign>> => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post<ApiResponse<Campaign>>(
        `${BASE_URL_CXI}/campaign/upload/${campaignId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );
      return response.data;
    } catch (error: any) {
      console.error('Failed to upload campaign file:', error);
      throw new Error(error.response?.data?.message || 'Failed to upload campaign file');
    }
  }
};

export default campaignApi;