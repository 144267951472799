import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import * as React from 'react';
import { getFilters, getQueueCounter } from '../../../services/api-service';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';

import { Badge, Box, Chip, Stack, Tab, Grid } from '@mui/material';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import '../QueueCountersDigital/QueueCountersDigital.css';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import { mapRowDataWithThreshold, mapRowDataWithThresholdNew } from '../../../utils/util';
import { TabContext, TabList } from '@mui/lab';

let interval: any;

const thresholdFields = [
	{ label: 'Longest wait time', value: '', key: 'LongestWaitTime', condition: 'GREATER_THAN' },
	{ label: 'Calls Waiting', value: '', key: 'QueueCount', condition: 'GREATER_THAN' }
];

export default function QueueCountersDigital() {
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [filterType, setFilterType] = useState<any>('1');
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
	const [queueCounterDigitalFilter, setQueueCounterDigitalFilter] = useState<Array<any>>([]);
	const [callType, setCallType] = React.useState("all");
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const downloadContent = useRef<null | any>(null);

	const retrieveTableData = async (filters = '0') => {
		const columns = [
			{ name: 'Skills', key: 'SkillName' },
			{ name: 'Conversations Waiting', key: 'QueueCount' },
			{ name: 'Agents Available', key: 'AgentsAvailable' },
			{ name: 'Longest Wait Time', key: 'LongestWaitTime', type: 'MIN_SEC' }
		];
		const rows: any = await getQueueCounter({ filters, type: filterType, ChannelType: "1" }); //just for trial later we have to remove 1 and change to 2
		// const updatedRowData = mapRowDataWithThreshold(thresholdItems, rows);
		const updatedRowData = mapRowDataWithThresholdNew(thresholdItems, rows);
		setRowData(updatedRowData);
		setColumnData(columns);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = queueCounterDigitalFilter.filter((item: any) => item.id !== id);
		setQueueCounterDigitalFilter(newFilter);
	};

	const clearFilter = () => {
		console.info('Clear all filter.');
		setQueueCounterDigitalFilter([]);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType }: any) => {
		setQueueCounterDigitalFilter(selectedFilters);
		setFilterType(newFilterType);
		toggleDrawer(false);
	};

	const filterItems = queueCounterDigitalFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			setThresholdItems(data);
		}
	};

	const updateThresholdItems = async () => {
		let newThresholdItems = [];
		const response: any = await getFilters('Skill');
		newThresholdItems.push({ label: 'Organization Level', fields: thresholdFields, isOrgSkill: true });
		for (const row of response) {
			if (row.name !== 'All') {
				newThresholdItems.push({ label: row.Name, key: 'SkillName', value: row.Name, fields: thresholdFields });
			}
		}
		setThresholdItems(newThresholdItems);
	};

	React.useEffect(() => {
		updateThresholdItems();
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = queueCounterDigitalFilter.length ? queueCounterDigitalFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.QUEUE_COUNTER_DIGITAL);
	}, [queueCounterDigitalFilter, thresholdItems, callType]);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setCallType(newValue);
	};

	return (
		<Card className="customCard">
			<div className="card-header">
				<div className="card-title">
					Queue Counter <span style={{ color: '#536580', fontWeight: 400, marginLeft: '3px' }}>(Digital)</span>
					<div className="card-title-badge">Live</div>
				</div>
				<div className="card-actions">
					<WidgetDownload element={downloadContent} handleDownload={handleDownload} name={'Queue Counter(Digital)'}></WidgetDownload>
					<div className="card-icon" onClick={() => toggleDrawer(true)}>
						<Badge badgeContent={queueCounterDigitalFilter.length} color="primary">
							<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</Badge>
					</div>
					<div onClick={() => toggleThresholdDrawer(true)}>
						<WidgetGraph></WidgetGraph>
					</div>
				</div>
			</div>

			<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
				<TabContext value={callType}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} className="customTabs">
							<Tab label="All" value="all" />
							<Tab label="Whatsapp" value="whatsapp" />
							<Tab label="Web Chat" value="webChat" />
							<Tab label="SMS" value="sms" />
						</TabList>
					</Box>
				</TabContext>
			</Box>

			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} allowedFilters={['Campaign', 'Skill']} existingSelectedFilters={queueCounterDigitalFilter} />
			<Box sx={{ width: '100%', typography: 'body1' }}>
				{queueCounterDigitalFilter.length > 0 && (
					<Box className='customTabPanel'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className="filter-outer">
									<div className="filter-container">
										<Stack direction="row" spacing={1}>
											{filterItems}
										</Stack>
									</div>
									<div className="filter-remove" onClick={clearFilter}>
										Remove
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				)}
				<OZTable columns={columnData} rows={rowData} maxHeight={360} downloadContent={downloadContent}></OZTable>
			</Box>
			<ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Queue Counter'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
		</Card>
	);
}
