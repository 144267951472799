import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Card, CardContent, CardMedia, Divider, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useMemo, useState } from "react";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import { OZCustomToggleButton } from "../../../components/admin/common/OZCustomToggleButton";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import SimpleSwitch from "../../../components/admin/common/OZSwitch/SimpleSwitch";
import { enableIdeskIntegration, forceRevokeIntegration, getAvailableIntegration, getEnabledUserIntegrations, removeIdeskIntegration, removeIntegrations, removeSsoLoginORCloudStorage, revokeIntegration } from "../../../services/api-service";
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";
import balto from "./balto.svg";
import BaltoIntegration from "./BaltoIntegration";
import CloudStorage from "./CloudStorage";
import cloudStorageImage from "./cloudstorage.svg";
import hubspot from "./hubspot.svg";
import { IdeskIntegration } from "./IdeskIntegration";
import Integration from "./Integration";
import "./Integration.css";
import netsapiens from "./netsapiens.png";
import NetsapiensIntegration from "./NetsapiensIntegration";
import ozonetel from "./ozonetel.svg";
import SSOLogin from "./SSOLogin";
import ssoImage from "./ssologin.svg";
import zoho from "./Zoho.png";
import { ReactComponent as ResetIcon } from '../../../static/icons/reset.svg';

const useStyles = makeStyles({
  root: {
    width: 313,
    Height: 142,
  },
  zohoImage: {
    height: 30,
    width: 90,
    cursor: "pointer",
  },
  ozonetelImage: {
    height: 37,
    width: 170,
    cursor: "pointer"
  },
  hubspotImage: {
    height: 37,
    width: 140,
    cursor: "pointer"
  },
  ssoImage: {
    height: 50,
    width: 50,
    cursor: "pointer",
  },
  cloudStorageImage: {
    height: 50,
    width: 92,
    cursor: "pointer",
  },
  zohoImageWithoutCursor: {
    height: 30,
    width: 90,
  },
  ozonetelImageWithoutCursor: {
    height: 37,
    width: 170,
  },
  hubspotImageWithoutCursor: {
    height: 37,
    width: 140,
  },
  ssoImageWithoutCursor: {
    height: 50,
    width: 50,
  },
  cloudStorageImageWithoutCursor: {
    height: 50,
    width: 92,
  },
  cardPara: {
    color: "#212121",
    paddingLeft: "8px",
    height: "14px",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 400
  },
  cardParaWithoutCursor: {
    color: "#212121",
    paddingLeft: "8px",
    height: "14px",
    fontSize: "12px",
    fontWeight: 400
  },
});

export default function IntegrationsHome() {
  const classes = useStyles();
  const [enabledIntegrations, setEnableIntegrations] = useState([{}]);
  const [integrationList, setIntegrationList] = useState([]);
  const [loading, setLoading] = useState();
  const [ideskIntegration, setIdeskIntegration] = useState(false);
  const [ideskData, setIdeskData] = useState([]);
  const [zohoIntegration, setZohoIntegration] = useState(false);
  const [hubspotIntegration, setHubspotIntegration] = useState(false);
  const [netsapiensIntegration, setNetsapiensIntegration] = useState(false);
  const [netsapiensData, setNetsapiensData] = useState(null);
  const [baltoIntegration, setBaltoIntegration] = useState(false);
  const [baltoData, setBaltoData] = useState(null);
  const [crmTab, setCrmTab] = useState(true);
  const [operationalTab, setOperationalTab] = useState(false);
  const [enabledOperationalIntegrations, setEnableOperationalIntegrations] = useState({
    ssoLogin: false,
    cloudStorage: false
  });
  const [ssoLogin, setSsoLogin] = useState(false);
  const [cloudStorage, setCloudStorage] = useState(false);
  const [ssoLoginData, setSsoLoginData] = useState(null);
  const [awsKeyData, setAwsKeyData] = useState(null);
  const [awsArnData, setAwsArnData] = useState(null);
  const [gcpData, setGcpData] = useState(null);
  const [azureData, setAzureData] = useState(null);
  let workingIntegrations = []
  let operationalIntegrations = [{ name: "ssoLogin" }, { name: "cloudStorage" }] //for cloudStorage Uncomment this and remove the below line
  // let operationalIntegrations = [{ name: "ssoLogin" }]

  // eslint-disable-next-line array-callback-return
  integrationList.map((integration) => {
    if (integration.name === "Zoho" || integration.name === "Idesk" || integration.name === "Netspaeins" || integration.name === "Hubspot" || integration.name === "Balto") {
      workingIntegrations.push(integration)
    }
  })

  const [tabValue, setTabValue] = useState("1");
  const toggleStyle = useMemo(
    () => ({
      color: "#212121",
      fontSize: " 0.75rem",
      fontWeight: 400,
      letterSpacing: "0em",
      backgroundColor: "#F5F8FA",
      width: "5.8125rem",
      height: "1.875rem",
      borderRadius: 0,
      paddingBottom: "5px",
      paddingTop: "5px",
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#FFFF',
      },
    }),
    []
  );

  const handleTabChange = (event, tabIndex) => {
    setTabValue(tabIndex);
    if (tabIndex === "1") {
      setCrmTab(true)
      setOperationalTab(false)
    }
    if (tabIndex === "2") {
      setOperationalTab(true)
      setCrmTab(false)
    }
  };


  useEffect(() => {
    getIntegrationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function enableIdesk() {
    setLoading(true);
    try {
      enableIdeskIntegration()
        .then((resp) => {
          if (resp.status === "success" && resp.statusCode === 200) {
            showSuccessNotification('Idesk Integration Enabled Successfully');
            getUserIntegrationsData();
            setLoading(false);
          }
          else {
            showErrorNotification("Error in Enabling Idesk Integration");
            setEnableIntegrations(prev => ({ ...prev, Idesk: false }));
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in Enabling Idesk Integration");
          setEnableIntegrations(prev => ({ ...prev, Idesk: false }));
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in Idesk Integration Enabling");
      setEnableIntegrations(prev => ({ ...prev, Idesk: false }));
      setLoading(false);
    }
  };

  async function removeIdesk() {
    setLoading(true);
    try {
      removeIdeskIntegration()
        .then((resp) => {
          if (resp.status === "success" && resp.statusCode === 200) {
            showSuccessNotification('Idesk Integration Revoked Successfully');
            getUserIntegrationsData()
            setLoading(false);
          }
          else {
            showErrorNotification("Error in revoking Idesk Integration");
            setEnableIntegrations(prev => ({ ...prev, Idesk: true }));
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification("Error in revoking Idesk Integration");
          setEnableIntegrations(prev => ({ ...prev, Idesk: true }));
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in Idesk Integration revoking");
      setEnableIntegrations(prev => ({ ...prev, Idesk: true }));
      setLoading(false);
    }
  };

  async function revokeIntegrations(integrationName) {
    setLoading(true);
    try {
      revokeIntegration(integrationName === "Zoho" ? integrationName.toLowerCase() : integrationName)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`${integrationName} Integration Revoked Successfully`);
            getUserIntegrationsData()
            setLoading(false);
          }
          else {
            showErrorNotification(`Error in revoking ${integrationName} Integration`);
            setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification(`Error in revoking ${integrationName} Integration`);
          setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification(`Error in ${integrationName} Integration revoking`);
      setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
      setLoading(false);
    }
  };

  async function removeIntegration(integrationName) {
    setLoading(true);
    try {
      removeIntegrations(integrationName)
        .then((resp) => {
          if (resp.Status === "success") {
            showSuccessNotification(`${integrationName} Integration Revoked Successfully`);
            getUserIntegrationsData()
            setLoading(false);
          }
          else {
            showErrorNotification(`Error in revoking ${integrationName} Integration`);
            setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
            setLoading(false);
          }
        })
        .catch((e) => {
          showErrorNotification(`Error in revoking ${integrationName} Integration`);
          setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification(`Error in ${integrationName} Integration revoking`);
      setEnableIntegrations(prev => ({ ...prev, [integrationName]: true }));
      setLoading(false);
    }
  }

  const toggleIntegrationState = async (e, integrationName, integrationItem) => {
    if (integrationName !== "ssoLogin" && integrationName !== "cloudStorage") {
      setEnableIntegrations(prev => ({ ...prev, [integrationName]: e.target.checked }));
    }
    if (integrationName === "Idesk") {
      if (e.target.checked === true) {
        enableIdesk()
      }
      if (e.target.checked === false) {
        removeIdesk()
      }
    }
    if (integrationName === "Zoho") {
      if (e.target.checked === true) {
        let state = encodeURIComponent(btoa(`${integrationName}~${window.location.origin}/oauth`))
        let zohoLink = `${integrationItem.codeUrl}?scope=${integrationItem.scope}&client_id=${integrationItem.clientId}&client_secret=${integrationItem.clientSecret}&access_type=offline&response_type=code&state=${state}&redirect_uri=${integrationItem.redirectUrl}`
        if (integrationItem.name === "Zoho") {
          window.open(zohoLink, "_self");
        }
      }
      if (e.target.checked === false) {
        revokeIntegrations(integrationItem.name);
      }
    }
    if (integrationName === "Hubspot") {
      if (e.target.checked === true) {
        let state = encodeURIComponent(btoa(`${integrationName}~${window.location.origin}/oauth`))
        let hubspotLink = `${integrationItem.codeUrl}?scope=${integrationItem.scope}&client_id=${integrationItem.clientId}&client_secret=${integrationItem.clientSecret}&access_type=offline&response_type=code&state=${state}&redirect_uri=${integrationItem.redirectUrl}`
        if (integrationItem.name === "Hubspot") {
          window.open(hubspotLink, "_self");
        }
      }
      if (e.target.checked === false) {
        revokeIntegrations(integrationItem.name);
      }
    }
    if (integrationName === "Netsapiens") {
      if (e.target.checked === true) {
        setNetsapiensIntegration(true);
      }
      if (e.target.checked === false) {
        removeIntegration(integrationItem.name)
      }
    }
    if (integrationName === "Balto") {
      if (e.target.checked === true) {
        setBaltoIntegration(true);
      }
      if (e.target.checked === false) {
        removeIntegration(integrationItem.name)
      }
    }
    if (integrationName === "ssoLogin") {
      if (e.target.checked === true) {
        removeSsoORCloudStorage("removeSsoDetails", integrationName, true)
      }
      if (e.target.checked === false) {
        removeSsoORCloudStorage("removeSsoDetails", integrationName, false)
      }
    }
    if (integrationName === "cloudStorage") {
      if (e.target.checked === true) {
        removeSsoORCloudStorage("removeCloudStorageDetails", integrationName, true)
      }
      if (e.target.checked === false) {
        removeSsoORCloudStorage("removeCloudStorageDetails", integrationName, false)
      }
    }
  };

  async function removeSsoORCloudStorage(pathName, integationName, isActive) {
    setLoading(true)
    try {
      removeSsoLoginORCloudStorage(pathName, isActive)
        .then((resp) => {
          if (resp.Status === "Success") {
            if (isActive === false) {
              showSuccessNotification(`Revoked ${integationName} integration successfully`)
            }
            else {
              showSuccessNotification(`Enabled ${integationName} integration successfully`)
            }
            setEnableOperationalIntegrations(prev => ({ ...prev, [integationName]: isActive }))
            getUserIntegrationsData()
            setLoading(false);
          }
          else {
            if (isActive === false) {
              showErrorNotification(`Error in Revoking  ${integationName} integration`)
            }
            else {
              showErrorNotification(`Error in enabling  ${integationName} integration`)
            }
            setEnableOperationalIntegrations(prev => ({ ...prev, [integationName]: !isActive }));
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          showErrorNotification(`Error in revoking ${integationName} Integration`);
          setEnableOperationalIntegrations(prev => ({ ...prev, [integationName]: !isActive }));
        });
    }
    catch {
      setLoading(false);
      showErrorNotification(`Error in Revoking  ${integationName} Integration`)
      setEnableOperationalIntegrations(prev => ({ ...prev, [integationName]: !isActive }));
    }
  }

  function convertToValidJson(inputString) {
    // const trimmedString = inputString.replace(/[{}]/g, "");
    const trimmedString = inputString.replace(/^\{|\}$/g, "");
    const keyValuePairs = trimmedString.split(", ");
    const storageConfig = {};
    keyValuePairs.forEach(pair => {
      // const [key, value] = pair.split("=");
      const [key, value] = pair.split(/=(.+)/);
      storageConfig[key] = isNaN(value) ? value : Number(value);
    });
    console.log(storageConfig);
    return storageConfig
  }

  async function getUserIntegrationsData() {
    let integrations = {};
    let integrationIds = [];
    let integrationData = {}
    try {
      getEnabledUserIntegrations()
        .then((resp) => {
          if (resp.Status === "Success") {
            let ideskData = []
            resp?.Data && resp?.Data?.length > 0 && resp?.Data?.map((item) => {
              if (item.name === "Idesk") {
                ideskData = {
                  ideskIntegration: item.jsonData,
                  token: item.authToken,
                }
                let parsedData = {
                  ideskIntegration: JSON.parse(ideskData.ideskIntegration),
                  token: ideskData.token
                }
                setIdeskData(parsedData)
              }
              if (item.name === "Netsapiens") {
                setNetsapiensData(JSON.parse(item))
              }
              if (item.name === "Balto") {
                setBaltoData(JSON.parse(item))
              }
            })
            resp?.Data && resp?.Data?.length > 0 && resp?.Data?.forEach(element => {
              if (element.name !== "AWS-Key" && element.name !== "AWS-ARN" && element.name !== "GCP" && element.name !== "Azure") {
                integrationIds.push(element.id)
              }
            });
            integrationData = JSON.parse(localStorage.getItem("IntegrationData"));
            integrationData.forEach(element => {
              if (integrationIds.includes(element.id) === true) {
                integrations[element.name] = true;
              }
              else {
                integrations[element.name] = false;
              }
            });
            setEnableIntegrations(integrations);
            if (resp?.AppUserList && resp?.AppUserList?.length === 1) {
              let SsoLoginData = {}
              resp?.AppUserList?.map((data) => {
                if (data.authType === "SAML") {
                  let parsedData = JSON.parse(data.authConfig)
                  SsoLoginData = {
                    authType: data.authType,
                    authConfig: parsedData.url,
                    federationUrl: (parsedData.federationUrl !== undefined && parsedData.federationUrl !== null) ? parsedData.federationUrl : "",
                    authFile: []
                  }
                  setSsoLoginData(SsoLoginData)
                  setEnableOperationalIntegrations(prev => ({ ...prev, ssoLogin: true }))
                }
                else if (data.authType === "LDAP") {
                  let parsedData = JSON.parse(data.authConfig)
                  SsoLoginData = {
                    authType: data.authType,
                    url: parsedData.url,
                    port: parsedData.port,
                    userDn: parsedData.userDn,
                    domain: parsedData.domain,
                    dc: parsedData.dc,
                    isAllowAdmin: parsedData.isAllowAdmin
                  }
                  setSsoLoginData(SsoLoginData)
                  setEnableOperationalIntegrations(prev => ({ ...prev, ssoLogin: true }))
                }
              })
            }
            let awsKeydata = null
            let awsArndata = null
            let gcpdata = null
            let azuredata = null
            resp?.Data && resp?.Data?.length > 0 && resp?.Data?.map((data) => {
              if (data.name !== "Idesk" && data.name !== "Zoho") {
                // let parsedJson = data?.jsonData !== undefined && data?.jsonData !== null && convertToValidJson(data?.jsonData)
                let parsedJson = data?.jsonData ? JSON.parse(data.jsonData) : data.jsonData;
                if (parsedJson?.name === "AWS-Key") {
                  awsKeydata = {
                    // provider: parsedJson.provider,
                    provider: "AmazonS3",
                    type: parsedJson.type,
                    accessKey: parsedJson.accessKey,
                    secretKey: parsedJson.secretKey,
                    bucket: parsedJson.bucket,
                    folder: parsedJson.folder,
                    isZip: parsedJson.isZip,
                    name: parsedJson.name,
                    date: data.date
                  }
                  setAwsKeyData(awsKeydata)
                  setEnableOperationalIntegrations(prev => ({ ...prev, cloudStorage: true }))
                }
                else if (parsedJson?.name === "AWS-ARN") {
                  awsArndata = {
                    accessKey: parsedJson.accessKey,
                    secretKey: parsedJson.secretKey,
                    // provider: parsedJson.provider,
                    provider: "AmazonS3",
                    type: parsedJson.type,
                    bucket: parsedJson.bucket,
                    folder: parsedJson.folder,
                    roleArn: parsedJson.roleArn,
                    externalId: parsedJson.externalId,
                    isZip: parsedJson.isZip,
                    name: parsedJson.name,
                    date: data.date
                  }
                  setAwsArnData(awsArndata)
                  setEnableOperationalIntegrations(prev => ({ ...prev, cloudStorage: true }))
                }
                else if (parsedJson?.name === "GCP") {
                  const credJson = parsedJson?.credJson ? JSON.parse(parsedJson.credJson) : parsedJson;
                  console.log("credJson", credJson)
                  gcpdata = {
                    // provider: parsedJson.provider,
                    provider: "GoogleCloud",
                    type: parsedJson.type,
                    gcptype: credJson.type,
                    project_id: credJson.project_id,
                    private_key_id: credJson.private_key_id,
                    private_key: credJson.private_key,
                    client_email: credJson.client_email,
                    client_id: credJson.client_id,
                    auth_uri: credJson.auth_uri,
                    token_uri: credJson.token_uri,
                    auth_provider_x509_cert_url: credJson.auth_provider_x509_cert_url,
                    client_x509_cert_url: credJson.client_x509_cert_url,
                    bucket: parsedJson.bucket,
                    folder: parsedJson.folder,
                    isZip: parsedJson.isZip,
                    name: parsedJson.name,
                    date: data.date
                  }
                  setGcpData(gcpdata)
                  setEnableOperationalIntegrations(prev => ({ ...prev, cloudStorage: true }))
                }
                else if (parsedJson?.name === "Azure") {
                  azuredata = {
                    provider: parsedJson.provider,
                    type: parsedJson.type,
                    accountUrl: parsedJson.accountUrl,
                    credential: parsedJson.credential,
                    containerName: parsedJson.containerName,
                    folder: parsedJson.folder,
                    isZip: parsedJson.isZip,
                    name: parsedJson.name,
                    date: data.date
                  }
                  setAzureData(azuredata)
                  setEnableOperationalIntegrations(prev => ({ ...prev, cloudStorage: true }))
                }
              }
              else {
                console.log("No data related to cloudStorage found")
              }
            })
          }
          else {
            console.log(resp.Message);
          }
        })
        .catch((e) => {
          console.error(e);
          showErrorNotification("Error in getEnabled integrations list");
        });
    } catch (e) {
      showErrorNotification("Error in getEnabled integrations list");
    }
  };

  async function getIntegrationsData() {
    try {
      getAvailableIntegration()
        .then((resp) => {
          if (resp.Status === "Success") {
            setIntegrationList(resp.Data);
            localStorage.setItem("IntegrationData", JSON.stringify(resp.Data))
            getUserIntegrationsData();
          }
          else {
            showWarningNotification(resp.Message);
          }
        })
        .catch((e) => {
          setIntegrationList([]);
        });
    } catch (e) {
      showErrorNotification("Error in integrations list");
    }
  };

  const onClickIntegrationCard = (integrationItem) => {
    if (enabledIntegrations[integrationItem.name] === true || enabledOperationalIntegrations[integrationItem.name] === true) {
      if (integrationItem.name === "Zoho") {
        setZohoIntegration(true);
      }
      if (integrationItem.name === "Idesk") {
        if (ideskData.token !== undefined && ideskData.token !== null) {
          setIdeskIntegration(true);
        } else {
          showWarningNotification("Idesk Token is not available")
        }
      }
      if (integrationItem.name === "Netsapiens") {
        setNetsapiensIntegration(true);
      }
      if (integrationItem.name === "Balto") {
        setBaltoIntegration(true);
      }
      if (integrationItem.name === "Hubspot") {
        setHubspotIntegration(true);
      }
      if (integrationItem.name === "ssoLogin") {
        setSsoLogin(true);
      }
      if (integrationItem.name === "cloudStorage") {
        setCloudStorage(true);
      }
    }
  };

  const onClickCancelOfSsoLoginDialog = () => {
    setSsoLogin(false);
  }

  const onClickCancelOfCloudStorageDialog = () => {
    setCloudStorage(false);
  }

  function getAccessToShow() {
    if (newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, "Edit") || newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, "Full") || newAuthPages(NewAdminPages.INTEGRATION_SETTINGS)) {
      return true;
    } else {
      return false;
    }
  }

  const handleForceRevokeIntegration = async (integrationName) => {
    setLoading(true);
    try {
      const resp = await forceRevokeIntegration(integrationName);
      if (resp.Status === "Success") {
        showSuccessNotification(`${integrationName} Integration Revoked Successfully`);
        getUserIntegrationsData();
      } else {
        showErrorNotification(`Error in revoking ${integrationName} Integration`);
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="ozAdminIntegrationCardContainer">
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        !ideskIntegration && !zohoIntegration && !netsapiensIntegration && !baltoIntegration && !hubspotIntegration && <div>
          <Grid sx={{ marginBottom: "10px" }}>
            <Typography className="oz-page-header"> Settings / Integrations </Typography>
          </Grid>
          <Stack direction="row" sx={{ marginBottom: "32px" }}>
            <Typography className="oz-page-heading"> Integrations </Typography>
          </Stack>
          <TabContext value={tabValue}>
            <div style={{ display: 'flex', flexGrow: 1, width: "100%" }}>
              <TabList
                TabIndicatorProps={{
                  sx: { backgroundColor: "#008EFF", marginBottom: "1.125rem" },
                }}
                onChange={handleTabChange}
              >
                <OZCustomToggleButton value="1" sx={{ borderRight: "0px", ...toggleStyle }} >
                  CRM
                </OZCustomToggleButton>
                <OZCustomToggleButton value="2" sx={{ ...toggleStyle }}  >
                  Operational
                </OZCustomToggleButton>
              </TabList>
              <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6", marginTop: "-19px" }} />
              </span>
            </div>
          </TabContext>
          {crmTab &&
            <Grid container spacing="20px">
              {workingIntegrations.map((integration, index) => {
                const enableForceRelease = integration?.name === "Zoho";
                return (
                  <Grid item key={index}>
                    <Card className={classes.root}>
                      <Stack direction="row" justifyContent="space-between" alignItems='center' marginTop='24px' marginLeft='24px' marginRight="24px">
                        <Tooltip title={getAccessToShow() && enabledIntegrations[integration.name] ? "Click To Edit" : ""}>
                          <CardMedia onClick={() => getAccessToShow() ? `${onClickIntegrationCard(integration)}` : null}
                            className={(integration.name === "Zoho" && (enabledIntegrations[integration.name] ? classes.zohoImage : classes.zohoImageWithoutCursor)) || (integration.name === "Idesk" && (enabledIntegrations[integration.name] ? classes.ozonetelImage : classes.ozonetelImageWithoutCursor)) || (integration.name === "Netsapiens" && (enabledIntegrations[integration.name] ? classes.ozonetelImage : classes.ozonetelImageWithoutCursor)) || (integration.name === "Hubspot" && (enabledIntegrations[integration.name] ? classes.hubspotImage : classes.hubspotImageWithoutCursor)) || (integration.name === "Balto" && (enabledIntegrations[integration.name] ? classes.ozonetelImage : classes.ozonetelImageWithoutCursor))}
                            image={(integration.name === "Zoho" && zoho) || (integration.name === "Idesk" && ozonetel) || (integration.name === "Netsapiens" && netsapiens) || (integration.name === "Hubspot" && hubspot) || (integration.name === "Balto" && balto)}
                            alt="card"
                          />
                        </Tooltip>
                        <Stack direction={"row"} gap={.4}>
                          <Tooltip title={(getAccessToShow() && enabledIntegrations[integration.name]) ? "Toogle To Revoke" : (getAccessToShow() && !enabledIntegrations[integration.name]) ? "Toogle To Integrate" : ''}>
                            <div style={{ marginTop: "5px" }}>
                              <SimpleSwitch name={integration.name}
                                disabled={newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, "View")}
                                checked={enabledIntegrations[integration.name]}
                                onChange={e => { toggleIntegrationState(e, integration.name, integration) }}
                              />
                            </div>
                          </Tooltip>
                          {enableForceRelease && <Tooltip title={(getAccessToShow() && enabledIntegrations[integration.name]) ? "Force Revoke" : ''}>
                            <IconButton sx={{
                              width: "24px",
                              height: "24px",
                              padding: "0px",
                              margin: "0px",
                              fontSize: "0.5rem",
                              mt: 1.2
                            }} onClick={() => { handleForceRevokeIntegration(integration.name) }} style={{ display: (getAccessToShow() && enabledIntegrations[integration.name]) ? "block" : "none" }}>
                              <ResetIcon />
                            </IconButton>
                          </Tooltip>}
                        </Stack>
                      </Stack>
                      <Stack direction="column">
                        <Tooltip title={getAccessToShow() && enabledIntegrations[integration.name] ? "Click To Edit" : ""}>
                          <CardContent onClick={() => getAccessToShow() ? `${onClickIntegrationCard(integration)}` : null}>
                            <div>
                              <label className={(getAccessToShow() && enabledIntegrations[integration.name]) ? "integrationNameLabel" : (getAccessToShow() && !enabledIntegrations[integration.name]) ? "integrationNameLabelWithoutCursor" : "integrationNameLabelWithoutCursor"} style={{ color: (enabledIntegrations[integration.name]) ? "#3D8BF8" : "#7C878B" }}>
                                {integration.name}
                              </label>
                              <p className={enabledIntegrations[integration.name] ? classes.cardPara : classes.cardParaWithoutCursor}>An Online sales CRM software</p>
                            </div>
                          </CardContent>
                        </Tooltip>
                      </Stack>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          }
          {operationalTab &&
            <Grid container spacing="20px">
              {operationalIntegrations.map((integration, index) => {
                return (
                  <Grid item key={index}>
                    <Card className={classes.root}>
                      <Stack direction="row" justifyContent="space-between" alignItems='center' marginTop='24px' marginLeft='24px' marginRight="24px">
                        <Tooltip title={getAccessToShow() && enabledOperationalIntegrations[integration.name] ? "Click To Edit" : ""}>
                          <CardMedia
                            onClick={() => getAccessToShow() ? `${onClickIntegrationCard(integration)}` : null}
                            className={(integration.name === "ssoLogin" && (enabledOperationalIntegrations[integration.name] ? classes.ssoImage : classes.ssoImageWithoutCursor)) || (integration.name === "cloudStorage" && (enabledOperationalIntegrations[integration.name] ? classes.cloudStorageImage : classes.cloudStorageImageWithoutCursor))}
                            image={(integration.name === "ssoLogin" && ssoImage) || (integration.name === "cloudStorage" && cloudStorageImage)}
                            alt="card"
                          />
                        </Tooltip>
                        <Tooltip title={(getAccessToShow() && enabledOperationalIntegrations[integration.name]) ? "Toogle To Revoke" : (getAccessToShow() && !enabledOperationalIntegrations[integration.name]) ? "Toogle To Integrate" : ""}>
                          {/* <Tooltip title={enabledOperationalIntegrations[integration.name] ? "Toogle To Revoke" : "Toogle To Integrate"}> */}
                          <div style={{ marginTop: "5px" }}>
                            <SimpleSwitch name={integration.name}
                              disabled={newAuthSubAdminAccess(NewAdminPages.INTEGRATION_SETTINGS, "View")}
                              checked={enabledOperationalIntegrations[integration.name]}
                              onChange={e => { toggleIntegrationState(e, integration.name, integration) }}
                            />
                          </div>
                        </Tooltip>
                      </Stack>
                      <Stack direction="column">
                        <Tooltip title={(getAccessToShow() && enabledOperationalIntegrations[integration.name]) ? "Click To Edit" : ""}>
                          <CardContent
                            onClick={() => getAccessToShow() ? `${onClickIntegrationCard(integration)}` : null}
                          >
                            <div>
                              <label className={(getAccessToShow() && enabledOperationalIntegrations[integration.name]) ? "integrationNameLabel" : (getAccessToShow() && !enabledOperationalIntegrations[integration.name]) ? "integrationNameLabelWithoutCursor" : "integrationNameLabelWithoutCursor"} style={{ color: (enabledOperationalIntegrations[integration.name]) ? "#3D8BF8" : "#7C878B", cursor: '' }}>
                                {/* <label className={enabledOperationalIntegrations[integration.name] ? "integrationNameLabel" : "integrationNameLabelWithoutCursor"} style={{ color: (enabledOperationalIntegrations[integration.name]) ? "#3D8BF8" : "#7C878B" }}> */}
                                {integration.name === "ssoLogin" ? "SSO Login" : integration.name === "cloudStorage" ? "Cloud Storage" : null}
                              </label>
                            </div>
                          </CardContent>
                        </Tooltip>
                      </Stack>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          }
        </div>
      }
      {ideskIntegration && <IdeskIntegration setIdeskIntegration={setIdeskIntegration} ideskData={ideskData} getUserIntegrationsData={() => { getUserIntegrationsData() }} />}
      {zohoIntegration && <Integration setZohoIntegration={setZohoIntegration} zoho={true} hubspot={false} />}
      {hubspotIntegration && <Integration setHubspotIntegration={setHubspotIntegration} zoho={false} hubspot={true} />}
      {netsapiensIntegration && <NetsapiensIntegration setNetsapiensIntegration={setNetsapiensIntegration} netsapiensData={netsapiensData} getUserIntegrationsData={() => { getUserIntegrationsData() }} />}
      {baltoIntegration && <BaltoIntegration setBaltoIntegration={setBaltoIntegration} baltoData={baltoData} getUserIntegrationsData={() => { getUserIntegrationsData() }} />}
      {ssoLogin && <SSOLogin open={ssoLogin} onClickCancel={() => { onClickCancelOfSsoLoginDialog() }} data={ssoLoginData} getUserIntegrationsData={() => { getUserIntegrationsData() }} />}
      {cloudStorage && <CloudStorage open={cloudStorage} onClickCancel={() => { onClickCancelOfCloudStorageDialog() }} awskey={awsKeyData} awsArn={awsArnData} gcp={gcpData} azure={azureData} getUserIntegrationsData={() => { getUserIntegrationsData() }} />}
    </div>
  );
};