import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Chip, Dialog, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../../static/icons/add.svg';
import { ReactComponent as CloseIcon } from '../../../../MFAicons/close.svg';
import { ReactComponent as FileIcon } from '../../../../static/icons/file.svg';
import { ReactComponent as SearchIcon } from '../../../../static/icons/search.svg';
import { ReactComponent as DropdownIcon } from '../../../../static/icons/dropdown.svg';
import { ReactComponent as PreviewIcon } from '../../../../static/icons/preview.svg';
import { convertBytes } from '../../../../utils/util';
import FileDropZone from './FileDropZone';
import OZCheckbox from '../../../../components/OZCheckbox/OZCheckbox';
import './files.css';
import OZGridToolbar from '../../../../components/admin/common/OZDataGrid/OZGridToolbar';
import OZDataGrid from '../../../../components/admin/common/OZDataGrid/OZDataGrid';

export default function Files({ activeTab }) {
    const [files, setFiles] = useState([]);

    async function getFiles() {
        try {
            // const response = await fetch('https://api.example.com/files');
            // const data = await response.json();
            setFiles([
                {
                    name: 'File 1',
                    tags: ['Google', 'Facebook', 'Twitter', 'WhatsApp', 'Instagram', 'LinkedIn', 'Snapchat', 'Pinterest', 'Reddit', 'Tumblr', 'YouTube',],
                    total: 100,
                    failedRecords: 10,
                },
                {
                    name: 'File 2',
                    tags: ['WhatsApp'],
                    total: 200,
                    failedRecords: 20,
                },
                {
                    name: 'File 1',
                    tags: ['Google', 'Facebook', 'Twitter', 'WhatsApp', 'Instagram', 'LinkedIn', 'Snapchat', 'Pinterest', 'Reddit', 'Tumblr', 'YouTube',],
                    total: 100,
                    failedRecords: 10,
                },
                {
                    name: 'File 2',
                    tags: ['WhatsApp'],
                    total: 200,
                    failedRecords: 20,
                },
                {
                    name: 'File 1',
                    tags: ['Google', 'Facebook', 'Twitter', 'WhatsApp', 'Instagram', 'LinkedIn', 'Snapchat', 'Pinterest', 'Reddit', 'Tumblr', 'YouTube',],
                    total: 100,
                    failedRecords: 10,
                },
                {
                    name: 'File 2',
                    tags: ['WhatsApp'],
                    total: 200,
                    failedRecords: 20,
                },
                {
                    name: 'File 1',
                    tags: ['Google', 'Facebook', 'Twitter', 'WhatsApp', 'Instagram', 'LinkedIn', 'Snapchat', 'Pinterest', 'Reddit', 'Tumblr', 'YouTube',],
                    total: 100,
                    failedRecords: 10,
                },
                {
                    name: 'File 2',
                    tags: ['WhatsApp'],
                    total: 200,
                    failedRecords: 20,
                }, {
                    name: 'File 1',
                    tags: ['Google', 'Facebook', 'Twitter', 'WhatsApp', 'Instagram', 'LinkedIn', 'Snapchat', 'Pinterest', 'Reddit', 'Tumblr', 'YouTube',],
                    total: 100,
                    failedRecords: 10,
                },
                {
                    name: 'File 2',
                    tags: ['WhatsApp'],
                    total: 200,
                    failedRecords: 20,
                }

            ]);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (activeTab === 'Files') {
            getFiles();
        }
    }, [activeTab]);

    return (activeTab === "Files" ?
        <Box className='oz-data-management-files-container'>
            <FiledHeader />
            <FilesList files={files} />
        </Box> : null
    )
}

const FiledHeader = () => {

    const [openAddForm, setOpenAddForm] = useState(false);

    return <Box className="oz-data-management-files-header">
        <Typography className="oz-data-management-title">
            Files
        </Typography>
        <Button
            className="oz-data-management-files-upload-button"
            type="primary"
            variant='contained'
            startIcon={<AddIcon />}
            onClick={() => setOpenAddForm(true)}
        >
            Add File
        </Button>
        {openAddForm && <FilesForm open={openAddForm} handleClose={setOpenAddForm} />}
    </Box>
}

const FilesForm = ({
    open = false,
    handleClose,
    data = { name: '', tags: [], file: null },
}) => {
    console.log(data);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedFile, setSelectedFile] = useState(data.name ? data : null);

    useEffect(() => {
        async function getTags() {
            try {
                const response = ['Google', 'Facebook', 'WhatsApp'];
                if (data.tags) {
                    let combinedTags = [...response, ...data.tags];
                    setTags(Array.from(new Set(combinedTags)));
                    setSelectedTags(data.tags);
                } else {
                    setTags(response);
                }
            } catch (error) {
                console.error(error);
            }
        }
        getTags();
    }, []);

    const handleCloseDialog = () => {
        if (handleClose) {
            handleClose(false);
        }
    }

    return <Dialog open={open} onClose={handleCloseDialog} maxWidth='xl' PaperProps={{ className: 'oz-data-management-files-add-form-dialog' }}>
        <Box className='oz-data-management-files-add-form-header'>
            <Typography className="oz-data-management-files-add-form-title">
                Add File
            </Typography>
            <IconButton onClick={handleCloseDialog} size='small'>
                <CloseIcon />
            </IconButton>
        </Box>
        <Box className="oz-data-management-files-add-form-content">
            {selectedFile && selectedFile.name ? <Box className="oz-data-management-files-add-form-content-item">
                <FileIcon />
                <Box className="oz-data-management-files-add-form-content-item-file-information">
                    <Box className="oz-data-management-files-add-form-content-item-file-name-container">
                        <Typography className="oz-data-management-files-add-form-content-item-file-name">
                            {selectedFile.name}
                        </Typography>
                        <IconButton onClick={() => setSelectedFile(null)} className="oz-data-management-files-add-form-content-item-file-close-button">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {selectedFile.size && <Typography className="oz-data-management-files-add-form-content-item-file-size">
                        {convertBytes(selectedFile.size)}
                    </Typography>}
                </Box>
            </Box> : <FileDropZone setFileData={setSelectedFile} />}
            <Box className="oz-data-management-files-add-form-content-item-tags-container">
                <Box className="oz-data-management-files-add-form-content-item-title-tags-container">
                    <Typography className="oz-data-management-files-add-form-content-item-title-tags">
                        Tags
                    </Typography>
                    <IconButton className="oz-data-management-files-add-form-content-item-title-tags-add-button">
                        <AddIcon className='oz-data-management-files-add-form-content-item-title-tags-add-icon' />
                    </IconButton>
                </Box>
                <Typography className="oz-data-management-files-add-form-content-item-title-tags-description">
                    Tag let you add keywords, making them easier to find and browse
                </Typography>
                <TagAutoComplete tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} setTags={setTags} />
            </Box>
        </Box>
        <Box className="oz-data-management-files-add-form-buttons">
            <Button
                variant='outlined'
                className="oz-data-management-files-add-form-cancel-button"
                onClick={handleCloseDialog}
            >
                Cancel
            </Button>
            <Button
                variant='contained'
                className="oz-data-management-files-add-form-submit-button"
                onClick={handleCloseDialog}
            >
                Save
            </Button>
        </Box>
    </Dialog>
}
const TagAutoComplete = ({ tags, selectedTags, setSelectedTags, setTags }) => {
    const [inputValue, setInputValue] = useState('');

    const handleCreateNewTag = (newTag) => {
        const newTags = [...tags, newTag];
        setTags(newTags);
        setSelectedTags([...selectedTags, newTag]);
    };
    console.log(tags, selectedTags)
    return (
        <Autocomplete
            multiple
            loading={tags.length === 0}
            id="oz-tb-tags-filled"
            options={tags}
            value={selectedTags}
            componentsProps={{
                paper: {
                    sx: {
                        border: "1px solid #D6D6D7", borderRadius: "8px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"
                    }
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            popupIcon={< DropdownIcon />}
            size='small'
            renderOption={(props, option, { selected }) => {
                const newValue = option.includes(' (Create New)') ? option.split(' (Create New)')[0] : option;
                return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '3px 0px 0px 0px', justifyContent: newValue ? 'center' : 'flex-start', gap: '4px' }} >
                        <OZCheckbox checked={selected} />
                    </Box>
                    <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                        {option}
                    </Typography>
                </li>
            }}
            clearIcon={null}
            autoComplete={true}
            disableCloseOnSelect={true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Add Tag"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #D6D6D7",
                                borderRadius: "8px",
                                // borderBottomLeftRadius: "0px",
                                // borderBottomRightRadius: "0px"
                            },
                            '&:hover fieldset': {
                                border: "1px solid #D6D6D7",
                                borderRadius: "8px",
                                // borderBottomLeftRadius: "0px",
                                // borderBottomRightRadius: "0px"
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #D6D6D7",
                                borderRadius: "8px",
                                borderBottomLeftRadius: "0px",
                                borderBottomRightRadius: "0px"
                            },
                        },
                    }}
                />
            )}
            filterOptions={(options, state) => {
                const filtered = options.filter(option =>
                    option.toLowerCase().includes(state.inputValue.toLowerCase())
                );
                if (state.inputValue !== '' && !filtered.includes(state.inputValue)) {
                    filtered.push(`${state.inputValue} (Create New)`);
                }
                return filtered;
            }}
            onChange={(event, newValue, reason) => {
                if (reason === 'selectOption' && newValue.includes(`${inputValue} (Create New)`)) {
                    handleCreateNewTag(inputValue);
                } else {
                    setSelectedTags(newValue);
                }
            }}
            renderTags={(value, getTagProps) =>
                value.map((option: string, index: number) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (<>
                        {index === 0 && <SearchIcon style={{
                            width: '16px',
                            height: '16px',
                            margin: '0px 4px',
                            color: '#D6D6D7'
                        }} />}
                        <Chip variant="contained"
                            sx={index % 2 === 0 ? {
                                backgroundColor: '#FFF9F0',
                                color: '#E7AF5B'
                            } : {
                                backgroundColor: '#E3F8DC',
                                color: '#4F9F52'
                            }}
                            label={option} key={key} {...tagProps} />
                    </>
                    );
                })
            }
        />
    );
};

const FilesList = ({ files }) => {

    const [filesGridData, setFilesGridData] = useState(null);

    const TagRenderer = (fileRowData) => {

        let chipWidth = 90;
        let totalWidthNeededForChips = chipWidth * fileRowData.value.length;
        let columnWidth = 300;
        let maxChips = Math.floor(columnWidth / chipWidth);
        if (totalWidthNeededForChips > columnWidth) {
            maxChips = Math.floor(columnWidth / chipWidth) - 1;
        }

        const isEvenRow = fileRowData.rowIndex % 2 === 0;

        return (
            <Box >
                {fileRowData.value.map((campaign, index) => (
                    (index < maxChips) ?
                        <Tooltip title={campaign} key={index}>
                            <Chip
                                sx={index % 2 === 0 ? {
                                    backgroundColor: '#FFF9F0',
                                    color: '#E7AF5B'
                                } : {
                                    backgroundColor: '#E3F8DC',
                                    color: '#4F9F52'
                                }}
                                size='small'
                                key={index} label={campaign} style={{ margin: '2px' }} />
                        </Tooltip>
                        : null
                ))}
                {fileRowData.value.length > maxChips ?
                    <Tooltip
                        title={
                            <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                {fileRowData.value.slice(maxChips).map((campaign) => campaign).join(', ')}
                            </div>
                        }
                    >
                        <Chip
                            size='small'
                            sx={isEvenRow ? {
                                backgroundColor: '#FFF9F0',
                                color: '#E7AF5B'
                            } : {
                                backgroundColor: '#E3F8DC',
                                color: '#4F9F52'
                            }}
                            label={`+${fileRowData.value.length - maxChips}`}
                        />
                    </Tooltip>
                    :
                    null}
            </Box>
        )
    }

    const PreviewFile = (filedRowData) => {
        const [openPreview, setOpenPreview] = useState(false);

        const handleOpenPreview = () => {
            setOpenPreview(true);
        }
        return (
            <Box className="oz-data-management-files-preview-icon-container">
                <IconButton className="oz-data-management-files-preview-icon" onClick={handleOpenPreview}>
                    <PreviewIcon />
                </IconButton>
                {openPreview && <FilesForm open={openPreview} handleClose={setOpenPreview} data={filedRowData.data} />}
            </Box>
        );
    }

    const TextRenderer = (fileRowData) => <Typography className="oz-data-management-files-name">
        {fileRowData.value}
    </Typography>

    const columnDefs = [
        {
            field: "name",
            headerName: "Name",
            cellRenderer: TextRenderer
        },
        {
            field: "tags",
            headerName: "Tags",
            cellRenderer: TagRenderer,
            minWidth: 300,
            maxWidth: 300,
        },
        {
            field: "total",
            headerName: "No. of Records",
            cellRenderer: TextRenderer

        },
        {
            field: "failedRecords",
            headerName: "Failed Records",
            cellRenderer: TextRenderer
        },
        {
            field: "Preview",
            headerName: "Preview",
            cellRenderer: PreviewFile,
        },
    ];

    const onCLickRow = (row) => {
        console.log(row);
    }

    return files.length > 0 ? <Box className='oz-data-management-files-list-container'>
        <OZGridToolbar
            title='List of Files'
            searchPlaceholder='Reason'
            rowCount={files.length}
            dataGridRef={filesGridData}
            fileName='files'
        />
        <OZDataGrid
            height={"26.3rem"}
            columns={columnDefs}
            data={files}
            setGridRef={setFilesGridData}
            rowClickCallback={onCLickRow}
        />
    </Box>
        :
        <Box className="oz-data-management-files-no-files">
            <Typography className="oz-data-management-files-no-files-text">
                No files
            </Typography>
        </Box>
}


