// interfaces.ts
import { SelectChangeEvent } from '@mui/material/Select';

// Campaign Types
export type CampaignType = 'SMS' | 'WhatsApp';  // Back to uppercase versions
export type ApiCampaignType = 'sms' | 'whatsapp';
export type MessageType = 'Simple' | 'Bulk';
export type CategoryType = 'simple' | 'dynamic';

// Template Types
export type SMSTemplateType = 'promotional' | 'transactional' | 'service_implicit' | 'service_explicit';
export type WhatsAppTemplateType = 'document' | 'image' | 'video' | 'text';
export type TemplateType = SMSTemplateType | WhatsAppTemplateType;

export interface AdvancedFilters {
  category: string;
  position: string;
}


// First, update the Template interface in interfaces.ts
export interface Template {
  id: number;
  name: string;
  text: string;
  templateId: string;
  type: TemplateType;        // Changed from separate category and templateType
  sender: string;            // Changed from senderId
  variables: Record<string, string>;
  approved: boolean;
  approvedBy: number;
  approvedOn: string;
  createdBy: number;
  createdOn: string;
  updatedBy: number | null;
  updatedOn: string | null;
  deleted: boolean;
  deletedBy: number | null;
  deletedOn: string | null;
  userId: number;
  userName: string;
}

export interface SMSVendor {
  sms_provider: string;
  username: string;
}

 // Add CampaignFormProps interface
export interface CampaignFormProps {
  type: CampaignType;
  formData: CampaignFormData;
  fieldErrors: ValidationErrors;
  setFieldErrors: (errors: ValidationErrors) => void;
  onFormChange: (field: keyof CampaignFormData) => (event: FormEventType) => void;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  onCancel: () => void;
  templateType?: TemplateType;
  isSubmitting?: boolean;
  submitError?: string | null;
  fileUpload: File | null;  // Make this required
  whatsAppFile: File | null; // Add this line
}

export interface ValidationResult {
  isValid: boolean;
  errors: ValidationErrors;
  error?: string;  // General error message
  fieldErrors?: FormError[];  // Detailed field errors
}

// Column Definition Interface
export interface ColumnDef {
  id: string;
  label: string;
  visible: boolean;
}

// Filter State Interface
export interface FilterState {
  inBound: boolean;
  chat: boolean;
}

// Tab Type Interface
export interface TabType {
  id: string;
  label: string;
}

// Download Option Interface
export interface DownloadOption {
  id: string;
  label: string;
}

export const formatScheduleDate = (date: string): string => {
  return new Date(date).toISOString().split('T')[0];
};


// Campaign Form Data Interface
export interface CampaignFormData {
  campaignName: string;
  messageType: MessageType;
  category: CategoryType;
  templateType: TemplateType;
  numbers?: string;
  senderId: string;
  templateId: string;
  templateName: string;
  templateText: string;
  templateLanguage?: string; // Add this line
  schedule: boolean;
  startDate: string;
  endDate: string;
  runTimeStart: string;
  runTimeEnd: string;
  fileUpload: File | null;  // Make sure this is explicitly typed
  whatsAppFile: File | null; // Add this line
  allowDuplicate: boolean;
  isWhatsappFlow?: boolean;
  callbackUrl?: string;
  extraData: Record<string, any>;
  smsVendor?: string; // Add this field
}


// Campaign Interface with updated types
export interface Campaign {
  id: number;
  name: string;
  campaignName: string;
  type: CampaignType;
  category: CategoryType;
  templateType: TemplateType;
  position: 'running' | 'stopped' | 'completed' | null;
  schedule: {
    scheduled: boolean;
    startDate: string;
    endDate: string;
    start: string;
    end: string;
  };
  sender: string;
  senderId?: string;
  templateId?: string;
  templateName: string;
  templateText: string;
  templateLanguage?: string; // Add this line
  bodyText?: string;
  headerText?: string;
  footerText?: string;
  did?: string;
  approved: boolean;
  createdBy: number;
  createdOn: string;
  updatedBy: number | null;
  updatedOn: string | null;
  deleted: boolean;
  deletedBy: number | null;
  deletedOn: string | null;
  userId: number;
  userName: string;
  username?: string;
  allowDuplicate: boolean;
  numbers: string | null;
  callbackUrl: string | null;
  quartzJobId: string | null;
  extraData: Record<string, any>;
  variables: Record<string, string>;
  modifiedOn?: string;
  counts: {
      uploaded: number;
      sent: number;
  };
}


// Campaign Request Interface
export interface Campaign_Request {
  name: string;
  type: 'sms' | 'whatsapp';
  category: CategoryType;
  templateType: TemplateType;
  schedule: {
    scheduled: boolean;
    startDate: string;
    endDate: string;
    start: string;
    end: string;
  };
  sender: string;
  templateName: string;
  templateText: string;
  templateLanguage?: string; // Add this line
  allowDuplicate: boolean;
  numbers?: string;
  callbackUrl?: string;
  extraData: Record<string, any>;
  vendor?: string; // Add this field
}

export interface ValidationErrors {
  name?: string;
  type?: string;
  category?: string;
  templateType?: string;
  sender?: string;
  templateName?: string;
  templateText?: string;
  schedule?: {
    startDate?: string;
    endDate?: string;
    start?: string;
    end?: string;
  };
  [key: string]: any;
}

export interface ValidationResult {
  isValid: boolean;
  errors: ValidationErrors;
}

// Sender Interface
export interface Sender {
  id: number;
  name: string;
  entityId: string;
  approved: boolean;
  approvedBy: number;
  approvedOn: string;  // Remove optional
  createdBy: number;   // Remove optional
  createdOn: string;   // Remove optional
  updatedBy: number;
  updatedOn: string;
  deleted: boolean;
  deletedBy: number;
  deletedOn: string;
  userId: number;
  username: string;
}

// Pagination Interface
export interface PaginationParams {
  page: number;
  size: number;
}

// Response Pagination Interface
export interface PaginatedResponse<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
}

// Helper function to convert campaign type
export const convertCampaignType = (type: CampaignType | null): ApiCampaignType => {
  if (!type) return 'sms';
  return type === 'SMS' ? 'sms' : 'whatsapp';
};

// Helper function to get template name display
export const getTemplateDisplay = (template: Template | null, campaignType: CategoryType) => {
  if (!template) return '';
  return `${template.name} (${campaignType})`;
};


// Update the convertSchedule function
export const convertSchedule = (formData: CampaignFormData) => {
  const today = new Date();
  const formatTimeForAPI = (time: string): string => {
    if (!time) return '09:00:00';
    return time.includes(':') ? `${time}:00` : `${time}:00:00`;
  };

  // If end time is 21:00, adjust it to 20:59:59
  let endTime = formData.runTimeEnd || '20:59';
  if (endTime === '21:00') {
    endTime = '20:59';
  }

  return {
    scheduled: true,
    startDate: formatScheduleDate(today.toISOString()),
    endDate: formatScheduleDate(today.toISOString()),
    start: formatTimeForAPI(formData.runTimeStart || '09:00'),
    end: formatTimeForAPI(endTime) + ':59' // Add seconds
  };
};

export const convertApiType = (type: ApiCampaignType): CampaignType => {
  return type === 'whatsapp' ? 'WhatsApp' : 'SMS';
};

export const convertToApiType = (type: CampaignType): ApiCampaignType => {
  return type === 'WhatsApp' ? 'whatsapp' : 'sms';
};

// Update the convertTemplateType function
export const convertTemplateType = (templateType: string): TemplateType => {
  return templateType.toLowerCase() as TemplateType;
};

// Utility type for handling form events
export type FormEventType = 
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | SelectChangeEvent<string>;

  export const createSyntheticEvent = (
    value: string | boolean,
    currentTarget: EventTarget & HTMLElement,
    isCheckbox: boolean = false
  ): FormEventType => {
    const event: any = {
      target: {
        value: isCheckbox ? String(value) : value,
        checked: isCheckbox ? Boolean(value) : undefined,
        name: currentTarget.getAttribute('name') || '',
        type: isCheckbox ? 'checkbox' : 'text'
      },
      currentTarget,
      nativeEvent: new Event('change'),
      preventDefault: () => {},
      stopPropagation: () => {},
      bubbles: true,
      cancelable: true,
      defaultPrevented: false,
      eventPhase: Event.AT_TARGET,
      isTrusted: true,
      timeStamp: Date.now(),
      type: 'change'
    };
    
    return event as FormEventType;
  };
// Adding convertSenderData utility function
export const convertSenderData = (sender: any): Sender => ({
  id: sender.id,
  name: sender.name,
  entityId: sender.entityId,
  approved: sender.approved,
  approvedBy: sender.approvedBy,
  approvedOn: sender.approvedOn,
  createdBy: sender.createdBy,
  createdOn: sender.createdOn,
  updatedBy: sender.updatedBy,
  updatedOn: sender.updatedOn,
  deleted: sender.deleted,
  deletedBy: sender.deletedBy,
  deletedOn: sender.deletedOn,
  userId: sender.userId,
  username: sender.username
});
// Add these interfaces to interfaces.ts
export interface PageableInfo {
  pageNumber: number;
  pageSize: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface PaginatedData<T> {
  content: T[];
  pageable: PageableInfo;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface ApiResponse<T> {
  status: 'success' | 'error';
  message: string | null;
  code: number | null;
  data: T | null;
  errors: {
    [key: string]: string[];
  } | null;
}

// Add FormError interface for consistent error handling
export interface FormError {
  field: string;
  message: string;
}


export interface Sort {
  empty: boolean;
  sorted: boolean;
  unsorted: true;
}

// Add helper function to map API errors to form fields
export const mapApiErrorsToFormFields = (apiErrors: Record<string, string[]>): ValidationErrors => {
  const fieldErrors: ValidationErrors = {};
  
  Object.entries(apiErrors).forEach(([key, messages]) => {
    const message = Array.isArray(messages) ? messages[0] : messages;
    
    // Map API field names to form field names
    switch (key) {
      case 'name':
        fieldErrors.campaignName = message;
        break;
      case 'sender_id':
        fieldErrors.senderId = message;
        break;
      case 'template_id':
        fieldErrors.templateId = message;
        break;
      case 'template_text':
        fieldErrors.templateText = message;
        break;
      case 'numbers':
        fieldErrors.numbers = message;
        break;
      default:
        fieldErrors[key] = message;
    }
  });
  
  return fieldErrors;
};
// Add helper function to convert API response to Template
export const convertTemplate = (template: any): Template => ({
  id: Number(template.id),
  name: template.name,
  text: template.text,
  templateId: template.templateId,
  type: template.type as TemplateType,
  sender: template.sender,
  variables: template.variables || {},
  approved: Boolean(template.approved),
  approvedBy: Number(template.approvedBy),
  approvedOn: template.approvedOn,
  createdBy: Number(template.createdBy),
  createdOn: template.createdOn,
  updatedBy: template.updatedBy ? Number(template.updatedBy) : null,
  updatedOn: template.updatedOn || null,
  deleted: Boolean(template.deleted),
  deletedBy: template.deletedBy ? Number(template.deletedBy) : null,
  deletedOn: template.deletedOn || null,
  userId: Number(template.userId),
  userName: template.userName
});

// Add helper function to validate form data
export const validateFormData = (data: CampaignFormData): ValidationResult => {
  const errors: ValidationErrors = {};
  const fieldErrors: FormError[] = [];

  // Campaign Name validation
  if (!data.campaignName) {
    errors.campaignName = "Campaign name is required";
    fieldErrors.push({ field: 'campaignName', message: "Campaign name is required" });
  } else if (data.campaignName.length < 3 || data.campaignName.length > 50) {
    errors.campaignName = "Campaign name should be between 3 and 50 characters";
    fieldErrors.push({ 
      field: 'campaignName', 
      message: "Campaign name should be between 3 and 50 characters" 
    });
  } else if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(data.campaignName)) {
    errors.campaignName = "Campaign name should be alphanumeric and underscore only";
    fieldErrors.push({ 
      field: 'campaignName', 
      message: "Campaign name should be alphanumeric and underscore only" 
    });
  }

  // Sender ID validation
  if (!data.senderId) {
    errors.senderId = "Sender ID is required";
    fieldErrors.push({ field: 'senderId', message: "Sender ID is required" });
  }

  // Template ID validation
  if (!data.templateId) {
    errors.templateId = "Template ID is required";
    fieldErrors.push({ field: 'templateId', message: "Template ID is required" });
  }

  // Template Text validation
  if (!data.templateText?.trim()) {
    errors.templateText = "Template text is required";
    fieldErrors.push({ field: 'templateText', message: "Template text is required" });
  }

  // Numbers validation for Simple Message Type
  if (data.messageType === 'Simple' && !data.numbers) {
    errors.numbers = "Numbers should be in comma separated format";
    fieldErrors.push({ 
      field: 'numbers', 
      message: "Numbers should be in comma separated format" 
    });
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors,
    fieldErrors,
    error: Object.values(errors)[0]  // First error message as general error
  };
};

export const validateWhatsAppVariables = (
  templateData: string,
  variables: Record<string, any>
): Record<string, string> => {
  const errors: Record<string, string> = {};
  
  try {
    const template = JSON.parse(templateData);
    
    // Validate header variables
    const headerSection = template.find((section: any) => section.type === 'HEADER');
    if (headerSection) {
      const headerType = headerSection.format.toLowerCase();
      const headerVarName = `header_${headerType}`;
      
      if (!variables[headerVarName]) {
        errors.header = `${headerSection.format.toLowerCase()} is required`;
      }
      
      // Additional media type validations
      if (variables[headerVarName] instanceof File) {
        const file = variables[headerVarName] as File;
        
        switch (headerType) {
          case 'image':
            if (!file.type.startsWith('image/')) {
              errors.header = 'Invalid image file type';
            } else if (file.size > 5 * 1024 * 1024) {
              errors.header = 'Image size should be less than 5MB';
            }
            break;
            
          case 'video':
            if (!file.type.startsWith('video/')) {
              errors.header = 'Invalid video file type';
            } else if (file.size > 16 * 1024 * 1024) {
              errors.header = 'Video size should be less than 16MB';
            }
            break;
            
          case 'document':
            const validDocTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
            if (!validDocTypes.includes(file.type)) {
              errors.header = 'Invalid document type. Only PDF and DOC files are allowed';
            } else if (file.size > 10 * 1024 * 1024) {
              errors.header = 'Document size should be less than 10MB';
            }
            break;
        }
      }
    }
    
    // Validate body variables
    const bodySection = template.find((section: any) => section.type === 'BODY');
    if (bodySection?.example?.body_text?.[0]) {
      const requiredVars = bodySection.example.body_text[0].length;
      
      for (let i = 1; i <= requiredVars; i++) {
        const varName = `body_${i}`;
        if (!variables[varName] || variables[varName].trim() === '') {
          errors.body = `All template variables must be filled`;
          break;
        }
      }
    }
  } catch (e) {
    errors.template = 'Invalid template format';
  }
  
  return errors;
};

/**
 * Formats a schedule time string to include seconds based on specific rules:
 * - Regular times get ':00' seconds (e.g., '09:00' → '09:00:00')
 * - End times of '23:59' get ':59' seconds (→ '23:59:59')
 * - End times of '20:59' get ':59' seconds (→ '20:59:59')
 * - Already formatted times (containing seconds) are returned as-is
 */
export const formatScheduleTime = (time: string, isEndTime: boolean = false): string => {
  // If time already has seconds, return as-is
  if (time.split(':').length === 3) {
    return time;
  }

  // For special end times, append :59 seconds
  if (isEndTime && (time === '23:59' || time === '20:59')) {
    return `${time}:59`;
  }

  // For regular times, append :00 seconds
  return `${time}:00`;
};

/**
 * Validates and formats schedule start and end times.
 */
export const formatScheduleTimes = (schedule: { 
  start: string; 
  end: string; 
}): { start: string; end: string } => {
  return {
    start: formatScheduleTime(schedule.start),
    end: formatScheduleTime(schedule.end, true)
  };
};
