import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { TabContext, TabList } from '@mui/lab';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Badge,
	Box,
	Card,
	Chip,
	Grid,
	LinearProgress,
	Menu,
	MenuItem,
	Popover,
	Stack,
	Tab,
	Theme,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as React from 'react';
import { getTopDispositionAndCount, getTopDispositionAndCountTable, getWidgetConfig } from '../../../services/api-service';
import { getLastFetchedDetail } from '../../../services/application-service';
import { ReactComponent as GraphIcon } from '../../../static/icons/graph.svg';
import { ReactComponent as TableIcon } from '../../../static/icons/table.svg';
import { filterTypeMapping, filterTypes, WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import '../TopDisposition/TopDisposition.css';
import OZTable from '../../OZTable/OZTable';
import useChartResize from '../../../hooks/useChartResize';
import { checkDataAccessFilters, determineFilterType } from '../../../utils/util';

// Highcharts.seriesTypes.area.prototype.drawLegendSymbol =
//      Highcharts.seriesTypes.line.prototype.drawLegendSymbol;
// const defaultFilters = [
// 	{ id: 1, label: 'General', color: '#EE4060' },
// 	{ id: 2, label: 'Canada Sales', color: '#EEBE40' },
// 	{ id: 3, label: 'Support', color: '#B0CFA5' },
// 	{ id: 4, label: 'English Sales', color: '#53B9ED' },
// 	{ id: 5, label: 'Premium Plus', color: '#B2A1F9' }
// ];

const useStyles: any = makeStyles((theme: Theme) => ({
	filterItem: {
		fontSize: 12,
		borderRadius: 20,
		background: 'rgba(0, 142, 255, 0.1)',
		border: '1px solid #008eff',
		padding: `0 ${theme.spacing(2)}`,
		minHeight: 22
	},
	itemIndicator: {
		width: 5,
		height: 5,
		borderRadius: 1,
		display: 'inline-block',
		position: 'relative',
		top: '-2px',
		marginRight: 4
	},
	filterItemText: {
		margin: 0,
		color: '#536580'
	},
	filterItemSubText: {
		color: '#99A0A8'
	},
	filterItemClose: {
		maxHeight: 14
	},
	popoverContent: {
		minWidth: 350,
		fontSize: '14px',
		lineHeight: 2
	}
}));

let interval: any;
const initialFilterType = 1;

function TopDisposition(props: any) {
	const classes = useStyles();
	const { useState, useRef } = React;
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [dispositionCount, setDispositionCount] = useState<any>('5');
	const [graphData, setGraphData] = useState<any>([]);
	const [showGraphData, setShowGraphData] = useState<Boolean>(true);
	const [graphCategories, setGraphCategories] = useState<any>([]);
	// const [duration, setDuration] = useState<string>('Today');
	const [duration, setDuration] = useState<string>('');
	const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
	const [selectedPoint, setSelectedPoint] = useState<any>(null);
	const [graphRefPoint, setGraphRefPoint] = useState<any>(null);
	const [callType, setCallType] = useState<string>('all');
	const [topDispositionFilter, setTopDispositionFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || (checkDataAccessFilters() ? 1 : initialFilterType));
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const downloadContent = useRef<null | HTMLElement>(null);
	const { chartContainerRef, chartDimensions, chartElementRef, redrawChart } = useChartResize();
	const open = Boolean(anchorEl);
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);

	const handleDuration = (type: string = '') => {
		if (type) {
			setDuration(type);
		}
		setAnchorEl(null);
	};
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		if (event.currentTarget) setAnchorEl(event.currentTarget);
	};

	// const chartElement: any = useRef(null);
	const clearFilter = () => {
		setFilterType(initialFilterType);
		setTopDispositionFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = topDispositionFilter.filter((item: any) => item.id !== id);
		setTopDispositionFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = topDispositionFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retriveGraphData = async (filters = '0') => {
		const data = await getTopDispositionAndCount({ filters, type: filterType, callType, disposition: '0', limit: dispositionCount });
		const { formattedGraphData, graphCategories } = updateSeriesFilter(data);
		// console.log("my formatted data is ->",formattedGraphData[0].data);
		// console.log("my graphCategories data is ->",graphCategories);
		setGraphData(formattedGraphData);
		setGraphCategories(graphCategories);
		// chartElement?.current?.chart?.redraw();
		redrawChart();
	};

	const retrieveTableData = async (filters = '0') => {
		let aggregatedData : any;
		if(topDispositionFilter && topDispositionFilter.length){
			const data = await getTopDispositionAndCountTable({ filters, type: filterType, callType, disposition: '0', limit: '100' });
			aggregatedData = aggregateMainDispositions(data.slice(0, dispositionCount));
		}else{
			try{
				const data = await getTopDispositionAndCount({ filters, type: filterType, callType, disposition: '0', limit: dispositionCount });
				const { formattedGraphData, graphCategories } = updateSeriesFilter(data);
				aggregatedData = formattedGraphData && formattedGraphData.length > 0 && formattedGraphData[0]?.data;
			}catch(e){
				console.log("error in setting data in Table of Top Disposition", e);
				aggregatedData = [];
			}
		}
		const sortedData: any[] = aggregatedData;
		sortedData.sort((a, b) => a.SNO - b.SNO);


		const columns = [];
		if (topDispositionFilter.length) {
			const filterKey: any = filterTypes.find((item: any) => item.id === +filterType) ?? filterTypes[0];
			const filterValueKey = `${filterKey.value}Name`;
			columns.push({ name: `${filterKey.value} Name`, key: filterValueKey });
		}
		columns.push(
			...[
				{ name: 'Disposition', key: 'MainDisposition' },
				{ name: 'Count', key: 'formattedTotalCalls' }
			]
		);
		for (const row of sortedData) {
			row.formattedTotalCalls = (
				<>
					<span className="disposition-count" onClick={(event) => handlePointClick(event.currentTarget, row)}>
						{row.TotalCalls}
						<KeyboardArrowDownOutlinedIcon style={{ color: '#008eff', fontSize: '12px' }} />
					</span>
				</>
			);
		}
		setRowData(sortedData);
		setColumnData(columns);
	};

	const handleDispositionCount = (event: React.MouseEvent<HTMLElement>, newCount: string) => {
		setDispositionCount(newCount);
	};

	const handleOnPopoverClose = () => {
		setGraphRefPoint(null);
		setSelectedPoint(null);
		setIsPopoverOpen(false);
	};

	const handlePointClick = (point: any, data = null) => {
		setSelectedPoint(data || point);
		setGraphRefPoint(point?.graphic?.element || point);
		setIsPopoverOpen(true);
	};

	const aggregateMainDispositions = (dispositionData: any): any[] => {
		const mainDispositionCounts: any = {};
		const subDispositionsKey = ['SubDisposition1', 'SubDisposition2', 'SubDisposition3'];
		for (const data of dispositionData) {
			const itemSubDisposition = [];
			if (!mainDispositionCounts[data.MainDisposition]) {
				mainDispositionCounts[data.MainDisposition] = { ...data, TotalCalls: 0, subDispositions: [] };
			}
			mainDispositionCounts[data.MainDisposition].TotalCalls += +data.TotalCalls;
			for (const subDispotion of subDispositionsKey) {
				if (data[subDispotion]) {
					itemSubDisposition.push({ dispositionName: subDispotion, name: data[subDispotion], count: +data.TotalCalls });
				}
			}
			if (itemSubDisposition.length) {
				mainDispositionCounts[data.MainDisposition].subDispositions.push(itemSubDisposition);
			}
		}
		return Object.keys(mainDispositionCounts).reduce((finalArr: any[], key: string) => [...finalArr, mainDispositionCounts[key]], []);
	};

	const updateSeriesFilter = (graphData: any[]): any => {
		const formattedGraphData: any[] = [];
		const graphCategoriesSet = new Set();
		const filterKey = filterTypeMapping[filterType] + 'Name';
		const aggregatedData: any = aggregateMainDispositions(graphData);

		const sortedData: any[] = aggregatedData;
		sortedData.sort((a, b) => a.SNO - b.SNO);

		const filteredMap = sortedData.reduce((acc: any, item: any) => ({ ...acc, [item[filterKey]]: [] }), {});

		for (const data of aggregatedData) {
			filteredMap[data[filterKey]].push({ y: +data.TotalCalls, ...data });
			graphCategoriesSet.add(data.MainDisposition);
		}
		for (const key in filteredMap) {
			formattedGraphData.push({ name: key, data: filteredMap[key] });
		}
		const graphCategories: any[] = Array.from(graphCategoriesSet);
		return { formattedGraphData, graphCategories };
	};

	const handleOnTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
		setCallType(value);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const toggleRefresh = () => {
		const filterIds = topDispositionFilter.length ? topDispositionFilter.map((item: any) => item.id).join(',') : '0';
		getWidgetData(filterIds);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setTopDispositionFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setTopDispositionFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const getWidgetData = (filterIds: any) => {
		if (!showGraphData) {
			retrieveTableData(filterIds);
		} else {
			retriveGraphData(filterIds);
		}
		setLastFetchedTime(getLastFetchedDetail());
	};

	const handleViewToggle = () => {
		if (showGraphData) {
			// retrieveTableData();
			setShowGraphData(false);
		} else {
			setShowGraphData(true);
		}
	};

	React.useEffect(()	=> {
		const fetchWidgetConfig = async () => {
			try {
				if(checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('106', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
										? widgetConfig.data
										: widgetConfig.config;
		
						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		console.log("responseLength", responseLength)
		console.log("topDispositionFilter.length", topDispositionFilter.length)
		const filterIds = (topDispositionFilter.length && !(responseLength === topDispositionFilter.length)) ? topDispositionFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = topDispositionFilter.length ? topDispositionFilter.map((item: any) => item.id).join(',') : '0';
		getWidgetData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		// interval = setInterval(() => getWidgetData(filterIds), WIDGET_REFRESH_INTERVAL.TOP_DISPOSITION);
	}, [topDispositionFilter, dispositionCount, callType, showGraphData]);

	const getPopoverContent = () => {
		if (!selectedPoint) return null;
		const { subDispositions, TotalCalls } = selectedPoint?.options || selectedPoint;
		if (!subDispositions.length)
			return (
				<Box sx={{ p: 2 }}>
					<p>No Sub dispositions</p>
				</Box>
			);
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				{subDispositions.map((subDisposition: any, idx: number) => (
					<Accordion key={idx} style={{ boxShadow: 'none' }} className="popover-accordion">
						<AccordionSummary className="popover-accordian-summary" expandIcon={<ExpandMoreIcon />}>
							<div>
								{subDisposition[0].name}({subDisposition[0].count})<br></br>
								<LinearProgress variant="determinate" value={(subDisposition[0].count / TotalCalls) * 100} />
							</div>
						</AccordionSummary>
						<AccordionDetails>
							{subDisposition.map(
								(nestedSubDisposition: any, childIdx: number) =>
									childIdx > 0 && (
										<div key={idx + '' + childIdx} className="popover-accordian-details">
											<div className="child-item">
												<p>
													{nestedSubDisposition.name}({nestedSubDisposition.count})
												</p>
												<LinearProgress variant="determinate" value={(nestedSubDisposition.count / TotalCalls) * 100} />
											</div>
										</div>
									)
							)}
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		);
	};

	const options = {
		chart: {
			type: 'bar',
			height: chartDimensions.height,
		},
		credits: { enabled: false },
		title: { text: '' },
		xAxis: {
			title: {
				text: 'Disposition',
				style: { fontWeight: 'bold' }
			},
			categories: graphCategories,
			labels: {
				style: {
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					width: '200px',
				},
				formatter(this: any): any{
					return `<span title="${this.value}">${this.value}</span>`;
				},
				useHTML: true
			},
			crosshair: true
		},
		yAxis: {
			title: {
				text: 'Count',
				style: { fontWeight: 'bold' }
			},
			labels: {},
			gridLineDashStyle: 'dash'
		},
		legend: {
			enabled: false
		},
		tooltip: {
			formatter(this: any): any {
				return `${this.y}`;
			}
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				borderRadiusTopLeft: '50%',
				borderRadiusTopRight: '50%',
				pointWidth: 8,
				// borderRadius: 10,
				point: {
					events: {
						click: function ({ point }: any) {
							handlePointClick(point);
						}
					}
				}
			}
		},
		series: graphData
	};

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				formattedTotalCalls: item?.TotalCalls ? item?.TotalCalls : item?.TotalCalls,
			};
			return rowObj;
		});
		return row;
	}

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Top Disposition
						<span className="card-title-badge">Live</span>
						<div className="disposition-toggle">
							<ToggleButtonGroup color="primary" exclusive value={dispositionCount} onChange={handleDispositionCount} aria-label="text alignment">
								<ToggleButton value="5">5</ToggleButton>
								<ToggleButton value="20">20</ToggleButton>
							</ToggleButtonGroup>
						</div>
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						{/* <div className="menu-text-actions">
							<div onClick={handleMenuOpen}>
								{duration}
								<KeyboardArrowDownOutlinedIcon style={{ color: '#536580', fontSize: '10px' }} />
							</div>
							<Menu
								id="download-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={open}
								onClose={() => handleDuration()}
								className="custom-dropdown"
							>
								<MenuItem className="download-item" onClick={() => handleDuration('Today')}>
									Today
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Yesterday')}>
									Yesterday
								</MenuItem>
								<MenuItem className="download-item" onClick={() => handleDuration('Same day last week')}>
									Same day last week
								</MenuItem>
							</Menu>
						</div> */}
						<div className="card-icon" onClick={() => handleViewToggle()}>
							{showGraphData ? <TableIcon /> : <GraphIcon />}
						</div>
						<WidgetDownload xlsx={showGraphData ? false : true} rowData={getRowData(rowData)} columns={columnData} element={downloadContent} handleDownload={handleDownload} name={'Top Disposition'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={topDispositionFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
					</div>
				</div>
				{/* <Box sx={{ pt: 2, pl: 2 }}>
					{agentStateFilter.length > 0 && (
						<div className="filter-outer">
							<div className="filter-container">
								<Stack direction="row" spacing={1}>
									{filterItems}
								</Stack>
							</div>
							<div className="filter-remove" onClick={clearFilter}>
								Remove
							</div>
						</div>
					)}
				</Box> */}
				<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1', height: "100%" }} >
					<Box>
						<TabContext value={callType}>
							<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList onChange={handleOnTabChange} aria-label="lab API tabs example" className="customTabs">
									<Tab label="All" value="all" />
									<Tab label="Voice" value="voice" />
									<Tab label="Digital" value="digital" />
									<Tab label="Manual" value="manual" />
									<Tab label="Preview" value="preview" />
									<Tab label="Progressive" value="progressive" />
								</TabList>
							</Box>
							{topDispositionFilter.length > 0 && <Box className="customTabPanel">
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<div className="filter-outer">
											<div className="filter-container">
												<Stack direction="row" spacing={1}>
													{filterItems}
												</Stack>
											</div>
											<div className="filter-remove" onClick={clearFilter}>
												Remove
											</div>
										</div>
									</Grid>
								</Grid>
							</Box>}
						</TabContext>
					</Box>
					<Box sx={{ height: topDispositionFilter.length > 0 ? chartDimensions.height < 360 ? 'calc(100% - 140px)' : 'calc(100% - 128px)' : chartDimensions.height < 360 ? 'calc(100% - 64px)' : 'calc(100% - 54px)' }} ref={chartContainerRef}>
						{showGraphData ? (
							<HighchartsReact ref={chartElementRef} highcharts={Highcharts} options={options} />
						) : (
							<OZTable downloadContent={downloadContent} columns={columnData} rows={rowData} noMaxHeight={true} height={chartDimensions.height} />
						)}
					</Box>
				</Box>
			</Card >
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={topDispositionFilter} allowedFilters={allowedFilters} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			<Popover
				open={isPopoverOpen}
				onClose={handleOnPopoverClose}
				anchorReference="anchorEl"
				anchorEl={graphRefPoint}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				{getPopoverContent()}
			</Popover>
		</>
	);
}
export default TopDisposition;
