import { Formik } from "formik";
import "./skill.css";
import {
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  Tooltip,
  TextField
} from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import OZSelect from "../../../components/admin/common/OZSelect";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import OZInput from "../../../components/admin/common/OZInput";
import {
  addSkill,
  getAllAgentsGroupWise,
  getDialoutNumbers,
  getIvrDropdown,
  getphoneNumberDropdownWithName,
  getSkillDropdown,
  getskilllocationDropdown,
} from "../../../services/api-service";
import Divider from "@mui/material/Divider";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import OZDialog from "../../../components/admin/common/OZDialog";
import Menu from "@mui/material/Menu";
import CustomizedCheckbox from "../../../components/admin/common/CustomCheckBox";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import * as yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../components/admin/common/NotiService";
import { checkRoles } from "../../../utils/util";
import { authPage, AdminPages } from "../../../services/page-service";
import { AgentList } from "./SkillList";
import { ScrollToFieldError } from "../../../components/admin/common/ScrollToFieldError";
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import { SkillAutocomplete } from "./MultiSkill";
import { makeStyles } from "@mui/styles";
import { AgentListForSub } from "./AgentListForSub";

const SkillView = (viewProps) => {
  // console.log("view props::::::::::::::::::::", viewProps)
  let styleFieldHeader = useMemo(
    () => ({
      fontSize: "12px",
      fontWeigth: 400,
      color: "#99A0A8",
    }),
    []
  );

  let styleFieldValue = useMemo(
    () => ({
      fontSize: "14px",
      fontWeigth: 400,
      marginTop: "2px",
      color: "#212121",
    }),
    []
  );

  let mainDivStyle = useMemo(
    () => ({
      display: "flex",
      marginRight: "0.5rem",
      marginLeft: "0.625rem",
      marginTop: "15px",
    }),
    []
  );

  let editButtonStyle = useMemo(
    () => ({
      fontWeight: 400,
      fontSize: "14px",
      color: "#3D8BF8",
      width: "32px",
      minWidth: "29px",
    }),
    []
  );

  let chipStyle = useMemo(
    () => ({
      height: "24px",
      "& .MuiChipLabel": {
        color: "#536580",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
      },
      flexShrink: 0,
    }),
    []
  );
  const SkillFieldsView = (props) => {
    return (
      <>
        <div className="oz-skill-view-lablel-container-skilldetails">
          <Typography sx={{ ...styleFieldHeader, marginTop: "7px" }}>
            {props?.label}
          </Typography>
          {props?.data?.length !== 0 ? (
            <Typography sx={{ ...styleFieldValue }}>{props?.data} </Typography>
          ) : (
            <Typography sx={{ ...styleFieldValue }}>--</Typography>
          )}
        </div>
      </>
    );
  };
  return (
    <div>
      <div className="oz-skill-Location-container">
        <div>
          <>
            <Typography sx={{ ...styleFieldHeader }}>Skill Location *</Typography>
            <Typography sx={{ ...styleFieldValue }}>
              {viewProps?.viewData?.location?.name}
            </Typography>
          </>
        </div>
        {viewProps?.editAccess && (
          <Typography
            size="small"
            variant="text"
            sx={{ ...editButtonStyle, cursor: "pointer" }}
            onClick={() => {
              viewProps?.editmode("BasicInformation");
            }}
            paddingtop={"5px"}
          >
            Edit
          </Typography>
        )}
      </div>
      {viewProps?.viewData?.recOn && (
        <SkillFieldsView
          data={viewProps?.viewData?.recOn?.record}
          label={"Record *"}
        />
      )}
      {viewProps?.viewData?.queueSize && (
        <SkillFieldsView
          data={viewProps?.viewData?.queueSize}
          label={"Queue Size *"}
        />
      )}
      {viewProps?.viewData?.priority && checkRoles('ROLE_ADMIN') && (
        <SkillFieldsView
          data={viewProps?.viewData?.priority}
          label={"Priority *"}
        />
      )}
      <div className="oz-skill-view-lablel-container">
        <Typography sx={{ ...styleFieldHeader }}>Queue Timeout *</Typography>
        <Typography sx={{ ...styleFieldValue, display: "flex" }}>
          {viewProps.viewData.queueTimeOut_Min + ""}
          <label
            style={{
              color: "#99A0A8",
              marginLeft: "5px",
              marginRight: "5px",
              marginBottom: "5px",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Mins
          </label>
          {"" + viewProps.viewData.queueTimeOut_Sec + ""}
          <label
            style={{
              color: "#99A0A8",
              marginLeft: "5px",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Secs
          </label>
        </Typography>
      </div>
      {viewProps?.viewData?.fallBackRule && (
        <SkillFieldsView
          data={viewProps?.viewData?.fallBackRule?.Type}
          label={"FallBack Rule *"}
        />
      )}
      {/*  {viewProps?.viewData?.ivrFlow && 
                <SkillFieldsView data={viewProps?.viewData?.ivrFlow?.flowName} label={"Ivr Flow *"} />}
            {viewProps?.viewData?.queueSkillTransfer&& 
                <SkillFieldsView data={viewProps?.viewData?.queueSkillTransfer?.skillName} label={"Skill *"} />}
            {viewProps?.viewData?.dialOut &&
                <SkillFieldsView data={viewProps?.viewData?.dialOut?.dialOutNumber} label={"Dailout Number *"} />} */}
      <div className="oz-skill-view-lablel-container">
        <Typography sx={{ ...styleFieldHeader }}>
          Phone numbers assigned for hunting (offline)
        </Typography>
        <div className="oz-skill-view-phonenumbers-container">
          <Typography sx={{ ...styleFieldValue, marginTop: "0px" }}>
            {" "}
            {viewProps?.viewData?.fwpNumbers?.map((Number, Index) => {
              return (
                <Chip
                  component={"span"}
                  size="small"
                  key={Index}
                  style={{ ...chipStyle, marginRight: "6px", marginTop: "8px" }}
                  label={Number.phoneNumber}
                />
              );
            })}
          </Typography>
        </div>
      </div>
      {viewProps?.viewData?.skillDetail1 && (
        <SkillFieldsView
          data={viewProps?.viewData?.skillDetail1}
          label={"Skill Detail 1"}
        />
      )}
      {viewProps?.viewData?.skillDetail2 && (
        <SkillFieldsView
          data={viewProps?.viewData?.skillDetail2}
          label={"Skill Detail 2"}
        />
      )}
      {viewProps?.viewData?.skillDetail3 && (
        <SkillFieldsView
          data={viewProps?.viewData?.skillDetail3}
          label={"Skill Detail 3"}
        />
      )}
      <Grid marginTop="1rem" marginLeft={"-20px"} marginRight={"-10px"}>
        {" "}
        <Divider />
      </Grid>
      {checkRoles("ROLE_SKILLDROPACTION") && (
        <div className="oz-skill-view-editFeature-container">
          <div className="oz-skill-view-calldropaction-container">
            <FormGroup>
              <FormControlLabel
                control={
                  <CustomizedCheckbox
                    size="small"
                    checked={viewProps.viewData.dropAction || false}
                    disabled
                  >
                    CallDrop Actions
                  </CustomizedCheckbox>
                }
                label={
                  <Typography fontSize={"14px"}>Call Back <span style={{ opacity: '0.5' }}>(Call Drop Action)</span></Typography>
                }
              />
            </FormGroup>
            <FormGroup>
              {/* <FormControlLabel
                control={
                  <CustomizedCheckbox
                    size="small"
                    checked={viewProps.viewData.enabled || false}
                    disabled
                  >
                    Call Back
                  </CustomizedCheckbox>
                }
                label={<Typography fontSize={"14px"}>Call Back</Typography>}
              /> */}
            </FormGroup>
          </div>
          {viewProps?.editAccess && (
            <Typography
              size="small"
              variant="text"
              spacing="3px"
              onClick={() => {
                viewProps?.editmode("EditFeature");
              }}
              sx={{ ...editButtonStyle, cursor: "pointer" }}
            >
              Edit
            </Typography>
          )}
        </div>
      )}
      {viewProps.viewData.callDropActions &&
        checkRoles("ROLE_SKILLDROPACTION") && (
          <div style={{ ...mainDivStyle, alignItems: "center" }}>
            <div>
              <Typography sx={{ ...styleFieldHeader }}>Request Type</Typography>
              {viewProps.viewData.dropAction === true ? (
                <Typography sx={{ ...styleFieldValue }}>
                  {viewProps.viewData?.actionUrlType?.method}
                </Typography>
              ) : (
                <Typography sx={{ ...styleFieldValue }}>---</Typography>
              )}
            </div>
            <div>
              <Typography sx={{ ...styleFieldHeader, marginLeft: "35px" }}>
                URL *
              </Typography>
              {viewProps.viewData.dropAction === true ? (
                <Typography sx={{ ...styleFieldValue, marginLeft: "35px" }}>
                  {viewProps.viewData?.callDropActions[0]?.actionUrl}
                </Typography>
              ) : (
                <Typography sx={{ ...styleFieldValue, marginLeft: "35px" }}>
                  ---
                </Typography>
              )}
            </div>
            <div>
              <Typography sx={{ ...styleFieldHeader, marginLeft: "140px" }}>
                Tries *
              </Typography>
              {viewProps.viewData.dropAction === true ? (
                <Typography sx={{ ...styleFieldValue, marginLeft: "140px" }}>
                  {viewProps?.viewData?.callDropActions[0]?.actionValue}
                </Typography>
              ) : (
                <Typography sx={{ ...styleFieldValue, marginLeft: "140px" }}>
                  ---
                </Typography>
              )}
            </div>
          </div>
        )}
      {checkRoles("ROLE_SKILLDROPACTION") && (
        <Grid marginTop="1.8rem" marginLeft={"-20px"} marginRight={"-10px"}>
          {" "}
          <Divider />
        </Grid>
      )}
      {viewProps?.viewData?.agentSkillList?.length > 0 && (
        <div className="oz-skill-view-lablel-container">
          <div className="oz-skill-view-calldropaction-container">
            <Typography sx={styleFieldHeader} alignSelf="center">
              Agents
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "#99A0A8",
                alignSelf: "center",
                marginLeft: "4px",
              }}
              flexGrow={1}
            >
              (Choose specific levels for different agents)
            </Typography>
            {viewProps?.editAccess && (
              <Button
                sx={editButtonStyle}
                onClick={() => {
                  viewProps?.editmode("Agents");
                }}
              >
                Edit
              </Button>
            )}
          </div>
          <div className="oz-skill-view-agents-container">
            <Typography
              sx={{
                ...styleFieldHeader,
                width: "58px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Priority
            </Typography>
            <Divider orientation="vertical" />
            <Typography
              sx={{
                ...styleFieldHeader,
                marginLeft: "18px",
              }}
            >
              Agents
            </Typography>
          </div>

          {localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false' ?
            <>
              {viewProps?.viewData?.agentListForView?.map((skillData, mainIndex) => {
                return (
                  <div
                    key={mainIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "38px",
                      border: "1px solid #D6D6D7",
                      borderTop: "0px",
                      borderRadius: " 0px 0px",
                      borderBottomLeftRadius:
                        viewProps?.viewData?.agentListForView?.length - 1 === mainIndex
                          ? "8px"
                          : "0px",
                      borderBottomRightRadius:
                        viewProps?.viewData?.agentListForView?.length - 1 === mainIndex
                          ? "8px"
                          : "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styleFieldHeader,
                        minWidth: "58px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {skillData.priority}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography sx={{ ...styleFieldHeader, marginLeft: "18px" }}>
                      {skillData?.agentList?.map(
                        (value, index) =>
                          index < 3 &&
                          value?.agentName +
                          (skillData?.agentList?.length - 1 > index ? ", " : "")
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#212121",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                      }}
                    >
                      {skillData?.agentList?.length > 3 ? "..." : ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#99A0A8",
                        paddingRight: "27px",
                        paddingLeft: "5px",
                      }}
                    >
                      {skillData?.agentList?.length > 3
                        ? "+" + (skillData?.agentList?.length - 3)
                        : ""}
                    </Typography>
                  </div>
                );
              })}
            </>
            :
            <>
              {viewProps?.viewData?.agentSkillList?.map((skillData, mainIndex) => {
                return (
                  <div
                    key={mainIndex}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "38px",
                      border: "1px solid #D6D6D7",
                      borderTop: "0px",
                      borderRadius: " 0px 0px",
                      borderBottomLeftRadius:
                        viewProps?.viewData?.agentSkillList?.length - 1 === mainIndex
                          ? "8px"
                          : "0px",
                      borderBottomRightRadius:
                        viewProps?.viewData?.agentSkillList?.length - 1 === mainIndex
                          ? "8px"
                          : "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styleFieldHeader,
                        minWidth: "58px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {skillData.priority}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography sx={{ ...styleFieldHeader, marginLeft: "18px" }}>
                      {localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false' ?
                        <>
                          {[...new Set(skillData?.agentList?.map(value => value?.agentId))]
                            .slice(0, 3)
                            .map((agentId, index, arr) => (
                              skillData?.agentList?.find(agent => agent.agentId === agentId)?.agentName +
                              (index < arr.length - 1 ? ", " : "")
                          ))}
                        </> 
                        :
                        <>
                          {skillData?.agentList?.map(
                            (value, index) =>
                              index < 3 &&
                              value?.agentName +
                              (skillData?.agentList?.length - 1 > index ? ", " : "")
                          )}
                        </>
                      }
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#212121",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                      }}
                    >
                      {skillData?.agentList?.length > 3 ? "..." : ""}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#99A0A8",
                        paddingRight: "27px",
                        paddingLeft: "5px",
                      }}
                    >
                      {skillData?.agentList?.length > 3
                        ? "+" + (skillData?.agentList?.length - 3)
                        : ""}
                    </Typography>
                  </div>
                );
              })}
            </>
          }
          {/* {viewProps?.viewData?.agentSkillList?.map((skillData, mainIndex) => {
            return (
              <div
                key={mainIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "38px",
                  border: "1px solid #D6D6D7",
                  borderTop: "0px",
                  borderRadius: " 0px 0px",
                  borderBottomLeftRadius:
                    viewProps?.viewData?.agentSkillList?.length - 1 === mainIndex
                      ? "8px"
                      : "0px",
                  borderBottomRightRadius:
                    viewProps?.viewData?.agentSkillList?.length - 1 === mainIndex
                      ? "8px"
                      : "0px",
                }}
              >
                <Typography
                  sx={{
                    ...styleFieldHeader,
                    minWidth: "58px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {skillData.priority}
                </Typography>
                <Divider orientation="vertical" />
                <Typography sx={{ ...styleFieldHeader, marginLeft: "18px" }}>
                  {localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false' ?
                    <>
                      {[...new Set(skillData?.agentList?.map(value => value?.agentId))]
                        .slice(0, 3)
                        .map((agentId, index, arr) => (
                          skillData?.agentList?.find(agent => agent.agentId === agentId)?.agentName +
                          (index < arr.length - 1 ? ", " : "")
                      ))}
                    </> 
                    :
                    <>
                      {skillData?.agentList?.map(
                        (value, index) =>
                          index < 3 &&
                          value?.agentName +
                          (skillData?.agentList?.length - 1 > index ? ", " : "")
                      )}
                    </>
                  }
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#212121",
                    paddingRight: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {skillData?.agentList?.length > 3 ? "..." : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#99A0A8",
                    paddingRight: "27px",
                    paddingLeft: "5px",
                  }}
                >
                  {skillData?.agentList?.length > 3
                    ? "+" + (skillData?.agentList?.length - 3)
                    : ""}
                </Typography>
              </div>
            );
          })} */}
        </div>
      )}
      {(viewProps?.viewData?.fallbackDetails != null) && (viewProps?.viewData?.fallBackRule.fallBackRule === 6) && (
        <div className="oz-skill-view-lablel-container">
          <div className="oz-skill-view-calldropaction-container">
            <>
              <Typography sx={styleFieldHeader} alignSelf="center">
                MultiSkills
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#99A0A8",
                  alignSelf: "center",
                  marginLeft: "4px",
                }}
                flexGrow={1}
              >
                (Choose specific Queue Timeout for different multi skills)
              </Typography>
            </>
          </div>
          <div className="oz-skill-view-agents-container">
            <Typography
              sx={{
                ...styleFieldHeader,
                width: "120px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ ...styleFieldHeader }}>Queue Timeout</Typography>
            </Typography>
            <Divider orientation="vertical" />
            <Typography
              sx={{
                ...styleFieldHeader,
                marginLeft: "18px",
              }}
            >
              Skill Name
            </Typography>
          </div>
          {JSON.parse(viewProps?.viewData?.fallbackDetails).skills?.map((skill, mainIndex) => {
            return (
              <div
                key={mainIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "38px",
                  border: "1px solid #D6D6D7",
                  borderTop: "0px",
                  borderRadius: " 0px 0px",
                }}
              >
                <>
                  <Typography
                    sx={{
                      ...styleFieldHeader,
                      minWidth: "120px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {Math.floor(skill.queueTime / 60)}
                    <label
                      style={{
                        color: "#99A0A8",
                        marginLeft: "5px",
                        marginRight: "5px",
                        marginBottom: "5px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Mins
                    </label>
                    {skill.queueTime % 60}
                    <label
                      style={{
                        color: "#99A0A8",
                        marginLeft: "5px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Secs
                    </label>
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography sx={{ ...styleFieldHeader, marginLeft: "18px" }}>
                    `{skill.skillName}
                  </Typography>
                </>
              </div>
            );
          })}
        </div>)}
    </div>
  );
};

const SkillForm = (props) => {

  const useStyles = makeStyles((theme) => ({
    box: {
      position: 'relative',
      backgroundColor: '#edf5ff',
      height: '32px',
      margin: '10px',
      alignItems: 'center',
      boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      display: 'inline-block',
      padding: '12px',
      width: '100%'
    },
    arrow: {
      position: 'absolute',
      top: '-7px',
      left: '6%',
      transform: 'translateX(-50%)',
      width: '0',
      height: '0',
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderBottom: '22px solid #edf5ff',
    },
    text: {
      fontWeight: 500,
    },
    newForm: {
      opacity: '0.5',
      marginLeft: '10px',
      fontSize: '12px',
      color: '#212121'
    }
  }));
  const classes = useStyles();

  const agentDataList = useMemo(
    () => (props.agentDataList
    ),
    [props.agentDataList]
  );
  const skill = [
    { recOn: 0, record: "Record the call in Full" },
    { recOn: 1, record: "Record only the conversation" },
    { recOn: 2, record: "Do not record calls" },
  ];
  const url = [
    { actionUrlType: 0, method: "GET" },
    { actionUrlType: 1, method: "POST" },
  ];
  const fallback = [
    { fallBackRule: 1, Type: "Dialout" },
    { fallBackRule: 2, Type: "Disconnect" },
    { fallBackRule: 3, Type: "voicemail" },
    { fallBackRule: 4, Type: "Skill" },
    { fallBackRule: 5, Type: "IVR" },
    { fallBackRule: 6, Type: "MultiSkill" },
    { fallBackRule: 7, Type: "Parking" }
  ];

  const fallbackRuleParking = [
    { fallBackRule: 1, Type: "Dialout" },
    { fallBackRule: 2, Type: "Disconnect" },
    { fallBackRule: 3, Type: "voicemail" },
    { fallBackRule: 4, Type: "Skill" },
    { fallBackRule: 5, Type: "IVR" },
    { fallBackRule: 6, Type: "MultiSkill" },
    { fallBackRule: 7, Type: "Parking" }
  ];

  const fallbackTypeParking = [
    { fallbackType: 1, Type: "Dialout" },
    { fallbackType: 2, Type: "Disconnect" },
    { fallbackType: 3, Type: "voicemail" },
    { fallbackType: 4, Type: "Skill" },
    { fallbackType: 5, Type: "IVR" },
    // { fallbackType: 6, Type: "MultiSkill" },
    { fallbackType: 7, Type: "Parking" }
  ];

  if (props.data) {
    if (props.data) {
      skill.forEach((item) => {
        if (item.recOn === props.data.recOn) {
          props.data.recOn = item;
        }
      });
    }
    if (props.data.callDropActions) {
      url.forEach((item) => {
        if (
          item.actionUrlType === props.data.callDropActions[0]?.actionUrlType
        ) {
          props.data.actionUrlType = item;
        }
      });
    }

    if (props?.data?.queueTimeOut === 0) {
      props.data.queueTimeOut_Min = Math.floor(props.data.queueTimeOut / 60);
      props.data.queueTimeOut_Sec =
        props.data.queueTimeOut - props.data.queueTimeOut_Min * 60;
    } else {
      if (props?.data?.queueTimeOut) {
        props.data.queueTimeOut_Min = Math.floor(props.data.queueTimeOut / 60);
        props.data.queueTimeOut_Sec =
          props.data.queueTimeOut - props.data.queueTimeOut_Min * 60;
      }
    }

    if (props.data) {
      if(props?.data?.fallBackRule === 7 || props?.data?.fallBackRule === '7'){
        if(checkRoles('ROLE_PARKING')){
          fallbackRuleParking.forEach((item) => {
            if (item.fallBackRule === props.data.fallBackRule) {
              props.data.fallBackRule = item;
            }
          });
        }else{
          props.data.fallBackRule = { fallBackRule: 2, Type: "Disconnect" };
        }
      }else{
        fallbackRuleParking.forEach((item) => {
          if (item.fallBackRule === props.data.fallBackRule) {
            props.data.fallBackRule = item;
          }
        });
      }
    }
  }

  const validationSchema = useMemo(
    () =>
      yup.object({
        skillName: yup
          .string()
          .min(2, "Skill Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
          .max(50, "Skill Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
          .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Skill Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
          .required("Skill Name is required."),
        queueSize: yup
          .number("Enter Queue_Size")
          .integer("Queuesize must be the number")
          .typeError('Queuesize must be the number')
          .min(1, 'Queue Size should be between 1 and 999')
          .max(999, 'Queue Size should be between 1 and 999')
          .required("Queue Size is required"),
        location: yup.object().required("Skill Location is required."),
        priority: yup.number()
          .when([], {
            is: () => (!checkRoles("ROLE_ADMIN")),
            then: yup.number().notRequired().nullable(),
            otherwise: yup.number("Enter Priority")
                .min(1, 'Priority should be between 1 and 999')
                .max(999, 'Priority should be between 1 and 999')
                .typeError('The value must be a number')
                .integer('The value must be a number')
                .required('Priority is required'),
        }),
        fwpNumbers: yup
          .array()
          .of(yup.object())
          .required("Assign Phone Numbers for Hunting(offline) is required.")
          .min(1, "Assign Phone Numbers for Hunting(offline) is required"),
        recOn: yup.object().required("Record is required"),
        queueTimeOut: yup.number(),
        dropAction: false,
        actionUrl: yup.string("Enter flow url").when("dropAction", {
          is: true,
          then: yup.string().required("URL is required"),
        }),
        actionValue: yup.number("Tries is required").when("dropAction", {
          is: true,
          then: yup
            .number("Enter No. of Tries")
            .required("Tries is required")
            .typeError("Tries must be a number")
            .min(1, "Tries should be between 1 and 99.")
            .max(99, "Tries should be between 1 and 99."),
        }),
        dialOut: yup
          .object()
          .typeError("Dial Out Number is required.")
          .when(["fallBackRule.fallBackRule"], {
            is: (fallBackRule) => fallBackRule === 1,
            then: yup
              .object()
              .typeError("Dial Out Number is required.")
              .required("Dial Out Number is required.")
              .nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
        dialOutMulti: yup
          .object()
          .typeError("Dial Out Number is required.")
          .when(["fallbackType.fallbackType"], {
            is: (fallbackType) => fallbackType === 1,
            then: yup
              .object()
              .typeError("Dial Out Number is required.")
              .required("Dial Out Number is required.")
              .nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
          agentSkillList: yup
          .array()
          .of(
            yup.object().shape({
              priority: yup
                .number()
                .required("Priority is required.")
                .min(1, 'Priority should be between 1 and 999')
                .typeError('The value must be a number')
                .integer('The value must be a number')
                .max(999, 'Priority should be between 1 and 999'),
              agentList: yup
                .array()
                .when([], {
                  is: () =>
                    (localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'),
                  then: yup
                    .array()
                    .of(
                      yup.object().shape({
                        id: yup.number().required("Agents is required"),
                        agentId: yup.string().required("must have at least 1 item"),
                      })
                    )
                    .required("Agents is required")
                    .min(1, "Assign Agents is required."),
                  otherwise: yup
                    .array()
                    .of(
                      yup.object().shape({
                        id: yup.number().required("Agents is required"),
                        agentName: yup.string().required("Agents is required"),
                        agentId: yup.string().required("must have at least 1 item"),
                      })
                    )
                    .required("Agents is required")
                    .min(1, "Assign Agents is required."),
                }),
            })
          )
          .required("Agents is required")
          .min(1, "Assign Agents is required."),

          //:::::::previous validation:::::://
          // agentSkillList: yup
          // .array()
          // .of(
          //   yup.object().shape({
          //     priority: yup
          //       .number()
          //       .required("Priority is required.")
          //       .min(1, 'Priority should be between 1 and 999')
          //       .typeError('The value must be a number')
          //       .integer('The value must be a number')
          //       .max(999, 'Priority should be between 1 and 999'),
          //     agentList: yup
          //       .array()
          //       .of(
          //         yup.object().shape({
          //           id: yup.number().required("Agents is required"),
          //           agentName: yup.string().required("agents is required"),
          //           agentId: yup.string().required("must have at least 1 item"),
          //         })
          //       )
          //       .required("Agents is required")
          //       .min(1, "Assign Agents is required."),
          //   })
          // )
          // .required("Agents is required")
          // .min(1, "Assign Agents is required.."),
          //:::::::previous validation::::::// 
        ivrFlow: yup
          .object()
          .typeError("IvrFlow is required")
          .when("fallBackRule.fallBackRule", {
            is: (fallBackRule) => fallBackRule === 5,
            then: yup
              .object()
              .typeError("IvrFlow is required")
              .required("IvrFlow is required.")
              .nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
        queueSkillTransfer: yup
          .object()
          .typeError("Transfer to Skill is required.")
          .when(["fallBackRule.fallBackRule"], {
            is: (fallBackRule) => fallBackRule === 4,
            then: yup
              .object()
              .typeError("Transfer to Skill is required.")
              .required("Transfer to Skill is required.")
              .nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
        queueSkillTransferMulti: yup
          .object()
          .typeError("Transfer to Skill is required.")
          .when(["fallbackType.fallbackType"], {
            is: (fallbackType) => fallbackType === 4,
            then: yup
              .object()
              .typeError("Transfer to Skill is required.")
              .required("Transfer to Skill is required.")
              .nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
        skillDetail1: yup
          .string()
          .min(2, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .max(200, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .matches(/^[A-Za-z0-9]((\.|@|_|-|\s)?[A-Za-z0-9])+$/, "Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-")
          .nullable(),
        skillDetail2: yup
          .string()
          .min(2, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .max(200, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .matches(/^[A-Za-z0-9]((\.|@|_|-|\s)?[A-Za-z0-9])+$/, "Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-")
          .nullable(),
        skillDetail3: yup
          .string()
          .min(2, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .max(200, 'Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-')
          .matches(/^[A-Za-z0-9]((\.|@|_|-|\s)?[A-Za-z0-9])+$/, "Skill Detail should be between 2 to 200 alphanumeric characters long and allows special characters like .,@,_,space,-")
          .nullable(),
        // queueIvrTransfer: yup
        //   .string()
        //   .typeError("Transfer to IVR App. URL is required.")
        //   .when("fallbackType.fallbackType", {
        //     is: (fallbackType) => (fallbackType === 5),
        //     then: yup
        //       .string()
        //       .typeError("Transfer to IVR App. URL is required")
        //       .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Not a valid URL")
        //       .required("Transfer to IVR App. URL is required")
        //       .nullable(),
        //     otherwise: yup.string().notRequired().nullable(),
        //   }),
        queueIvrTransfer: yup
          .string()
          .typeError("Transfer to IVR App. URL is required.")
          .when(["fallBackRule.fallBackRule", "ivrFlow.flowName", "ivrFlow.id"], {
            is: (fallBackRule, name, id) => (fallBackRule === 5 && name === "Other" && id === "1_Other"),
            then: yup
              .string()
              .typeError("Transfer to IVR App. URL is required")
              .matches(/^(?!\s).*$/, "Transfer to IVR App. URL is required")
              .required("Transfer to IVR App. URL is required")
              .nullable(),
            otherwise: yup.string().notRequired().nullable(),
          }),
        queueIvrTransferMulti: yup
          .string()
          .typeError("Transfer to IVR App. URL is required.")
          .when("fallbackType.fallbackType", {
            is: (fallbackType) => (fallbackType === 5),
            then: yup
              .string()
              .typeError("Transfer to IVR App. URL is required")
              .matches(/^(?!\s).*$/, "Transfer to IVR App. URL is required")
              // .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Not a valid URL")
              .required("Transfer to IVR App. URL is required")
              .nullable(),
            otherwise: yup.string().notRequired().nullable(),
          }),

      }),
    []
  );

  const [SkillFormMode, setSkillFormMode] = useState(props.mode);
  const [openSkillFormMode, setOpenSkillFormMode] = useState(true);
  const [skillViewMenuanchorE2, setSkillViewMenuAnchorE2] =
    React.useState(false);
  const [skillRenameanchorE1, setSkillRenameAnchorE1] = React.useState(false);
  const [openDeleteDailog, setDeleteDialog] = useState(false);
  const anchorRef = React.useRef();
  const anchorRef2 = React.useRef();

  const morevertClick = () => {
    setTimeout(() => setSkillViewMenuAnchorE2(anchorRef?.current), 1);
  };
  const handleMenuClose = () => {
    setSkillViewMenuAnchorE2(null);
  };
  const closeSkillForm = () => {
    props.onClose();
    setOpenSkillFormMode(false);
    // localStorage.clear();
    localStorage.removeItem("agents");
  };
  const onClickEditButton = () => {
    setSkillFormMode(ADMIN_FORM_MODES.edit);
  };

  function openRenameDialog() {
    setSkillFormMode("Rename");
    setSkillViewMenuAnchorE2(null);
    setTimeout(() => setSkillRenameAnchorE1(anchorRef2?.current), 1);
  }

  const [PhoneNumberList, setNumberList] = useState([]);
  const [ivrList, setIvrList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [dailoutList, setDailoutList] = useState([]);
  const [location, setLocation] = useState([]);
  const [valid, setValid] = useState(true);
  const [loggedInAgentsByGroup, setLoggedInAgentsByGroup] = useState([]);
  const [fullLengthOfAgentsByGroup, setFullLengthOfAgentsByGroup] = useState(0);

  useEffect(() => {
    getphoneDropDown();
    getlocationDropDown();
    getIvrsDropDown();
    getSkillsDropDown();
    getDailoutDropDown();
    if(localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'){
      getLoggedInAgentsByGroupData();
    }
  }, []);

  const getLoggedInAgentsByGroupData = async () => {
    try {
        const resp = await getAllAgentsGroupWise();
        if (resp.Status === "Success" && resp.Message === "Success") {
            const data = JSON.parse(resp?.Data);
            if (Object.keys(data)?.length === 0) {
                return;
            }
            setLoggedInAgentsByGroup(Object.keys(data).map((key) => {
              setFullLengthOfAgentsByGroup(Object.values(data).reduce((acc, currArray) => acc + currArray?.length || 0, 0));
                let groupName = null;
                try {
                    groupName = data[key][0].GroupName || key
                }
                catch (e) {
                    groupName = key
                }
                return {
                    groupName,
                    groupId: key,
                    items: data[key].map((item) => {
                        return {
                            id: item.AgentId || null,
                            label: item.AgentName || null,
                            // agentName: item.AgentName || null,
                            clientId: item.ClientId || null,
                            Agent_Id: item.Agent_Id || null,
                            checked: false
                        }
                    })
                }
            }))
        }
    }
    catch (e) {
        console.log("getLoggedInAgentsByGroupData ~ e:", e)
    }
}

  const deleteSkill = () => {
    setSkillViewMenuAnchorE2(null);
    props.skillsCallBack("deleteFunction", props.data.id);
    closeSkillForm();
    setDeleteDialog(false);
  };

  async function getphoneDropDown() {
    try {
      getphoneNumberDropdownWithName()
        .then((resp) => {
          setNumberList(resp.Data);
        })
        .catch((e) => {
          setNumberList([{ id: "0", skillName: "No Internet" }]);
          showErrorNotification(
            "No Phone Number Data Available, Check your Internet"
          );
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setNumberList([]);
    }
  }

  async function getDailoutDropDown() {
    try {
      getDialoutNumbers()
        .then((resp) => {
          setDailoutList(resp.Data);
        })
        .catch((e) => {
          setDailoutList([{ id: "0", skillName: "No Internet" }]);
          showErrorNotification(
            "No Dailout Number Data Available, Check your Internet"
          );
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setDailoutList([]);
    }
  }

  async function getIvrsDropDown() {
    try {
      getIvrDropdown()
        .then((resp) => {
          // setIvrList(resp.Data);
          if (resp?.Status === 'Success') { //this is written for the Other option to add in dropDown of IVR
            const newIvrOption = [{
              id: '1_Other',
              flowName: 'Other'
            }, ...resp?.Data];
            setIvrList(newIvrOption);
          } else {
            setIvrList([]);
            showErrorNotification("No Ivr Data Available, Check your Internet");
          }
        })
        .catch((e) => {
          setIvrList([{ id: "0", flowName: "No Internet" }]);
          showErrorNotification("No Ivr Data Available, Check your Internet");
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setIvrList([]);
    }
  }

  async function getSkillsDropDown() {
    try {
      getSkillDropdown()
        .then((resp) => {
          setSkillList(resp.Data);
        })
        .catch((e) => {
          setSkillList([]);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setSkillList([]);
    }
  }

  async function getlocationDropDown() {
    try {
      getskilllocationDropdown()
        .then((resp) => {
          setLocation(resp.Data);
        })
        .catch((e) => {
          setLocation([]);
          showErrorNotification("no connection4");
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setLocation([]);
    }
  }

  function openDeleteDialog() {
    setSkillViewMenuAnchorE2(null);
    setDeleteDialog(true);
  }

  async function addSkillForm(data) {
    try {
      let qtime = data.queueTimeOut_Min * 60 + data.queueTimeOut_Sec;
      data.queueTimeOut = qtime;
      data.recOn = data.recOn.recOn;

      if (!data.skillDetail1) {
        data.skillDetail1 = null;
      }
      if (data.queueTimeOut === 0) {
        data.fallBackRule = 2;
      } else {
        data.fallBackRule = data.fallBackRule.fallBackRule;
      }
      data.queueSize = Number(data.queueSize);
      if (data.fallBackRule === 6) {
        let queuetime = []
        let skillName = []
        data?.skills?.map((row) => {
          let queueTimeOut = row.multiskillqueueTimeOut_Min * 60 + row.multiskillqueueTimeOut_Sec;
          queuetime.push(queueTimeOut);
          skillName.push(row.skills);
          delete row.skillList;
        })
        delete data.skills;
        let multiSkillJsonArray = [];
        for (let i = 0; i < queuetime.length; i++) {
          multiSkillJsonArray.push({
            queueTime: queuetime[i],
            skillName: skillName[i].skillName,
            id: skillName[i].id
          })
        }
        let fallBackDetailsJson = {}
        fallBackDetailsJson.skills = multiSkillJsonArray;
        fallBackDetailsJson.fallbackType = data.fallbackType.fallbackType;
        let TransferSkillLists = []
        skillList?.map((row) => {
          TransferSkillLists.push(row.skillName)
        })

        // if (fallBackDetailsJson.fallbackType === 4) {
        //   fallBackDetailsJson.fallbackValue = data.queueSkillTransfer.skillName;
        // }
        // else if (fallBackDetailsJson.fallbackType === 5) {
        //   if (data.queueIvrTransfer === "") {
        //     data.queueIvrTransfer = null;
        //     fallBackDetailsJson.fallbackValue = data.queueIvrTransfer;
        //   }
        //   else {
        //     fallBackDetailsJson.fallbackValue = data.queueIvrTransfer;
        //   }
        // }
        // else if (fallBackDetailsJson.fallbackType === 1) {
        //   fallBackDetailsJson.fallbackValue = data.dialOut.dialOutName;
        // }
        // else {
        //   delete fallBackDetailsJson.fallBackRule;
        // }

        //only for testing when falalbackrule is 6 so converting//
        if (fallBackDetailsJson.fallbackType === 4) {
          fallBackDetailsJson.fallbackValue = data?.queueSkillTransferMulti?.skillName;
        }
        else if (fallBackDetailsJson.fallbackType === 5) {
          if (data.queueIvrTransferMulti === "") {
            data.queueIvrTransferMulti = null;
            fallBackDetailsJson.fallbackValue = data?.queueIvrTransferMulti;
          }
          else {
            fallBackDetailsJson.fallbackValue = data?.queueIvrTransferMulti;
          }
        }
        else if (fallBackDetailsJson.fallbackType === 1) {
          fallBackDetailsJson.fallbackValue = data?.dialOutMulti.dialOutName;
        }
        else {
          delete fallBackDetailsJson.fallBackRule;
        }
        //only for testing when falalbackrule is 6 so converting//

        fallBackDetailsJson.skillIndex = 0;
        delete data.fallbackType;
        data.fallbackDetails = JSON.stringify(fallBackDetailsJson)
      }
      else {
        delete data.fallbackType;
        delete data.skills;
        data.fallbackDetails = null;
      }
      if (data.ivrFlow?.id === "1_Other" && data.ivrFlow.flowName === "Other") {
        data.ivrFlow = null;
      }
      delete data.queueIvrTransferMulti;
      delete data.queueSkillTransferMulti;
      delete data.dialOutMulti;
      // console.log("full detaisl we are getting", data);
      addSkill(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            props.skillsCallBack("listFunction");
            closeSkillForm();
            showSuccessNotification(`Skill (${data.skillName}) has been added Successfully`);
          } else {
            showErrorNotification(resp?.Message?.skillDetail2 || resp?.Message?.skillDetail3 || resp?.Message);
            // showErrorNotification(JSON.stringify(resp.Message));
          }
        })
        .catch((e) => {
          showErrorNotification(JSON.stringify(e));
          console.log(e);
        });
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
    }
  }

  const getValidationInViewMode = (e, value) => {
    props.data.skillName = value;
    const regex = /^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/;
    const isMatch = regex.test(value);

    const digitCount = value.length;

    // Check the conditions for validation
    const isValidLength = value.length >= 2 && value.length <= 50;
    const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

    // Final validation check
    if (isMatch && isValidLength && isValidDigitCount) {
      setValid(true);
    } else {
      setValid(false);
    }
  }

  let buttonStyle = useMemo(
    () => ({
      borderRadius: "0.5rem",
      height: "2.75rem",
      fontSize: "0.875rem",
      fontWeight: 400,
    }),
    []
  );

  const styleListItemButton = useMemo(
    () => ({
      "&.MuiButtonBase-root": {
        color: "#212121",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        height: "18px",
        lineHeight: "14px",
      },
      "&:hover": {
        borderRadius: "8px",
      },
    }),
    []
  );

  const DataForm = (props) => {

    let initData = props.editData || {
      skillName: "",
      skillDetail1: "",
      skillDetail2: null,
      skillDetail3: null,
      location: "",
      recOn: { recOn: 0, record: "Record the call in Full" },
      actionUrl: "",
      actionValue: "",
      actionUrlType: null,
      queueSize: "",
      priority: 1,
      dropAction: false,
      enabled: false,
      agents: [{ priority: null, agentList: [] }],
      agentSkillList: [{ priority: null, agentList: [] }],
      ivrFlow: null,
      queueSkillTransfer: null,
      queueIvrTransfer: "",
      actionType: 0,
      queueTimeOut_Sec: 0,
      queueTimeOut_Min: 5,
      fallBackRule: { fallBackRule: 2, Type: 'Disconnect' },
      dialOut: null,
      fwpNumbers: [],
      skills: [{ multiskillqueueTimeOut_Min: 0, multiskillqueueTimeOut_Sec: 1, skills: [] }],
      fallbackType: { fallbackType: 2, Type: "Disconnect" },
      queueIvrTransferMulti: "",
      queueSkillTransferMulti: null,
      dialOutMulti: null,

    };


    if (props?.editData?.fallBackRule?.fallBackRule === 5) {
      if (props?.editData?.ivrFlow === null) {
        props.editData.ivrFlow = {
          "id": "1_Other",
          "flowName": "Other"
        }
      }
    }

    if (props?.editData) {
      initData.actionUrl = props?.editData?.callDropActions[0]?.actionUrl;
      initData.actionValue = props?.editData?.callDropActions[0]?.actionValue;
      initData.enabled = props?.editData?.callDropActions[0]?.enabled;
    }

    if (props?.editData?.fallbackDetails === null) {
      props.editData.skills = [{ multiskillqueueTimeOut_Min: 0, multiskillqueueTimeOut_Sec: 1, skillName: [] }];
    }

    if (props?.editData && props?.editData?.fallbackDetails !== null) {
      let formattedMultiSkillData = []
      JSON.parse(props?.editData?.fallbackDetails).skills.forEach(skill => {
        let queueTimeSec = skill.queueTime;
        let queueTimeMin = Math.floor(queueTimeSec / 60);
        queueTimeSec %= 60;
        let formattedSkill = {
          "skills": {
            "skillName": skill.skillName,
            "id": skill.id
          },
          "multiskillqueueTimeOut_Min": queueTimeMin,
          "multiskillqueueTimeOut_Sec": queueTimeSec
        };
        formattedMultiSkillData.push(formattedSkill);
      })

      const mutliskillfallback = [
        { fallbackType: 1, Type: "Dialout" },
        { fallbackType: 2, Type: "Disconnect" },
        { fallbackType: 3, Type: "voicemail" },
        { fallbackType: 4, Type: "Skill" },
        { fallbackType: 5, Type: "IVR" },
        { fallbackType: 7, Type: "Parking" }
      ];

      // console.log("here data is", props?.editData)
      const parsedFallbackType = JSON.parse(props?.editData?.fallbackDetails).fallbackType;
      let matchingFallback ;
      if(checkRoles('ROLE_PARKING')){
        matchingFallback = mutliskillfallback.find(item => item.fallbackType === parsedFallbackType);
      }else{
        let newValue ;
        newValue = mutliskillfallback.filter(itemN => itemN.fallbackType !== 7 || itemN.Type !== 'Parking');
        matchingFallback = newValue.find(item => item.fallbackType === parsedFallbackType);
      }
      // const matchingFallback = mutliskillfallback.find(item => item.fallbackType === parsedFallbackType);

      if (matchingFallback) {
        initData.fallbackType = {
          fallbackType: parsedFallbackType,
          Type: matchingFallback.Type
        };

        initData.skills = formattedMultiSkillData;

        if (initData?.fallbackType?.fallbackType === 4) {
          const matchSkill = skillList?.find(item => item.skillName === JSON.parse(props.editData.fallbackDetails).fallbackValue);
          if (matchSkill) {
            const newSkillObject = {
              skillName: matchSkill.skillName,
              id: matchSkill.id,
            }
            initData.queueSkillTransfer = newSkillObject
          }
          else {
            return null;
          }
        }
        if (initData?.fallbackType?.fallbackType === 5) {
          initData.queueIvrTransfer = JSON.parse(props.editData.fallbackDetails).fallbackValue;
        }
        if (initData?.fallbackType?.fallbackType === 1) {
          const matchDialout = dailoutList?.find(item => item.dialOutName === JSON.parse(props.editData.fallbackDetails).fallbackValue);
          if (matchDialout) {
            const newDialoutObject = {
              dialOutNumber: matchDialout.dialOutNumber,
              id: matchDialout.id,
              sip: matchDialout.sip,
              dialOutName: matchDialout.dialOutName
            }
            initData.dialOut = newDialoutObject;
          }
          else {
            return null;
          }
        }
      }
    }
    return (
      <div className="oz-agent-skill-form">
        <Formik
          initialValues={initData}
          validationSchema={
            SkillFormMode === "rename" || SkillFormMode === "Rename"
              ? null
              : validationSchema
          }
          onSubmit={(values) => {
            let data = structuredClone(values);
            // console.log(data, ":::::::::::::::::data on submit");
            let flag = false;
            let flagValid = 0;
            let skillFlagValid = 0;
            if (((SkillFormMode === ADMIN_FORM_MODES.add) || (SkillFormMode === "BasicInformation")) && (data.fallBackRule.fallBackRule === 6)) {
              data?.skills?.map((item, itemIndex) => {
                // console.log("here item ", item);
                if (item?.skills?.length === 0 || !item?.hasOwnProperty('skills')) {
                  flag = true;
                  showErrorNotification(`No Skills is selected on index ${itemIndex}`);
                  return null;
                } else if (item?.skills?.length !== 0 || item.hasOwnProperty('skills')) {
                  flag = false;
                  return null
                }
              })

            }

            if(localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false'){
              console.log("i came inside subAdmin", data)

              // function validateAgentSkillList(data) {
              //   const idMap = new Map();
              //   const agentIdMap = new Map();
              //   const groupIdMap = new Map();
              //   let errorMessages = '';
              
              //   for (let i = 0; i < data.agentSkillList.length; i++) {
              //     const priorityGroup = data.agentSkillList[i];

              //     // Check for empty agentList with non-null priority
              //     if (priorityGroup.priority !== null && priorityGroup?.agentList?.length === 0) {
              //       errorMessages = `Priority cannot be empty at index ${i+1}`;
              //       skillFlagValid = 1;
              //     }
                  
              //     for (const agent of priorityGroup.agentList) {

              //       if (idMap.has(agent.id) && groupIdMap.has(agent.groupId)) {
              //         const existingPriority = idMap.get(agent.id);
              //         skillFlagValid = 1;
              //         errorMessages = `Duplicate agent found with Agent Name111${agent.label || agent.agentName} is present on index ${i+1}`;
              //       }
                    
              //       if (agentIdMap.has(agent.agentId) && groupIdMap.has(agent.groupId)) {
              //         const existingPriority = agentIdMap.get(agent.agentId);
              //         skillFlagValid = 1;
              //         errorMessages = `Duplicate agent found with Agent Name222 ${agent.label || agent.agentName} is present on index ${i+1}`;
              //       }
                    
              //       idMap.set(agent.id, priorityGroup.priority);
              //       agentIdMap.set(agent.agentId, priorityGroup.priority);
              //       groupIdMap.set(agent.groupId, priorityGroup.priority);
              //     }
              //   }

              //   if (skillFlagValid === 1) {
              //     return {
              //       isValid: false,
              //       errors: errorMessages
              //     };
              //   }
              
              //   return {
              //     isValid: true,
              //     message: "Validation passed: No duplicate agents found across priority groups."
              //   };
              // }

              function validateAgentSkillList(data) {
                const idMap = new Map();
                const agentIdGroupMap = new Map(); // To store agentId along with groupId
                let errorMessages = '';
            
                for (let i = 0; i < data.agentSkillList.length; i++) {
                    const priorityGroup = data.agentSkillList[i];
            
                    // Check for empty agentList with non-null priority
                    if (priorityGroup.priority !== null && priorityGroup?.agentList?.length === 0) {
                        errorMessages = `Priority cannot be empty at index ${i+1}`;
                        skillFlagValid = 1;
                    }
            
                    for (const agent of priorityGroup.agentList) {
            
                        // Combine agentId and groupId to create a unique key for each agent-group combination
                        const agentKey = `${agent.agentId}-${agent.groupId}`;
            
                        // Check if both agentId and groupId are already present
                        if (agentIdGroupMap.has(agentKey)) {
                            const existingPriority = agentIdGroupMap.get(agentKey);
                            skillFlagValid = 1;
                            errorMessages = `Duplicate agent found with Agent Name ${agent.label || agent.agentName} is present on index ${i+1}`;
                        }
            
                        // Update the map with the agentId and groupId combination
                        agentIdGroupMap.set(agentKey, priorityGroup.priority);
                    }
                }
            
                if (skillFlagValid === 1) {
                    return {
                        isValid: false,
                        errors: errorMessages
                    };
                }
            
                return {
                    isValid: true,
                    message: "Validation passed: No duplicate agents found across priority groups."
                };
              }
            

              let validForSkillList = validateAgentSkillList(data);

              if(validForSkillList.isValid === false){
                  showErrorNotification(validForSkillList.errors);
                  return;
              }else{

                if (SkillFormMode !== "rename" && SkillFormMode !== "Agents") {
                  if (data.dropAction === true) {
                    data.callDropActions = [];
                    let callDropActionObj = {};
                    callDropActionObj.actionType =
                      data.dropAction === true ? data.actionType : null;
                    callDropActionObj.actionUrl =
                      data.dropAction === true ? data.actionUrl : null;
                    callDropActionObj.actionValue =
                      data.dropAction === true ? data.actionValue : null;
                    callDropActionObj.actionUrlType =
                      data.dropAction === true
                        ? data.actionUrlType?.actionUrlType
                        : null;
                    callDropActionObj.enabled =
                      data.dropAction === true ? data.enabled : null;
                    data.callDropActions.push(callDropActionObj);
                  }
                }
                if (!data.skillDetail1) {
                  data.skillDetail1 = null;
                }
                if (SkillFormMode === ADMIN_FORM_MODES.add && flag === false) {
                  let tempAgents =
                    SkillFormMode === "Agents" || SkillFormMode === "Create"
                      ? JSON.parse(localStorage.getItem("agentSkillList")) !== null
                        ? JSON.parse(localStorage.getItem("agentSkillList"))
                        : data.agentSkillList
                      : data.agentSkillList;

                  let newAgents = [];
                  let canAdd = true;
                  // eslint-disable-next-line array-callback-return
                  tempAgents.map((val, index) => {
                    let priority = val.priority;
                    if (priority !== null && val.agentList.length > 0) {
                      // eslint-disable-next-line array-callback-return
                      val.agentList.map((agentVal, agentIndex) => {
                        newAgents.push({
                          priority: Number(priority),
                          agentName: agentVal.agentName || agentVal.label,
                          id: agentVal.id,
                          agentId: agentVal.agentId,
                          groupId: agentVal.groupId || null,
                          groupName: agentVal.groupName || null
                        });
                      });
                    } else {
                      canAdd = false;
                    }
                  });
                  data.agents = newAgents;
                  data.agentSkillList = newAgents;
                  if (canAdd) {
                    addSkillForm(data);
                  }
                } else {
                  if (valid === true && flag === false) {
                    if (SkillFormMode !== "rename") {
                      if (data?.fallBackRule?.fallBackRule === 6) {
                        if (data?.fallbackType === "" || data?.fallbackType === null) {
                          flagValid = 1;
                          showErrorNotification("fallbacktype not seleceted");
                          return;
                        } else if (data?.fallbackType?.fallbackType === 4) {
                          if (data?.queueSkillTransferMulti === null || data?.queueSkillTransferMulti === undefined) {
                            flagValid = 1;
                            showErrorNotification("Skill not seleceted");
                            return;
                          }
                        } else if (data?.fallbackType?.fallbackType === 1) {
                          if (data?.dialOutMulti === null || data?.dialOutMulti === undefined) {
                            flagValid = 1;
                            showErrorNotification("DialOut not seleceted");
                            return;
                          }
                        } else if (data?.fallbackType?.fallbackType === 5) {
                          if (data?.queueIvrTransferMulti === null || data?.queueIvrTransferMulti === undefined || data?.queueIvrTransferMulti === "") {
                            flagValid = 1;
                            showErrorNotification("IVR Transfer not seleceted");
                            return;
                          }
                        }
                      } else if (data?.fallBackRule?.fallBackRule === 1) {
                        if (data?.dialOut === null || data?.dialOut === undefined) {
                          flagValid = 1;
                          showErrorNotification("DialOut not seleceted");
                          return;
                        }
                      } else if (data?.fallBackRule?.fallBackRule === 4) {
                        if (data?.queueSkillTransfer === null || data?.queueSkillTransfer === undefined) {
                          flagValid = 1;
                          showErrorNotification("Skill not seleceted");
                          return;
                        }
                      } else if (data?.fallBackRule?.fallBackRule === 5) {
                        if (data?.ivrFlow?.flowName === "Other" && data?.ivrFlow?.id === "1_Other") {
                          if (data?.queueIvrTransfer === "" || data?.queueIvrTransfer === undefined || data?.queueIvrTransfer === null) {
                            flagValid = 1;
                            showErrorNotification("Transfer to IVR App. URL not seleceted");
                            return;
                          }
                        }
                        if (data?.ivrFlow === null || data?.ivrFlow === undefined) {
                          flagValid = 1;
                          showErrorNotification("IvrFlow not seleceted");
                          return;
                        }
                      }
                      if (flagValid === 0) {
                        let tempAgents =
                          SkillFormMode === "Agents" || SkillFormMode === "Create"
                            ? JSON.parse(localStorage.getItem("agentSkillList")) !== null
                              ? JSON.parse(localStorage.getItem("agentSkillList"))
                              : data.agentSkillList
                            : data.agentSkillList;
                        let newAgent = [];
                        let canEdit = true;
                        // eslint-disable-next-line array-callback-return
                        tempAgents.map((val, index) => {
                          let priority = val.priority;
                          if (priority !== null && val.agentList.length > 0) {
                            // eslint-disable-next-line array-callback-return
                            val.agentList.map((agentVal, agentIndex) => {
                              newAgent.push({
                                priority: priority,
                                agentName: agentVal.agentName || agentVal.label,
                                id: agentVal.id,
                                agentId: agentVal.agentId,
                                groupId: agentVal.groupId || null,
                                groupName: agentVal.groupName || null
                              });
                            });
                          } else {
                            canEdit = false;
                          }
                        });
                        data.agents = newAgent;
                        data.agentSkillList = newAgent;
                        if (canEdit) {
                          props.updateTransfer(data, SkillFormMode);
                          SkillFormMode !== "rename" && setSkillFormMode("View");
                        }
                      }
                    }
                    else {
                      props.updateTransfer(data, SkillFormMode);
                    }
                  }
                }
              }
            }else{
              console.log("i came inside Admin", data)

              if (SkillFormMode !== "rename" && SkillFormMode !== "Agents") {
                if (data.dropAction === true) {
                  data.callDropActions = [];
                  let callDropActionObj = {};
                  callDropActionObj.actionType =
                    data.dropAction === true ? data.actionType : null;
                  callDropActionObj.actionUrl =
                    data.dropAction === true ? data.actionUrl : null;
                  callDropActionObj.actionValue =
                    data.dropAction === true ? data.actionValue : null;
                  callDropActionObj.actionUrlType =
                    data.dropAction === true
                      ? data.actionUrlType?.actionUrlType
                      : null;
                  callDropActionObj.enabled =
                    data.dropAction === true ? data.enabled : null;
                  data.callDropActions.push(callDropActionObj);
                }
              }
              if (!data.skillDetail1) {
                data.skillDetail1 = null;
              }
              if (SkillFormMode === ADMIN_FORM_MODES.add && flag === false) {
                let tempAgents =
                  SkillFormMode === "Agents" || SkillFormMode === "Create"
                    ? JSON.parse(localStorage.getItem("agentSkillList")) !== null
                      ? JSON.parse(localStorage.getItem("agentSkillList"))
                      : data.agentSkillList
                    : data.agentSkillList;
  
                let newAgents = [];
                let canAdd = true;
                // eslint-disable-next-line array-callback-return
                tempAgents.map((val, index) => {
                  let priority = val.priority;
                  if (priority !== null && val.agentList.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    val.agentList.map((agentVal, agentIndex) => {
                      newAgents.push({
                        priority: Number(priority),
                        agentName: agentVal.agentName,
                        id: agentVal.id,
                        agentId: agentVal.agentId,
                      });
                    });
                  } else {
                    canAdd = false;
                  }
                });
                data.agents = newAgents;
                data.agentSkillList = newAgents;
                if (canAdd) {
                  addSkillForm(data);
                }
              } else {
                if (valid === true && flag === false) {
                  if (SkillFormMode !== "rename") {
                    if (data?.fallBackRule?.fallBackRule === 6) {
                      if (data?.fallbackType === "" || data?.fallbackType === null) {
                        flagValid = 1;
                        showErrorNotification("fallbacktype not seleceted");
                        return;
                      } else if (data?.fallbackType?.fallbackType === 4) {
                        if (data?.queueSkillTransferMulti === null || data?.queueSkillTransferMulti === undefined) {
                          flagValid = 1;
                          showErrorNotification("Skill not seleceted");
                          return;
                        }
                      } else if (data?.fallbackType?.fallbackType === 1) {
                        if (data?.dialOutMulti === null || data?.dialOutMulti === undefined) {
                          flagValid = 1;
                          showErrorNotification("DialOut not seleceted");
                          return;
                        }
                      } else if (data?.fallbackType?.fallbackType === 5) {
                        if (data?.queueIvrTransferMulti === null || data?.queueIvrTransferMulti === undefined || data?.queueIvrTransferMulti === "") {
                          flagValid = 1;
                          showErrorNotification("IVR Transfer not seleceted");
                          return;
                        }
                      }
                    } else if (data?.fallBackRule?.fallBackRule === 1) {
                      if (data?.dialOut === null || data?.dialOut === undefined) {
                        flagValid = 1;
                        showErrorNotification("DialOut not seleceted");
                        return;
                      }
                    } else if (data?.fallBackRule?.fallBackRule === 4) {
                      if (data?.queueSkillTransfer === null || data?.queueSkillTransfer === undefined) {
                        flagValid = 1;
                        showErrorNotification("Skill not seleceted");
                        return;
                      }
                    } else if (data?.fallBackRule?.fallBackRule === 5) {
                      if (data?.ivrFlow?.flowName === "Other" && data?.ivrFlow?.id === "1_Other") {
                        if (data?.queueIvrTransfer === "" || data?.queueIvrTransfer === undefined || data?.queueIvrTransfer === null) {
                          flagValid = 1;
                          showErrorNotification("Transfer to IVR App. URL not seleceted");
                          return;
                        }
                      }
                      if (data?.ivrFlow === null || data?.ivrFlow === undefined) {
                        flagValid = 1;
                        showErrorNotification("IvrFlow not seleceted");
                        return;
                      }
                    }
                    if (flagValid === 0) {
                      let tempAgents =
                        SkillFormMode === "Agents" || SkillFormMode === "Create"
                          ? JSON.parse(localStorage.getItem("agentSkillList")) !== null
                            ? JSON.parse(localStorage.getItem("agentSkillList"))
                            : data.agentSkillList
                          : data.agentSkillList;
                      let newAgent = [];
                      let canEdit = true;
                      // eslint-disable-next-line array-callback-return
                      tempAgents.map((val, index) => {
                        let priority = val.priority;
                        if (priority !== null && val.agentList.length > 0) {
                          // eslint-disable-next-line array-callback-return
                          val.agentList.map((agentVal, agentIndex) => {
                            newAgent.push({
                              priority: priority,
                              agentName: agentVal.agentName,
                              id: agentVal.id,
                              agentId: agentVal.agentId,
                            });
                          });
                        } else {
                          canEdit = false;
                        }
                      });
                      data.agents = newAgent;
                      data.agentSkillList = newAgent;
                      if (canEdit) {
                        props.updateTransfer(data, SkillFormMode);
                        SkillFormMode !== "rename" && setSkillFormMode("View");
                      }
                    }
                  }
                  else {
                    props.updateTransfer(data, SkillFormMode);
                  }
                }
              }

            }
          }
          }
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              id="skillForm"
              className="ozcw-scrollbar"
            >
              {SkillFormMode === ADMIN_FORM_MODES.add && (
                <Grid
                  container
                  spacing={1}
                  sx={{
                    backgroundColor: "white",
                    padding: "7px",
                    paddingTop: "10px",
                    height: "500px",
                    width: "800px",
                  }}
                >
                  <Grid item xs={12} sm={6} md={4} lg={12}>
                    <Grid
                      marginBottom="21px"
                      marginTop="-10px"
                      marginLeft={"-10px"}
                      marginRight={"-10px"}
                    >
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    paddingBottom="15px"
                  >
                    <Grid item xs={6}>
                      <OZInput
                        name="skillName"
                        placeholder="Enter Skill Name"
                        label="Skill Name *"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <OZSelect
                        name="location"
                        label="Skill Location *"
                        placeholder="Please select  location"
                        options={location}
                        optionLabel="name"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    display="flex"
                    direction={"row"}
                    paddingBottom="15px"
                  >
                    <Grid item xs={6}>
                      {" "}
                      <OZSelect
                        name="recOn"
                        label="Record *"
                        placeholder="Full"
                        optionLabel="record"
                        options={skill}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomAutoComplete
                        limitTags={2}
                        mode='Add'
                        name="fwpNumbers"
                        placeholder={"Please select phone numbers"}
                        Datas={PhoneNumberList}
                        labelHeader='Assign Phone Numbers for Hunting (offline) *'
                        optionLabelName='phoneNumber'
                        showCheckBox={true}
                        multiple={true}
                        showselectall="true"
                        popupIcon={null}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} display="flex">
                    <Grid item xs={6}>
                      <OZInput
                        name="skillDetail1"
                        placeholder="Enter Details"
                        label="Skill Details 1"
                      />
                    </Grid>
                    <Grid item xs={6} paddingLeft="25px" marginBottom="10px">
                      <OZInput
                        name="queueSize"
                        label="Queue Size *"
                        placeholder="Enter Value"
                      />
                    </Grid>
                    {checkRoles('ROLE_ADMIN') &&
                      <Grid item xs={6}>
                        <OZInput
                          name="priority"
                          label="Priority *"
                          placeholder="Priority"
                        />
                      </Grid>
                    }
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      marginLeft={"2px"}
                    >
                      <Grid item xs={6} marginTop="10px">
                        <OZInput
                          name="skillDetail2"
                          placeholder="Enter Details"
                          label="Skill Details 2"
                        />
                      </Grid>
                      <Grid item xs={3} marginTop="10px">
                        <OZSelect
                          name="queueTimeOut_Min"
                          label="Queue Timeout"
                          placeholder="Select Mins"
                          options={[...Array(31).keys()]}
                        />
                      </Grid>
                      <Grid item xs={3} marginTop="30px">
                        <OZSelect
                          name="queueTimeOut_Sec"
                          placeholder="Select Secs"
                          options={[...Array(60).keys()]}
                        />
                      </Grid>
                      <Grid item xs={6} marginTop="10px">
                        <OZInput
                          name="skillDetail3"
                          placeholder="Enter Details"
                          label="Skill Details 3"
                        />
                      </Grid>
                      {(props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) && (
                          <Grid item xs={6} marginTop="10px">
                            <OZSelect
                              name="fallBackRule"
                              label="FallBack Rule*" //this is written just for testing in Add mode
                              placeholder="Disconnect"
                              optionLabel="Type"
                              // options={[
                              //   { fallBackRule: 1, Type: "Dialout" },
                              //   { fallBackRule: 2, Type: "Disconnect" },
                              //   { fallBackRule: 3, Type: "voicemail" },
                              //   { fallBackRule: 4, Type: "Skill" },
                              //   { fallBackRule: 5, Type: "IVR" },
                              //   { fallBackRule: 6, Type: "MultiSkill" },
                              //   { fallBackRule: 7, Type: "Parking" }
                              // ]}
                              options={checkRoles('ROLE_PARKING') ? fallbackRuleParking : fallbackRuleParking.filter(item => item.fallBackRule !== 7 || item.Type !== 'Parking')}
                            />
                            {props.values.fallBackRule.fallBackRule === 7 &&
                              <div>
                                <span className={classes.newForm} >(Note:Parking is applicable only to WhatsApp queues.)</span>
                              </div>
                            }
                          </Grid>
                        )}
                      {(props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) && props.values.fallBackRule &&
                        JSON.stringify(props.values.fallBackRule) ===
                        JSON.stringify({
                          fallBackRule: 6,
                          Type: "MultiSkill",
                        }) && (
                          <>
                            <Grid item sx={{ width: "50%" }}>
                              <OZSelect
                                name="fallbackType"
                                label="FallBack Rule*"
                                placeholder="Disconnect"
                                optionLabel="Type"
                                // options={[
                                //   { fallbackType: 1, Type: "Dialout" },
                                //   { fallbackType: 2, Type: "Disconnect" },
                                //   { fallbackType: 3, Type: "voicemail" },
                                //   { fallbackType: 4, Type: "Skill" },
                                //   { fallbackType: 5, Type: "IVR" },
                                //   { fallbackType: 7, Type: "Parking" }
                                // ]}
                                options={checkRoles('ROLE_PARKING') ? fallbackTypeParking : fallbackTypeParking.filter(item => (item.fallbackType !== 7 || item.Type !== 'Parking') && (item.fallbackType !== 6 || item.Type !== 'MultiSkill'))}
                              />
                              {props.values.fallbackType.fallbackType === 7 &&
                                <div>
                                  <span className={classes.newForm} >(Note:Parking is applicable only to WhatsApp queues.)</span>
                                </div>
                              }
                            </Grid>
                            {props.values.fallbackType &&
                              JSON.stringify(props.values.fallbackType) ===
                              JSON.stringify({
                                fallbackType: 1,
                                Type: "Dialout",
                              }) && (
                                <Grid item sx={{ width: "50%" }}>
                                  <OZSelect
                                    name="dialOutMulti"
                                    label="Dailout Name *"
                                    placeholder="Select Name"
                                    optionLabel="dialOutName"
                                    options={dailoutList}
                                  />
                                </Grid>
                              )}
                            {props.values.fallbackType &&
                              JSON.stringify(props.values.fallbackType) ===
                              JSON.stringify({ fallbackType: 5, Type: "IVR" }) && (
                                <Grid item sx={{ width: "50%" }}>
                                  <OZInput
                                    name="queueIvrTransferMulti"
                                    placeholder="Transfer to IVR App. URL"
                                    label="Transfer to IVR App. URL*" />
                                  <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '5px', marginLeft: '10px' }}>
                                    <label>eg: http://kookoo.in/myIvr</label>
                                  </div>
                                </Grid>
                              )}
                            {props.values.fallbackType &&
                              JSON.stringify(props.values.fallbackType) ===
                              JSON.stringify({
                                fallbackType: 4,
                                Type: "Skill",
                              }) && (
                                <Grid item sx={{ width: "50%" }}>
                                  <OZSelect
                                    name="queueSkillTransferMulti"
                                    label="Transfer Skill *"
                                    placeholder="Select Skill"
                                    optionLabel="skillName"
                                    options={skillList && skillList?.filter(item => item?.skillName !== props?.values?.skillName)}
                                  />
                                </Grid>
                              )}
                            <Grid item xs={10}>
                              <SkillAutocomplete
                                mode={"Create"}
                                autoCompletePlaceHolder={
                                  "Select Skills"
                                }
                                skillDataList={skillList && skillList?.filter(item => item.skillName !== props?.values?.skillName)}
                                formikValues={props}
                              />
                            </Grid>
                          </>
                        )}
                      {(props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) && props.values.fallBackRule &&
                        JSON.stringify(props.values.fallBackRule) ===
                        JSON.stringify({
                          fallBackRule: 1,
                          Type: "Dialout",
                        }) && (
                          <Grid item xs={6}>
                            <OZSelect
                              name="dialOut"
                              label="Dailout Name *"
                              placeholder="Select Name"
                              optionLabel="dialOutName"
                              options={dailoutList}
                            />
                          </Grid>
                        )}
                      {(props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) && props.values.fallBackRule &&
                        JSON.stringify(props.values.fallBackRule) ===
                        JSON.stringify({ fallBackRule: 5, Type: "IVR" }) && (
                          <>
                            <Grid item xs={6}>
                              <OZSelect
                                name="ivrFlow"
                                label="IVR Flow *"
                                placeholder="Select Ivr Flow"
                                optionLabel="flowName"
                                options={ivrList}
                              />
                            </Grid>
                            {props.values.fallBackRule &&
                              JSON.stringify(props.values.fallBackRule) === JSON.stringify({ fallBackRule: 5, Type: "IVR" }) &&
                              props?.values?.ivrFlow?.flowName === "Other" && (
                                <Grid item xs={6}>
                                  <OZInput
                                    name="queueIvrTransfer"
                                    placeholder="Transfer to IVR App. URL"
                                    label="Transfer to IVR App. URL*"
                                  />
                                  <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '5px', marginLeft: '10px' }}>
                                    <label>eg: http://kookoo.in/myIvr</label>
                                  </div>
                                </Grid>
                              )}
                          </>
                        )}
                      {(props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) && props.values.fallBackRule &&
                        JSON.stringify(props.values.fallBackRule) ===
                        JSON.stringify({
                          fallBackRule: 4,
                          Type: "Skill",
                        }) && (
                          <Grid item xs={6}>
                            <OZSelect
                              name="queueSkillTransfer"
                              label="Transfer Skill *"
                              placeholder="Select Skill"
                              optionLabel="skillName"
                              options={skillList && skillList?.filter(item => item?.skillName !== props?.values?.skillName)}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  {checkRoles("ROLE_SKILLDROPACTION") && (
                    <Grid container>
                      <Stack direction={"row"} spacing={1} marginTop={"10px"}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          lg={12}
                          paddingBottom="30px"
                          marginLeft={"5px"}
                        >
                          <OZFCheckbox
                            name="dropAction"
                            label={<>Call Back <span style={{ opacity: '0.5' }}>(Call Drop Action)</span></>}
                            labelPosition="end"
                          />
                          {/* <OZFCheckbox
                            name="enabled"
                            label="Call Back"
                            labelPosition="end"
                            disabled={!props.values.dropAction}
                          /> */}
                        </Grid>
                      </Stack>
                    </Grid>
                  )}
                  {checkRoles("ROLE_SKILLDROPACTION") && (
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      direction={"row"}
                      paddingBottom="13px"
                      paddingTop="5px"
                    >
                      <Grid sx={{ width: "14%" }} paddingLeft="25px">
                        <OZSelect
                          name="actionUrlType"
                          label="Request Type"
                          disabled={!props.values.dropAction}
                          optionLabel="method"
                          placeholder="GET"
                          options={url}
                        />
                      </Grid>
                      <Grid sx={{ width: "36.5%" }} paddingLeft="20px">
                        <OZInput
                          name="actionUrl"
                          disabled={!props.values.dropAction}
                          placeholder="URL"
                          label="URL *"
                        />
                      </Grid>
                      <Grid sx={{ width: "14%" }} paddingLeft="23px">
                        <OZInput
                          name="actionValue"
                          label="Tries *"
                          placeholder="Tries"
                          disabled={!props.values.dropAction}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {SkillFormMode === "Create" && (
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      marginTop={
                        checkRoles("ROLE_SKILLDROPACTION") ? "px" : "-10px"
                      }
                      paddingBottom="10px"
                    >
                      <Grid
                        item
                        xs={12}
                        paddingRight="15px"
                        sx={{ display: "flex", gap: 1 }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 400,
                          }}
                        >
                          Agents *
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "#99A0A8",
                            alignSelf: "center",
                          }}
                        >
                          (Choose specific levels for different Agents)
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {SkillFormMode === "Create" && (
                    <Grid
                      container
                      spacing={3}
                      display="flex"
                      direction={"row"}
                      paddingBottom="18px"
                    >
                      <Grid item xs={7.5} marginRight="20px">
                        {localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false' ? 
                          <AgentListForSub
                            mode={"Create"}
                            autoCompleteMode={"Add"}
                            autoCompletePlaceHolder={
                              "Select agents or type name to search"
                            }
                            agentDataList={agentDataList}
                            formikValues={props}
                            updateValues={loggedInAgentsByGroup}
                            fullLengthOfAgentsByGroup={fullLengthOfAgentsByGroup}
                          />
                          :
                          <AgentList
                            mode={"Create"}
                            autoCompleteMode={"Add"}
                            autoCompletePlaceHolder={
                              "Select agents or type name to search"
                            }
                            agentDataList={agentDataList}
                            formikValues={props}
                            updateValues={loggedInAgentsByGroup}
                          />
                        }
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {SkillFormMode === "BasicInformation" && (
                <div>
                  <Grid
                    container
                    sx={{
                      height: "400px",
                      width: "650px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={12}
                      marginBottom="10px"
                    >
                      <OZSelect
                        name="location"
                        label="Skill Location *"
                        placeholder="Please select  location"
                        options={location}
                        optionLabel="name"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={12}
                      marginBottom="10px"
                    >
                      <OZSelect
                        name="recOn"
                        label="Record *"
                        placeholder="Full"
                        optionLabel="record"
                        options={skill}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={12}
                      marginBottom="10px"
                    >
                      <OZInput
                        name="queueSize"
                        label="Queue Size*"
                        placeholder="Enter Value"
                      />
                    </Grid>
                    {checkRoles('ROLE_ADMIN') &&
                      <Grid item xs={12} marginBottom="10px">
                        <OZInput
                          name="priority"
                          label="Priority *"
                          placeholder="Priority"
                        />
                      </Grid>
                    }
                    <Grid container direction={"row"} marginTop="5px">
                      <Grid item xs={5.5}>
                        <OZSelect
                          name="queueTimeOut_Min"
                          label="Queue Timeout"
                          placeholder="Select Mins"
                          options={[...Array(31).keys()]}
                        />
                      </Grid>
                      <Grid item xs={5.5} marginLeft="30px" marginTop={"20px"}>
                        <OZSelect
                          name="queueTimeOut_Sec"
                          placeholder="Select Secs"
                          options={[...Array(60).keys()]}
                        />
                      </Grid>
                    </Grid>
                    {(props.values.queueTimeOut_Min !== 0 ||
                      props.values.queueTimeOut_Sec !== 0) && (
                        <Grid item xs={12} marginTop="10px">
                          <OZSelect
                            name="fallBackRule"
                            label="FallBack Rule*"
                            placeholder="Disconnect"
                            optionLabel="Type"
                            // options={[
                            //   { fallBackRule: 1, Type: "Dialout" },
                            //   { fallBackRule: 2, Type: "Disconnect" },
                            //   { fallBackRule: 3, Type: "voicemail" },
                            //   { fallBackRule: 4, Type: "Skill" },
                            //   { fallBackRule: 5, Type: "IVR" },
                            //   { fallBackRule: 6, Type: "MultiSkill" },
                            //   { fallBackRule: 7, Type: "Parking" }
                            // ]}
                            options={checkRoles('ROLE_PARKING') ? fallbackRuleParking : fallbackRuleParking.filter(item => item.fallBackRule !== 7 || item.Type !== 'Parking')}
                          />
                          {props.values.fallBackRule.fallBackRule === 7 &&
                            <div>
                              <span className={classes.newForm} >(Note:Parking is applicable only to WhatsApp queues.)</span>
                            </div>
                          }
                        </Grid>
                      )}
                    {props.values.fallBackRule &&
                      (props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) &&
                      JSON.stringify(props.values.fallBackRule) ===
                      JSON.stringify({
                        fallBackRule: 1,
                        Type: "Dialout",
                      }) && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={12}
                          marginBottom="10px"
                          marginTop={"10px"}
                        >
                          <OZSelect
                            name="dialOut"
                            label="Dailout Name *"
                            placeholder="Select Name"
                            optionLabel="dialOutName"
                            options={dailoutList}
                          />
                        </Grid>
                      )}
                    {props.values.fallBackRule &&
                      (props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) &&
                      JSON.stringify(props.values.fallBackRule) ===
                      JSON.stringify({ fallBackRule: 5, Type: "IVR" }) && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={12}
                            marginBottom="10px"
                            marginTop={"10px"}
                          >
                            <OZSelect
                              name="ivrFlow"
                              label="IVR Flow *"
                              placeholder="Select Ivr Flow"
                              optionLabel="flowName"
                              options={ivrList}
                            />
                          </Grid>
                          {props.values.fallBackRule &&
                            JSON.stringify(props.values.fallBackRule) === JSON.stringify({ fallBackRule: 5, Type: "IVR" }) &&
                            props?.values?.ivrFlow?.flowName === "Other" && (
                              <Grid item xs={12}>
                                <OZInput
                                  name="queueIvrTransfer"
                                  placeholder="Transfer to IVR App. URL"
                                  label="Transfer to IVR App. URL*"
                                />
                                <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '5px', marginLeft: '10px' }}>
                                  <label>eg: http://kookoo.in/myIvr</label>
                                </div>
                              </Grid>
                            )}
                        </>
                      )}
                    {props.values.fallBackRule &&
                      (props.values.queueTimeOut_Min !== 0 ||
                        props.values.queueTimeOut_Sec !== 0) &&
                      JSON.stringify(props.values.fallBackRule) ===
                      JSON.stringify({ fallBackRule: 4, Type: "Skill" }) && (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={12}
                          marginBottom="10px"
                          marginTop={"10px"}
                        >
                          <OZSelect
                            name="queueSkillTransfer"
                            label="Transfer Skill *"
                            placeholder="Select Skill"
                            optionLabel="skillName"
                            options={skillList && skillList?.filter(item => item?.skillName !== props?.values?.skillName)}
                          />
                        </Grid>
                      )}

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={12}
                      paddingBottom="14px"
                      paddingTop={"10px"}
                    >
                      <CustomAutoComplete
                        limitTags={2}
                        mode='Add'
                        name="fwpNumbers"
                        placeholder={"Please select phone numbers"}
                        Datas={PhoneNumberList}
                        labelHeader='Assign Phone Numbers for Hunting (offline) *'
                        optionLabelName='phoneNumber'
                        showCheckBox={true}
                        multiple={true}
                        showselectall="true"
                        popupIcon={null}
                      />
                    </Grid>
                    <Grid item xs={12} paddingBottom={"10px"}>
                      <OZInput
                        name="skillDetail1"
                        placeholder="Enter Details"
                        label="Skill Details 1"
                      />
                    </Grid>
                    <Grid item xs={12} paddingBottom={"10px"}>
                      <OZInput
                        name="skillDetail2"
                        placeholder="Enter Details"
                        label="Skill Details 2"
                      />
                    </Grid>
                    <Grid item xs={12} paddingBottom={"-30px"}>
                      <OZInput
                        name="skillDetail3"
                        placeholder="Enter Details"
                        label="Skill Details 3"
                      />
                    </Grid>
                    {(props.values.queueTimeOut_Min !== 0 ||
                      props.values.queueTimeOut_Sec !== 0) && props.values.fallBackRule &&
                      JSON.stringify(props.values.fallBackRule) ===
                      JSON.stringify({
                        fallBackRule: 6,
                        Type: "MultiSkill",
                      }) && (
                        <>
                          <Grid
                            item
                            sx={{ width: "50%" }}
                            marginTop="15px"
                            paddingLeft={SkillFormMode === 'Create' ? '25px' : '3px'}
                          >
                            <OZSelect
                              name="fallbackType"
                              label="FallBack Rule*"
                              placeholder="Disconnect"
                              optionLabel="Type"
                              // options={[
                              //   { fallbackType: 1, Type: "Dialout" },
                              //   { fallbackType: 2, Type: "Disconnect" },
                              //   { fallbackType: 3, Type: "voicemail" },
                              //   { fallbackType: 4, Type: "Skill" },
                              //   { fallbackType: 5, Type: "IVR" },
                              //   { fallbackType: 7, Type: "Parking" }
                              // ]}
                              options={checkRoles('ROLE_PARKING') ? fallbackTypeParking : fallbackTypeParking.filter(item => (item.fallbackType !== 7 || item.Type !== 'Parking') && (item.fallbackType !== 6 || item.Type !== 'MultiSkill'))}
                            />
                            {props.values.fallbackType.fallbackType === 7 &&
                              <div>
                                <span className={classes.newForm} >(Note:Parking is applicable only to WhatsApp queues.)</span>
                              </div>
                            }
                          </Grid>
                          {props.values.fallbackType &&
                            JSON.stringify(props.values.fallbackType) ===
                            JSON.stringify({
                              fallbackType: 1,
                              Type: "Dialout",
                            }) && (
                              <Grid item sx={{ width: "50%" }}
                                marginTop={SkillFormMode === 'Create' ? "35px" : '15px'}
                                paddingLeft={SkillFormMode === 'Create' ? '25px' : '5px'}
                              >
                                <OZSelect
                                  name="dialOutMulti"
                                  label="Dailout Name *"
                                  placeholder="Select Name"
                                  optionLabel="dialOutName"
                                  options={dailoutList}
                                />
                              </Grid>
                            )}
                          {props.values.fallbackType &&
                            JSON.stringify(props.values.fallbackType) ===
                            JSON.stringify({ fallbackType: 5, Type: "IVR" }) && (
                              <Grid
                                item
                                sx={{ width: "50%" }}
                                marginTop={SkillFormMode === 'Create' ? "35px" : '15px'}
                                paddingLeft={SkillFormMode === 'Create' ? '25px' : '5px'}
                              >
                                <OZInput
                                  name="queueIvrTransferMulti"
                                  placeholder="Transfer to IVR App. URL"
                                  label="Transfer to IVR App. URL*" />
                                <div style={{ fontSize: '12px', display: 'flex', color: '#99A0A8', fontWeight: '400', marginTop: '5px', marginLeft: '10px' }}>
                                  <label>eg: http://kookoo.in/myIvr</label>
                                </div>
                              </Grid>
                            )}
                          {props.values.fallbackType &&
                            JSON.stringify(props.values.fallbackType) ===
                            JSON.stringify({
                              fallbackType: 4,
                              Type: "Skill",
                            }) && (
                              <Grid
                                item
                                sx={{ width: "50%" }}
                                marginTop={SkillFormMode === 'Create' ? "35px" : '15px'}
                                paddingLeft={SkillFormMode === 'Create' ? '25px' : '5px'}
                              >
                                <OZSelect
                                  name="queueSkillTransferMulti"
                                  label="Transfer Skill *"
                                  placeholder="Select Skill"
                                  optionLabel="skillName"
                                  options={skillList && skillList?.filter(item => item?.skillName !== props?.values?.skillName)}
                                />
                              </Grid>
                            )}
                          <Grid item xs={10} marginTop="15px" paddingLeft={SkillFormMode === 'Create' ? '25px' : '5px'}>
                            <SkillAutocomplete
                              mode={"Edit"}
                              autoCompletePlaceHolder={
                                "Select Skills"
                              }
                              skillDataList={skillList && skillList?.filter(item => item?.skillName !== props?.values?.skillName)}
                              formikValues={props}
                            />
                          </Grid>

                        </>
                      )}
                  </Grid>
                </div>
              )}
              {SkillFormMode === "Rename" && (
                <Grid
                  container={true}
                  spacing="10px"
                  marginLeft={"10px"}
                  sx={{ height: "250px", width: "464px" }}
                >
                  <div className="oz-skill-basicinfo-container">
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "18px",
                        marginTop: "30px",
                      }}
                    >
                      Rename
                    </Typography>
                    <IconButton
                      aria-label="close"
                      size="small"
                      onClick={() => {
                        setSkillFormMode(ADMIN_FORM_MODES.view);
                      }}
                      sx={{
                        color: "#99A0A8",
                        marginLeft: "430px",
                        position: "absolute",
                        height: "30px",
                        marginTop: "35px",
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/icons/close.svg`}
                        alt="x"
                      ></img>
                    </IconButton>
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12} marginLeft={"-10px"} >
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          backgroundColor: '#ffff',
                          fontSize: '18px',
                          color: "#212121",
                          height: '44px',
                          '&:hover': {
                            border: "none"
                          },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #D6D6D7'
                        },
                        '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #D6D6D7'
                        }
                      }}
                      defaultValue={props?.values?.skillName}
                      fullWidth
                      onChange={(e) => getValidationInViewMode(e, e.target.value)}
                      error={!valid}
                      helperText={!valid ? "Skill Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters." : ""}
                    />
                  </Grid>
                  <div className="oz-skill-basicinfo-buttons-container">
                    <Button
                      variant="outlined"
                      sx={buttonStyle}
                      onClick={() => {
                        setSkillFormMode(ADMIN_FORM_MODES.view);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      sx={buttonStyle}
                      style={{ marginLeft: "10px" }}
                      variant="contained"
                      id="skillForm"
                    >
                      save
                    </Button>
                  </div>
                </Grid>
              )}
              {SkillFormMode === "EditFeature" &&
                checkRoles("ROLE_SKILLDROPACTION") && (
                  <div style={{ height: "120px", width: "400px" }}>
                    <Grid container>
                      <Stack direction={"row"} spacing={1} marginLeft={"3px"}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={4}
                          lg={12}
                          paddingBottom="20px"
                        >
                          <OZFCheckbox
                            size="medium"
                            name="dropAction"
                            label={<>Call Back <span style={{ opacity: '0.5' }}>(Call Drop Action)</span></>}
                            labelPosition="end"
                          />
                          {/* <OZFCheckbox
                            name="enabled"
                            label="Call Back"
                            labelPosition="end"
                            disabled={!props.values.dropAction}
                          /> */}
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      display="flex"
                      direction={"row"}
                    >
                      <Grid item lg={3} xs={3} md={3}>
                        <OZSelect
                          name="actionUrlType"
                          label="Request Type"
                          disabled={!props.values.dropAction}
                          optionLabel="method"
                          placeholder="GET"
                          options={url}
                        />
                      </Grid>
                      <Grid item lg={6.2} xs={6.2} md={6.2}>
                        <OZInput
                          name="actionUrl"
                          disabled={!props.values.dropAction}
                          placeholder="URL"
                          label="URL *"
                        />
                      </Grid>
                      <Grid item lg={2.8} xs={2.8} md={2.8}>
                        <OZInput
                          name="actionValue"
                          label="Tries *"
                          placeholder="Tries"
                          disabled={!props.values.dropAction}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              {SkillFormMode === "Agents" && (
                <Grid
                  container={true}
                  sx={{
                    /*  marginTop: checkRoles("ROLE_SKILLDROPACTION") ? '35px' : "15px", */
                    width: (localStorage.getItem('parentUser') !== 'null' && !checkRoles('ROLE_ADMIN')) ? "35vw" : "458px",
                  }}
                >
                  <Grid item>
                    {localStorage.getItem('isAdmin') === false || localStorage.getItem('isAdmin') === 'false' ?
                      <AgentListForSub
                        mode={"Edit"}
                        agentDataList={agentDataList}
                        autoCompleteMode={"Edit"}
                        showAvatar={true}
                        autoCompletePlaceHolder={"Search Agent"}
                        formikValues={props}
                        updateValues={loggedInAgentsByGroup}
                        fullLengthOfAgentsByGroup={fullLengthOfAgentsByGroup}
                      />
                      :
                      <AgentList
                        mode={"Edit"}
                        agentDataList={agentDataList}
                        autoCompleteMode={"Edit"}
                        showAvatar={true}
                        autoCompletePlaceHolder={"Search Agent"}
                        formikValues={props}
                        updateValues={loggedInAgentsByGroup}
                      />
                    }
                  </Grid>
                </Grid>
              )}
              {SkillFormMode === "rename" && (
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop="10px">
                  <OZInput
                    name="skillName"
                    placeholder="SkillName"
                    label="Skill Name *"
                  />
                </Grid>
              )}
              <ScrollToFieldError />
            </form>
          )}
        </Formik>
      </div>
    );
  };
  return (
    <div>
      {SkillFormMode === ADMIN_FORM_MODES.add ? (
        SkillFormMode === ADMIN_FORM_MODES.add && (
          <OZDialog
            open={openSkillFormMode}
            title="Add New Skill "
            onClose={closeSkillForm}
            saveButtonProps={{
              form: "skillForm",
              type: "submit",
              label: "save",
            }}
          >
            {SkillFormMode === ADMIN_FORM_MODES.add && <DataForm />}
          </OZDialog>
        )
      ) : SkillFormMode === "rename" ? (
        <div>
          <OZDialog
            open={openSkillFormMode}
            onClose={closeSkillForm}
            title="Rename"
            saveButtonProps={{ form: "skillForm", type: "submit" }}
          >
            <DataForm
              updateTransfer={props.updateTransfer}
              editData={props.data}
            />
          </OZDialog>
        </div>
      ) : (
        <div>
          <OZDrawer
            width="464px"
            open={openSkillFormMode}
            hideEditIcon={true}
            title={
              <div>
                <div>
                  <div className="oz-skill-view-drawer-title-container">
                    {/* {authPage(AdminPages.SKILLS_EDIT) &&
                      checkRoles("ROLE_ADMIN") && (
                        <img
                          ref={anchorRef}
                          className="oz-skill-view-moreverticon-img"
                          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                          alt="edit"
                          onClick={morevertClick}
                        ></img>
                      )} */}
                    {props?.editAccess &&
                      checkRoles("ROLE_ADMIN") && (
                        <img
                          ref={anchorRef}
                          className="oz-skill-view-moreverticon-img"
                          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                          alt="edit"
                          onClick={morevertClick}
                        ></img>
                      )}
                    <Tooltip title={props.data.skillName}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "18px",
                          alignSelf: "center",
                          marginTop: "2px",
                          maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden",
                          marginLeft: checkRoles("ROLE_ADMIN")
                            ? "0px"
                            : "10px" && authPage(AdminPages.IVR_FLOWS_EDIT)
                              ? "0px"
                              : "10px",
                        }}
                      >
                        {props.data.skillName}
                      </Typography>
                    </Tooltip>
                    <Menu
                      anchorEl={skillViewMenuanchorE2}
                      open={Boolean(skillViewMenuanchorE2)}
                      onClose={handleMenuClose}
                      sx={{
                        "& .MuiPaper-root": {
                          backgroundColor: "white",
                          width: "155px",
                        },
                        "& .MuiMenu-paper": {
                          backgroundColor: "white",
                          boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                          border: "0.0625rem solid #E6E5E6",
                          borderRadius: "12px",
                          marginLeft: "-9.9999rem",
                          marginTop: "-1.5rem",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ ...styleListItemButton }}
                        ref={anchorRef2}
                        onClick={() => {
                          openRenameDialog();
                        }}
                      >
                        Rename
                      </MenuItem>

                      {checkRoles("ROLE_ADMIN") && <MenuItem
                        sx={{ ...styleListItemButton, marginTop: "7px" }}
                        onClick={openDeleteDialog}
                      >
                        Delete{" "}
                      </MenuItem>}
                    </Menu>
                  </div>
                </div>
                {openDeleteDailog && (
                  <DeleteDialog
                    title={"Delete Skill"}
                    content={
                      "This will delete this skill:" +
                      props.data.skillName +
                      " permanently. You cannot undo this action."
                    }
                    onClose={() => {
                      setDeleteDialog(false);
                    }}
                    open={openDeleteDailog}
                    deleteFunction={deleteSkill}
                  />
                )}
              </div>
            }
            onClose={closeSkillForm}
          >
            <SkillView
              viewData={props.data}
              updateFunction={props.updateTransfer}
              editmode={setSkillFormMode}
              editAccess={props?.editAccess}
            />
          </OZDrawer>
          {SkillFormMode === "BasicInformation" && (
            <>
              <OZDialog
                open={openSkillFormMode}
                title="Edit basic information"
                onClose={() => {
                  setSkillFormMode(ADMIN_FORM_MODES.view);
                }}
                saveButtonProps={{
                  form: "skillForm",
                  type: "submit",
                  label: "save",
                }}
              >
                {
                  <DataForm
                    updateTransfer={props.updateTransfer}
                    editData={props.data}
                  />
                }
              </OZDialog>
            </>
          )}
          <div>
            {SkillFormMode === "Rename" && (
              <Menu
                sx={{
                  "& .MuiMenu-paper": {
                    backgroundColor: "white",
                    boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                    borderRadius: "12px",
                  },
                }}
                anchorEl={skillRenameanchorE1}
                open={skillRenameanchorE1}
                onClose={() => {
                  setSkillFormMode(ADMIN_FORM_MODES.view);
                }}
              >
                <DataForm
                  updateTransfer={props.updateTransfer}
                  editData={props.data}
                />
              </Menu>
            )}
          </div>
          {SkillFormMode === "EditFeature" && (
            <OZDialog
              open={openSkillFormMode}
              title="Edit Feature"
              onClose={() => {
                setSkillFormMode(ADMIN_FORM_MODES.view);
              }}
              onEdit={onClickEditButton}
              saveButtonProps={{
                form: "skillForm",
                type: "submit",
                label: "save",
              }}
            >
              <DataForm
                updateTransfer={props.updateTransfer}
                editData={props.data}
              />
            </OZDialog>
          )}
          {SkillFormMode === "Agents" && (
            <OZDialog
              open={openSkillFormMode}
              title="Edit Agents "
              onClose={() => {
                setSkillFormMode(ADMIN_FORM_MODES.view);
              }}
              onEdit={onClickEditButton}
              saveButtonProps={{
                form: "skillForm",
                type: "submit",
                label: "save",
              }}
            >
              {
                <DataForm
                  updateTransfer={props.updateTransfer}
                  editData={props.data}
                />
              }
            </OZDialog>
          )}
        </div>
      )}
    </div>
  );
};
export default SkillForm;
