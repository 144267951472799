// styles.ts
export const styles = {
  // Container Styles
  container: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '24px'
  },

  // Header Styles
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px'
  },

  headerTitle: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '14.06px',
    color: '#1A1A1A'
  },

  createButton: {
    textTransform: 'none',
    borderRadius: '4px',
    height: '36px',
    padding: '8px 16px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    backgroundColor: '#4B87FF',
    '&:hover': {
      backgroundColor: '#3D78E6'
    },
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },

  // Tabs Styles
  tabsContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: '12px',
    borderBottom: '1px solid #E6E6E6',
    backgroundColor: '#FFFFFF'
  },

  tab: (isActive: boolean) => ({
    color: isActive ? '#1A1A1A' : '#1A1A1A',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: isActive ? 500 : 400,
    lineHeight: '14.06px',
    backgroundColor: isActive ? '#FFFFFF' : '#F8F9FA',
    minHeight: '12px',
    minWidth: 'auto',
    padding: '8px 16px 4px 16px',
    textTransform: 'none',
    borderRadius: 0,
    border: '1px solid #E6E5E6',
    borderBottom: isActive ? '2px solid #4B87FF' : '1px solid #E6E6E6',
    marginBottom: isActive ? '-1px' : 0,
    marginRight: '-1px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#1A1A1A',
      borderBottom: isActive ? '2px solid #4B87FF' : '1px solid #E6E6E6'
    },
    '&.MuiButton-root': {
      boxShadow: 'none'
    }
  }),

  // Table Container Styles
  tableContainer: {
    position: 'relative',
    minHeight: '200px',
    boxShadow: 'none',
    border: '1px solid #E6E6E6',
    borderRadius: '8px',
    backgroundColor: '#fff',
    '& .MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: 0,
      width: '100%'
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: '#F8F9FA'
      }
    },
    '& .MuiTableCell-root': {
      borderBottom: '1px solid #E6E6E6',
      padding: '8px 12px',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      color: '#1A1A1A',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px'
    },
    '& .MuiTableCell-head': {
      backgroundColor: '#F8F9FA',
      position: 'sticky',
      top: 0,
      zIndex: 1
    },
    '& .MuiTableCell-checkbox': {
      width: '40px',
      padding: '8px'
    },
    '& .col-status': {
      width: '120px'
    },
    '& .col-type': {
      width: '100px'
    },
    '& .col-campaignName': {
      minWidth: '150px',
      maxWidth: '250px'
    },
    '& .col-senderId': {
      width: '120px'
    },
    '& .col-template': {
      width: '180px'
    },
    '& .col-fromDate': {
      width: '100px'
    },
    '& .col-runTimeStart, & .col-runTimeEnd': {
      width: '80px'
    },
    '& .col-actions': {
      width: '70px',
      textAlign: 'center'
    },
    '& .template-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    '& .action-buttons': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'flex-end'
    },
    '@media (max-width: 1200px)': {
      '& .MuiTableCell-root': {
        padding: '6px 8px'
      },
      '& .col-template': {
        width: '150px'
      }
    },
    '@media (max-width: 992px)': {
      '& .template-container': {
        maxWidth: '120px'
      },
      '& .col-campaignName': {
        maxWidth: '200px'
      }
    }
  },

  // Search Container Styles
  searchContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E6E6E6'
  },

  searchControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },

  searchInput: {
    width: '240px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      height: '36px',
      '& fieldset': {
        borderColor: '#E6E6E6'
      },
      '&:hover fieldset': {
        borderColor: '#4B87FF'
      },
      '& input': {
        padding: '8px 12px 8px 36px',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '14.06px',
        '&::placeholder': {
          color: '#666666',
          opacity: 1
        }
      }
    }
  },

  // Action Button Styles
  actionButton: {
    padding: '7px',
    minWidth: '36px',
    height: '36px',
    border: '1px solid #E6E6E6',
    borderRadius: '6px',
    color: '#666666',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#F8F9FA'
    }
  },

  // Table Header Styles
  tableHead: {
    '& .MuiTableRow-root': {
      backgroundColor: '#F8F9FA',
      '& .MuiTableCell-root': {
        borderBottom: '1px solid #E6E6E6',
        fontSize: '0.75rem',
        fontWeight: 500,
        color: '#000000',
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        padding: '12px 16px',
        backgroundColor: '#F8F9FA'
      },
      '& .MuiTableCell-root:first-of-type': {
        borderTopLeftRadius: '8px'
      },
      '& .MuiTableCell-root:last-child': {
        borderTopRightRadius: '8px'
      }
    }
  },

  tableHeaderCell: {
    backgroundColor: '#F8F9FA',
    color: '#666666',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    borderBottom: '1px solid #E6E6E6',
    padding: '12px 16px',
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },

  // Empty State Styles
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 20px',
    minHeight: '400px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #E6E6E6',
    gap: '16px'
  },
  emptyIcon: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  
  emptyText: {
    color: '#666',
    fontSize: '16px',
    fontWeight: 500
  },

  emptyStateIcon: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    backgroundColor: '#F8F9FA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  },

  emptyStateText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#666666',
    textAlign: 'center'
  },

  // Loading Overlay Styles
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },

  // Pagination Styles
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    borderTop: '1px solid #E6E6E6',
    backgroundColor: '#fff'
  },

  rowsPerPage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '& .MuiSelect-select': {
      padding: '6px 12px',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      borderRadius: '4px',
      border: '1px solid #E6E5E6',
      minWidth: '36px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      '&:focus': {
        borderColor: '#4B87FF'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiSelect-outlined': {
      backgroundColor: '#fff',
      '&:hover': {
        borderColor: '#4B87FF'
      }
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      color: '#666666'
    }
  },

  pagination: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },

  paginationButton: (isActive: boolean) => ({
    minWidth: '32px',
    height: '32px',
    padding: '4px 8px',
    border: '1px solid',
    borderColor: isActive ? '#4B87FF' : '#E6E6E6',
    borderRadius: '4px',
    backgroundColor: isActive ? '#FFFFFF' : '#FFFFFF',
    color: isActive ? '#4B87FF' : '#666666',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#F8F9FA',
      borderColor: '#4B87FF',
      color: '#4B87FF'
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  }),

  // Filter Styles
  filterPopover: {
    p: 2,
    minWidth: '240px',
    backgroundColor: '#fff'
  },

  filterTitle: {
    mb: 2,
    '& .MuiTypography-subtitle2': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      color: '#1A1A1A',
      marginBottom: '4px'
    },
    '& .MuiTypography-body2': {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      color: '#666666'
    }
  },

  filterActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: 2,
    pt: 2,
    borderTop: '1px solid #E6E6E6'
  },

  resetButton: {
    color: '#4B87FF',
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  },

  // Dialog Styles
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: '500px',
      width: '100%',
      margin: '16px',
      borderRadius: '8px'
    }
  },

  dialogHeader: {
    padding: '16px 24px',
    borderBottom: '1px solid #E6E6E6',
    position: 'relative'
  },

  dialogTitle: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    color: '#1A1A1A'
  },

  dialogCloseButton: {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '8px',
    color: '#666666'
  },

  dialogContent: {
    padding: '24px'
  },

  dialogFormContent: {
    '& .MuiFormControl-root': {
      marginBottom: '16px'
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      color: '#666666',
      fontFamily: 'Roboto',
      fontWeight: 500
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      '& fieldset': {
        borderColor: '#E6E6E6'
      },
      '&:hover fieldset': {
        borderColor: '#4B87FF'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4B87FF'
      }
    }
  },

  dialogFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px', // Add consistent spacing between buttons
    padding: '16px 24px',
    borderTop: '1px solid #E6E6E6',
    marginTop: '24px',
    backgroundColor: '#FFFFFF'
  },
  // Add styles for dialog action buttons
// Add styles for dialog action buttons
dialogButton: {
  minWidth: '100px',
  padding: '8px 16px',
  height: '36px',
  textTransform: 'none',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '14.06px',
},

dialogCancelButton: {
  color: '#666666',
  borderColor: '#E6E6E6',
  '&:hover': {
    borderColor: '#D1D5DB',
    backgroundColor: '#F9FAFB'
  }
},

dialogSubmitButton: {
  backgroundColor: '#4B87FF',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#3D78E6'
  },
  '&:disabled': {
    backgroundColor: '#E6E6E6',
    color: '#999999'
  }
},

  // Campaign Type Button Styles
  campaignTypeLabel: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    color: '#666666',
    marginBottom: '16px'
  },

  campaignTypeButton: {
    width: '100%',
    justifyContent: 'flex-start',
    textTransform: 'none',
    padding: '12px 16px',
    backgroundColor: '#F8F9FA',
    border: '1px solid #E6E6E6',
    borderRadius: '8px',
    color: '#1A1A1A',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    marginBottom: '12px',
    '&:hover': {
      backgroundColor: '#F0F2F5',
      borderColor: '#4B87FF'
    },
    '&.selected': {
      backgroundColor: '#EBF2FF',
      borderColor: '#4B87FF'
    }
  },

  // Continue Button Styles
  continueButton: {
    backgroundColor: '#4B87FF',
    color: '#fff',
    borderRadius: '4px',
    padding: '8px 24px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#3D78E6'
    },
    '&:disabled': {
      backgroundColor: '#E6E6E6',
      color: '#999999'
    }
  },

  // Column Management Styles
  columnManagePopover: {
    '& .MuiPaper-root': {
      width: '300px',
      maxHeight: '400px',
      overflow: 'auto',
      borderRadius: '4px',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
      border: '1px solid #E6E6E6',
      backgroundColor: '#fff'
    }
  },

  columnManageItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#F8F9FA'
    }
  },

  dragHandle: {
    color: '#666666',
    cursor: 'move',
    marginRight: '8px'
  },

  // Download Menu Styles
  downloadMenu: {
    '& .MuiPaper-root': {
      marginTop: '8px',
      minWidth: '120px',
      borderRadius: '4px',
      boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
      border: '1px solid #E6E6E6'
    }
  },

  downloadMenuItem: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
    padding: '8px 16px',
    color: '#1A1A1A',
    '&:hover': {
      backgroundColor: '#F8F9FA'
    }
  },

  // List Title Styles
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },

  listTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '14.06px',
    color: '#1A1A1A',
    display: 'inline-flex',
    alignItems: 'center',
    '& .total': {
      color: '#666666',
      marginLeft: '8px',
      '&::before': {
        content: '"•"',
        marginRight: '8px'
      }
    }
  },

  // Switch Style
  switch: {
    width: 32,
    height: 16,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: '2px',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#4B87FF',
          opacity: 1
        }
      }
    },
    '& .MuiSwitch-thumb': {
      width: 12,
      height: 12
    },
    '& .MuiSwitch-track': {
      borderRadius: '8px',
      opacity: 1,
      backgroundColor: '#E6E6E6'
    }
  },

  // Chip Styles
  chipLabel: {
    height: '24px',
    borderRadius: '4px',
    backgroundColor: '#F8F9FA',
    border: '1px solid #E6E6E6',
    '& .MuiChip-label': {
      padding: '4px 8px',
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '14.06px',
      color: '#666666'
    }
  },

  // Form Field Styles
  textField: {
    '& .MuiOutlinedInput-root': {
      height: '48px'
    }
  },

  selectField: {
    height: '48px'
  },

  multilineField: {
    '& .MuiOutlinedInput-root': {
      minHeight: '120px'
    }
  },

  // Dialog Action Styles
  dialogActions: {
    padding: '16px 24px',
    borderTop: '1px solid #E6E6E6',
    gap: '12px'
  },

  // Template Specific Styles
  templateDialog: {
    '& .MuiDialog-paper': {
      maxWidth: '600px',
      width: '100%',
      margin: '16px',
      borderRadius: '8px'
    }
  },

  formControl: {
    marginBottom: '24px',
    '& .MuiInputLabel-root': {
      color: '#666666',
      fontSize: '14px'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px'
    }
  },

  templateTable: {
    '& .MuiTableCell-head': {
      backgroundColor: '#F8F9FA',
      color: '#666666',
      fontWeight: 500,
      fontSize: '12px',
      textTransform: 'uppercase'
    },
    '& .MuiTableCell-body': {
      fontSize: '14px',
      color: '#1A1A1A'
    }
  },

  statusCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },

  actionCell: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end'
  },

  whatsappName: {
    color: '#F87171',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },

  // Global MUI component styles
  '.MuiTypography-root': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
  },
  
  '.MuiButton-root': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
  },
  
  '.MuiInputBase-root': {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.06px',
  },
  // Add these to the existing styles object
columnManageButton: {
  padding: '7px',
  minWidth: '36px',
  height: '36px',
  border: '1px solid #E6E6E6',
  borderRadius: '6px',
  color: '#666666',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#F8F9FA'
  },
  '& img': {  // Add this for the SVG image
    width: '20px',
    height: '20px'
  }
 }
} as const;

export type StyleKey = keyof typeof styles;