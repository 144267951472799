import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { DataManagementTabsList } from '../../utils/constants';

export default function DataManagementTabs({ activeTab, setActiveTab }) {
    console.log(activeTab);
    return (
        <Box className='oz-data-management-tabs-container'>
            {activeTab && <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
            >
                {DataManagementTabsList.map((tab, index) => (
                    <Tab key={tab.id} label={tab} value={tab} />
                ))}
            </Tabs>}
        </Box>
    )
}
