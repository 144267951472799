import * as React from 'react';
import { ReactComponent as SearchIcon } from '../../static/icons/search.svg';
import '../Widget-Search/Widget-Search.css';

type Props = {
	onTextChange?: any;
	placeholder?: string;
	onSearchClick?: any;
	widget?: any;
};
export default function WidgetSearch(props: Props) {
	const searchInputRef = React.useRef<any>();
	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter' && props?.onSearchClick) {
			props?.onSearchClick(searchInputRef?.current?.value);
		}
	};

	const handleDoubleClick = () => {
		if (searchInputRef.current) {
			searchInputRef.current.select();
		}
	};

	return (
		<>
			<div className={props.widget ? 'card-search-widget' : "card-search"}>
				<input type="text" ref={searchInputRef} placeholder={props.placeholder ? props.placeholder : 'Search'} onKeyDown={handleKeyDown} onChange={props?.onTextChange} onDoubleClick={handleDoubleClick} />
				<SearchIcon onClick={() => props?.onSearchClick(searchInputRef?.current?.value)} style={{ cursor: 'pointer', opacity: '0.4' }}></SearchIcon>
			</div>
		</>
	);
}
