import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { Button, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography, } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { showErrorNotification, showSuccessNotification, showWarningNotification } from '../../../components/admin/common/NotiService';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import LockedAndStatusConfirmationDialog from '../../../components/admin/common/OZLockedAndStatusDialog';
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import { getAdminSettingsList, getsubadmin, getsubadminById, updatesubadminById,getUserLoggedIn, getLogoDetailsForPartner } from "../../../services/api-service";
import { ReactComponent as Edit } from '../../../static/icons/edit.svg';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import AddUser from './AddUserForm';
import './Users.css';

function Users() {
  const GetUserNameRenderer = (props) => {
    return (
      <Tooltip title={(props?.data?.username) ? props?.data?.username : ''}>
        <span style={{ marginLeft: '5px', color: "#3D8BF8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }}>{props.value}</span>
      </Tooltip>
    );
  };

  const TextRenderer = (props) => {
    return (
      <div>
        <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props.value}</label>
      </div>
    );
  };

  const EmailRenderer = (props) => {
    return (
      <div>
        <Tooltip title={(props?.value) ? props?.value : ''}>
          <label style={{ color: props.data.enabled ? "#000000" : "#99A0A8" }}>{props?.value}</label>
        </Tooltip>
      </div>
    );
  };

  const LockedRenderer = (props) => {

    const [lockedState, setLockedState] = useState(props.value);
    const [lockedconfirmationDialogState, setLockedConfirmationDialogState] = useState(false);

    const handlelockedState = (e) => {
      setLockedState(e.target.checked);
      setLockedConfirmationDialogState(true);
    };

    const onCloseLocked = () => {
      setLockedConfirmationDialogState(false);
      setLockedState(props.value);
    };

    return (
      <div>
        {lockedconfirmationDialogState &&
          <LockedAndStatusConfirmationDialog title={"Locked State Confirmation"} content={"Are you Sure you want to change the Locked State?"}
            open={lockedconfirmationDialogState} updateFunction={() => { updateUser("accountLocked", lockedState, props.data.id); }} onClose={() => { onCloseLocked() }} />
        }
        {props.data.enabled === true &&
          <div style={{ display: "flex", alignContent: "center" }}>
            <SimpleSwitch name={props.value} checked={lockedState}
              onChange={(e) => handlelockedState(e)}
            />
          </div>
        }
      </div>
    );
  };

  const CheckBoxRenderer = (props) => {
    return (
      <div>
        <input type="checkbox" disabled="disabled" checked={props?.value} />
      </div>
    );
  };

  // const PartnerCheckBoxRenderer = (props) => {
  //   const [propState, setPropState] = useState(props.value);
  //   const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  //   const handlePropState = (e) => {
  //     setPropState(e.target.checked);
  //     setConfirmationDialogState(true);
  //   };

  //   const onCloseDialog = () => {
  //     setConfirmationDialogState(false);
  //     setPropState(props.value);
  //   };
  //   return (
  //     <div>
  //       {confirmationDialogState &&
  //         <LockedAndStatusConfirmationDialog title={"Partner State Confirmation"} content={"Are you Sure you want to change the Partner State?"}
  //           open={confirmationDialogState} updateFunction={() => { updateUser("partner", propState, props.data.id); }} onClose={() => { onCloseDialog() }} />
  //       }
  //       <div style={{ display: "flex", alignContent: "center" }}>
  //         <input type="checkbox" disabled={!props.data.enabled} checked={propState} onChange={(e) => authPage(AdminPages.USERS_EDIT) && handlePropState(e)} />
  //       </div>
  //     </div>
  //   );
  // };

  const EditRenderer = (props) => {
    const showEditForm = () => {
      if (props.data.enabled) {
        onClickRow(props);
        setUserForm(true);
        setMode(ADMIN_FORM_MODES.edit);
      } else {
        showErrorNotification(`${props?.data?.username} is Deactivated, Kindly Activate the User first`);
      }
    };
    return (
      <div className='oz-user-Status-container'>
        <div>
          <input type="checkbox" disabled="disabled" checked={props?.value} />
        </div>
        <IconButton className="oz-admin-holidays-edit-icon" onClick={showEditForm}>
          <Edit />
        </IconButton>
      </div>
    );
  };

  const StatusRenderer = (props) => {

    const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
    const anchorRef = React.useRef();
    const [statusState, setStatusState] = useState(props.value);
    const [statusconfirmationDialogState, setStatusConfirmationDialogState] = useState(false);

    const onMorevertIconClick = () => {
      setTimeout(() => setStatusAnchorEl(anchorRef?.current), 1);
    };

    const onMenuClose = () => {
      setStatusAnchorEl(null);
    };

    const onCloseStatus = () => {
      setStatusConfirmationDialogState(false);
      setStatusState(props.value);
    };

    const onClickActivateHandleStatus = () => {

      console.log("hideButoon::::::::: i came in deactivate")
      if (hideAddButton()) {
        setStatusConfirmationDialogState(true);
        setStatusAnchorEl(null);
      }
      else {
        showErrorNotification
          ("User cannot be activated. limit exceeded")
      }

    };

    const onClickDeactivateActivateHandleStatus = () => {
      setStatusConfirmationDialogState(true);
      setStatusAnchorEl(null);

    };

    const styleListItemButton = useMemo(
      () => ({
        "&.MuiButtonBase-root": {
          color: '#212121',
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: 400,
          height: '16px',
          lineHeight: "14px",
        },
        '&:hover': {
          borderRadius: "4px",
        },
      }),
      []
    );

    return (
      <div className='oz-user-Status-container'>
        <div>
          <input type="checkbox" disabled="disabled" checked={props.data.enabled} />
        </div>
        <img className="userMorevertIcon"
          onClick={onMorevertIconClick}
          src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
        >
        </img>
        <div ref={anchorRef}>
          <Menu anchorEl={statusAnchorEl} open={Boolean(statusAnchorEl)} onClose={onMenuClose} sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#FFFFFF",
              width: "133px",
              height: "35px",
            },
            "& .MuiMenu-paper": {
              backgroundColor: "#FFFFFF",
              boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
              border: "0.0625rem solid #E6E5E6",
              borderRadius: "12px",
              marginLeft: "-0.3625rem",
              marginTop: "0rem",
            },
          }}>
            <MenuItem onClick={props.data.enabled ? onClickDeactivateActivateHandleStatus : onClickActivateHandleStatus} sx={{ ...styleListItemButton, marginTop: "1px" }}>{!props.data.enabled ? 'Activate' : 'Deactivate'}</MenuItem>
          </Menu>
          {statusconfirmationDialogState &&
            <LockedAndStatusConfirmationDialog title={"Status Confirmation"} content={"Are you Sure you want to change the Status?"}
              open={statusconfirmationDialogState} updateFunction={() => { updateUser(props.data.accountLocked, statusState, props.data.id); }} onClose={() => { onCloseStatus() }} />
          }
        </div>
      </div>
    );
  };

  const GetFullNameRenderer = (props) => {
    return (
      <>
        {/* <Avatar variant="circular" style={{ width: 24, height: 24, fontWeight: 400, color: "#212121", fontSize: "10px" }} {...stringAvatar(props.value)} ></Avatar> */}
        <Tooltip title={(props?.data?.fullName) ? props?.data?.fullName : ''}>
          <span style={{ color: props.data.enabled ? "#000000" : "#99A0A8", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", cursor: 'pointer' }}>{props.value}</span>
        </Tooltip>
      </>
    );
  };

  //   const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
  //   const anchorRef = React.useRef();
  //   const [statusState, setStatusState] = useState(props.value);
  //   const [statusconfirmationDialogState, setStatusConfirmationDialogState] = useState(false);

  //   const onMorevertIconClick = () => {
  //     setTimeout(() => setStatusAnchorEl(anchorRef?.current), 1);
  //   };

  //   const onMenuClose = () => {
  //     setStatusAnchorEl(null);
  //   };

  //   const onCloseStatus = () => {
  //     setStatusConfirmationDialogState(false);
  //     setStatusState(props.value);
  //   };

  //   const onClickActivateHandleStatus = () => {

  //     console.log("hideButoon::::::::: i came in deactivate")
  //     if (hideAddButton()) {
  //       setStatusConfirmationDialogState(true);
  //       setStatusAnchorEl(null);
  //     }
  //     else {
  //       showErrorNotification
  //         ("User cannot be activated. limit exceeded")
  //     }

  //   };

  //   const onClickDeactivateActivateHandleStatus = () => {
  //     setStatusConfirmationDialogState(true);
  //     setStatusAnchorEl(null);

  //   };

  //   const styleListItemButton = useMemo(
  //     () => ({
  //       "&.MuiButtonBase-root": {
  //         color: '#212121',
  //         fontFamily: "Roboto",
  //         fontSize: "12px",
  //         fontWeight: 400,
  //         height: '16px',
  //         lineHeight: "14px",
  //       },
  //       '&:hover': {
  //         borderRadius: "4px",
  //       },
  //     }),
  //     []
  //   );

  //   return (
  //     <div className='oz-user-Status-container'>
  //       <input type="checkbox" disabled="disabled" checked={props?.value} />
  //       {authPage(AdminPages.USERS_EDIT) &&
  //         <img className="userMorevertIcon"
  //           onClick={onMorevertIconClick}
  //           src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit"
  //         >
  //         </img>
  //       }
  //       <div ref={anchorRef}>
  //         <Menu anchorEl={statusAnchorEl} open={Boolean(statusAnchorEl)} onClose={onMenuClose} sx={{
  //           "& .MuiPaper-root": {
  //             backgroundColor: "#FFFFFF",
  //             width: "133px",
  //             height: "35px",
  //           },
  //           "& .MuiMenu-paper": {
  //             backgroundColor: "#FFFFFF",
  //             boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
  //             border: "0.0625rem solid #E6E5E6",
  //             borderRadius: "12px",
  //             marginLeft: "-0.3625rem",
  //             marginTop: "0rem",
  //           },
  //         }}>
  //           <MenuItem onClick={props.data.enabled ? onClickDeactivateActivateHandleStatus : onClickActivateHandleStatus} sx={{ ...styleListItemButton, marginTop: "1px" }}>{!props.data.enabled ? 'Activate' : 'Deactivate'}</MenuItem>
  //         </Menu>
  //         {statusconfirmationDialogState &&
  //           <LockedAndStatusConfirmationDialog title={"Status Confirmation"} content={"Are you Sure you want to change the Status?"}
  //             open={statusconfirmationDialogState} updateFunction={() => { updateUser(props.data.accountLocked, statusState, props.data.id); }} onClose={() => { onCloseStatus() }} />
  //         }
  //       </div>
  //     </div>
  //   );
  // };

  const [userForm, setUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [gridRef, setGridRef] = useState(null);
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [mode, setMode] = useState(null);
  
  
  
  async function getuserLogged() {
    try {
        await getUserLoggedIn()
            .then((resp) => {
                let data = resp?.Data?.[0]; 
                setLoggedInUser(data || {}); 
            })
            .catch((e) => {
                setLoggedInUser({}); 
            });
    } catch (e) {
        setLoggedInUser({}); 
    }
}



  async function getSettingsData() {
    try {
      getAdminSettingsList().then(resp => {
        console.log(":::::::::???????????::::::::::::", resp.Data)
        setSettingsData(resp.Data);
      }).catch(e => {
        setSettingsData({})
        console.log(e)
      })
    }
    catch (e) {
      console.log(e)
    }
  }
  function hideAddButton() {
    console.log("::::;filteredUserList::::::::", filteredUserList)
    let usersCount = "";
    if (settingsData.length > 0) {
      settingsData.map((item) => {
        if (item?.ParameterCode === "USER_CREATION_LIMIT") {
          if (item?.ParameterValue === null) {
            usersCount = item?.DefaultValue;
            return usersCount
          }
          else {
            usersCount = (item?.ParameterValue)
            return usersCount
          }
        }
      })
      if (usersCount === "-1") {
        return true;
      } else if (filteredUserList?.length < Number(usersCount)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  useEffect(() => {
    getUsersList();
    getSettingsData();
    getuserLogged();
  }, []);

  const columnDefs = [
    // {
    //   field: 'accountLocked',
    //   headerName: 'Locked',
    //   enableRowGroup: false,
    //   maxWidth: 80,
    //   cellRenderer: LockedRenderer
    // },
    { field: 'username', headerName: 'User Name ', enableRowGroup: false, cellRenderer: GetUserNameRenderer, maxWidth: 250 },
    { field: 'partner', headerName: 'Partner', cellRenderer: CheckBoxRenderer, maxWidth: 80 },
    { field: 'partnerName', headerName: 'Partner Name', cellRenderer: TextRenderer, maxWidth: 150 },
    { field: 'partnerLevel', headerName: 'Partner Level', cellRenderer: TextRenderer, maxWidth: 130 },
    { field: 'fullName', headerName: 'Full Name', cellRenderer: GetFullNameRenderer, maxWidth: 300 },
    { field: 'email', headerName: 'Email', cellRenderer: EmailRenderer, maxWidth: 300 },
    { field: 'mapToServer', headerName: 'Map To Server', cellRenderer: TextRenderer, maxWidth: 150 },
    { field: 'redis', headerName: 'Redis', cellRenderer: CheckBoxRenderer, maxWidth: 100 },
    { field: 'enabled', headerName: 'Enabled', cellRenderer: StatusRenderer },
  ];

  async function getUsersList() {
    setLoading(true);
    try {
      await getsubadmin()
        .then((resp) => {
          setFilteredUserList(resp.Data.filter(item => item.enabled === true));
          setUserList(resp.Data);
        })
        .catch((e) => {
          showErrorNotification("Error in fetching Users list");
          setUserList([]);
        });
    } catch (e) {
      showErrorNotification("Error in fetching Users list");
      setUserList([]);
    }
    setLoading(false);
  };

  async function updateUser(locked, status, id) {
    setLoading(true);
    try {
      let data = [];
      await getsubadminById(id)
        .then((resp) => {
          data = resp.Data[0];
          data.confirmPassword = data.password;
          data.accountLocked = locked;
          data.enabled = !status;
        })
        .catch((e) => {
          setSelectedUser({});
          showErrorNotification("Error updating user");
        });
      updatesubadminById(data)
        .then((resp) => {
          if (resp.Status === "Success") {
            showSuccessNotification(`User ${data.username} Updated Successfully`);
            getUsersList();
          }
          else {
            showWarningNotification(resp.Message.allowedIp || resp.Message);
          }
        })
        .catch((e) => {
          console.log(e);
          showErrorNotification("Error updating user");
        });
    }
    catch (e) {
      showErrorNotification("Error updating user");
    }
    setLoading(false);
  };

  let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

  const showUserForm = () => {
    setUserForm(true);
    setMode(ADMIN_FORM_MODES.add);
  };

  const closeUserForm = () => {
    setUserForm(false);
  };

  async function onClickRow(rowItem) {
    if (rowItem.data.enabled === false) {
      showErrorNotification(`${rowItem?.data?.username} is Deactivated, Kindly Activate the User first`);
      return null;
    }
    else {
      setSelectedUser({});
      setLoading(true);
      try {
        await getsubadminById(rowItem.data.id).then(async (resp) => {
            if (resp?.Status === 'Success') {
              await getLogoDetailsForPartner(rowItem.data.id).then((respN) => {
                console.log("respN", respN);
                if(respN.Status === "Success"){
                  let finalDetails = {
                    ...resp?.Data[0],
                    logoImage : respN?.Data[0]?.logoImage || '',
                    iconImage : respN?.Data[0]?.iconImage || '',
                    partnerDomain : respN?.Data[0]?.partnerDomain || '',
                  }
                  setSelectedUser(finalDetails)
                  setUserForm(true);
                  setMode(ADMIN_FORM_MODES.view);
                }else{
                  let finalDetails = {
                    ...resp?.Data[0],
                    logoImage : '',
                    iconImage : '',
                    partnerDomain : '',
                  }
                  setSelectedUser(finalDetails)
                  setUserForm(true);
                  setMode(ADMIN_FORM_MODES.view);
                }
              }).catch((e) => {
                setSelectedUser({});
                showErrorNotification("Error getting Selected User");
              });
              //  setSelectedUser(resp.Data[0]);
              // setUserForm(true);
              // setMode(ADMIN_FORM_MODES.view);
            } else {
              showErrorNotification("Error getting Selected User");
              setSelectedUser({});
            }
          }).catch((e) => {
            setSelectedUser({});
            showErrorNotification("Error getting Selected User");
          });
      } catch (e) {
        showErrorNotification("Error getting Selected User");
        setSelectedUser({});
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {!userForm &&
        <div className="ozAdminUsersContainer">
          <Grid sx={{ marginBottom: "10px" }}>
            <Typography className='oz-page-header'> People </Typography>
          </Grid>
          <Stack direction="row" sx={{ marginBottom: "16px" }} justifyContent="space-between">
            <Typography className='oz-page-heading'> Accounts </Typography>
            
            {!(loggedInUser?.account?.partnerLevel === 0 && loggedInUser?.account?.accountName !== "ozoneca10") && (
  <Button
    sx={buttonStyle}
    variant="contained"
    startIcon={<AddBoxOutlinedIcon />}
    onClick={showUserForm}
  >
    Add Account
  </Button>
)}
            
            
          </Stack>
          <OZGridToolbar title='Accounts List' searchPlaceholder='User Name' rowCount={userList.length} dataGridRef={gridRef} fileName='Users' showFilters={true} filtertProps={{ column: 'enabled', options: [{ Active: true }, { Deactivated: false }] }} />
          <OZDataGrid height="26.3rem" columns={columnDefs} data={userList} rowClickCallback={onClickRow} setGridRef={setGridRef} />
          {loading &&
            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
              <LoadingScreen />
            </div>
          }
        </div >
      }
      {loading ?
        (
          <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
            <LoadingScreen />
          </div>
        ) :
        (
          <>
            {/* {userForm && <AddUser onClose={closeUserForm} rowData={selectedUser} updateFunction={getUsersList} mode={mode} />} */}
            {userForm && <AddUser onClose={closeUserForm} rowData={selectedUser} updateFunction={getUsersList} mode={mode} />}
          </>
        )
      }
    </div>
  )
};

export default Users;