import React, { useState, useMemo, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import OZDataGrid from "../../../components/admin/common/OZDataGrid/OZDataGrid";
import { deleteTemplateById, getEmailById, getSmsById, getTemplates, getWhatsappById, } from "../../../services/api-service";
// import SearchIcon from "@mui/icons-material/Search";
// import { InputBase, styled} from '@mui/material';
import OZGridToolbar from "../../../components/admin/common/OZDataGrid/OZGridToolbar";
import { Button, Divider, ListItemButton, Menu, Tooltip } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import TabList from '@mui/lab/TabList';
import { Stack, Typography } from '@mui/material';
import EmailTemplateForm from './EmailTemplateForm';
import OZDialog from '../../../components/admin/common/OZDialog'
import WhatsappTemplateForm from './WhatsappForm';
import SMSTemplateForm from './SmsTemplateForm';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import './Email.css'
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { OZCustomToggleButton } from '../../../components/admin/common/OZCustomToggleButton';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';
import WebChatTemplateForm from './WebChatForm';


export function Templates(props) {
    const handleChange = (event, newMenuValue) => {
        setMenuValue(newMenuValue);
        changeColumn(newMenuValue);
    };
    const [addFormtype, setAddFormType] = useState("1");
    const [open] = useState(true)
    const [dialog, setDialog] = useState(false)
    const [menuValue, setMenuValue] = React.useState('1')
    const [showMenu, setMenu] = useState({ isNew: false, show: false });
    const [gridRef, setGridRef] = useState(null);
    const [rowData, setRowdata] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [data, setData] = useState({});

    const [showForm, setshowForm] = useState({ isNew: false, show: false });
    const [loading, setLoading] = useState(false);

    let buttonStyle = useMemo(() => ({ borderRadius: '8px', height: '30px', fontSize: '14px', fontWeight: 400 }), []);

    function ChooseForm(chooseMenuProps) {
        const handleClose = () => {
            chooseMenuProps.close('10');
        };

        return (
            <div>
                {chooseMenuProps.menuType === '1' && <EmailTemplateForm emailTemplate={chooseMenuProps.email} onClose={handleClose} />}
                {chooseMenuProps.menuType === '2' && <WhatsappTemplateForm whatsappTemplate={chooseMenuProps.whatsapp} onClose={handleClose} />}
                {chooseMenuProps.menuType === '3' && <SMSTemplateForm smsTemplate={chooseMenuProps.sms} onClose={handleClose} />}
                {chooseMenuProps.menuType === '4' && <WebChatTemplateForm webchatTemplate={chooseMenuProps.web} onClose={handleClose} />}
            </div>
        )
    }

    const handleClose = () => {
        setDialog(false)

    };

    const handleClick = () => {
        setDialog(true)
    };

    const emailTemplate = () => {
        setMenuValue("1");
    };

    const whatsAppTemplate = () => {
        setMenuValue("2");
    };

    const smsTemplate = () => {
        setMenuValue("3");
    };

    const webChatTemplate = () => {
        setMenuValue("4");
    };

    function MoreVertColumnRenderer(props) {
        const [anchorE1, setAnchorE1] = useState(false);
        const anchorRef = useRef();
        const [openDelete, setopenDelete] = useState(false)

        const morevertClick = () => {
            setTimeout(() => setAnchorE1(anchorRef?.current), 1);
        };

        const handleClose = () => {
            setAnchorE1(false);
        };

        function onClickDelete() {
            setAnchorE1(false);
            setopenDelete(true)
        }

        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    width: '105px',
                    fontWeight: 400,
                    height: '14px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        async function onClickDialogDelete(type) {
            try {
                deleteTemplateById(props.data.id).then((resp) => {
                    if (resp.Status === "Success") {
                        if (type === "whatsapp") {
                            closeWhatsappTemplateForm();
                            getWhatsappTemplate();
                        } else if (type === "sms") {
                            closeSMSTemplateForm();
                            getSmsTemplate();
                        } else if (type === "web") {
                            closeWebchatemplateForm();
                            getWebchatTemplate();
                        }
                        showSuccessNotification(resp.Message);
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }

                }).catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                })
            }
            catch (e) {
                showErrorNotification(JSON.stringify(e));
            }
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{
                    width: "600px",
                    fontSize: "12px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}>{props.value}</span>
                {(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full')) && <img className="delMoreIcon"
                    onClick={morevertClick}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit">
                </img>}
                <div ref={anchorRef}>
                    <Menu anchorEl={anchorE1} open={Boolean(anchorE1)} onClose={handleClose} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "103px",
                            height: "35px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-0.6525rem",
                            marginTop: ".2rem",
                        },
                    }}>
                        <ListItemButton
                            onClick={onClickDelete}
                            sx={styleListItemButton}
                            disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')}
                        >
                            Delete
                        </ListItemButton>
                    </Menu>
                    {openDelete &&
                        <DeleteDialog
                            open={openDelete}
                            onClose={() => { setopenDelete(false) }}
                            title={(props.data.type === "whatsapp") ? "Delete Whatsapp Template" : props.data.type === "sms" ? "Delete SMS Template" : props.data.type === "web" ? "Delete WebChat Template" : ''}
                            content={(props.data.type === "whatsapp") ? `This will delete Whatsapp Template (${props.data.name}) permanently. You cannot undo this action.` : props.data.type === "sms" ? `This will delete SMS Template (${props.data.name}) permanently. You cannot undo this action.` : props.data.type === "web" ? `This will delete WebChat Template (${props.data.name}) permanently. You cannot undo this action.` : ''}
                            deleteFunction={() => onClickDialogDelete(props.data.type)}
                        />
                    }
                </div>
            </div>
        );
    }

    const continueClicked = (value) => {
        if (value === '1') {
            showTemplateForm('1');
        }
        else if (value === '2') {
            showTemplateForm('2');
        }
        else if (value === '3') {
            showTemplateForm('3');
        }
        else if (value === '4') {
            showTemplateForm('4');
        }
    };

    const showTemplateForm = (formValue) => {
        setMenu({ isNew: true, show: true });
        handleClose();
        switch (formValue) {
            case '1':
                setAddFormType('1');
                break;

            case '2':
                setAddFormType('2');
                break;

            case '3':
                setAddFormType('3');
                break;
            case '4':
                setAddFormType('4');
                break;
            default:
        }
    };

    async function getEmailTemplate(type) {
        setLoading(true);
        try {
            getTemplates('email')
                .then((resp) => {
                    setRowdata(resp.Data);
                    setLoading(false);
                })
                .catch((e) => {
                    setRowdata([]);
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    };

    async function getSmsTemplate(type) {
        setLoading(true);
        try {
            getTemplates('sms')
                .then((resp) => {
                    setRowdata(resp.Data);
                    setLoading(false);
                })
                .catch((e) => {
                    setRowdata([]);
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    };

    async function getWhatsappTemplate(type) {
        setLoading(true);
        try {
            getTemplates('whatsapp')
                .then((resp) => {
                    setRowdata(resp.Data);
                    setLoading(false);
                })
                .catch((e) => {
                    setRowdata([]);
                    setLoading(false);
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    };

    async function getWebchatTemplate(type) {
        setLoading(true);
        try {
            getTemplates('web').then((resp) => {
                if(resp && resp.Data){
                    setRowdata(resp.Data);
                }else{
                    setRowdata([]);
                }
                setLoading(false);
            }).catch((e) => {
                setRowdata([]);
                setLoading(false);
            });
        } catch (e) {
            setRowdata([]);
            showErrorNotification(JSON.stringify(e));
            setLoading(false);
        }
    };

    let styleToggleButtonInput = useMemo(
        () => ({
            color: "#212121",
            width: "64px",
            heigh: "14px",
            fontFamily: "Roboto",
            fontSize: " 12px",
            fontWeight: 400,
            lineHeight: "14px",
            letterSpacing: "0em",
            textAlign: "center",
        }),
        []
    );

    let toggleStyle = useMemo(
        () => ({
            backgroundColor: "#F5F8FA",
            width: "93px",
            height: "30px",
            borderRadius: 0,
            '&.Mui-selected, &.Mui-selected:hover': {
                color: 'white',
                backgroundColor: '#FFFF',
            }

        }),
        []
    );

    const styleListItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                width: '374px',
                height: '40px',
                borderRadius: "0.5rem",
                marginBottom: "2px",
                marginLeft: "1.1rem",
                marginRight: "1.2rem",
                fontStyle: "normal",
                fontFamily: "Roboto",
                fontSize: " 0.75rem",
                fontWeight: 400,
                lineHeight: "0.875rem",
                paddingLeft: '12px'
            },
        }),
        []
    );

    // const StyledInputBase = styled(InputBase)(({ theme }) => ({
    //     color: "#99A0A8",
    //     "& .MuiInputBase-input": {
    //         fontSize: '12px',
    //         transition: theme.transitions.create("width"),
    //         height: '30px',
    //         padding: '0px',
    //     },
    // }));

    // const Search = styled("div")(({ theme }) => ({
    //     position: "relative",
    //     borderRadius: "8px",
    //     border: "1px solid #99A0A8",
    //     width: '200px'
    // }));

    useEffect(() => {
        changeColumn(menuValue);
    }, []);

    const getNameRenderer = (props) => {
        return (
            <Tooltip title={props?.value ? props?.value : ""}>
                <span style={{ color: '#3D8BF8' }}>{props.value}</span>
            </Tooltip>
        )
    };

    function EmailRender(props) {
        const [anchorE2, setAnchorE2] = useState(false)
        const anchorRefs = useRef();
        const [openDelete, setopenDelete] = useState(false)

        const morevertClick = () => {
            setTimeout(() => setAnchorE2(anchorRefs?.current), 1);
        };
        const onClickDialogDelete = () => {
            deleteEmailById(props.data.id);
            setopenDelete(false);
        };

        async function deleteEmailById() {
            try {
                deleteTemplateById(props.data.id)
                    .then((resp) => {
                        if (resp.Status === "Success") {
                            closeEmailTemplateForm();
                            getEmailTemplate();
                            showSuccessNotification(resp.Message);

                        } else {
                            showErrorNotification(JSON.stringify(resp.Message));
                        }
                    })
                    .catch((e) => {
                        showErrorNotification(JSON.stringify(e));
                    });
            } catch (e) {
                showErrorNotification(JSON.stringify(e));
            }
        };

        const handleClose = () => {
            setAnchorE2(false);
        };

        function onClickDelete() {
            setAnchorE2(false);
            setopenDelete(true)
        }
        const styleListItemButton = useMemo(
            () => ({
                "&.MuiButtonBase-root": {
                    color: '#212121',
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    width: '105px',
                    fontWeight: 400,
                    height: '14px',
                    lineHeight: "14px",
                },
                '&:hover': {
                    borderRadius: "4px",
                },
            }),
            []
        );

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <span style={{
                    width: "600px",
                    fontSize: "12px",
                    fontWeight: 400,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }} dangerouslySetInnerHTML={{ __html: (props?.value) ? props?.value : '' }}></span>
                {/* {authPage(AdminPages.TEMPLATES_EDIT) && <img className="delMoreIcon"
                    onClick={morevertClick}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit">
                </img>} */}
                {(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full')) && <img className="delMoreIcon"
                    onClick={morevertClick}
                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`} alt="edit">
                </img>}
                <div ref={anchorRefs}>
                    <Menu anchorEl={anchorE2} open={Boolean(anchorE2)} onClose={handleClose} sx={{
                        "& .MuiPaper-root": {
                            backgroundColor: "#FFFFFF",
                            width: "103px",
                            height: "35px",
                        },
                        "& .MuiMenu-paper": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                            border: "0.0625rem solid #E6E5E6",
                            borderRadius: "12px",
                            marginLeft: "-0.6525rem",
                            marginTop: ".2rem",
                        },
                    }}>
                        <ListItemButton
                            onClick={onClickDelete}
                            disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')}
                            sx={{ ...styleListItemButton }} >
                            Delete
                        </ListItemButton>
                    </Menu>
                    {openDelete &&
                        <DeleteDialog
                            open={openDelete}
                            onClose={() => { setopenDelete(false) }}
                            title="Delete Email Template"
                            // content={"This will delete Email Template:" + props.data.name + " permanently. You cannot undo this action."}
                            content={`This will delete Email Template (${props.data.name}) permanently. You cannot undo this action.`}
                            deleteFunction={onClickDialogDelete}
                        />
                    }
                </div>
            </div>

        )
    };

    function changeColumn(index) {
        switch (index) {
            case "1":
                setColumnDefs([
                    {
                        field: 'name', headerName: "Template Name", cellRenderer: getNameRenderer,
                    },
                    { field: 'text', headerName: "Email Text", cellRenderer: EmailRender },
                ]);
                getEmailTemplate()
                break;

            case "2":
                setColumnDefs([
                    {
                        field: 'name', headerName: "Template Name", cellRenderer: getNameRenderer,
                    },
                    { field: 'text', headerName: "Feedback Text", cellRenderer: MoreVertColumnRenderer },
                ]);
                getWhatsappTemplate()
                break;
            case "3":
                setColumnDefs([

                    {
                        field: 'name', headerName: "Template Name", cellRenderer: getNameRenderer,
                    },
                    { field: 'text', headerName: "SMS Text", cellRenderer: MoreVertColumnRenderer },
                ]);
                getSmsTemplate()
                break;
            case "4":
                setColumnDefs([
                    {
                        field: 'name', headerName: "Template Name", cellRenderer: getNameRenderer,
                    },
                    { field: 'text', headerName: "Template Text", cellRenderer: MoreVertColumnRenderer },
                ]);
                getWebchatTemplate()
                break;
            default:
        }
    };

    let buttonStyles = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            width: "5.68rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    function gridTitle() {
        if (menuValue === "1") {
            return ("List of Email Templates");
        } else if (menuValue === "2") {
            return ("List of Templates");
        } else if (menuValue === "3") {
            return ("List of Templates");
        } else if (menuValue === "4") {
            return ("List of Templates");
        }
    };

    const closeWhatsappTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    const closeEmailTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    const closeSMSTemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    const closeWebchatemplateForm = () => {
        setshowForm(false);
        setshowForm({ isNew: false, show: false });
    };

    async function onClickRow(rowItem) {
        setLoading(true);
        setshowForm({ isNew: false, show: true });
        setData({});
        switch (menuValue) {
            case "1":
                try {
                    getEmailById(rowItem.data.id)
                        .then((resp) => {
                            setData(resp.Data[0]);
                            setLoading(false);
                        })
                        .catch((e) => {
                            setData({});
                            setLoading(false);
                        });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;

            case "2":
                try {
                    getWhatsappById(rowItem.data.id)
                        .then((resp) => {
                            setData(resp.Data[0]);
                            setLoading(false);
                        })
                        .catch((e) => {
                            setData({});
                            setLoading(false);
                        });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;

            case "3":
                try {
                    getSmsById(rowItem.data.id)
                        .then((resp) => {
                            setData(resp.Data[0]);
                            setLoading(false);
                        })
                        .catch((e) => {
                            setData({});
                            setLoading(false);
                        });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;
            case "4":
                try {
                    getSmsById(rowItem.data.id).then((resp) => {
                        if(resp && resp.Data && resp.Status === 'Success'){
                            setData(resp.Data[0]);
                        }else{
                            setData({});
                        }
                        setLoading(false);
                    }).catch((e) => {
                        setData({});
                        setLoading(false);
                    });
                } catch (e) {
                    showErrorNotification(JSON.stringify(e));
                    setLoading(false);
                }
                break;
            default:
        }
    };

    return (
        <div className="ozAdminEmailContainer">
            <div >
                <Stack direction="row" sx={{ marginBottom: 2, }} spacing={1} justifyContent="space-between"  >
                    <Typography sx={{
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '28px'
                    }} flexGrow={1} alignSelf='center'>Template</Typography>
                    {/*    <Search sx={{ display: 'flex', maxHeight: '30px', padding: '10px', width: '210px' }} style={{ marginRight: '10px', marginTop: '10px', }}>
                        <StyledInputBase style={{ width: '169px' }}
                            placeholder={'Search any template by name'}
                            inputProps={{ "aria-label": "search" }} />
                        <SearchIcon sx={{
                            color: "#99A0A8",
                            alignSelf: 'center'
                        }} />
                    </Search> */}
                    <div>
                        {/* {authPage(AdminPages.TEMPLATES_EDIT) && <Button
                            id="basic-button"
                            sx={buttonStyle}
                            variant="contained"
                            style={{ marginTop: '10px', width: '158px' }}
                            onClick={handleClick}
                            startIcon={<AddBoxOutlinedIcon />} >
                            New Template
                        </Button>} */}
                        {(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full')) && <Button
                            id="basic-button"
                            sx={buttonStyle}
                            variant="contained"
                            style={{ marginTop: '10px', width: '158px' }}
                            onClick={handleClick}
                            startIcon={<AddBoxOutlinedIcon />} >
                            New Template
                        </Button>}
                        {dialog && <  OZDialog
                            hideActions={true}
                            onClose={handleClose}
                            open={open}
                            title='Which template you’re creating ' >
                            <Typography sx={{
                                fontSize: '12px', marginBottom: '8px'
                            }} > Choose a type of template</Typography>
                            <Box sx={{
                                border: '1px solid #D6D6D7',
                                borderRadius: '8px',
                                background: '#FFFFFF',
                                width: '414px',
                                minHeight: '145px'
                            }} >
                                <ListItemButton
                                    onClick={() => { emailTemplate(); changeColumn("1") }}
                                    selected={"1" === menuValue}
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "1" ? "#3D8BF8" : "#212121", marginTop: "10px"
                                    }}>
                                    Email
                                </ListItemButton>
                                <ListItemButton
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "2" ? "#3D8BF8" : "#212121",
                                    }}
                                    onClick={() => { whatsAppTemplate(); changeColumn("2") }}
                                    selected={"2" === menuValue} >
                                    Whatsapp
                                </ListItemButton>
                                <ListItemButton
                                    selected={"3" === menuValue}
                                    onClick={() => { smsTemplate(); changeColumn("3") }}
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "3" ? "#3D8BF8" : "#212121", marginBottom: "10px"
                                    }}>
                                    SMS
                                </ListItemButton>
                                <ListItemButton
                                    selected={"4" === menuValue}
                                    onClick={() => { webChatTemplate(); changeColumn("4") }}
                                    sx={{
                                        ...styleListItemButton, color: menuValue === "4" ? "#3D8BF8" : "#212121", marginBottom: "10px"
                                    }}>
                                    Webchat
                                </ListItemButton></Box >
                            <Stack marginTop='18px' marginLeft={'218px'} direction={'row'} spacing='16px'>
                                <Button sx={{ ...buttonStyles }} variant='outlined' onClick={handleClose} >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => continueClicked(menuValue)}
                                    sx={{ ...buttonStyles }}>
                                    Continue
                                </Button>
                            </Stack>
                        </OZDialog>}

                    </div>
                </Stack>
                <div style={{ display: "flex", height: "30px", marginLeft: '3px' }}>
                    <TabContext value={menuValue}>
                        <TabList
                            TabIndicatorProps={{
                                sx: { backgroundColor: "#008EFF", marginBottom: "18px" },
                            }}
                            onChange={handleChange} aria-label="lab API tabs example">

                            <OZCustomToggleButton
                                sx={{ ...toggleStyle, borderRight: 0 }}
                                value="1">
                                <Typography sx={styleToggleButtonInput}>Email</Typography>
                            </OZCustomToggleButton>
                            <OZCustomToggleButton
                                sx={{ ...toggleStyle, borderRight: 0 }}
                                value="2">
                                <Typography sx={styleToggleButtonInput}>Whatsapp</Typography>
                            </OZCustomToggleButton>
                            <OZCustomToggleButton
                                sx={{ ...toggleStyle }}
                                value="3">
                                <Typography sx={styleToggleButtonInput}>SMS </Typography>
                            </OZCustomToggleButton>
                            <OZCustomToggleButton
                                sx={{ ...toggleStyle }}
                                value="4">
                                <Typography sx={styleToggleButtonInput}>Webchat </Typography>
                            </OZCustomToggleButton>
                        </TabList>
                        <span style={{ flexGrow: 1, alignSelf: "flex-end" }}>
                            <Divider variant="fullWidth" sx={{ borderColor: "#E6E5E6" }} />
                        </span>
                    </TabContext>
                </div>
                <div className="oz-admin-Template-container">
                    {menuValue === "1" && showForm.show && showForm.isNew && (
                        <EmailTemplateForm emailTemplate={getEmailTemplate} onClose={closeEmailTemplateForm} />
                    )}
                    {menuValue === "2" && showForm.show && showForm.isNew && (
                        <WhatsappTemplateForm onClose={closeWhatsappTemplateForm} whatsappTemplate={getWhatsappTemplate} />
                    )}
                    {menuValue === "3" && showForm.show && showForm.isNew && (
                        <SMSTemplateForm onClose={closeSMSTemplateForm} smsTemplate={getSmsTemplate} />
                    )}
                    {menuValue === "4" && showForm.show && showForm.isNew && (
                        <WebChatTemplateForm onClose={closeSMSTemplateForm} webchatTemplate={getWebchatTemplate} />
                    )}
                    {loading ?
                        (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                <LoadingScreen />
                            </div>
                        )
                        :
                        (
                            <>
                                {menuValue === "1" && showForm.show && !showForm.isNew && (
                                    <EmailTemplateForm emailTemplate={getEmailTemplate} onClose={closeEmailTemplateForm} data={data} />
                                )}
                                {menuValue === "2" && showForm.show && !showForm.isNew && (
                                    <WhatsappTemplateForm onClose={closeWhatsappTemplateForm} whatsappTemplate={getWhatsappTemplate} data={data} />
                                )}
                                {menuValue === "3" && showForm.show && !showForm.isNew && (
                                    <SMSTemplateForm onClose={closeSMSTemplateForm} smsTemplate={getSmsTemplate} data={data} />
                                )}
                                {menuValue === "4" && showForm.show && !showForm.isNew && (
                                    <WebChatTemplateForm onClose={closeEmailTemplateForm} webchatTemplate={getWebchatTemplate} data={data} />
                                )}
                                <>
                                    <OZGridToolbar
                                        title={gridTitle()}
                                        searchPlaceholder={"Search by name"}
                                        rowCount={rowData?.length}
                                        dataGridRef={gridRef}
                                        fileName='Templates'
                                    />
                                    {showMenu.show && showMenu.isNew && (
                                        <ChooseForm email={getEmailTemplate} web={getWebchatTemplate} whatsapp={getWhatsappTemplate} sms={getSmsTemplate} menuType={addFormtype} close={setAddFormType} />
                                    )}
                                    <OZDataGrid
                                        height="26.3rem"
                                        columns={columnDefs}
                                        data={rowData}
                                        rowClickCallback={onClickRow}
                                        setGridRef={setGridRef}
                                    />
                                </>
                            </>
                        )
                    }

                </div>
            </div>
        </div >
    );
}