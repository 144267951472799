import { Table, TableBody, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import React from "react";
import { TableComponents, TableVirtuoso } from "react-virtuoso"


interface Props {
    tableDataRef: any
    data: any,
    HeaderContent?: any,
    rowContent?: any,
}


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        // backgroundColor: '#edf0f280'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));


const getGroupRowStyle = (item: any) => {
    if (item.SNO % 2 === 0) {
        return {
            backgroundColor: '#edf0f280'
        }
    }
}


const VTableComponents: TableComponents<any> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer className="custom-table-scroll custom-table-maxHeight" {...props} ref={ref} />
    )),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <StyledTableRow sx={getGroupRowStyle(_item)} {...props} className={_item.hasOwnProperty('groupName') ? 'rowsGroupWrapper' : ''} />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

const HuntingStatusVTable: React.FC<Props> = (VirtualTableProps) => {


    return (
        <TableVirtuoso
            data={VirtualTableProps.data}
            components={{
                ...VTableComponents,
                Table: (props: any) => (
                    <Table ref={VirtualTableProps.tableDataRef} {...props} />
                ),
            }}
            fixedHeaderContent={VirtualTableProps.HeaderContent}
            itemContent={VirtualTableProps.rowContent}
        />
    )
}


export default HuntingStatusVTable