import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { Button, Grid } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import { reMapDataFile } from '../../../services/api-service';
import OZDialog from "../../../components/admin/common/OZDialog";
import { showErrorNotification, showInfoNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { DropzoneArea } from "mui-file-dropzone";
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';

let uploadedFiles = [];

const RemapDataForm = (props) => {
    const [openRemapDataform, setRemapDataform] = useState(true);
    const [disabledByLoading, setDisabledByLoading] = useState(false);
    const [newLoading, setNewLoading] = useState(false);

    const closeRemapDataform = () => {
        uploadedFiles = [];
        setRemapDataform(false);
        props.onClose();
    }

    async function addUploadFile(id, name) {
        setNewLoading(true);
        if (uploadedFiles?.length > 0) {
            var form = new FormData();
            console.log(uploadedFiles[0]);
            form.append("file", uploadedFiles[0]);
        }
        try {
            reMapDataFile(form, id).then(resp => {
                if (resp?.Status === 'Success') {
                    showSuccessNotification(`Data updated is successfully initiated for (${name}) Campaign`);
                    props.updateRemapDataForm();
                    closeRemapDataform();
                } else {
                    showErrorNotification(`Data upload is failed for (${name}) Campaign`);
                }
                setNewLoading(false);
            }).catch(e => {
                setNewLoading(false);
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    const formButtonStyle = useMemo(
        () => ({
            borderRadius: '8px',
            fontWeight: '400',
            fontSize: '14px',
        }),
        []
    );

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `remap_sample.xlsx`;
        link.href = `${process.env.PUBLIC_URL}/sample_files/remap_sample.xlsx`;
        link.click();
    };

    const DataForm = (props) => {
        if (props?.disabled) {
            setDisabledByLoading(true);
        } else {
            setDisabledByLoading(false);
        }

        let initData = props.data;

        const UploadFileComp = (props) => {

            const [fileObjsUploaded, setFileObjsUploaded] = useState(uploadedFiles?.length > 0 ? uploadedFiles : [])
            const maxSize = 25 * 1024 * 1024;

            function updateUploadedFiles(data, id) {
                id = 12345;
                uploadedFiles = data;
            }

            function checkVariant(message, variant) {
                if (variant === "success") {
                    showSuccessNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
                else if (variant === "error") {
                    showErrorNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
                else {
                    showInfoNotification(`${(variant[0].toUpperCase() + variant.slice(1))}: ${message}`);
                }
            }

            function UploadIcon() {
                return (
                    <div style={{ paddingTop: '10px', float: 'right', paddingRight: '15px' }}>
                        <img alt='edit' src={`${process.env.PUBLIC_URL}/icons/downArrowDropDown.svg`}></img>
                    </div>
                )
            }
            let clone = structuredClone(props?.props);
            const uploadPropsId = clone?.campaignId;

            return (
                <Grid item xs={12}>
                    <div style={{ marginBottom: '4px' }}>
                        <label style={{ height: '10px', width: '10px' }}>Upload File * <span style={{ opacity: '0.5', float: 'right', cursor: 'pointer' }} onClick={onDownload}>Download Sample</span></label>
                    </div>
                    <div style={{ backgroundColor: 'white' }}>
                        <DropzoneArea
                            disabled={disabledByLoading}
                            maxFileSize={maxSize}
                            initialFiles={uploadedFiles}
                            fileObjects={fileObjsUploaded}
                            Icon={UploadIcon}
                            dropzoneParagraphClass="campaign-dropzone-text"
                            dropzoneClass="campaign-drop-zone-area"
                            previewGridProps={{
                                item: {
                                    position: 'absolute',
                                    display: 'flex',
                                    flexWrap: "wrap",
                                    alignItems: 'end',
                                },
                                container: { spacing: 1, direction: 'row' }
                            }}
                            dropzoneText={" "}
                            acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                            onDelete={(fileObj) => { console.log('Removed File:', fileObj); uploadedFiles = []; setFileObjsUploaded([]) }}
                            onChange={(files) => {
                                if (files?.length > 0) {
                                    setFileObjsUploaded([files[0]])
                                }
                                updateUploadedFiles(files, uploadPropsId)
                            }}
                            onAlert={(message, variant) => { checkVariant(message, variant); }}
                            filesLimit={1}
                            useChipsForPreview={true}
                            previewText="Selected files"
                            previewChipProps={{ marginLeft: "12px" }}
                            showAlerts={false}
                        />
                    </div>
                </Grid>
            )
        }

        return (
            <div >
                <Formik
                    initialValues={initData}
                    onSubmit={(values) => {
                        let saveData = structuredClone(values);
                        if (uploadedFiles?.length === 0) {
                            showErrorNotification("File to Upload is required.");
                            return
                        }
                        addUploadFile(saveData.campaignId, props.data.campaignName);
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="addRemapData-Form" className='ozcw-scrollbar' style={{
                                maxHeight: '80vh',
                                overflowY: 'auto',
                            }}>
                            <Grid container={true} spacing={2} direction='column'>
                                <Grid item xs={6}>
                                    <OZInput
                                        disabled={true}
                                        name="campaignName"
                                        label="Campaign Name *"
                                        placeholder="Please Enter"
                                    />
                                </Grid>
                            </Grid>
                            <Grid style={{ marginTop: '2px', fontSize: '12px' }} container spacing={2} display='flex' direction={'row'} >
                                <Grid item xs={12}>
                                    <UploadFileComp props={props?.data} />
                                </Grid>
                            </Grid>
                            <div style={{ color: '#99A0A8', fontSize: '12px', fontWeight: '400', paddingTop: '5px' }}>
                                Only XLSX Format is accepted.
                                <span style={{ color: '#3D8BF8', fontSize: '12px', fontWeight: '400', cursor: 'pointer' }} onClick={onDownload}>Download XLSX Sample</span>
                            </div>
                            <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 1 }} justifyContent='end'>
                                <Grid item>
                                    <Button style={formButtonStyle} onClick={closeRemapDataform} variant="outlined" type="submit">
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button style={formButtonStyle} variant="contained" type="submit" form="addRemapData-Form">
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>

                            {disabledByLoading && (
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            )
                            }
                            {newLoading &&
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </div >
        );
    }
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        {`ReMap Agent ${props?.props?.campaignType ? props?.props?.campaignType : ''} Data`}
                    </label>
                }
                open={openRemapDataform}
                onClose={closeRemapDataform}
            >
                <DataForm data={props?.props} disabled={props?.disabled} />
            </OZDialog>
        </div>
    );
};

export default RemapDataForm;
