import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useMemo, useRef, useState } from "react"
import * as yup from "yup";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { createFeature } from "../../../services/api-service";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import { Formik } from "formik";
import OZInput from "../../../components/admin/common/OZInput";
import OZDialog from "../../../components/admin/common/OZDialog";
import OZDrawer from "../../../components/admin/common/OZDrawer/OZDrawer";
import DeleteDialog from "../../../components/admin/common/CustomDeleteDialog";
import '../addWidget/WidgetReportForm.css';
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";

const FeatureViewDrawer = (widgetViewProps) => {
    return (<div style={{ maxHeight: "60vh", overflowY: "auto", padding: "10px", width: "29rem", }}>
        <Grid container={true} spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-administration-view-Header">Feature Name</label>
                </Grid>
                <label className="oz-adminstar">{widgetViewProps?.data.name}</label>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} marginTop={"0.313rem"}>
                    <label className="oz-administration-view-Header">Feature Desc</label>
                </Grid>
                <label className="oz-adminstar">{widgetViewProps?.data.description}</label>
            </Grid>
        </Grid>
    </div>)
}

const FeatureForm = (props) => {

    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;

    const [reportFormMode, setReportFormMode] = useState(initMode);
    const [ReportFormAnchorEl, setReportAnchorEl] = useState(false);
    const [open, setState] = useState(true);
    const anchorRef = useRef();
    const [openDeleteDialog, setDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const validationSchema = useMemo(() =>
        yup.object({
            name: yup
                .string("Enter FeatureName")
                .required("FeatureName is required"),
            description: yup
                .string("Enter FeatureDesc")
                .required("FeatureDesc is required"),
        }), []
    );

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }), []
    );

    const viewModemorevertclick = () => {
        setTimeout(() => setReportAnchorEl(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setReportAnchorEl(false);
    };

    const closeReportForm = () => {
        props.onClose();
        setState(false);
        localStorage.removeItem("featureForm");
    }

    const onClickEditButton = () => {
        setReportFormMode(ADMIN_FORM_MODES.edit);
    }

    const onClickDiscardButton = () => {
        setReportFormMode(ADMIN_FORM_MODES.view);
    }

    function onOpenDeleteDialog() {
        setReportAnchorEl(false);
        setDeleteDialog(true);
    }

    const deleteDashboardWigdet = () => {
        setReportAnchorEl(false);
        props.delFunction(props.data.id, props.data.name);
        setDeleteDialog(false);
    }

    async function addFeature(data) {
        setLoading(true);
        try {
            createFeature(data).then((resp) => {
                if (resp.Status === "Success") {
                    closeReportForm();
                    props.Report();
                    showSuccessNotification(resp.Message)
                }
                else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                showErrorNotification(JSON.stringify(e))
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateFeature(data) {
        setLoading(true);
        delete data.createdDate;
        delete data.updatedDate;
        try {
            createFeature(data).then((resp) => {
                if (resp.Status === "Success") {
                    closeReportForm();
                    props.Report();
                    showSuccessNotification(resp.Message);
                }
                else {
                    showErrorNotification(JSON.stringify(resp.Message));
                }
                setLoading(false);
            }).catch((e) => {
                setLoading(false);
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    };

    let buttonStyle = useMemo(() => ({
        borderRadius: "0.5rem",
        height: "2.75rem",
        fontSize: "0.875rem",
        fontWeight: 400,
    }), []
    );


    const styleMenuItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {
        let initData = props.data ||
        {
            name: '',
            description: '',
        };

        let style1 = useMemo(() => ({
            width: '400px',
            minHeight: "20vh",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            minHeight: '40vh',
            padding: '5px'
        }), [])

        function getInitData() {
            let localData = localStorage.getItem('featureForm');
            if (localData) {
                return JSON.parse(localData);
            } else {
                return initData;
            }
        }

        return (<div className="ozReportAddForm">
            <Formik
                initialValues={getInitData()}
                validateOnMount
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (reportFormMode === ADMIN_FORM_MODES.add || reportFormMode === ADMIN_FORM_MODES.edit) {
                        localStorage.setItem('featureForm', JSON.stringify(values));
                    }
                    let finalData = structuredClone(values)
                    if (reportFormMode === ADMIN_FORM_MODES.add) {
                        addFeature(finalData);
                    }
                    else if (reportFormMode === ADMIN_FORM_MODES.edit) {
                        updateFeature(finalData);
                    }
                }}>
                {(props) => (<form
                    onSubmit={props.handleSubmit}
                    id="featureForm"
                    className="ozcw-scrollbar"
                    style={reportFormMode === ADMIN_FORM_MODES.add ? style1 : style2}>
                    <Grid container spacing={2}>
                        <>
                        <Grid container xs={12} spacing={2} sx={{marginTop: 2, padding: '5px', marginLeft: '2px'}}>
                            <Grid item xs={12}>
                                <OZInput
                                    name='name'
                                    label='Feature Name *'
                                    placeholder='Feature Name'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OZInput
                                    name='description'
                                    label='Feature Description *'
                                    placeholder='Feature Description'
                                />
                            </Grid>
                        </Grid>
                        {reportFormMode === ADMIN_FORM_MODES.edit &&
                            <Grid item xs={12}>
                                {reportFormMode === ADMIN_FORM_MODES.edit &&
                                    <div className="oz-administration-view-drawer-buttons-container">
                                        <Button sx={buttonStyle} variant="outlined" onClick={closeReportForm}>
                                            Cancel
                                        </Button>
                                        <Button sx={{ ...buttonStyle, marginLeft: "10px" }} variant="contained" type="submit" form="featureForm" 
                                            onClick={() => {
                                                if (!props.isValid) {
                                                    props.handleSubmit();
                                                    showErrorNotification("Please Check the values and fill all the mandatory field");
                                                }
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                }
                            </Grid>
                        }
                        {loading ?
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
                                    <LoadingScreen />
                                </div>
                            :
                            null
                        }
                        </>
                    </Grid>
                </form>)}
            </Formik>
        </div>)
    };
    return (<div>
        <div>
            {reportFormMode === ADMIN_FORM_MODES.add && (
                <OZDialog
                    title="Add Feature"
                    open={open}
                    onClose={closeReportForm}
                    saveButtonProps={{
                        form: 'featureForm',
                        type: 'submit',
                        label: 'save'
                    }}>
                    {reportFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                </OZDialog>
            )}
        </div>
        {reportFormMode !== ADMIN_FORM_MODES.add &&
            <OZDrawer
                open={open}
                title={
                    <div style={{ width: "100%" }}>
                        <div className="oz-adminstration-drawer-title-container">
                            {reportFormMode !== ADMIN_FORM_MODES.edit && <img className="oz-ivr-view-morevereticon" ref={anchorRef}
                                src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                alt="edit"
                                onClick={viewModemorevertclick}
                            ></img>}
                            {reportFormMode !== ADMIN_FORM_MODES.edit &&
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '18px',
                                        alignSelf: 'center',
                                        marginLeft: "10px"
                                    }}
                                >
                                    {props?.data?.name}
                                </Typography>}
                            <div  >
                                <Menu
                                    anchorEl={ReportFormAnchorEl}
                                    open={Boolean(ReportFormAnchorEl)}
                                    onClose={handleMenuClose}
                                    sx={{
                                        "& .MuiPaper-root": {
                                            backgroundColor: "white",
                                            width: "133px",
                                        },
                                        "& .MuiMenu-paper": {
                                            backgroundColor: "white",
                                            boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                            border: "0.0625rem solid #E6E5E6",
                                            borderRadius: "12px",
                                            marginLeft: "-8.9625rem",
                                            marginTop: '-1.5rem'
                                        },
                                    }}
                                >
                                    <MenuItem sx={{ ...styleMenuItemButton, marginTop: "7px" }} onClick={onOpenDeleteDialog}>Delete</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        {openDeleteDialog && <DeleteDialog title={"Delete"} content={`This will completely delete (${props.data.name}) Feature permanently. You cannot undo this action.`} onClose={() => { setDeleteDialog(false) }
                        } open={true} deleteFunction={deleteDashboardWigdet} />}
                        <div>

                            {reportFormMode === ADMIN_FORM_MODES.edit && (<Typography
                                size="small"
                                variant="text"
                                sx={{
                                    ...styleEditButton,
                                    marginLeft: "362px",
                                    marginTop: "14px",
                                    position: "absolute",
                                    cursor: "pointer"
                                }}
                                onClick={onClickDiscardButton}
                            >
                                Discard
                            </Typography>)}
                        </div>
                    </div>
                }
                onClose={closeReportForm}
                onEdit={onClickEditButton}
                mode={reportFormMode}
            >
                {reportFormMode !== ADMIN_FORM_MODES.edit && <FeatureViewDrawer data={props.data} />}
                {reportFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
            </OZDrawer >}
    </div>)
};
export default FeatureForm