import { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { ReactComponent as DropdownIcon } from '../../../../static/icons/dropdown.svg';
import { ReactComponent as SegmentIcon } from '../../../../static/icons/segment.svg';
import { ReactComponent as AddIcon } from '../../../../static/icons/addsquare.svg';
import { ReactComponent as DeleteIcon } from '../../../../static/icons/deletenew.svg';
import './sendToCampaign.css';
import OZCheckbox from '../../../../components/OZCheckbox/OZCheckbox';
import { showErrorNotification } from '../../../../components/admin/common/NotiService';

export default function SendToCampaign({ activeTab }) {
    const [segments, setSegments] = useState([]);
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [contactOptions, setContactOptions] = useState([]);

    async function sendToCampaign() {
        console.log('send to campaign');
    }

    async function getSegments() {
        console.log('get segments');
        return ['Segment1', 'Segment2', 'Segment3', 'Segment4'];
    }

    async function getContactOptions() {
        console.log('get contact options');
        return ['Contact1', 'Contact2', 'Contact3', 'Contact4'];
    }

    useEffect(() => {
        if (activeTab !== "Send to Campaign") {
            return;
        }
        getSegments().then((segments) => {
            setSegments(segments);
        });
        getContactOptions().then((contactOptions) => {
            setContactOptions(contactOptions);
        });
    }, [activeTab]);

    return (activeTab === "Send to Campaign" ?
        <Box className='oz-data-management-send-to-campaign-container'>
            <Box className="oz-data-management-send-to-campaign-header">
                <Typography className="oz-data-management-title">
                    Send to Campaign
                </Typography>
            </Box>
            <Box className="oz-data-management-send-to-campaign-content">
                <Box className='oz-data-management-send-to-campaign-autocomplete-container'>
                    <Typography className='oz-data-management-send-to-campaign-autocomplete-label'>
                        Choose Segment
                    </Typography>
                    <Autocomplete
                        multiple={true}
                        id="oz-data-management-send-to-campaign-autocomplete"
                        options={segments}
                        value={selectedSegments}
                        inputValue={inputValue}
                        onChange={(event, newValue) => {
                            setSelectedSegments(newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        componentsProps={{
                            paper: {
                                sx: {
                                    border: "1px solid #D6D6D7", borderRadius: "8px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                        },
                                        '&:hover fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: "1px solid #D6D6D7",
                                            borderRadius: "8px",
                                            borderBottomLeftRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                    },
                                }}
                            />
                        )}
                        popupIcon={<DropdownIcon />}
                        size='small'
                        renderOption={(props, option, { selected }) => {
                            const newValue = option.includes(' (Create New)') ? option.split(' (Create New)')[0] : option;
                            return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                <Box sx={{ display: 'flex', alignItems: 'center', margin: '3px 0px 0px 0px', justifyContent: newValue ? 'center' : 'flex-start', gap: '4px' }} >
                                    <OZCheckbox checked={selected} />
                                </Box>
                                <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                    {option}
                                </Typography>
                            </li>
                        }}
                        autoComplete={true}
                        disableCloseOnSelect={true}
                        renderTags={(value, getTagProps) => value.map((option, index) => (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', pl: 1 }} key={index}>
                                <Typography style={{ fontSize: '16px', color: '#1D242D', fontWeight: 400 }}>
                                    {option + (index === value.length - 1 ? '' : ',')}
                                </Typography>
                            </Box>
                        ))}
                        limitTags={3}
                    />
                </Box>
                <SendToCampaignContent segments={selectedSegments} contactOptions={contactOptions} />
                <CampaignContent />
            </Box>
            <Box className="oz-data-management-send-to-campaign-footer">
                <Button
                    className='oz-data-management-send-to-campaign-cancel-button'
                    variant="outlined">Cancel</Button>
                <Button
                    className='oz-data-management-send-to-campaign-update-button'
                    variant="contained" onClick={sendToCampaign}>Update </Button>
            </Box>
        </Box> : null
    )
}

const SendToCampaignContent = ({ segments, contactOptions }) => {
    const [contact, setContact] = useState([]);

    return (
        <Box className="oz-data-management-send-to-campaign-content-container">
            <Box className="oz-data-management-send-to-campaign-content-header">
                <SegmentIcon />
                <Typography className="oz-data-management-send-to-campaign-content-title">
                    Choose Primary Contact
                </Typography>
            </Box>
            {segments.map((segment, index) => (
                <Box key={index} className="oz-data-management-send-to-campaign-content-segment-row">
                    <Typography className="oz-data-management-send-to-campaign-content-segment-row-subtitle">
                        {segment} :
                    </Typography>
                    <Box className="oz-data-management-send-to-campaign-content-row">
                        <Typography className="oz-data-management-send-to-campaign-autocomplete-label">
                            Select Primary Contact
                        </Typography>
                        <Autocomplete
                            multiple={true}
                            options={contactOptions}
                            value={contact[index]}
                            onChange={(event, newValue) => {
                                setContact([...contact.slice(0, index), newValue, ...contact.slice(index + 1)]);
                            }}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        border: "1px solid #D6D6D7", borderRadius: "8px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"
                                    }
                                }
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&:hover fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderBottomRightRadius: "0px"
                                            },
                                        },
                                    }}
                                />
                            )}
                            popupIcon={<DropdownIcon />}
                            size='small'
                            renderOption={(props, option, { selected }) => {
                                const newValue = option.includes(' (Create New)') ? option.split(' (Create New)')[0] : option;
                                return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '3px 0px 0px 0px', justifyContent: newValue ? 'center' : 'flex-start', gap: '4px' }} >
                                        <OZCheckbox checked={selected} />
                                    </Box>
                                    <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                        {option}
                                    </Typography>
                                </li>
                            }}
                            autoComplete={true}
                            disableCloseOnSelect={true}
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', pl: 1 }} key={index}>
                                    <Typography style={{ fontSize: '16px', color: '#1D242D', fontWeight: 400 }}>
                                        {option + (index === value.length - 1 ? '' : ',')}
                                    </Typography>
                                </Box>
                            ))}
                            limitTags={3}
                        />
                    </Box>
                </Box>
            ))}

        </Box>
    )
}

const CampaignContent = () => {

    const [campaigns, setCampaigns] = useState([{ campaign: [], percentage: 0 }]);
    const remainingPercentage = 100 - campaigns.reduce((acc, campaign) => acc + Number(campaign.percentage), 0);

    const handleClickAddCampaign = () => {
        setCampaigns((prevCampaign) => [...prevCampaign, { campaign: [], percentage: '0' }]);
    }

    return (
        <Box className="oz-data-management-send-to-campaign-content-container">
            <Box className="oz-data-management-send-to-campaign-content-header">
                <Typography className="oz-data-management-send-to-campaign-content-title" flexGrow={1}>
                    Choose Campaign
                </Typography>
                <IconButton onClick={handleClickAddCampaign}>
                    <AddIcon />
                </IconButton>
            </Box>
            {campaigns.map((campaign, index) => (
                <Box key={index} className="oz-data-management-send-to-campaign-content-row-container">
                    <Box className="oz-data-management-send-to-campaign-content-row">
                        <Typography className="oz-data-management-send-to-campaign-autocomplete-label">
                            Campaign*
                        </Typography>
                        <Autocomplete
                            multiple={true}
                            options={['Campaign1', 'Campaign2', 'Campaign3', 'Campaign4']}
                            value={campaign.campaign}
                            onChange={(event, newValue) => {
                                setCampaigns((prevCampaign) => {
                                    let clonedCampaign = [...prevCampaign];
                                    clonedCampaign[index].campaign = newValue;
                                    return clonedCampaign;
                                });
                            }}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        border: "1px solid #D6D6D7", borderRadius: "8px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px"
                                    }
                                }
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Select"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&:hover fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: "1px solid #D6D6D7",
                                                borderRadius: "8px",
                                                borderBottomLeftRadius: "0px",
                                                borderBottomRightRadius: "0px"
                                            },
                                        },
                                    }}
                                />
                            )}
                            popupIcon={<DropdownIcon />}
                            size='small'
                            renderOption={(props, option, { selected }) => {
                                const newValue = option.includes(' (Create New)') ? option.split(' (Create New)')[0] : option;
                                return <li {...props} style={{ padding: '14px 12px', height: "24px", cursor: 'pointer', gap: '2px' }} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', margin: '3px 0px 0px 0px', justifyContent: newValue ? 'center' : 'flex-start', gap: '4px' }} >
                                        <OZCheckbox checked={selected} />
                                    </Box>
                                    <Typography style={{ fontSize: '12px', color: '#546881', fontWeight: 400 }}>
                                        {option}
                                    </Typography>
                                </li>
                            }}
                            autoComplete={true}
                            disableCloseOnSelect={true}
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', pl: 1 }} key={index}>
                                    <Typography style={{ fontSize: '16px', color: '#1D242D', fontWeight: 400 }}>
                                        {option + (index === value.length - 1 ? '' : ',')}
                                    </Typography>
                                </Box>
                            ))}
                            limitTags={3}
                        />
                    </Box>
                    <Box className="oz-data-management-send-to-campaign-content-row-percentage">
                        <Typography className="oz-data-management-send-to-campaign-autocomplete-label">
                            Percentage*
                        </Typography>
                        <TextField
                            value={Number(campaign.percentage)}
                            type='number'
                            onChange={(event) => {
                                let percentage = Number(event.target.value || 0);
                                if (percentage < 0) {
                                    showErrorNotification('Percentage should not be negative');
                                    return;
                                }
                                campaigns.forEach((campaign, i) => {
                                    if (i !== index) {
                                        percentage += Number(campaign.percentage);
                                    }
                                });
                                console.log(percentage);
                                if (percentage > 100) {
                                    showErrorNotification('Total percentage should not exceed 100%');
                                    return;
                                }
                                setCampaigns((prevCampaign) => {
                                    let clonedCampaign = [...prevCampaign];
                                    clonedCampaign[index].percentage = Number(event.target.value || 0);
                                    return clonedCampaign;
                                });
                            }}
                            variant="outlined"
                            size='small'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                    '&:hover fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: "1px solid #D6D6D7",
                                        borderRadius: "8px",
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Typography mt={3}>
                        %
                    </Typography>
                    <IconButton
                        sx={{ mt: 3 }}
                        onClick={() => {
                            setCampaigns((prevCampaign) => {
                                let clonedCampaign = [...prevCampaign];
                                clonedCampaign.splice(index, 1);
                                return clonedCampaign;
                            });
                        }}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}

            <Box className="oz-data-management-send-to-campaign-content-row">
                <Typography className="oz-data-management-send-to-campaign-autocomplete-label">
                    Remaining Percentage: {Math.round(remainingPercentage * 100) / 100}%
                </Typography>
                <Typography className='oz-data-management-send-to-campaign-distribute-evenly' onClick={() => {
                    if (remainingPercentage < 0) {
                        showErrorNotification('Total percentage should not exceed 100%');
                        return;
                    }
                    if (campaigns.length === 0) {
                        showErrorNotification('Please add campaigns');
                        return;
                    }

                    setCampaigns((prevCampaign) => {
                        let clonedCampaign = [...prevCampaign];
                        clonedCampaign.forEach((campaign, index) => {
                            clonedCampaign[index].percentage = (100 / clonedCampaign.length).toFixed(2);
                        });
                        return clonedCampaign;
                    });
                }}>
                    Distribute evenly
                </Typography>
            </Box>
        </Box >
    )
}
