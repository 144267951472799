import { Box, Typography } from '@mui/material'
import './dataManagement.css';
import DataManagementTabs from './DataManagementTabs';
import Files from './Tabs/Files/Files.jsx';
import SendToCampaign from './Tabs/SendToCampaign/SendToCampaign';
import AssignPriorities from './Tabs/AssignPriorities/AssignPriorities';
import { useState } from 'react';
import { DataManagementTabsList } from '../../utils/constants';
import DeleteData from './Tabs/DeleteData/DeleteData';

export default function DataManagement() {
    const [activeTab, setActiveTab] = useState(DataManagementTabsList[0]);

    return (
        <Box className='oz-data-management-container'>
            <Typography className="oz-data-management-title">
                Data Management
            </Typography>
            <DataManagementTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <Files activeTab={activeTab} />
            <SendToCampaign activeTab={activeTab} />
            <AssignPriorities activeTab={activeTab} />
            <DeleteData activeTab={activeTab} />
        </Box>
    )
}
