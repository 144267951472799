import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Grid, Typography, TextField, Tooltip } from '@mui/material';
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import OZRichText from '../../../components/admin/common/OZRichText/OZRichText';
import './Email.css'
import OZDialog from '../../../components/admin/common/OZDialog';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { addTemplate, deleteTemplateById, updateTemplate } from '../../../services/api-service';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import { AdminPages, NewAdminPages, authPage, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { ScrollToFieldError } from '../../../components/admin/common/ScrollToFieldError';

const WebChatView = (props) => {
    const TemplateFieldsView = (props) => {
        return (
            <div>
                <label className='oz-template-view-value' dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <label className='oz-template-view-label'>{props.label}</label>
                {
                    (props.data.length !== 0) ?
                        <label className='oz-template-view-value'>{props.data} </label> :
                        <label className='oz-template-view-value'> - </label>
                }
            </div>
        )
    }

    return (
        <div className="oz-Template-view-lablel-container">
            {props?.data?.text &&
                <TemplateFieldsView data={props?.data?.text} label={" Template Text *"} />}
            {props?.data?.templateId &&
                <TemplateFieldsView data={props?.data?.templateId} label={"Template ID *"} />}
            {props?.data?.entityId &&
                <TemplateFieldsView data={props?.data?.entityId} label={"Entity Id *"} />}
            {props?.data?.type &&
                <TemplateFieldsView data={props?.data?.type} label={"Type *"} />}
        </div>
    )
}
const WebChatTemplateForm = (props) => {
    let initMode = props.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add
    const validationSchema = useMemo(() => (yup.object({
        name: yup
            .string('Enter Name ')
            .min(3, "Template Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .max(50, "Template Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Template Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters.")
            .required('Template Name is required'),
        text: yup
            .string()
            .matches(/^(?!\s+$).{1,1200}$/, "Text should not be empty and allows max of 1200 characters.")
            .required('Template Text is required.'),
    })), []);
    const [open, setState] = useState(true)
    const [webchatTemplateFormMode, setWebChatTemplateFormMode] = useState(initMode);
    const [webChatTemplateanchorE1, setWebchatTemplateAnchorE1] = React.useState(false);
    const anchorRef = React.useRef();
    const opens = Boolean(webChatTemplateanchorE1);
    const [openDeleteDailog, setDeleteDialog] = useState(false)
    const [valid, setValid] = useState(true);

    const closeWebchatTemplateForm = () => {
        props.onClose();
        setState(false)
    };

    const EditWebChatTemplateForm = () => {
        setWebChatTemplateFormMode(ADMIN_FORM_MODES.edit);
    };

    let buttonStyle = useMemo(
        () => ({
            borderRadius: "0.5rem",
            height: "2.75rem",
            fontSize: "0.875rem",
            fontWeight: 400,
        }),
        []
    );

    const morevertclick = () => {
        setTimeout(() => setWebchatTemplateAnchorE1(anchorRef.current), 1);
    };

    const handleMenuClose = () => {
        setWebchatTemplateAnchorE1(null);
    };

    function openDeleteDialog() {
        setWebchatTemplateAnchorE1(null);
        setDeleteDialog(true)
    };

    const deleteWebchatTemplate = () => {
        setWebchatTemplateAnchorE1(null);
        deleteWebChatById(props.data.id)
        setDeleteDialog(false)
    };

    async function deleteWebChatById() {
        try {
            deleteTemplateById(props.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeWebchatTemplateForm();
                        props.webchatTemplate();
                        showSuccessNotification(resp.Message)

                    } else {
                        showErrorNotification(typeof resp.Message === 'string' ? resp.Message : resp.Message.name ? resp.Message.name : resp.Message.text);
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const onClickDiscardButton = () => {
        setWebChatTemplateFormMode(ADMIN_FORM_MODES.view);
    };

    async function addWebChat(data) {
        data.type = "web"
        try {
            addTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        showSuccessNotification(`Template ${data.name} has been added Successfully`);
                        props.webchatTemplate();
                        closeWebchatTemplateForm();
                    }
                    else {
                        // showErrorNotification(resp?.Message);
                        showErrorNotification(typeof resp.Message === 'string' ? resp.Message : resp.Message.name ? resp.Message.name : resp.Message.text);
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function updateWebChat(data) {
        data.type = "web"
        try {
            updateTemplate(data)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        closeWebchatTemplateForm();
                        props.webchatTemplate();
                        showSuccessNotification(`Template ${data.name} has been updated Successfully`);
                    }
                    else {
                        // showErrorNotification(resp?.Message);
                        showErrorNotification(typeof resp.Message === 'string' ? resp.Message : resp.Message.name ? resp.Message.name : resp.Message.text);
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const getValidationInViewMode = (e, value) => {
        props.data.name = value;
        const regex = /^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/;
        const isMatch = regex.test(value);

        const digitCount = value?.length;

        // Check the conditions for validation
        const isValidLength = value.length >= 2 && value.length <= 50;
        const isValidDigitCount = digitCount >= 2 && digitCount <= 50;

        // Final validation check
        if (isMatch && isValidLength && isValidDigitCount) {
            setValid(true);
        } else {
            setValid(false);
        }
    }

    let styleEditButton = useMemo(() => ({
        fontWeight: '400',
        fontSize: '14px',
        color: '#3D8BF8',
    }), []
    );
    const styleListItemButton = useMemo(
        () => ({
            padding: "0px",
            alignSelf: "center",
            paddingLeft: "10px",
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "8px",
            },
        }),
        []
    );

    const DataForm = (props) => {
        let initData = props.data || {
            name: '',
            text: '',
        };
        let style1 = useMemo(() => ({
            width: '600px',
            height: "20rem",
            paddingtop: "10px"

        }), [])

        let style2 = useMemo(() => ({
            maxHeight: '76vh',
            overflowY: 'auto',
            padding: '5px'
        }), [])


        return (
            <div className='oz-email-add-form'>
                <Formik initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let data = structuredClone(values);
                        if (data?.text === "" || data?.text?.length > 1200) {
                            showErrorNotification("Text should not be empty and allows max of 1200 characters.");
                            return;
                        }else {
                            if (webchatTemplateFormMode === ADMIN_FORM_MODES.add) {
                                addWebChat(data);
                            }
                            if (webchatTemplateFormMode !== ADMIN_FORM_MODES.add) {
                                if (valid === true) {
                                    updateWebChat(data);
                                }
                            }
                        }
                    }}>
                    {props => (
                        <form onSubmit={props.handleSubmit} id='webForm' className='ozcw-scrollbar' style={webchatTemplateFormMode === ADMIN_FORM_MODES.add ? style1 :
                            style2} >
                            <Grid container spacing={1} >
                                <Grid container spacing={2} direction={'row'}>
                                    {webchatTemplateFormMode === ADMIN_FORM_MODES.add && <Grid item xs={12} sm={6} md={4} lg={12} marginLeft='10px' marginTop={'35px'}>
                                        <OZInput
                                            name="name"
                                            label="Template Name*"
                                            placeholder="Name" />
                                    </Grid>}
                                </Grid>
                                {(webchatTemplateFormMode === ADMIN_FORM_MODES.add || webchatTemplateFormMode === ADMIN_FORM_MODES.edit) && (<Grid item xs={12} sm={12} md={12} lg={12} marginLeft="2px" marginTop={webchatTemplateFormMode === ADMIN_FORM_MODES.add ? "3px" : "10px"} >
                                    <OZInput
                                        name="text"
                                        label="Template Text *"
                                        placeholder="WebChat Subject"
                                    />
                                </Grid>)}
                            </Grid>
                            <ScrollToFieldError />
                        </form>
                    )
                    }
                </Formik>
                {webchatTemplateFormMode === ADMIN_FORM_MODES.edit && (
                    <div className='oz-emailtemplate-drawer-buttons'>
                        <Grid item marginRight={'10px'} >
                            <Button disabled={webchatTemplateFormMode === ADMIN_FORM_MODES.view} sx={{ ...buttonStyle, marginLeft: "10px" }} variant='outlined' onClick={closeWebchatTemplateForm}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item marginRight={'10px'}>
                            <Button
                                disabled={webchatTemplateFormMode === ADMIN_FORM_MODES.view}

                                variant="contained"
                                type="submit"
                                sx={{ ...buttonStyle, width: "130px" }}
                                form='webForm'
                            >
                                Save Changes
                            </Button></Grid>
                    </div>)}
            </div >
        )
    };
    return (
        <div>
            <div>
                {webchatTemplateFormMode === ADMIN_FORM_MODES.add && (
                    <OZDialog
                        title="Template"
                        formType="template"
                        subTitle=" Create Webchat Template"
                        saveButtonProps={{
                            form: "webForm",
                            type: 'submit',
                            label: 'save'
                        }}
                        open={open}
                        mode={webchatTemplateFormMode}
                        onClose={closeWebchatTemplateForm}>
                        <Divider style={{ color: "#E6E5E6" }}></Divider>
                        {webchatTemplateFormMode === ADMIN_FORM_MODES.add && <DataForm />}
                    </OZDialog>
                )}
            </div>
            {webchatTemplateFormMode !== ADMIN_FORM_MODES.add && (
                <OZDrawer
                    open={open}
                    formType='template'
                    title={
                        <div className='oz-template-drawer-textdeild-container'>
                            <div className='oz-template-drawer-title-container'>
                                {(webchatTemplateFormMode !== ADMIN_FORM_MODES.edit &&(newAuthPages(NewAdminPages.TEMPLATES_PAGE) || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Full') || newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit'))) && <img className='oz-template-view-morevert-img' ref={anchorRef}
                                    src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                    alt="edit"
                                    onClick={morevertclick}
                                ></img>}
                                {webchatTemplateFormMode !== ADMIN_FORM_MODES.edit &&
                                    <Tooltip title={props?.data?.name}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '18px',
                                                alignSelf: 'center',
                                                marginLeft: "0px",
                                                maxWidth: "400px", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"
                                            }}
                                        >
                                            {props?.data?.name}
                                        </Typography></Tooltip>}

                                {webchatTemplateFormMode === ADMIN_FORM_MODES.edit &&
                                    <TextField
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: "none",
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                fontWeight: 600,
                                                fontSize: "18px",
                                                color: "#212121",
                                                borderRadius: '8px',
                                                backgroundColor: '#ffff',
                                                border: "1px solid #D6D6D7",
                                                height: '44px',
                                                '&:hover': {
                                                    border: "1px solid #D6D6D7"
                                                },
                                            },
                                        }}
                                        defaultValue={props?.data?.name}
                                        fullWidth
                                        onChange={(e) => getValidationInViewMode(e, e.target.value)}
                                        error={!valid}
                                        helperText={!valid ? "Template Name should be between 2 to 50 alphanumeric characters long and allows special characters like _ and Name cannot start and end with Special characters." : ""}
                                    />
                                }
                                <div >
                                    <Menu
                                        anchorEl={webChatTemplateanchorE1}
                                        open={opens}
                                        onClose={handleMenuClose}
                                        sx={{
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                height: "37px",
                                                width: "133px",
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-8.9625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <MenuItem sx={{ ...styleListItemButton }} disabled={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'Edit')} onClick={openDeleteDialog}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                            {openDeleteDailog &&
                                <DeleteDialog
                                    title={"Delete WebChat Template"}
                                    content={`This will delete (${props.data.name}) Webchat Template permanently. You cannot undo this action.`}
                                    onClose={() => { setDeleteDialog(false) }}
                                    open={openDeleteDailog}
                                    deleteFunction={deleteWebchatTemplate}
                                />
                            }
                            <div className='oz-template-drawer-label-container'>
                                <Typography marginLeft={webchatTemplateFormMode === ADMIN_FORM_MODES.edit ? "2px" : "23px"} 
                                    marginBottom={webchatTemplateFormMode === ADMIN_FORM_MODES.edit ? "0px" : "3px"} 
                                    marginTop={webchatTemplateFormMode === ADMIN_FORM_MODES.edit ? "5px" : "2px"} 
                                    style={{ color: "#99A0A8", fontSize: '14px' }}
                                > 
                                    WebChat Template
                                </Typography>
                                {webchatTemplateFormMode === ADMIN_FORM_MODES.edit && (<Typography
                                    size="small"
                                    variant="text"
                                    sx={{
                                        ...styleEditButton,
                                        marginLeft: "408px",
                                        marginTop: "5px",
                                        position: "absolute",
                                        cursor: "pointer"
                                    }}
                                    onClick={onClickDiscardButton}
                                >
                                    Discard
                                </Typography>)}
                            </div>
                        </div>
                    }
                    onClose={closeWebchatTemplateForm}
                    onEdit={EditWebChatTemplateForm}
                    hideEditIcon={newAuthSubAdminAccess(NewAdminPages.TEMPLATES_PAGE, 'View')}
                    mode={webchatTemplateFormMode}>
                    {webchatTemplateFormMode !== ADMIN_FORM_MODES.edit && <WebChatView data={props.data} />}
                    {webchatTemplateFormMode === ADMIN_FORM_MODES.edit && <DataForm data={props.data} />}
                </OZDrawer>
            )}
        </div>
    );
};
export default WebChatTemplateForm;
